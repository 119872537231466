import { Box } from '@mui/material';
import CurrencyInput from 'react-currency-input-field';
import { Label, TextHelper } from './styles';

const CustomCurrencyInput = (props: any) => {
  const {
    field,
    values,
    setFieldValue,
    disabled,
    label,
    placeholder,
    value,
    maxLength,
    decimalsLimit,
    error,
    required,
  } = props;

  return (
    <Box>
      <Label disabled={disabled} errorHelper={error}>
        <span style={{ color: error && !disabled ? '#ff1744' : '' }}>{`${label} ${
          required && !disabled ? '*' : ''
        }`}</span>
        <CurrencyInput
          name={field}
          id={field}
          placeholder={placeholder || '0.00'}
          prefix="$ "
          defaultValue={value ?? values[field]}
          value={value ?? values[field]}
          allowNegativeValue={false}
          maxLength={maxLength ?? 8}
          decimalsLimit={decimalsLimit ?? 2}
          groupSeparator=","
          decimalSeparator="."
          onValueChange={(val) => setFieldValue(`${field}`, val || '')}
          disabled={disabled}
        />
      </Label>
      {error && !disabled && <TextHelper>{error}</TextHelper>}
    </Box>
  );
};

export default CustomCurrencyInput;
