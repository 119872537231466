import { useTranslation } from 'react-i18next';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';

const useDocumentsStates = () => {
  const { t } = useTranslation();

  const getDocumentsStatesMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.description,
          value: item.value,
          query: 'isPublic',
        } as FilterByColumn),
    );
    return res;
  };

  const documentsStateObj = [
    {
      id: 1,
      description: t('documents_list_is_public_column_public', { ns: 'documents' }),
      value: 'true',
    },
    {
      id: 2,
      description: t('documents_list_is_public_column_targeted', { ns: 'documents' }),
      value: 'false',
    },
  ];

  const documentsStates = getDocumentsStatesMapper(documentsStateObj);

  return { documentsStates };
};

export default useDocumentsStates;
