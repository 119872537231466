import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { AttendantColumn } from './types';
import { ActionColumn, ActionHeader } from '@/src/components/custom-data-table/types/DataTableFormModal';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import AttendantFormContainer from './form/AttendantFormContainer';

export const useColumns = () => {
  const { t } = useTranslation();

  const columns: CustomDataTableColumnsProps<AttendantColumn>[] = [
    {
      id: 'id',
      name: 'id',
      selector: (row: AttendantColumn) => row.id,
      sortField: 'id',
      sortable: true,
      right: true,
      maxWidth: '1%',
      filters: [
        {
          id: 'id',
          query: 'AttendantId',
          type: 'numeric',
        },
      ],
    },
    {
      id: 'fullName',
      name: t('parking_attendant_page_column_full_name', { ns: 'parking' }),
      selector: (row: AttendantColumn) => `${row.name} ${row.surname}`,
      sortField: 'fullName',
      sortable: true,
      filters: [
        {
          id: 'customer-full-name',
          query: 'FullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'email',
      name: 'Email',
      selector: (row: AttendantColumn) => row.email || '-',
      sortField: 'email',
      sortable: true,
      filters: [
        {
          id: 'customer-full-email',
          query: 'Email',
          type: 'contains',
        },
      ],
    },
    {
      id: 'full_phone_number',
      name: t('parking_attendant_page_column_phone', { ns: 'parking' }),
      selector: (row: AttendantColumn) => row.full_phone_number,
      sortField: 'full_phone_number',
      sortable: true,
      filters: [
        {
          id: 'customer-full-phoneNumber',
          query: 'FullPhoneNumber',
          type: 'contains',
        },
      ],
    },
  ];

  const headerActions: ActionHeader[] = [
    {
      id: 'availabilities-create',
      component: () => (
        <CustomTableAction
          modalTitle="attendants"
          component={(props: any) => <AttendantFormContainer {...props} />}
          Icon={() => <AddIcon />}
          type="create"
          width={{ xs: '90%', sm: '550px' }}
        />
      ),
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'edit',
      icon: (props: any) => <EditIcon sx={props.sx} />,
      component: (props: any) => <AttendantFormContainer {...props} isEdit />,
      width: { xs: '90%', sm: '550px' },
    },
  ];

  return { columns, headerActions, actionColumns };
};
