import { AccountStateSection } from './account-state-section/AccountStateSection';
import ResidentsDetailSection from './residents-detail-section';
import { ResidentsSection } from './residents-section/ResidentsSection';
import { useResidentsPage } from './useResidentsPage';

export const ResidentsPage = () => {
  const { isAccountStateOpen, isDetailOpen, openSubGrid, closeSubGrid, selectedRow } = useResidentsPage();

  if (isAccountStateOpen && selectedRow)
    return <AccountStateSection closeSubGrid={closeSubGrid} selectedRow={selectedRow} />;
  if (isDetailOpen && selectedRow)
    return <ResidentsDetailSection closeSubGrid={closeSubGrid} selectedRow={selectedRow} />;
  return <ResidentsSection openSubGrid={openSubGrid} />;
};
