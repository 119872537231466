import React from 'react';
import { Button, Divider, Menu, MenuList } from '@mui/material';
import { FilterByColumn, FilterByColumnType } from '../../types/CustomDataTableColumnProps';
import St from './FilterColumnIcon.styled';
import FilterColumnDate from './filter-column-date';
import FilterColumnHour from './filter-hour';
import FilterCheckbox from './filter-checkbox';
import FilterContains from './filter-contains';
import FilterBoolean from './filter-boolean';
import FilterSort from './filter-sort';
import FilterSearch from './filter-search';
import FilterMinMax from './filter-min-max';

const FilterColumnIcon = (props: FilterColumnIconProps) => {
  const {
    name,
    open,
    order,
    filters,
    sortable,
    anchorEl,
    isDotActive,
    localFilterString,
    handleClick,
    handleClose,
    setOrder,
    handleClearFilters,
    handleSubmitFilters,
    setLocalFilterString,
    center = false,
    right = false,
  } = props;

  return (
    <>
      <St.Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          // eslint-disable-next-line no-nested-ternary
          justifyContent: center ? 'center' : right ? 'flex-end' : 'flex-start',
        }}
      >
        {isDotActive && <St.Dot />}
        {name}
        {open ? <St.OpenMenu /> : <St.ClosedMenu />}
      </St.Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ minWidth: '50px', height: '500px' }}
      >
        <St.Box filters={Array.isArray(filters) && filters.length > 10}>
          <Button onClick={handleClearFilters}>Clear</Button>
          <Button onClick={handleSubmitFilters}>Apply</Button>
        </St.Box>
        <MenuList
          dense
          sx={{
            overflow: 'hidden',
            backgroundColor: 'scroll',
            marginTop: Array.isArray(filters) && filters.length > 10 ? '30px' : '-8px',
          }}
        >
          {/* {sortable && <FilterSort order={order} setOrder={setOrder} />} */}
          {sortable && <FilterSort order={order} setOrder={setOrder} />}
          {filters && <Divider sx={{ marginBottom: '5px' }} />}
          {filters &&
            filters.map((f) => {
              const filterProps = {
                setLocalFilterString,
                localFilterString,
                filter: f,
                key: f.id,
              };
              switch (f.type as FilterByColumnType) {
                case 'date':
                  return <FilterColumnDate {...filterProps} />;
                case 'default':
                  return <FilterCheckbox {...filterProps} />;
                case 'contains':
                  return <FilterContains {...filterProps} />;
                case 'numeric':
                  return <FilterContains isNumeric {...filterProps} />;
                case 'boolean':
                  return <FilterBoolean {...filterProps} />;
                case 'time':
                  return <FilterColumnHour {...filterProps} />;
                case 'search':
                  return <FilterSearch {...filterProps} />;
                case 'min-max':
                  return <FilterMinMax {...filterProps} />;
                default:
                  return <FilterCheckbox {...filterProps} />;
              }
            })}
        </MenuList>
      </Menu>
    </>
  );
};

interface FilterColumnIconProps {
  name: string;
  isDotActive: boolean;
  filters: FilterByColumn[] | undefined;
  sortable?: boolean | null | undefined;
  anchorEl: null | HTMLElement;
  open: boolean;
  order: 'asc' | 'desc' | null;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  handleSubmitFilters: () => void;
  handleClearFilters: () => void;
  localFilterString: string;
  setLocalFilterString: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc' | null>>;
  center?: boolean;
  right?: boolean;
}

export default FilterColumnIcon;
