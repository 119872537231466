import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

export const AdministratorsPage = () => {
  const { columns, actionColumns, headerActions } = useColumns();
  const { t } = useTranslation();

  return (
    <CustomDataTable
      title={t('administrators')}
      columns={columns}
      resource="admins"
      pathApi="accounts/v1/admins"
      listName="admins"
      canDisable
      canExport
      canSearch
      defaultSortFieldId="name"
      defaultSortAsc
      extraActionsInHeader={headerActions}
      customActionColumns={actionColumns}
    />
  );
};
