import { Checkbox, MenuItem } from '@mui/material';
import React from 'react';
import St from '../FilterColumnIcon.styled';
import { FilterByColumn, FilterByColumnValue } from '../../../types/CustomDataTableColumnProps';

const FilterCheckbox = (props: FilterCheckboxProps) => {
  const { filter, getCheckedValue, handleFilters, validateFilterIfArray } = props;
  return (
    <MenuItem onChange={() => handleFilters(filter.query, validateFilterIfArray(filter.value))}>
      <St.CheckboxLabel
        checked={getCheckedValue(filter.query, validateFilterIfArray(filter.value))}
        value={filter.value}
        control={<Checkbox size="small" />}
        label={!Array.isArray(filter.label) ? filter.label : filter.label[0]}
      />
    </MenuItem>
  );
};

interface FilterCheckboxProps {
  filter: FilterByColumn;
  handleFilters: (query: string, value: string | number | boolean, type?: 'date' | 'default') => void;
  getCheckedValue: (query: string, value: string | number | boolean) => boolean;
  validateFilterIfArray: (value?: FilterByColumnValue) => string | number | boolean;
}

export default FilterCheckbox;
