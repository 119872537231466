import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import { Button, FormHelperText, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PhoneSelect from '@/src/components/phone-select/PhoneSelect';
import CustomInput from '@/src/components/forms/CustomInput';
import { useRegistryBreakpoints } from '../registry/hooks/useRegistryBreakpoints';
import { Header, HeaderContainer } from '../registry/style';
import { InputProps, RegistryProps } from './types';
import getEnvVariables from '@/src/utilities/helpers/getEnvVariables';
import { capitalizeFirstLetter } from '@/src/utilities/helpers/stringsHelper';
import {
  BoxContentSubtitle,
  TypographySubTitle1,
  TypographySubTitle2,
  TypographySubTitle3,
  TypographyTitle,
} from '../registry/Registry.styled';
// #A78C5D

export const RegistryForm = ({
  inputFields,
  values,
  setFieldValue,
  errors,
  handleOpen,
  dateFull,
  dateFrom,
  dateTo,
}: RegistryProps) => {
  const { t } = useTranslation();
  const { VITE_TENANT_NAME } = getEnvVariables();
  const { selfiBtnStyles } = useRegistryBreakpoints();
  return (
    <>
      <HeaderContainer>
        <Header>
          <TypographyTitle>
            {t('guest_invitation_title', { name: capitalizeFirstLetter(VITE_TENANT_NAME), ns: 'guests' })}
          </TypographyTitle>
          {dateFrom && dateTo && (
            <TypographySubTitle1>
              {dateFrom === dateTo ? `${t('guest_invitation_sub_p1', { ns: 'guests' })} ${dateFrom}` : dateFull}
            </TypographySubTitle1>
          )}
          <BoxContentSubtitle>
            <TypographySubTitle2>{t('guest_invitation_sub_p2', { ns: 'guests' })}</TypographySubTitle2>
            <TypographySubTitle3>{t('guest_invitation_sub_p3', { ns: 'guests' })}</TypographySubTitle3>
          </BoxContentSubtitle>
        </Header>
      </HeaderContainer>
      <Grid container item spacing={1}>
        {inputFields.map((info: InputProps) => (
          <Grid key={info.id} xs={info.xs} md={info.md} lg={info.lg} item>
            {info.field === 'phone_number' ? (
              <PhoneSelect errors={errors} values={values} setFieldValue={setFieldValue} />
            ) : (
              <CustomInput
                inputType={info.type}
                field={info.field}
                errors={errors}
                label={info.label}
                setFieldValue={setFieldValue}
                values={values}
                regex={info.regex}
                maxLength={info.maxLength}
              />
            )}
          </Grid>
        ))}
      </Grid>
      <Grid xs={12} item>
        <Button
          variant="outlined"
          sx={{ padding: '8px 0' }}
          color="secondary"
          fullWidth
          type="button"
          onClick={handleOpen}
        >
          <CameraAltRoundedIcon fontSize="medium" sx={{ mr: 2, color: 'secondary.main' }} />
          <Typography sx={selfiBtnStyles}>
            {values.image
              ? t('guest_invitation_picture_uploaded', { ns: 'guests' })
              : t('guest_invitation_cam', { ns: 'guests' })}
          </Typography>
        </Button>
        {errors.image && (
          <FormHelperText error sx={{ margin: '4px 14px 0px' }}>
            {t('required_field', { ns: 'errors' })}
          </FormHelperText>
        )}
      </Grid>
      <Grid xs={12} item>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          fullWidth
          sx={{ textTransform: 'uppercase', padding: '8px 0' }}
        >
          {t('guest_invitation_submit', { ns: 'guests' })}
        </Button>
      </Grid>
    </>
  );
};
