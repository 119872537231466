import { styled, TextareaAutosize } from '@mui/material';
import { grey } from '@mui/material/colors';

export const StyledTextArea = styled(TextareaAutosize)(({ disabled }: { disabled?: any }) => ({
  boxizing: 'border-box',
  borderRadius: '4px',
  padding: '4px',
  backgroundColor: 'transparent',
  resize: 'none',
  width: '100%',
  height: '40px',
  borderColor: '2px solid rgba(0,0,0,0.23)',
  fontFamily: 'sans-serif',
  font: 'inherit',
  letterSpacing: 'inherit',
  '&:focus': {
    borderColor: grey[900],
  },
  '&:hover': {
    borderColor: disabled ? '' : grey[900],
  },
}));
