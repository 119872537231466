import { useQuery } from '@tanstack/react-query';
import { getPriceRange } from '@/src/api/endpoints/interested';
import { PriceRange } from '@/src/utilities/helpers/commonTypes';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';

const usePriceFilters = () => {
  const getPriceRangeMapper = (data: Array<PriceRange>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: `$${item.min_price} - $${item.max_price}`,
          value: item.id,
          query: 'PriceRange',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: priceRange, isLoading } = useQuery(['getPriceRange'], getPriceRange, {
    select: (response) => getPriceRangeMapper(response?.data?.price_ranges as Array<PriceRange>),
  });

  return { priceRange, isLoading };
};

export default usePriceFilters;
