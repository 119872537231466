import { Box, Card, CardContent, CardHeader, IconButton, Tooltip, Typography } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BackHandIcon from '@mui/icons-material/BackHand';
import { getMonthDayYearValue } from '@/src/utilities/helpers/dateParser';
import CustomLoader from '@/src/components/custom-loader';
import { FadeIn } from '@/src/components/animations';
import { LoaderContainer } from '@/src/components/loader-container/LoaderContainer';
import RenderCommunityImages from '../render-images/RenderImages';
import CustomCardMedia from '@/src/components/custom-card-media/CustomCardMedia';
import { getTenant } from '@/src/utilities/helpers/utils';
import { deleteArticle } from '@/src/api/endpoints/community';
import { useGetArticlesById } from '../fetchHelperCommunity';
import { CardAvatar } from '../community-card/CommunityCard.styles';
import CommunityModal from '../community-modal/CommunityModal';
import RestrictModal from './restrictModal/RestrictModal';
import { CardContainer } from './CommunityDetail.styles';

const CommunityDetail = ({ idSelected, setIdSelected, setSnackBarMessage }: any) => {
  const queryClient = useQueryClient();
  const [openEditInformation, setOpenEditInformation] = useState(false);
  const [restrictModal, setRestrictModal] = useState(false);
  const { t } = useTranslation();
  const { dataById, isLoading, refetch } = useGetArticlesById(idSelected);
  const { mutate: deleteMutation, status } = useMutation(deleteArticle, {
    onError: async () => {
      setSnackBarMessage(t('uncatch_error', { ns: 'errors' }), 'error');
    },
    onSuccess: async () => {
      queryClient.resetQueries(['posts']);
      setIdSelected(null);
      setSnackBarMessage(t('successful_post_deletion_snackbar_text', { ns: 'community' }));
    },
  });

  const onCloseArticleSelected = () => {
    setIdSelected(null);
  };

  return (
    <>
      <Box sx={{ paddingRight: '20px', overflow: 'auto', ml: '5px' }}>
        {isLoading ? (
          <LoaderContainer>
            <CustomLoader />
          </LoaderContainer>
        ) : (
          <FadeIn>
            <Card sx={{ borderRadius: '5px' }}>
              <CardHeader
                avatar={<CardAvatar aria-label="card-label">{getTenant()}</CardAvatar>}
                title={`${dataById?.post?.title ? dataById?.post?.title : t('article', { ns: 'community' })} - ${
                  dataById?.post?.creation_user?.name
                } ${dataById?.post?.creation_user?.surname}`}
                titleTypographyProps={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                subheader={getMonthDayYearValue(new Date(dataById?.post?.creation_date ?? Date.now()).toString())}
                action={
                  <Box sx={{ width: 'max-content' }}>
                    {!dataById?.post?.is_restricted && (
                      <Tooltip title={t('community_restrict_post', { ns: 'community' })}>
                        <IconButton sx={{ color: 'secondary.main' }} onClick={() => setRestrictModal(true)}>
                          <BackHandIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                    <RestrictModal
                      title={t('community_restrict_post', { ns: 'community' })}
                      contentText={t('community_restrict_post_text', { ns: 'community' })}
                      open={restrictModal}
                      setOpen={setRestrictModal}
                      idSelected={idSelected}
                    />
                    <Tooltip title={t('close')}>
                      <IconButton
                        onClick={onCloseArticleSelected}
                        aria-label="settings"
                        sx={{ color: 'secondary.main' }}
                      >
                        <ClearRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                }
              />
              <CardContainer hasFiles={dataById?.post?.files.length > 0}>
                <Typography
                  variant="subtitle1"
                  sx={{ padding: '8px 0 ', fontSize: '1.10rem', color: 'rgba(0, 0, 0, 0.6)', marginTop: '20px' }}
                >
                  {`${dataById?.post?.description.substring(0, 50)}${
                    dataById?.post?.description.length > 50 ? '...' : ''
                  }`}
                </Typography>
                {dataById?.post?.files[0]?.url && (
                  <CustomCardMedia src={dataById?.post?.files[0]?.url ?? ''} alt="cover-image" height="250px" />
                )}
                <RenderCommunityImages data={dataById?.post?.files} />
                {dataById?.post?.files.length === 0 && (
                  <Typography
                    variant="subtitle1"
                    sx={{ padding: '8px 0 ', fontSize: '1.10rem', color: 'rgba(0, 0, 0, 0.6)' }}
                  >
                    {t('community_images_not_found', { ns: 'community' })}
                  </Typography>
                )}
                {dataById?.post?.is_restricted && (
                  <Typography variant="subtitle1" sx={{ padding: '8px 0 ', fontSize: '1.10rem' }}>
                    {t('community_post_is_restricted', { ns: 'community' })}
                  </Typography>
                )}
              </CardContainer>
            </Card>
          </FadeIn>
        )}
      </Box>
      {openEditInformation && (
        <CommunityModal
          dataById={dataById}
          refetchDataById={refetch}
          openModal={openEditInformation}
          setOpenModal={setOpenEditInformation}
          setSnackBarMessage={setSnackBarMessage}
        />
      )}
    </>
  );
};

export default CommunityDetail;
