export const handleDownloadDocument = (link: string) => {
  const element = document.createElement('a');
  element.href = link;
  const downloadPdf = link.split('/').reverse()[0];
  element.download = downloadPdf;
  element.target = '_blank';
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
