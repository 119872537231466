import { FormikValues, useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { EditResident } from './EditResident';
import { personSelected, editPersonSelected, getStates } from '@/src/api/endpoints/residents';
import { CustomDataTablePropsPeople, PeopleState, PrevValuesProps } from '../types';

export const EditResidentContainer = ({ row, close, setSnackBarMessage }: CustomDataTablePropsPeople) => {
  const [prevValues, setPrevValues] = useState<PrevValuesProps | any>({});
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: updateSelectedMutate, isLoading: isLoadingupdatePersonSelected } = useMutation(editPersonSelected);
  const { data: queryResponse, isLoading: isLoadingStates } = useQuery(['getPeopleStates'], () => getStates());
  const userStates: Array<PeopleState> = queryResponse?.data?.residency_states;

  const { isLoading: isLoadingPersonSelected, data: personSelectedData } = useQuery(
    ['onPersonSelectedMutate'],
    () => personSelected(row.id),
    {
      select: (data) => data?.data?.property_resident,
      onSuccess: ({ person }) => {
        const {
          country_code: countryCode,
          country_iso_code: countryIsoCode,
          email,
          name,
          phone_number: phoneNumber,
          surname,
        } = person;
        setPrevValues({
          countryCode: countryCode || '+549',
          countryIsoCode: countryIsoCode || '',
          email: email || '',
          from: person.from || '',
          name: name || '',
          phoneNumber: phoneNumber || '',
          surname: surname || '',
          to: person.from || '',
        });
      },
    },
  );

  const memoizedResetQueries = useMemo(() => queryClient.resetQueries(['onPersonSelectedMutate']), []);
  useEffect(() => {
    memoizedResetQueries;
  }, []);

  // FORMIK DECLATARION
  const getInitialValues = () => ({
    name: personSelectedData?.person.name ?? '',
    surname: personSelectedData?.person.surname ?? '',
    countryCode: personSelectedData?.person.country_code ?? '+549',
    countryIsoCode: personSelectedData?.person.country_iso_code ?? '',
    datePickerDisabled: false,
    email: personSelectedData?.person.email ?? '',
    from: personSelectedData?.residency?.from ?? '',
    functional_unit: personSelectedData?.property.code ?? '',
    isReadOnly: false,
    phoneNumber: personSelectedData?.person.phone_number ?? '',
    showOtherInputs: true,
    state: personSelectedData?.residency.state.id ?? '',
    to: personSelectedData?.residency.to ?? 1640995200000,
    unitDisabled: true,
    has_deposit: personSelectedData?.residency.has_deposit ?? false,
    leaseSigner: personSelectedData?.person.is_lease_signer ?? false,
    deposit_amount: personSelectedData?.residency?.deposit_amount ?? '',
    residencyType: personSelectedData?.residency?.type ?? null,
    // residencyId:personSelectedDataresidency.id -> Id de residencia
    // residencyStat:personSelectedDataresidency.state -> Id de residencia
  });

  const getValidationSchema = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        countryIsoCode: Yup.string().required(t('required_field', { ns: 'errors' })),
        countryCode: Yup.string().required(t('required_field', { ns: 'errors' })),
        email: Yup.string()
          .required(t('required_field', { ns: 'errors' }))
          .trim(),
        from: Yup.number()
          .required(t('required_field', { ns: 'errors' }))
          .typeError('Enter a valid date'),
        functional_unit: Yup.string().required(t('required_field', { ns: 'errors' })),
        name: Yup.string().required(t('required_field', { ns: 'errors' })),
        phoneNumber: Yup.string().required(t('required_field', { ns: 'errors' })),
        surname: Yup.string().required(t('required_field', { ns: 'errors' })),
        state: Yup.number().required(t('required_field', { ns: 'errors' })),
        to: Yup.number()
          .required(t('required_field', { ns: 'errors' }))
          .typeError('Enter a valid date'),
        residencyType: Yup.object()
          .shape({
            id: Yup.number(),
            code: Yup.string(),
            name: Yup.string(),
          })
          .nullable()
          .required(t('required_field', { ns: 'errors' })),
      }),
    );

  const onSubmit = (values: FormikValues) => {
    const objKeys = Object.keys(prevValues);
    const validator = objKeys.map((prop) => values[prop] === prevValues[prop]);
    const hasChange = validator.filter((response: boolean) => !response);

    let depositAmount: number | null = 0;
    if (values.has_deposit && values.deposit_amount !== '') {
      if (depositAmount === 0) {
        depositAmount = null;
      } else {
        depositAmount = parseFloat(values.deposit_amount);
      }
    }

    const valuesToUpdate = {
      // name: values.name,
      // surname: values.surname,
      // country_code: values.countryCode,
      // country_iso_code: values.countryIsoCode,
      // phone_number: values.phoneNumber,
      from: values.from,
      to: values.to,
      // email: values.email.trim(),
      residency_state_id: values.state,
      has_deposit: values.has_deposit,
      deposit_amount: Number(values.deposit_amount),
      residency_type_id: values.residencyType.id,
    };

    if (hasChange.length > 0) {
      const info = {
        id: personSelectedData?.residency?.id,
        values: valuesToUpdate,
      };
      updateSelectedMutate(info, {
        onSuccess: () => {
          setSnackBarMessage(t('success'));
          close();
        },
        onError: ({ response }: any) => {
          setSnackBarMessage(t(response.data.error_messages[0].code, { ns: 'errors' }), 'error');
        },
      });
    }

    if (hasChange.length === 0) {
      setSnackBarMessage(t('SameValues', { ns: 'errors' }), 'error');
    }
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema,
  });

  const handleClose = () => {
    close();
  };

  const childProps = {
    userStates,
    isLoadingStates,
    errors,
    handleClose,
    handleSubmit,
    isLoading: isLoadingPersonSelected || isLoadingupdatePersonSelected,
    setFieldValue,
    values,
  };
  return <EditResident {...childProps} />;
};

export default EditResidentContainer;
