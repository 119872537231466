import React from 'react';
import { useTranslation } from 'react-i18next';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { VisibilityRounded } from '@mui/icons-material';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { ActionColumn, ActionHeader } from '@/src/components/custom-data-table/types/DataTableFormModal';
import { ChargesRow, PaymentRow } from './types';
import { paymentsStates } from '../../charges/payments/types';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import { PeopleRowProps } from '../residents-section/types';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import ChargeForm from '../../charges/list/charge-form';
import ChargeInfo from '../../charges/list/charge-info';
import PaymentDetail from '@/src/pages/charges/payments/paymentDetail';
import DeleteComponent from '../../charges/list/delete-charge';
import TextLabel from '@/src/components/text-label/TextLabel';
import useAccountBalance from './hooks/useAccountBalance';
import usePaymentStatesFilters from '@/src/hooks/filters/paymentStates.hooks';
import useConceptsFilters from '@/src/hooks/filters/concepts.hooks';

export const useColumns = (selectedRow: PeopleRowProps) => {
  const { t } = useTranslation();
  const actualDate = new Date().getTime();
  const lang = getLanguage()?.split('-')[0];

  const queryFilters = React.useMemo(
    () => [{ query: 'residencyId', queryValue: selectedRow.residency.id }],
    [selectedRow.residency.id],
  );
  const isExpired = selectedRow?.residency.has_checked_out || selectedRow.residency.to < actualDate;
  const { balance, isLoading, refetch } = useAccountBalance(selectedRow.residency.id);
  const { paymentStates } = usePaymentStatesFilters();
  const { concepts } = useConceptsFilters();

  const chargesColumns: CustomDataTableColumnsProps<ChargesRow>[] = [
    {
      id: 'name',
      name: t('resident', { ns: 'residents' }),
      selector: (row: ChargesRow) => `${row.property_resident.person.name} ${row.property_resident.person.surname}`,
      sortable: false,
      filters: [
        {
          id: 'property-resident-full-name',
          query: 'PropertyResidentFullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'concept',
      name: t('concept'),
      selector: (row: ChargesRow) => row.charge_concept.name,
      sortable: true,
      sortField: 'concept_name',
      filters: concepts,
    },
    {
      id: 'quantity',
      name: t('quantity'),
      selector: (row: ChargesRow) => row.quantity || '-',
      sortable: true,
      sortField: 'quantity',
      center: true,
      filters: [
        {
          id: 'quantity',
          query: 'Quantity',
          type: 'min-max',
        },
      ],
    },
    {
      id: 'amount',
      name: t('amount'),
      selector: (row: ChargesRow) => row.amount * (row.quantity || 1),
      sortField: 'amount',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'amount',
          query: 'Amount',
          type: 'min-max',
        },
      ],
    },
    {
      id: 'creation_date',
      name: t('creation_date'),
      selector: (row: ChargesRow) =>
        row?.creation_date
          ? `${getFormattedDateByLanguague(
              lang,
              getFormattedDateFullYearEs,
              getFormattedDateFullYearEn,
              row.creation_date,
            )}`
          : '-',
      sortField: 'creation_date',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'creation-date',
          query: 'CreationDate',
          type: 'date',
        },
      ],
    },
  ];

  const paymentsColumns: CustomDataTableColumnsProps<PaymentRow>[] = [
    {
      id: 'creation_date',
      name: t('creation_date'),
      selector: (row: PaymentRow) =>
        row?.creation_date
          ? `${getFormattedDateByLanguague(
              lang,
              getFormattedDateFullYearEs,
              getFormattedDateFullYearEn,
              row.creation_date,
            )}`
          : '-',
      sortField: 'creation_date',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'creation-date',
          query: 'CreationDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'total_amount',
      name: t('amount'),
      selector: (row: PaymentRow) => row.amount,
      sortField: 'total_amount',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'amount',
          query: 'Amount',
          type: 'min-max',
        },
      ],
    },
    {
      id: 'payment_state',
      name: t('status', { ns: 'charges' }),
      cell: (row: PaymentRow) => (
        <TextLabel
          text={t(`${row?.payment_state?.name.toLocaleUpperCase()}`, { ns: 'charges' })}
          color="#F7F7F7"
          padding="2px 12px"
          width="100px"
          bg={paymentsStates[row?.payment_state?.code]}
        />
      ),
      sortField: 'payment_state',
      sortable: true,
      center: true,
      filters: paymentStates,
    },
  ];

  const chargesActionColumns: ActionColumn[] = [
    {
      id: 'detail',
      icon: (props: any) => <VisibilityRounded sx={props.sx} />,
      component: (props: any) => <ChargeInfo {...props} chargeId={props.row.id} />,
      width: { xs: '90%', sm: '500px', lg: '700px' },
    },
    {
      id: 'edit',
      icon: (props: any) => <ModeEditIcon sx={props.sx} />,
      component: (props: any) => <ChargeForm {...props} isEditMode refetch={refetch} />,
      width: { xs: '90%', lg: '70%', xl: '55%' },
      isHidden: (props: any) => props.is_payed || isExpired,
    },
    {
      id: 'delete_btn',
      icon: (props: any) => <DeleteRoundedIcon sx={props.sx} />,
      component: (props: any) => <DeleteComponent {...props} />,
      width: '23%',
      isHidden: (props: any) => props.is_payed || isExpired,
    },
  ];

  const paymentsActionColumns: ActionColumn[] = [
    {
      id: 'detail',
      customizedTitle: true,
      icon: (props: any) => <VisibilityRounded sx={props.sx} />,
      component: (props: any) => <PaymentDetail {...props} PaymentId={props.row.id} />,
      width: { xs: '100%', sm: '500px', lg: '800px' },
    },
  ];

  const chargesHeaderColumns: ActionHeader[] = React.useMemo(() => {
    if (!isExpired) {
      return [
        {
          id: 'create',
          component: () => (
            <CustomTableAction
              modalTitle="charge"
              component={(props: any) => <ChargeForm {...props} resident={selectedRow} refetch={refetch} />}
              Icon={() => <AddIcon />}
              type="create"
              width={{ xs: '90%', lg: '50%', xl: '55%' }}
            />
          ),
        },
      ];
    }
    return [];
  }, [isExpired]);

  return {
    chargesHeaderColumns,
    chargesActionColumns,
    paymentsActionColumns,
    chargesColumns,
    paymentsColumns,
    queryFilters,
    balance,
    isLoading,
    refetch,
  };
};
