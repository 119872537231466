import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface InputProps {
  id: number;
  field: string;
  label: string;
  xs: number;
  md: number;
  lg: number;
}

export type ConfirmationProps = {
  requestResponse: boolean;
};

export const Confirmation = ({ requestResponse }: ConfirmationProps) => {
  const { t } = useTranslation();
  return (
    <Grid xs={12} item>
      <Typography
        variant="h6"
        component="h2"
        sx={{
          fontSize: '32px',
          color: `${requestResponse ? 'secondary.main' : '#ff1744'} `,
          fontFamily: 'Sorts Mill Goudy,serif',
        }}
      >
        {requestResponse
          ? t('guest_invitation_success_header', { ns: 'guests' })
          : t('guest_invitation_error', { ns: 'guests' })}
      </Typography>
      {requestResponse ? (
        <Typography
          variant="body1"
          component="div"
          sx={{ fontSize: '16px', fontFamily: 'Avenir Next !important', marginBottom: '16px' }}
        >
          {t('guest_invitation_success_body', { ns: 'guests' })}
        </Typography>
      ) : null}
    </Grid>
  );
};
