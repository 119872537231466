import React from 'react';
import { t } from 'i18next';
import CustomDateTimePicker from './DateTimePicker';
import { SXBreakpoint } from '@/src/utilities/helpers/commonTypes';
import { getLanguage } from '@/src/utilities/storage';

const CustomDateTimePickerContainer = (props: CustomDatePickerContainerProps) => {
  const { value } = props;
  const locale = new Date(value ?? new Date().getTime());

  const lang = getLanguage()?.split('-')[0];

  const childProps = {
    ...props,
    t,
    lang,
    locale,
  };

  return <CustomDateTimePicker {...childProps} />;
};

CustomDateTimePickerContainer.defaultProps = {
  disabled: false,
  required: false,
  placeholder: t('select_date', { ns: 'date' }),
};

interface CustomDatePickerContainerProps {
  disabled: boolean;
  value: Date | number;
  placeholder: string;
  required: boolean;
  minDate?: number | Date;
  maxDate?: number | Date;
  width?: SXBreakpoint<string | number>;
  minWidth?: SXBreakpoint<string | number>;
  fieldValue?: string;
  errorMessage?: string | string[];
  onChange: (value: number | Date | null, keyboardInputValue?: string | undefined) => void;
}

export default CustomDateTimePickerContainer;
