import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { tRequiredFieldError } from '@/src/utilities/helpers/appConstants';
import CustomInput from '@/src/components/forms/CustomInput';
import { RestrictArticle } from '@/src/api/endpoints/community';

interface RestrictDialogProp {
  title: string;
  contentText: string;
  open: boolean;
  setOpen: (state: boolean) => void;
  idSelected: number;
}

const getInitialValues = (idSelected: number) => ({
  id: idSelected,
  restriction_reason: '',
});

const getValidationSchema = () =>
  Yup.object().shape({
    restriction_reason: Yup.string().required(tRequiredFieldError),
  });

const RestrictModal = ({ title, contentText, open, setOpen, idSelected }: RestrictDialogProp) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<{ code: string; message: string; property_name: string } | null>(
    null,
  );
  const { mutate, isLoading } = useMutation(RestrictArticle, {
    onError: async (e: any) => {
      setErrorMessage(e.response.data.error_messages[0]);
    },
    onSuccess: async () => {},
  });

  const onSubmit = (data: FormikValues) => {
    const payload = {
      id: data.id,
      body: {
        restriction_reason: data.restriction_reason,
      },
    };
    mutate(payload);
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(idSelected),
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema: getValidationSchema(),
    onSubmit,
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box component="form" onSubmit={handleSubmit} sx={{ marginY: '5px' }}>
        <DialogContent>
          <CustomInput
            field="restriction_reason"
            inputType="string"
            errors={errors}
            label={t('community_form_reason_field_label', { ns: 'community' })}
            placeholder={t('community_form_reason_field_placeholder', { ns: 'community' })}
            setFieldValue={setFieldValue}
            values={values}
            maxLength={50}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" size="small" onClick={handleClose}>
            {t('delete_dialog_cancel_button_text')}
          </Button>
          <LoadingButton variant="contained" size="small" type="submit" sx={{ width: { xs: '100%', sm: 'auto' } }}>
            {t('form_button_submit_text', { ns: 'users' })}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default RestrictModal;
