import React from 'react';

import { useTranslation } from 'react-i18next';
import { Visibility, Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';
import { VehiclesDetailModal } from './vehicles-detail/VehiclesDetail';
import VehiclesModal from './vehicles-form';

import VehicleDeleteModal from './vehicles-delete';
import { ActionColumn, CustomIconProps } from '@/src/components/custom-data-table/types/DataTableFormModal';

const actionColumns: Array<ActionColumn> = [
  {
    id: 'detail',
    icon: ({ sx }: CustomIconProps) => <Visibility sx={sx} />,
    component: (props: any) => <VehiclesDetailModal {...props} showButtons={false} />,
    customizedTitle: true,
    width: { xs: '90%', sm: '540px' },
  },
  {
    id: 'edit',
    icon: ({ sx }: CustomIconProps) => <EditIcon sx={sx} />,
    component: (props: any) => <VehiclesModal isEdit {...props} />,
    customizedTitle: true,
    width: { xs: '90%', sm: '540px' },
  },
  {
    id: 'delete_btn',
    icon: ({ sx }: CustomIconProps) => <Delete sx={sx} />,
    component: (props: any) => <VehicleDeleteModal {...props} />,
    width: { xs: '90%', sm: '540px' },
  },
];

export const VehiclesPage = () => {
  const { columnsVehicles } = useColumns();
  const { t } = useTranslation();

  return (
    <CustomDataTable
      title={t('vehicles', { ns: 'customerUsers' })}
      columns={columnsVehicles}
      resource="vehicle_residencies"
      pathApi="vehicles/v1/vehicle-residencies"
      listName="vehicle_residencies"
      extraFilters={[{ query: 'VehicleStateCodes', queryValue: 'Approved' }]}
      canExport
      canSearch
      defaultSortFieldId="vehicle_creation_date"
      customActionColumns={actionColumns}
      defaultSortAsc
    />
  );
};
