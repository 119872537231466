import { axiosAuthenticationInstance } from '../instances/authInstance';
import { AssignInterestedRequest } from '../types/types';

export const getInterested = () => axiosAuthenticationInstance.get(`accounts/v1/potential-customers`);
export const getInterestedById = (id: string) =>
  axiosAuthenticationInstance.get(`accounts/v1/potential-customers/${id}`);
export const assignInterested = (req: AssignInterestedRequest) =>
  axiosAuthenticationInstance.post(`accounts/v1/potential-customers/${req.id}/properties`, req);
export const getInterest = () => axiosAuthenticationInstance.get('/accounts/v1/potential-customers/interests');
export const getPriceRange = () => axiosAuthenticationInstance.get('/accounts/v1/potential-customers/price-ranges');
export const getRoomTypes = () => axiosAuthenticationInstance.get('/accounts/v1/potential-customers/room-types');
