import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CardMedia, IconButton, useMediaQuery, useTheme } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import LinkedCameraRoundedIcon from '@mui/icons-material/LinkedCameraRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Webcam from 'react-webcam';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { SelfiModalProps } from './types';

const videoConstraints = {
  width: 330,
  height: 330,
  facingMode: 'user',
};

export const SelfieModal = ({
  open,
  handleScreenShot,
  handleClose,
  webcamRef,
  imageUrl,
  isPermissionGiven,
  handleChooseShot,
}: SelfiModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className={styles.modalBox} style={{ width: matchesMd ? '90%' : 'auto' }}>
        <Box component="div" sx={{ height: '96%' }}>
          {!isPermissionGiven && (
            <Box component="div" sx={{ textAlign: 'center' }}>
              <Typography>{t('guest_invitation_camera_permission', { ns: 'guests' })}</Typography>
            </Box>
          )}
          {imageUrl ? (
            <CardMedia
              component="img"
              height={330}
              width="100%"
              image={imageUrl}
              alt="user_selfie"
              sx={{ borderRadius: '10px' }}
            />
          ) : (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              style={{ borderRadius: '10px', paddingBottom: '0', width: '100%' }}
              videoConstraints={videoConstraints}
            />
          )}
        </Box>
        <Box component="div" className={styles.modalBoxButtons}>
          <IconButton onClick={handleClose}>
            <CancelRoundedIcon sx={{ color: '#d11212' }} />
          </IconButton>
          {imageUrl ? (
            <IconButton onClick={() => handleChooseShot(imageUrl)}>
              <ArrowForwardIosRoundedIcon sx={{ color: '#1d8508' }} />
            </IconButton>
          ) : (
            <IconButton onClick={handleScreenShot}>
              <LinkedCameraRoundedIcon sx={{ color: '#1d8508' }} />
            </IconButton>
          )}
        </Box>
      </div>
    </Modal>
  );
};
