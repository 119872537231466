import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import NewsForm from './NewsForm';
import { validate } from './NewsForm.validate';
import { createArticle, editArticle, getArticles } from '@/src/api/endpoints/news';
import { ArticleBody } from '../../types';

const actualDate = new Date().getTime();

const NewsFormContainer = ({ dataById, close, refetchDataById, setSnackBarMessage }: any) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const getArticlesData = async () => {
    const response = await getArticles({});
    return response.data;
  };
  const { refetch } = useQuery(['publications'], getArticlesData);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { mutate } = useMutation(createArticle);
  const { mutate: mutateEdit } = useMutation(editArticle);
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const getInitialValues = () => {
    const hasUsersList = dataById?.targets?.roles.length > 0 || dataById?.targets?.users.length > 0;
    const usersList = dataById?.targets?.roles.length > 0 ? dataById?.targets?.roles : dataById?.targets?.users;
    const userType = dataById?.targets?.roles.length > 0 ? 'group' : 'user';

    return {
      title: dataById?.title ?? '',
      subtitle: dataById?.subtitle ?? '',
      start_date: dataById?.date ?? actualDate,
      document: dataById?.document_url ?? null,
      video: dataById?.video_url ?? null,
      video_url_64: null,
      cover_image_url: dataById?.cover_image_url ?? '',
      body: dataById?.body ?? '',
      publication_image_urls: dataById?.publication_images ?? [],
      isEdit: !!dataById,
      editId: dataById?.id ?? null,
      selectedUser: '',
      userSelectedList: hasUsersList ? usersList : [],
      // eslint-disable-next-line no-nested-ternary
      userListTargetType: hasUsersList ? userType : 'group',
      // userListTargetType: 'group',
    };
  };

  const onSubmit = (data: any) => {
    setIsLoading(true);
    const imageUrls: any = [];
    data.publication_image_urls.map((imageUrl: { image_url: string }) => imageUrls.push(imageUrl.image_url));

    const rolesList: any = [];
    const usersList: any = [];

    if (data?.userListTargetType === 'group') {
      data.userSelectedList.map((rol: { value: string }) => rolesList.push(rol?.value));
    } else {
      data.userSelectedList.map((user: { id: number }) => usersList.push(user?.id));
    }

    const body: ArticleBody = {
      title: data.title,
      subtitle: data.subtitle,
      start_date: data.start_date,
      document_url: data.document,
      video_url: data.video || '',
      cover_image_url: data.cover_image_url,
      body: data.body,
      publication_image_urls: imageUrls,
      targets: { roles: rolesList, users: usersList },
    };

    if (!data.isEdit) {
      mutate(body, {
        onSuccess: async () => {
          refetch();
          setSnackBarMessage(t('successful_publication_creation_snackbar_text', { ns: 'news' }));
          close();
        },
        onError: async () => {
          setErrorMessage(t('failed_publication_creation_snackbar_text', { ns: 'news' }));
        },
        onSettled: async () => {
          setIsLoading(false);
        },
      });
    } else {
      mutateEdit(
        { id: dataById.id, body },
        {
          onSuccess: async () => {
            setIsLoading(false);
            refetch();
            refetchDataById(dataById.id);
            setSnackBarMessage(t('successful_publication_edition_snackbar_text', { ns: 'news' }));
            close();
          },
          onError: async () => {
            setIsLoading(false);
            setErrorMessage(t('failed_publication_edition_snackbar_text', { ns: 'news' }));
          },
        },
      );
    }
  };

  const { handleSubmit, values, setFieldValue, errors, setFieldError } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate(),
  });

  const childProps = {
    handleSubmit,
    values,
    setFieldValue,
    setFieldError,
    errors,
    isLoadingSubmit: isLoading,
    errorMessage,
    disableButton,
    setDisableButton,
    t,
    handleChange,
    tabValue,
    a11yProps,
  };

  return <NewsForm {...childProps} />;
};

export default NewsFormContainer;
