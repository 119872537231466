import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthorizations } from '../../hook/useDeliveryEndpoints';
import { DeliveryButtonsProps } from '../../types';

const DeliveryButtons = ({
  values,
  peopleExist,
  setSnackBarMessage,
  close,
  mutateGetPersonIfAuthorized,
  mutateGetPeople,
  setError,
}: DeliveryButtonsProps) => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useAuthorizations();
  const insertNewDelivery = (data: { [key: string]: string }) => {
    mutate(
      {
        carrier_id: data.carrierId,
        carrier_name: data.carrierName,
        property_code: data.propertyCode,
        customer_id: data.customerId,
      },
      {
        onSuccess: () => {
          close();
          setSnackBarMessage(t('success'));
        },
        onError: () => {
          setSnackBarMessage(t('error'), 'error');
        },
      },
    );
  };

  const submitSearchPeople = () => {
    const { carrierId, carrierName, propertyCode } = values;
    if (propertyCode && carrierName) {
      mutateGetPersonIfAuthorized(
        { propertyCode: propertyCode.trim(), carrierId },
        {
          onError: ({ response }: any) => {
            if (response.status === 404) {
              mutateGetPeople(propertyCode, {
                onSuccess: (data: any) => {
                  data?.data.people.length < 1 && setError(t('delivery_modal_no_records_found', { ns: 'delivery' }));
                },
              });
            }
          },
        },
      );
    }
  };
  return (
    <Grid container item xs={12} justifyContent="flex-end">
      {!peopleExist ? (
        <Button
          type="submit"
          onClick={submitSearchPeople}
          variant="contained"
          size="small"
          sx={{ marginLeft: '1%', width: { xs: '100%', sm: 'auto' } }}
        >
          {t('delivery_modal_button_consult', { ns: 'delivery' })}
        </Button>
      ) : (
        <LoadingButton
          loading={isLoading}
          type="button"
          onClick={() => insertNewDelivery(values)}
          variant="contained"
          size="small"
          disabled={!values.customerId && true}
          sx={{ marginLeft: '1%', width: { xs: '100%', sm: 'auto' } }}
        >
          {t('delivery_modal_button_authorize_entry', { ns: 'delivery' })}
        </LoadingButton>
      )}
    </Grid>
  );
};

export default DeliveryButtons;
