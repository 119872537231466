import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { LoadingButton } from '@mui/lab';
import { Box, CardMedia, Grid, IconButton, InputLabel } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomInput from '@/src/components/forms/CustomInput';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { CreateEditActivityProps } from '../../types';
import { GridImage } from './CreateEditAmenity.styled';

const CreateEditAmenity = (props: CreateEditActivityProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    values,
    setFieldValue,
    errors,
    getUrlIn64,
    isLoadingImage,
    isUploadingImage,
    deleteImage,
    wrongImage,
    isLoadingGeneral,
    isCreateEditLoading,
    creationFailed,
  } = props;
  return (
    <Box component="form" onSubmit={handleSubmit}>
      {isLoadingGeneral ? (
        <CircularSpinner />
      ) : (
        <Grid container spacing={1} pt={1}>
          <Grid xs={12} mt={1} item>
            <CustomInput
              field="description"
              errors={errors}
              label={t('name')}
              placeHolder=""
              setFieldValue={setFieldValue}
              values={values}
              maxLength={300}
              required
              autoFocus
            />
          </Grid>
          {values.document_url ? (
            <Grid xs={6} item ml={1} sx={{ margin: '1rem auto 0', minWidth: '40px' }}>
              <Box
                component="div"
                sx={{
                  borderRadius: '20px',
                  position: 'relative',
                }}
              >
                <IconButton
                  onClick={() => deleteImage()}
                  sx={{
                    position: 'absolute',
                    top: -15,
                    right: -26,
                  }}
                >
                  <CancelRoundedIcon />
                </IconButton>
                <CardMedia
                  component="img"
                  height={130}
                  image={values.document_url}
                  alt="images"
                  sx={{ borderRadius: '10px' }}
                />
              </Box>
            </Grid>
          ) : (
            <GridImage xs={12} item ml={1} sm={12} lg={12}>
              {isLoadingImage ? (
                <CircularSpinner />
              ) : (
                <InputLabel htmlFor="imageHeader" style={{ margin: 'auto' }}>
                  <IconButton>
                    <InputLabel htmlFor="imageHeader">
                      <input
                        id="imageHeader"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => getUrlIn64(e)}
                        style={{ display: 'none' }}
                        type="file"
                        accept=".jpg, .jpeg, .HEIC, .png, .svg"
                      />
                      <AddPhotoAlternateRoundedIcon fontSize="large" />
                    </InputLabel>
                  </IconButton>
                </InputLabel>
              )}
              <ErrorText error={(wrongImage?.code as string) || (errors.document_url as string)} />
            </GridImage>
          )}
          <Grid container spacing={1} sx={{ paddingTop: '10px', placeContent: 'flex-end' }}>
            <LoadingButton
              loading={isCreateEditLoading}
              variant="contained"
              type="submit"
              disabled={isUploadingImage}
              sx={{ width: { xs: 'calc(100% - 16px)', sm: 'auto' }, mt: 1 }}
            >
              {values.formButtonText}
            </LoadingButton>
          </Grid>
        </Grid>
      )}
      <ErrorText error={creationFailed as string} />
    </Box>
  );
};

export default CreateEditAmenity;
