import { useTranslation } from 'react-i18next';
import { getLanguage } from '@/src/utilities/storage';
import ChargeInfo from './ChargeInfo';
import { CustomDataPropsType } from '../../../residents/account-state-section/types';
import { useChargeInfo } from '../hooks/useChargeEndpoints';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import AlertNoData from '@/src/components/custom-data-table/components/AlertNoData';
import { getFormattedDateFullYearEn, getFormattedDateFullYearEs } from '@/src/utilities/helpers/dateParser';

const ChargeInfoContainer = ({ chargeId, close }: CustomDataPropsType) => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const { values, isLoading } = useChargeInfo(chargeId);

  const noDataComponent = isLoading ? <CircularSpinner /> : <AlertNoData title={t('something_went_wrong')} />;

  const getFormattedDateNumbeByLanguague = (date: number) =>
    lang === 'es' ? getFormattedDateFullYearEs(date) : getFormattedDateFullYearEn(date);

  const quantity = values?.quantity || 1;
  const totalAmount = values?.amount ? values.amount * quantity : '-';

  const chargeInfoItems = [
    { label: t('concept'), value: values?.charge_concept.name ?? '-' },
    { label: t('date'), value: getFormattedDateNumbeByLanguague(values?.creation_date) },
    { label: t('amount_per_unity'), value: values?.amount },
    { label: t('quantity'), value: quantity },
    { label: t('total_amount'), value: totalAmount },
  ];

  const propertyInfoItems = [
    { label: t('property_code', { ns: 'residents' }), value: values?.property_resident?.property?.code ?? '-' },
    {
      label: t('date'),
      value: `${getFormattedDateNumbeByLanguague(values?.property_resident?.from)} - ${getFormattedDateNumbeByLanguague(
        values?.property_resident?.to,
      )}`,
    },
  ];

  const childProps = {
    getFormattedDateNumbeByLanguague,
    handleClose: close,
    chargeInfoItems,
    propertyInfoItems,
    values,
    isLoading,
    noDataComponent,
    t,
  };

  return <ChargeInfo {...childProps} />;
};
export default ChargeInfoContainer;
