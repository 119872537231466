import { Box, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxImage = styled(Box)(({ theme }) => ({
  border: 'none',
  background: theme.palette.primary.main,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
}));

export const CardContainer = styled(Card)(({ theme }) =>
  theme.unstable_sx({
    height: 'auto',
    margin: 'auto',
    paddingBottom: '20px',
    borderRadius: '0px',
    backgroundColor: '#f3f3f4',
    width: { xl: '75%', lg: '75%', md: '85%', sm: '100%', xs: '100%' },
  }),
);

export const TypographyTitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: 'center',
    color: 'secondary.main',
    fontFamily: 'Sorts Mill Goudy,serif',
    fontSize: { xl: '30px', lg: '30px', md: '30px', sm: '22px', xs: '22px' },
  }),
);

export const TypographySubTitle1 = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontFamily: 'Avenir Next !important',
    marginBottom: '16px',
    fontSize: { xl: '16px', lg: '16px', md: '16px', sm: '14px', xs: '14px' },
  }),
);

export const BoxContentSubtitle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'inline', xs: 'inline' },
    marginBottom: '16px',
  }),
);

export const TypographySubTitle2 = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontFamily: 'Avenir Next !important',
    fontSize: { xl: '16px', lg: '16px', md: '16px', sm: '14px', xs: '14px' },
    marginRight: { xl: '4px', lg: '4px', md: '4px', sm: '0', xs: '0' },
  }),
);

export const TypographySubTitle3 = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontFamily: 'Avenir Next !important',
    fontSize: { xl: '16px', lg: '16px', md: '16px', sm: '14px', xs: '14px' },
  }),
);
