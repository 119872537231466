import * as Yup from 'yup';

export const validate = (t: any) =>
  Yup.lazy(() =>
    Yup.object().shape({
      guestName: Yup.string().required(t('required_field', { ns: 'errors' })),
      ticketNumber: Yup.string(),
      parkingNumber: Yup.string(),
      countryCode: Yup.string().required(t('required_field', { ns: 'errors' })),
      countryIsoCode: Yup.string().required(t('required_field', { ns: 'errors' })),
      phoneNumber: Yup.string().required(t('required_field', { ns: 'errors' })),
    }),
  );
