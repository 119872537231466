import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import i18n, { TFunction } from 'i18next';
import { TextField } from '@mui/material';
import { SXBreakpoint } from '@/src/utilities/helpers/commonTypes';

const CustomDateTimePicker = (props: CustomDatePickerProps) => {
  const {
    t,
    lang,
    disabled,
    placeholder,
    value,
    minWidth,
    required,
    width,
    maxDate,
    minDate,
    errorMessage,
    fieldValue,
    onChange,
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      <MobileDateTimePicker
        disabled={disabled}
        inputFormat={lang === 'es' ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY HH:mm'}
        dayOfWeekFormatter={(day: string) => day.charAt(0).toUpperCase()}
        label={placeholder || `${t(fieldValue || '', { ns: 'date' })}${required ? ' * ' : ''}`}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChange}
        renderInput={(params: any) => (
          <TextField
            {...params}
            fullWidth={!width}
            size="small"
            id="outlined-size-small"
            variant="outlined"
            error={errorMessage}
            helperText={errorMessage}
            sx={{
              width,
              minWidth,
              input: {
                WebkitTextFillColor: disabled ? 'rgba(0,0,0,0.6) !important' : '',
                color: disabled ? 'rgba(0,0,0,0.7) !important' : '',
                opacity: disabled ? 0.7 : '',
                backgroundColor: disabled ? 'rgba(0,0,0,0.05) ' : '',
              },
              label: {
                color: disabled ? 'rgba(0,0,0,1) !important' : '',
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

interface CustomDatePickerProps {
  t: TFunction;
  lang: string;
  locale: Date;
  disabled: boolean;
  value: Date | number;
  placeholder: string;
  required: boolean;
  minDate?: number | Date;
  maxDate?: number | Date;
  width?: SXBreakpoint<string | number>;
  minWidth?: SXBreakpoint<string | number>;
  fieldValue?: string;
  errorMessage?: string | string[];
  onChange: (value: number | Date | null, keyboardInputValue?: string | undefined) => void;
}

export default CustomDateTimePicker;
