import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Delete } from './Delete';
import { disableInformation } from '@/src/api/endpoints/residents';
import { PageStateProps, CustomDataTablePropsPeople } from '../types';

const DeleteContainer = ({ row, close, setSnackBarMessage }: CustomDataTablePropsPeople) => {
  const [disableUserInformation, setDisableUserInformation] = useState<PageStateProps['disableUserInfo'] | null>(null);
  const { t } = useTranslation();
  const { mutate } = useMutation(disableInformation, {
    onError: async (error: any) => {
      close();
      if (error.response.data.error_messages[0].code === 'DeletePropertyResidentUserExists') {
        setSnackBarMessage(t(error.response.data.error_messages[0].code, { ns: 'errors' }), 'error');
      } else {
        setSnackBarMessage(t('delete_message_error', { ns: 'residents' }), 'error');
      }
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('delete_message_success', { ns: 'residents' }));
    },
  });

  const { id } = row;
  useEffect(() => {
    setDisableUserInformation({ idProp: id });
  }, [id]);

  const handleCloseDisable = () => {
    setDisableUserInformation(null);
    close();
  };

  const onConfirm = ({ idProp }: { idProp: number }) => {
    mutate(idProp);
  };

  const childProps = {
    values: disableUserInformation,
    onConfirm,
    handleClose: handleCloseDisable,
  };

  return <Delete {...childProps} />;
};

export default DeleteContainer;
