import { useQuery } from '@tanstack/react-query';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getCountries } from '@/src/api/endpoints/master';

const useCountriesFilters = () => {
  const getCountriesMapper = (data: Array<any>) => {
    const filters = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.name,
          query: 'nationality',
        } as FilterByColumn),
    );

    const res = [
      {
        id: 1,
        value: filters,
        query: 'CountryFilters',
        type: 'search',
      } as FilterByColumn,
    ];

    return res;
  };

  const { data: countries, isLoading } = useQuery(['getCountries'], getCountries, {
    select: (response) => getCountriesMapper(response?.data?.countries),
  });

  return { countries, isLoading };
};

export default useCountriesFilters;
