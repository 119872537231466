import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ChargesFormContainerProps } from '../types';
import ChargeForm from './ChargeForm';
import { useGetConcepts } from '../hooks/useConceptsEndpoint';
import { createCharge, editCharge, getChargeById } from '@/src/api/endpoints/residents';
import { useGetResidents } from '../hooks/useResidentsEndpoint';

const ChargeFormContainer = (props: ChargesFormContainerProps) => {
  const { refetch } = props;
  const { concepts } = useGetConcepts();
  const { residents } = useGetResidents(
    props?.isEditMode ? props?.row?.property_resident?.person?.id : props?.resident?.residency.id,
  );
  const { t } = useTranslation();
  const { isEditMode = false, row, close, setSnackBarMessage } = props;
  const { mutate: editChargeMutate, isLoading: isLoadingEdit } = useMutation(editCharge);
  const { mutate: createChargeMutate, isLoading: isLoadingCreate } = useMutation(createCharge);
  const [prevValues, setPrevValues] = React.useState<any>({
    amount: '',
    quantity: 1,
    resident: '',
    concept: '',
  });

  const { isLoading: isLoadingCharge } = useQuery(['onChargeSelectedMutate', row?.id], () => getChargeById(row?.id), {
    enabled: isEditMode || false,
    select: (data) => data?.data?.charge,
    onSuccess: (response) =>
      setPrevValues({
        amount: response?.amount || 0,
        quantity: response?.quantity || 1,
        resident: response?.person,
        concept: response?.charge_concept,
      }),
  });

  const getValidationSchema = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        amount: Yup.number()
          .required(t('required_field', { ns: 'errors' }))
          .moreThan(0, t('must_be_greater_than_zero', { ns: 'errors' })),
        quantity: Yup.number()
          .required(t('required_field', { ns: 'errors' }))
          .moreThan(0, t('must_be_greater_than_zero', { ns: 'errors' }))
          .integer(t('must_be_integer', { ns: 'errors' })),
        concept: Yup.object().test('required_field', t('required_field', { ns: 'errors' }), (value) => !!value?.id),
        resident: Yup.object().test('required_field', t('required_field', { ns: 'errors' }), (value) => !!value?.id),
      }),
    );

  const options = {
    onError: () => {
      setSnackBarMessage(t('error'), 'error');
    },
    onSuccess: () => {
      setSnackBarMessage(t('success'));
      close();
      refetch();
    },
  };

  const onSubmitCreate = (values: FormikValues) =>
    createChargeMutate(
      {
        property_resident_id: values.resident?.id,
        amount: Number(values.amount),
        quantity: values.quantity,
        charge_concept_id: values.concept.id,
      },
      options,
    );
  const onSubmitEdit = (values: FormikValues) => {
    const objKeys = ['amount', 'quantity', 'concept', 'resident'];
    const compareWPrevValues = objKeys.map((prop) => values[prop] === prevValues[prop]);
    const hasChange = compareWPrevValues.includes(false);

    if (hasChange) {
      const valuesToUpdate = {
        property_resident_id: values.resident?.id,
        charge_concept_id: values.concept.id,
        amount: Number(values.amount),
        quantity: Number(values.quantity),
      };

      editChargeMutate({ id: row?.id, values: valuesToUpdate }, options);
    } else {
      setSnackBarMessage(t('SameValues', { ns: 'errors' }), 'error');
    }
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    enableReinitialize: true,
    initialValues: prevValues,
    onSubmit: isEditMode ? onSubmitEdit : onSubmitCreate,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema(),
  });

  const childProps = {
    ...props,
    isLoadingCharge: isEditMode && isLoadingCharge,
    isLoading: isLoadingEdit || isLoadingCreate,
    concepts,
    residents,
    t,
    handleSubmit,
    values,
    setFieldValue,
    errors,
  };
  return <ChargeForm {...childProps} />;
};
export default ChargeFormContainer;
