import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxImage = styled(Box)(({ theme }) => ({
  border: 'none',
  background: theme.palette.primary.main,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
}));

export const BoxContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    marginY: '5px',
    height: { xs: '500px', md: '100%' },
  }),
);
