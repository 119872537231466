import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Navigate, useSearchParams } from 'react-router-dom';
import { requestGuestVehicle } from '@/src/api/endpoints/parking';
import { CarRecall } from './CarRecall';

export const CarRecallPage = () => {
  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get('code');
  const [requestResponse, setRequestResponse] = useState<null | boolean>(null);
  const { mutate, isLoading } = useMutation(requestGuestVehicle, {
    onSuccess: () => {
      setRequestResponse(true);
    },
    onError: () => {
      setRequestResponse(false);
    },
  });
  const handleRequestGuestVehicle = () => mutate(codeParam || '');

  const childProps = {
    requestResponse,
    isLoading,
    handleRequestGuestVehicle,
  };

  if (codeParam) return <CarRecall {...childProps} />;

  return <Navigate to="/auth/login" replace />;
};
