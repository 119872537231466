import { useQuery } from '@tanstack/react-query';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getStates } from '@/src/api/endpoints/parking';

const useParkingStates = () => {
  const getParkingStatesMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.id,
          query: 'GuestParkingStateIds',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: parkingStates, isLoading } = useQuery(['getStates'], getStates, {
    select: (response) => getParkingStatesMapper(response?.data?.guest_parking_states),
  });

  return { parkingStates, isLoading };
};

export default useParkingStates;
