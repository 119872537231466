import { useQuery, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getAdminInfo } from '@/src/api/endpoints/admin-users';
import AdministratorDetail from './AdministratorDetail';
import { getLanguage } from '@/src/utilities/storage';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { getMfaAuthenticationByAminId, resetAdminAuthentication } from '@/src/api/endpoints/accounts-security';

const AdministratorDetailContainer = (props: any) => {
  const { row, setSnackBarMessage } = props;
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const user = localStorage.getItem('user');
  const isSuperAdmin = user ? JSON.parse(user)?.roles?.includes('SuperAdmin') : false;

  const { data: adminInfo, isLoading: isLoadingGetAdminInfo } = useQuery(
    [`getAdminInfo_${row.id}`],
    () => getAdminInfo(row.id),
    {
      select: (data) => data?.data?.admin,
    },
  );

  const { data: mfaAuthInfo, isLoading: isLoadingGetMfaAuth } = useQuery(
    [`getMfaAuthInfo_${row.id}`],
    () => getMfaAuthenticationByAminId(row.id),
    {
      select: (data) => data?.data?.mfa,
    },
  );

  const { mutate, isLoading: isLoadingReset } = useMutation(resetAdminAuthentication, {
    onSuccess: () => setSnackBarMessage(t('approved_request_success_message')),
    onError: () => setSnackBarMessage(t('approved_request_error_message'), 'error'),
  });

  const onConfirmReset = () => mutate(adminInfo?.user_id);

  const creationDate =
    row.creation_date || adminInfo.creation_date
      ? getFormattedDateByLanguague(
          lang,
          getFormattedDateFullYearEs,
          getFormattedDateFullYearEn,
          row.creation_date || adminInfo.creation_date,
        )
      : '-';

  const childProps = {
    ...props,
    t,
    adminInfo,
    isLoadingGetAdminInfo: isLoadingGetAdminInfo || isLoadingGetMfaAuth,
    creationDate,
    lang,
    getFormattedDateByLanguague,
    getFormattedDateFullYearEs,
    getFormattedDateFullYearEn,
    mfaAuthInfo,
    isSuperAdmin,
    onConfirmReset,
    isLoadingReset,
  };

  return <AdministratorDetail {...childProps} />;
};

export default AdministratorDetailContainer;
