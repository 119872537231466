import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { loadRackPackage } from '@/src/api/endpoints/packages';
import LoadRackPackageForm from './LoadRackPackageForm';
import { validate } from './LoadRackPackageForm.validate';
import { isValidDigit } from '@/src/utilities/helpers/stringsHelper';

const LoadRackPackageContainer = ({ close, setSnackBarMessage }: any) => {
  const { t } = useTranslation();
  const [responseError, setResponseError] = useState('');
  const [barcode, setBarcode] = useState('');
  const { mutate: loadRackPackageMutate, isLoading } = useMutation(loadRackPackage);
  const errorEvent = {
    onError: ({ response }: any) => {
      setResponseError(
        t(response.data.error_messages[0].code, {
          resource: response.data.error_messages[0].message,
          item: t(`${response.data.error_messages[0].property_name.toLowerCase()}_error_response`, { ns: 'packages' }),
          ns: 'errors',
        }),
      );
    },
  };
  const successEvent = {
    onSuccess: () => {
      close();
      setSnackBarMessage(t('success'));
    },
  };

  const onSubmit = (data: any) => {
    setResponseError('');
    loadRackPackageMutate(
      {
        package_code: data.package_code,
        rack_code: data.rack_code,
      },
      {
        ...errorEvent,
        ...successEvent,
      },
    );
  };

  const { handleSubmit, values, setFieldValue, errors, validateForm } = useFormik({
    initialValues: {
      package_code: '',
      rack_code: '',
    },
    onSubmit,
    validationSchema: validate(t),
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const onKeyDown = (keyEvent: any) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const onKeyPressed = (e: any) => {
    const packageCodeInput = document.getElementById('package_code');
    const rackCodeInput = document.getElementById('rack_code');
    const { keyCode } = e;
    if (keyCode === 13) {
      if (document.activeElement === packageCodeInput) {
        setFieldValue('package_code', barcode);
        rackCodeInput?.focus();
        setBarcode('');
      } else if (document.activeElement === rackCodeInput) {
        setFieldValue('rack_code', barcode);
        setBarcode('');
        onSubmit({ package_code: values.package_code, rack_code: values.rack_code });
      } else {
        setBarcode('');
      }
    } else if (isValidDigit(keyCode)) {
      setBarcode(barcode + e.key);
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', onKeyPressed, false);

    return () => {
      document.removeEventListener('keydown', onKeyPressed, false);
    };
  });
  useEffect(() => {
    const packageCodeInput = document.getElementById('package_code');
    packageCodeInput?.focus();
  }, []);
  useEffect(() => {
    const packageCodeInput = document.getElementById('package_code');
    if (document.activeElement === packageCodeInput) setBarcode(values.package_code);
  }, [values.package_code]);
  useEffect(() => {
    const rackCodeInput = document.getElementById('rack_code');
    if (document.activeElement === rackCodeInput) setBarcode(values.rack_code);
  }, [values.rack_code]);

  const childProps = {
    handleSubmit,
    values,
    setFieldValue,
    errors,
    isLoadingSubmit: isLoading,
    responseError,
    validateForm,
    onKeyDown,
  };
  return <LoadRackPackageForm {...childProps} />;
};

export default LoadRackPackageContainer;
