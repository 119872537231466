import { useQuery } from '@tanstack/react-query';
import { getRoomTypes } from '@/src/api/endpoints/interested';
import { RoomType } from '@/src/utilities/helpers/commonTypes';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';

const useRoomFilters = () => {
  const getRoomMapper = (data: Array<RoomType>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.description,
          value: item.id,
          query: 'RoomsAmount',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: roomTypes, isLoading } = useQuery(['getRoomType'], getRoomTypes, {
    select: (response) => getRoomMapper(response?.data?.room_types as Array<RoomType>),
  });

  return { roomTypes, isLoading };
};

export default useRoomFilters;
