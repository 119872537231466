import { Dispatch, SetStateAction } from 'react';
import { QueryProps } from '@/src/utilities/helpers/commonTypes';

export const updateArrayByKey = (
  query: string | null,
  newValue: string | number | boolean | null,
  setQueryFilters: Dispatch<SetStateAction<Array<QueryProps>>>,
) => {
  setQueryFilters((prevArray: any) =>
    prevArray.map((obj: any) => {
      if (obj.query === query) {
        return { query, queryValue: newValue };
      }
      return obj;
    }),
  );
};
