import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ConstructionForm from './ConstructionForm';
import {
  createConstructionProgresses,
  editConstructionProgresses,
  getConstructionProgress,
} from '@/src/api/endpoints/constructionProgress';
import { ArticleBody } from '../../types';
import useImageUploader from '@/src/components/image-uploader/useImageUploader';
import { tRequiredFieldError } from '@/src/utilities/helpers/appConstants';

const actualDate = new Date().getTime();

const ConstructionFormContainer = ({ dataById, close, refetchDataById, setSnackBarMessage }: any) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const getConstructionProgressData = async () => {
    const response = await getConstructionProgress();
    return response.data;
  };
  const { refetch } = useQuery(['publications'], getConstructionProgressData);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { mutate } = useMutation(createConstructionProgresses);
  const { mutate: mutateEdit } = useMutation(editConstructionProgresses);

  const getInitialValues = () => ({
    title: dataById?.title ?? '',
    subtitle: dataById?.subtitle ?? '',
    start_date: dataById?.date ?? actualDate,
    document: dataById?.document_url ?? null,
    video: dataById?.video_url ?? null,
    external_video_url: dataById?.external_video_url ?? null,
    cover_image_url: dataById?.cover_image_url ?? '',
    body: dataById?.body ?? '',
    publication_image_urls: dataById?.publication_images ?? [],
    isEdit: !!dataById,
    editId: dataById?.id ?? null,
  });

  const getValidationSchema = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        title: Yup.string().required(tRequiredFieldError),
        subtitle: Yup.string().required(tRequiredFieldError),
        body: Yup.string().required(tRequiredFieldError),
        cover_image_url: Yup.string().required(tRequiredFieldError),
        external_video_url: Yup.string()
          .required(tRequiredFieldError)
          .nullable()
          .test(
            'valid_url_link',
            t('enter_video_url_64', {
              ns: 'constructionProgress',
            }),
            (value) => value != null && value?.includes('https://www.youtube.com/'),
          ),
      }),
    );

  const onSubmit = (data: any) => {
    setIsLoading(true);
    const imageUrls: any = [];
    data.publication_image_urls.map((imageUrl: { image_url: string }) => imageUrls.push(imageUrl.image_url));

    const body: ArticleBody = {
      title: data.title,
      subtitle: data.subtitle,
      start_date: data.start_date,
      document_url: data.document,
      video_url: data.video || '',
      external_video_url: data.external_video_url || '',
      cover_image_url: data.cover_image_url,
      body: data.body,
      publication_image_urls: imageUrls,
    };

    if (!data.isEdit) {
      mutate(body, {
        onSuccess: async () => {
          refetch();
          setSnackBarMessage(t('successful_publication_creation_snackbar_text', { ns: 'constructionProgress' }));
          close();
        },
        onError: async () => {
          setErrorMessage(t('failed_publication_creation_snackbar_text', { ns: 'constructionProgress' }));
        },
        onSettled: async () => {
          setIsLoading(false);
        },
      });
    } else {
      mutateEdit(
        { id: dataById.id, body },
        {
          onSuccess: async () => {
            setIsLoading(false);
            refetch();
            refetchDataById(dataById.id);
            setSnackBarMessage(t('successful_publication_edition_snackbar_text', { ns: 'constructionProgress' }));
            close();
          },
          onError: async () => {
            setIsLoading(false);
            setErrorMessage(t('failed_publication_edition_snackbar_text', { ns: 'constructionProgress' }));
          },
        },
      );
    }
  };

  const { handleSubmit, values, setFieldValue, errors, setFieldError } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema(),
  });

  const { ImageUploader, wrongImages: wrongCoverImages } = useImageUploader({
    htmlFor: 'image-header',
    values,
    valueName: 'cover_image_url',
    setFieldValue,
    setDisableForm: setDisableButton,
  });
  const { ImageUploader: AttachedImagesUploader, wrongImages: wrongAttachedImages } = useImageUploader({
    htmlFor: 'attached-images-urls',
    values,
    valueName: 'publication_image_urls',
    setFieldValue,
    setDisableForm: setDisableButton,
  });

  const childProps = {
    handleSubmit,
    values,
    setFieldValue,
    setFieldError,
    errors,
    isLoadingSubmit: isLoading,
    errorMessage,
    disableButton,
    setDisableButton,
    t,
    ImageUploader,
    AttachedImagesUploader,
    wrongCoverImages,
    wrongAttachedImages,
  };

  return <ConstructionForm {...childProps} />;
};

export default ConstructionFormContainer;
