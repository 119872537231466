import * as Yup from 'yup';
import { VALID_NUMBER_FIELD } from '@/src/utilities/helpers/constants';

export const validate = (t: any) =>
  Yup.lazy(() =>
    Yup.object().shape({
      propertyCode: Yup.string().required(t('required_field', { ns: 'errors' })),
      customerCode: Yup.string()
        .required(t('required_field', { ns: 'errors' }))
        .matches(VALID_NUMBER_FIELD, t('yup_required_phone')),
    }),
  );
