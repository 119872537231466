import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemButton, Radio, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { People, DeliveryPeopleProps } from '../../types';

export const DeliveryPeople = ({ people, customerId, setFieldValue }: DeliveryPeopleProps) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Typography variant="caption">{t('delivery_modal_caption_info_text', { ns: 'delivery' })}</Typography>
      <List>
        {people.map((el: People) => {
          const fullName = `${el.name} ${el.surname}`;
          return (
            <ListItem key={el.id} disablePadding>
              <ListItemButton
                sx={{ padding: '8px 0 !important' }}
                onClick={() => setFieldValue('customerId', el.customer_id)}
              >
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item container xs={6} alignItems="center">
                    <ListItemAvatar>
                      <Avatar alt={fullName} src={el.avatar_url} sx={{ width: 70, height: 70, marginRight: '15px' }} />
                    </ListItemAvatar>
                    <Typography variant="subtitle2">{fullName}</Typography>
                  </Grid>
                  <Grid item container xs={4} direction="row" alignItems="center">
                    <Typography variant="subtitle2" mr={1}>
                      {el.full_phone_number}
                    </Typography>
                  </Grid>
                  <Grid item container xs={2} direction="row" justifyContent="flex-end">
                    <Radio checked={customerId === el.customer_id} />
                  </Grid>
                </Grid>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
};
