import { axiosAuthenticationInstance } from '../instances/authInstance';
import { ConfirmPackagesRequest, CreatePackageRequest, LoadRackPackagesBody } from '../types/types';

export const getPackagesByPropertyCodeAndCustomerCode = (req: { propertyCode: string; customerCode: string }) =>
  axiosAuthenticationInstance.get(
    `packaging/v1/packages/validate?propertyCode=${req.propertyCode}&customerCode=${req.customerCode}`,
  );

export const getPackageById = (id: number) => axiosAuthenticationInstance.get(`packaging/v1/packages/${id}`);
export const createPackage = (req: CreatePackageRequest) =>
  axiosAuthenticationInstance.post(`packaging/v1/packages`, req);
export const confirmPackages = (req: ConfirmPackagesRequest) =>
  axiosAuthenticationInstance.post(`packaging/v1/packages/confirm`, req);
export const deletePackage = (id: number) => axiosAuthenticationInstance.delete(`packaging/v1/packages/${id}`);
export const loadPackage = () => axiosAuthenticationInstance.get(`packaging/v1/carriers`);
export const loadRackPackage = (req: LoadRackPackagesBody) =>
  axiosAuthenticationInstance.put(`packaging/v1/packages/${req.package_code}/location`, {
    location: req.rack_code,
  });
export const sendToPrint = (id: number) => axiosAuthenticationInstance.post(`packaging/v1/packages/${id}/print`);
export const getPackagesStates = () => axiosAuthenticationInstance.get(`packaging/v1/package-states`);
