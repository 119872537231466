import { axiosAuthenticationInstance } from '../instances/authInstance';
import { AddsNewMessageRequest, ClosesIssueByIdRequest, NewIssue } from '../types/types';

export const createsNewIssue = (req: NewIssue) => axiosAuthenticationInstance.post('/support/v1/issues', req);

export const getsIssueById = (req: { id: number }) => axiosAuthenticationInstance.get(`/support/v1/issues/${req.id}`);

export const addsNewMessage = (req: AddsNewMessageRequest) =>
  axiosAuthenticationInstance.post(`/support/v1/issues/${req.id}/messages`, req.newMessage);

export const closesIssueById = (req: ClosesIssueByIdRequest) =>
  axiosAuthenticationInstance.put(`/support/v1/issues/${req.id}/closure`, req.closingReq);
