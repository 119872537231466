import { useQuery } from '@tanstack/react-query';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getProperties } from '@/src/api/endpoints/residents';

const useApartmentsFilters = () => {
  const getApartmentsMapper = (data: Array<any>) => {
    const filters = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.code,
          value: item.code,
          query: 'PropertyCodes',
        } as FilterByColumn),
    );

    const res = [
      {
        id: 1,
        value: filters,
        query: 'ApartmentsFilters',
        type: 'search',
      } as FilterByColumn,
    ];

    return res;
  };

  const { data: apartments, isLoading } = useQuery(['getProperties'], getProperties, {
    select: (response) => getApartmentsMapper(response?.data?.properties),
  });

  return { apartments, isLoading };
};

export default useApartmentsFilters;
