import * as Yup from 'yup';
import { TProp } from '@/src/utilities/helpers/commonTypes';

export const validate = ({ t }: TProp) =>
  Yup.lazy(() =>
    Yup.object().shape({
      name: Yup.string().required(t('required_field', { ns: 'errors' })),
      breed: Yup.object()
        .shape({
          id: Yup.number(),
          name: Yup.string(),
        })
        .nullable()
        .required(t('required_field', { ns: 'errors' })),
      type: Yup.object()
        .shape({
          id: Yup.number(),
          name: Yup.string(),
        })
        .nullable()
        .required(t('required_field', { ns: 'errors' })),
      birthday: Yup.number()
        .min(2000, t('must_be_greater_than', { ns: 'errors', resource: '2000' }))
        .max(
          new Date().getFullYear(),
          t('must_be_lower_than', { ns: 'errors', resource: `${new Date().getFullYear()}` }),
        )
        .required(t('required_field', { ns: 'errors' })),
      sex: Yup.object()
        .shape({
          id: Yup.number(),
          name: Yup.string(),
        })
        .nullable()
        .required(t('required_field', { ns: 'errors' })),
      isSterilized: Yup.boolean().required(t('required_field', { ns: 'errors' })),
      isServiceAnimal: Yup.boolean().required(t('required_field', { ns: 'errors' })),
      lastVaccinationDate: Yup.number().required(t('required_field', { ns: 'errors' })),
      imageUrl: Yup.string().required(t('required_field', { ns: 'errors' })),
    }),
  );
