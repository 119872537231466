import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useGetPeople, useGetPersonIfAuthorized, useLoadDelivery } from '../hook/useDeliveryEndpoints';
import DeliveryForm from './DeliveryForm';
import { validate } from './DeliveryForm.validate';
import { DeliveryFormContainerProps } from '../types';

const DeliveryFormContainer = ({ row, close, setSnackBarMessage }: DeliveryFormContainerProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const { company } = useLoadDelivery();
  const { mutateGetPeople, isLoadingGetPeople, people } = useGetPeople();
  const { mutateGetPersonIfAuthorized, isLoadingGetPersonIfAuthorized, personAuthorized, deliveryId } =
    useGetPersonIfAuthorized();

  const getInitialValues = () => ({
    carrierId: '',
    carrierName: '',
    propertyCode: '',
    customerId: '',
  });

  const onSubmit = () => {
    setError('');
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate({ t }),
  });

  const childProps = {
    handleSubmit,
    values,
    setFieldValue,
    errors,
    error,
    setError,
    company,
    mutateGetPersonIfAuthorized,
    deliveryId,
    setSnackBarMessage,
    personAuthorized,
    people,
    mutateGetPeople,
    generalLoading: isLoadingGetPersonIfAuthorized || isLoadingGetPeople,
    close,
    row,
  };

  return <DeliveryForm {...childProps} />;
};

export default DeliveryFormContainer;
