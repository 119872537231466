import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomInput from '@/src/components/forms/CustomInput';
import TitleWithStatus from '@/src/components/title-with-status/TitleWithStatus';
import { DeliveryCheckProps } from '../../types';

const DeliveryCheck = ({ close, values, setFieldValue, errors, peopleExist, company }: DeliveryCheckProps) => {
  const { t } = useTranslation();
  return (
    <>
      <TitleWithStatus
        title={t('delivery_page_button_report_delivery', { ns: 'delivery' })}
        statusText=""
        statusBg=""
        onClose={close}
      />
      <Grid item xs={12} sx={{ pt: '0 !important' }}>
        <Typography variant="subtitle2">{t('delivery_modal_delivery_information', { ns: 'delivery' })}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          size="small"
          inputValue={values.carrierName}
          options={company || []}
          clearOnEscape
          sx={{ backgroundColor: peopleExist ? 'rgba(0,0,0,0.05) ' : '' }}
          disabled={peopleExist}
          onInputChange={(e, newInputValue) => setFieldValue('carrierName', newInputValue)}
          onChange={(e, newValue) => {
            setFieldValue('carrierId', newValue?.id);
          }}
          getOptionLabel={({ name }) => name}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              label={`${t('delivery_modal_input_carrier', { ns: 'delivery' })} * `}
              error={errors.carrierName !== undefined}
              helperText={errors.carrierName?.toString()}
              sx={{
                input: {
                  WebkitTextFillColor: peopleExist ? 'rgba(0,0,0,0.6) !important' : '',
                },
                label: {
                  color: peopleExist ? 'rgba(0,0,0,1) !important' : '',
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomInput
          field="propertyCode"
          label={t('delivery_modal_input_property_code', { ns: 'delivery' })}
          placeholder={t('delivery_modal_input_property_code', { ns: 'delivery' })}
          disabled={peopleExist}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          required
        />
      </Grid>
    </>
  );
};

export default DeliveryCheck;
