import { Box, Grid, IconButton, Typography, Tooltip, FormHelperText } from '@mui/material';
import React from 'react';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import CustomInput from '@/src/components/forms/CustomInput';
import PdfFileUploader from '@/src/components/pdf-file-uploader/PdfFileUploader';
import { CustomDatePicker } from '@/src/components/date-picker/DatePicker';
import VideoUploader from '@/src/components/video-uploader/VideoUploader';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { PerksBenefitsFormProps } from '../../types';
import CustomEditor from '@/src/components/custom-editor/CustomEditor';
import {
  AttachedImagesContainer,
  AttachedImageUploaderContainer,
  AttachedLoadedImagesContainer,
  CoverImageContainer,
  DeleteAttachedImageIcon,
  SubmitButton,
} from './PerksBenefitsForm.styled';
import CustomCardMedia from '@/src/components/custom-card-media/CustomCardMedia';
import { handlerDateSelector } from '@/src/utilities/helpers/utils';

const PerksBenefitsForm = ({
  handleSubmit,
  values,
  setFieldValue,
  errors,
  isLoadingSubmit,
  errorMessage,
  disableButton,
  setDisableButton,
  setFieldError,
  t,
  ImageUploader,
  AttachedImagesUploader,
  wrongCoverImages,
  wrongAttachedImages,
}: PerksBenefitsFormProps) => {
  const deleteImage = (value: any, url?: string) => {
    setFieldValue(
      value,
      Array.isArray(values[`${value}`]) ? values?.[`${value}`].filter((element: any) => element.image_url !== url) : '',
    );
  };

  const attachedImagesLength = values?.publication_image_urls.length;
  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: '5px' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="subtitle2">{t('attached_header_image', { ns: 'events' })}</Typography>
        </Grid>
        {values.cover_image_url ? (
          <Grid item xs={12} sm={12} md={12} sx={{ position: 'relative' }}>
            <Tooltip title={t('remove_item_tooltip_msn', { ns: 'perksBenefits' })}>
              <IconButton
                onClick={() => deleteImage('cover_image_url')}
                sx={{ position: 'absolute', right: -15, top: -14 }}
              >
                <CancelRoundedIcon />
              </IconButton>
            </Tooltip>
            <CustomCardMedia src={values.cover_image_url} alt="cover-image" height="180px" />
          </Grid>
        ) : (
          <CoverImageContainer>
            <ImageUploader />
            <ErrorText error={wrongCoverImages?.code} />
            {errors?.cover_image_url && <ErrorText error={t('attach_header_image', { ns: 'perksBenefits' })} />}
          </CoverImageContainer>
        )}
        <Grid item xs={12} sm={6} md={6}>
          <CustomInput
            field="title"
            noError
            label={t('title')}
            placeholder={t('enter_title', { ns: 'perksBenefits' })}
            setFieldValue={setFieldValue}
            values={values}
            autoFocus
            maxLength={100}
            required
          />
          <ErrorText error={errors && errors.title ? t('enter_title', { ns: 'perksBenefits' }) : null} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomDatePicker
            value={values.start_date}
            minDate={new Date()}
            onChange={(newValue: any): void => handlerDateSelector(newValue, setFieldValue, 'start_date')}
            fieldValue="start_date"
            placeholder={t('start_date', { ns: 'perksBenefits' })}
            errorMessage={errors.start_date as string}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CustomInput
            field="subtitle"
            noError
            label={t('subtitle', { ns: 'perksBenefits' })}
            placeholder={t('enter_subtitle', { ns: 'perksBenefits' })}
            setFieldValue={setFieldValue}
            values={values}
            maxLength={100}
            required
          />
          <ErrorText error={errors && errors.subtitle ? t('enter_subtitle', { ns: 'perksBenefits' }) : null} />
        </Grid>
       
        <Grid item xs={12}>
          <CustomEditor values={values} valueName="body" setFieldValue={setFieldValue} />
          {errors.body && <ErrorText error={t('enter_message', { ns: 'perksBenefits' })} />}
        </Grid>
        <Grid item xs={12} md={6} sx={{ height: 'auto' }}>
          <PdfFileUploader
            values={values}
            setFieldValue={setFieldValue}
            setDisableButton={setDisableButton}
            setError={setFieldError}
          />
          {errors?.document && <FormHelperText error>{`${errors?.document}`}</FormHelperText>}
        </Grid>
        <Grid item xs={12} md={6} sx={{ height: 'auto' }}>
          <VideoUploader
            values={values}
            setFieldValue={setFieldValue}
            setDisableButton={setDisableButton}
            setError={setFieldError}
          />
          {errors.video && <ErrorText error={errors.video as string} />}
        </Grid>
        <Grid item xs={12} sm={12} md={12} mt={4} sx={{ height: 135, minHeight: 'fit-content' }}>
          <Typography variant="subtitle2">
            {t('perks_benefits_form_add_images_label', { ns: 'perksBenefits' })}
          </Typography>

          <AttachedLoadedImagesContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <AttachedImagesUploader disabled={attachedImagesLength >= 4} />
              <ErrorText error={wrongAttachedImages?.code || errors.publication_image_urls} />
            </Box>
            <AttachedImageUploaderContainer
              className="AttachedImageUploaderContainer"
              justifyContent={`${attachedImagesLength > 3 ? 'space-between' : 'flex-start'}`}
            >
              {values.publication_image_urls.map((images: any, i: number) => (
                <AttachedImagesContainer key={i} width={{ xs: '100%', lg: '25%' }}>
                  <DeleteAttachedImageIcon
                    onClick={() => deleteImage('publication_image_urls', images?.image_url ?? images)}
                  >
                    <CancelRoundedIcon />
                  </DeleteAttachedImageIcon>
                  <CustomCardMedia src={images?.image_url ?? images} alt={`attached-image-${i}`} height="128px" />
                </AttachedImagesContainer>
              ))}
            </AttachedImageUploaderContainer>
          </AttachedLoadedImagesContainer>
        </Grid>
        {errorMessage && (
          <Grid xs={6} item>
            <ErrorText error={errorMessage} />
          </Grid>
        )}
        <Grid container item xs={errorMessage ? 6 : 12} justifyContent="flex-end">
          <SubmitButton loading={isLoadingSubmit} variant="contained" type="submit" disabled={disableButton}>
            {t('post', { ns: 'perksBenefits' })}
          </SubmitButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(PerksBenefitsForm);
