import { axiosAuthenticationInstance } from '../instances/authInstance';
import { AuthenticateUserRequest, DisableAuthenticateUserRequest, LoginOTPRequest } from '../types/types';
import axiosAnonInstance from '../instances/anonInstances';

export const getMfaAuthentication = () => axiosAuthenticationInstance.get(`/accounts-security/v1/admins/mfa`);
export const getMfaAuthenticationByAminId = (id: string) =>
  axiosAuthenticationInstance.get(`/accounts-security/v1/admins/${id}/mfa`);
export const getMfaAuthenticationByUserId = (id: string | number) =>
  axiosAuthenticationInstance.get(`/accounts-security/v1/customers/${id}/mfa`);

export const getSecurityCode = () => axiosAuthenticationInstance.post(`/accounts-security/v1/admins/mfa/otp/setup`);
export const enableAuthentication = (req: AuthenticateUserRequest) =>
  axiosAuthenticationInstance.post(`accounts-security/v1/admins/mfa/otp/enable`, req);
export const disableAuthentication = (req: DisableAuthenticateUserRequest) =>
  axiosAuthenticationInstance.post(`accounts-security/v1/admins/mfa/otp/disable`, req);

export const mfaChallenge = (req: LoginOTPRequest) =>
  axiosAnonInstance.post('accounts-security/v1/admins/mfa/otp/challenge', req);
export const resetAdminAuthentication = (id: string) =>
  axiosAuthenticationInstance.put(`accounts-security/v1/admins/${id}/mfa/reset`);
export const resetUserAuthentication = (id: string | number) =>
  axiosAuthenticationInstance.put(`accounts-security/v1/customers/${id}/mfa/reset`);
