import { useTranslation } from 'react-i18next';
import PaymentDetail from './PaymentDetail';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { ChargesType } from '../types';

const PaymentDetailContainer = (props: any) => {
  const { PaymentId, close, row: selectedRow } = props;
  const { t } = useTranslation();

  const columnsCharges: CustomDataTableColumnsProps<ChargesType>[] = [
    {
      id: 'name',
      sortField: 'name',
      name: t('name'),
      selector: (row: ChargesType) => row?.charge_concept?.name,
      center: true,
      sortable: true,
    },
    {
      id: 'quantity',
      sortField: 'quantity',
      name: t('quantity'),
      selector: (row: ChargesType) => row?.quantity,
      center: true,
      sortable: true,
    },
    {
      id: 'total_amount',
      sortField: 'total_amount',
      name: t('total_amount'),
      selector: (row: ChargesType) => row?.total_amount,
      center: true,
      sortable: true,
    },
  ];

  const childProps = {
    PaymentId,
    columnsCharges,
    close,
    selectedRow,
  };
  return <PaymentDetail {...childProps} />;
};

export default PaymentDetailContainer;
