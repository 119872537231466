import { useQuery } from '@tanstack/react-query';
import { getMarketingMaterialsById } from '@/src/api/endpoints/marketingMaterials';
import { ArticleByIdBody } from './types';

const getMarketingByIdData = async (id: number) => {
  const response = await getMarketingMaterialsById(id);
  return response.data;
};

export const useGetMarketingById = (id: number) => {
  const { data, isLoading, refetch } = useQuery([`${id}`, getMarketingByIdData], () => getMarketingByIdData(id), {
    cacheTime: 10000,
  });
  const dataById: ArticleByIdBody = data;
  return { dataById, isLoading, refetch };
};
