import { UseMutateFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { FormikErrors, FormikValues } from 'formik';
import { Dispatch, FormEvent, SetStateAction } from 'react';
import { TProp } from '@/src/utilities/helpers/commonTypes';

export interface Delivery {
  id: number;
  authorization_date: number;
  customer: {
    name: string;
    surname: string;
  };
  carrier: {
    name: string;
  };
  authorization_state: {
    name: string;
  };
  property: {
    code: string;
  };
  creation_date: number;
}

export type TPackagesStateOptions = {
  [key: string]: string;
};

export const deliveryStates: TPackagesStateOptions = {
  Delivered: '#424867',
  Entregado: '#424867',
  Authorized: '#516F3F',
  Autorizado: '#516F3F',
};

export interface People {
  id: number;
  customer_id: number;
  name: string;
  surname: string;
  full_phone_number: string;
  avatar_url: string;
}

export interface DeliveryPeopleProps {
  people: Array<People>;
  customerId: number;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}
export interface DeliveryButtonsProps {
  values: FormikValues;
  peopleExist: boolean;
  setSnackBarMessage: (message: string, sever?: string) => void;
  close: () => void;
  mutateGetPersonIfAuthorized: UseMutateFunction<
    AxiosResponse<any, any>,
    unknown,
    {
      propertyCode: string;
      carrierId: number;
    },
    unknown
  >;
  mutateGetPeople: UseMutateFunction<AxiosResponse<any, any>, unknown, string, unknown>;
  setError: Dispatch<SetStateAction<string>>;
}
export interface DeliveryCheckProps {
  close: () => void;
  values: FormikValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  errors: FormikErrors<FormikValues>;
  peopleExist: boolean;
  company: Array<any>;
}
export interface DeliveryFormContainerProps {
  row: any;
  close: () => void;
  setSnackBarMessage: (message: string, sever?: string) => void;
}

export interface DeliveryFormProps {
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  errors: FormikErrors<FormikValues>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  company: any;
  mutateGetPersonIfAuthorized: UseMutateFunction<
    AxiosResponse<any, any>,
    unknown,
    {
      propertyCode: string;
      carrierId: number;
    },
    unknown
  >;
  insertNewDelivery: (data: { [key: string]: string }) => void;
  isLoadingSubmitInsertNewDelivery: boolean;
  propsInfoContainer: {
    row: any;
    deliveryId: any;
    close: () => void;
    setSnackBarMessageSuccess: (message: string) => void;
    setSnackBarMessageError: (message: string) => void;
  };
  personAuthorized: People;
  people: Array<People>;
  mutateGetPeople: UseMutateFunction<AxiosResponse<any, any>, unknown, string, unknown>;
  generalLoading: boolean;
  close: () => void;
}

export interface DeliveryInfoProps extends TProp {
  isLoading: boolean;
  handleClose: () => void;
  values: FormikValues;
  handleAdmission: () => void;
  isLoadingAdmission: boolean;
  isDelivered: boolean;
  lang: string;
  noDataComponent: React.ReactNode;
}
