import { useTranslation } from 'react-i18next';
import {
  getFormattedDateByLanguague,
  getFormattedLocalFullDateNumberEn,
  getFormattedLocalFullDateNumberEs,
} from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { Schedule } from './types';

export const useColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];

  const columns: CustomDataTableColumnsProps<Schedule>[] = [
    {
      id: 'name',
      name: t('schedules_list_activity_column', { ns: 'bookings' }),
      selector: (row: Schedule) => row.agenda.name,
      sortField: 'name',
      sortable: false,
    },
    {
      id: 'from',
      name: t('schedules_list_datetime_column', { ns: 'bookings' }),
      selector: (row: Schedule) =>
        getFormattedDateByLanguague(
          lang,
          getFormattedLocalFullDateNumberEs,
          getFormattedLocalFullDateNumberEn,
          row.from,
        ),
      sortField: 'from',
      sortable: false,
      center: true,
    },
  ];

  return { columns };
};
