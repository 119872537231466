import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

export const AttendantPage = () => {
  const { t } = useTranslation();
  const { columns, actionColumns, headerActions } = useColumns();
  return (
    <CustomDataTable
      title={t('parking_attendant_page_title', { ns: 'parking' })}
      columns={columns}
      resource="attendants"
      pathApi="/valet-parking/v1/attendants"
      listName="attendants"
      defaultSortFieldId="id"
      defaultSortAsc={false}
      canSearch
      extraActionsInHeader={headerActions}
      customActionColumns={actionColumns}
      canDelete
      canExport
    />
  );
};
