import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { VehiclesModalsProps } from '../types';
import VehicleDeleteModal from './VehicleDeleteModal';
import { deleteVehicle } from '@/src/api/endpoints/houseHold';

const VehicleDeleteModalContainer = (props: VehiclesModalsProps) => {
  const { row, close, setSnackBarMessage } = props;
  const { t } = useTranslation();
  const residencyId = row?.residency?.id;
  const vehicleId = row?.vehicle?.id;
  const { mutate } = useMutation(deleteVehicle, {
    onError: async () => {
      close();
      setSnackBarMessage(t('delete_message_error_vehicle', { ns: 'cdtModal' }), 'error');
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('delete_message_success_vehicle', { ns: 'cdtModal' }));
    },
  });

  const handleCloseDisable = () => {
    close();
  };

  const onConfirm = () => {
    const body = {
      vehicleId,
      residencyId,
    };
    mutate(body);
  };

  const childProps = {
    onConfirm,
    handleClose: handleCloseDisable,
  };

  return <VehicleDeleteModal {...childProps} />;
};

export default VehicleDeleteModalContainer;
