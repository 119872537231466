import { axiosAuthenticationInstance } from '../instances/authInstance';
import { CreateEventRequest, EditEventRequest } from '../types/types';

export const getEventsCustomer = () => axiosAuthenticationInstance.get(`feed/v1/customers/events`);
export const getEventsByIdCustomer = (id: number) => axiosAuthenticationInstance.get(`feed/v1/customers/events/${id}`);
export const getEvents = () => axiosAuthenticationInstance.get(`feed/v1/events`);
export const getEventsById = (id: number) => axiosAuthenticationInstance.get(`feed/v1/events/${id}`);
export const createEvent = (req: CreateEventRequest) => axiosAuthenticationInstance.post('feed/v1/events', req);
export const editEvent = (req: EditEventRequest) =>
  axiosAuthenticationInstance.put(`feed/v1/events/${req.id}`, req.body);
export const deleteEvent = (id: number) => axiosAuthenticationInstance.delete(`feed/v1/events/${id}`);
