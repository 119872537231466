import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogContentText, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { deleteAmenity } from '@/src/api/endpoints/bookings';
import { DeleteActivityProps } from '../../types';

const DeleteAmenity = ({ close, row, setSnackBarMessage }: DeleteActivityProps) => {
  const { t } = useTranslation();
  const [dialog, setDialog] = React.useState<{ title: string; isError: boolean }>({
    title: t('detele_amenity_title', { ns: 'bookings' }),
    isError: false,
  });
  const { mutate, isLoading } = useMutation(deleteAmenity, {
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('detele_amenity_success', { ns: 'bookings' }));
    },
    onError: async (err: any) => {
      if (err.response.data.error_messages[0].code === 'DeleteAmentyNotAllowed') {
        setDialog({ title: t('DeleteAmentyNotAllowed', { ns: 'errors' }), isError: true });
      } else {
        setSnackBarMessage(t('error'), 'error');
        close();
      }
    },
  });
  return (
    <Grid>
      <DialogContentText id="alert-dialog-description" sx={{ color: `${dialog.isError ? 'red' : 'inherit'}`, my: 1 }}>
        {`${dialog.title}`}
      </DialogContentText>
      <Box component="div" sx={{ widht: '100%', textAlign: 'right', pt: 1 }}>
        <Button size="small" type="button" variant="outlined" color="secondary" onClick={close} sx={{ mr: 1 }}>
          {t('delete_dialog_cancel_button_text')}
        </Button>
        <LoadingButton
          loading={isLoading}
          size="small"
          type="button"
          variant="contained"
          onClick={() => mutate(row.id as number)}
          disabled={dialog.isError}
        >
          {t('delete_dialog_submit_button_text')}
        </LoadingButton>
      </Box>
    </Grid>
  );
};

export default DeleteAmenity;
