import { Box, FormHelperText, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import CustomInput from '@/src/components/forms/CustomInput';
import PhoneSelect from '@/src/components/phone-select/PhoneSelect';
import { ALFANUMERICO_REGEX } from '@/src/utilities/helpers/constants';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';

const GuestForm = ({
  errors,
  handleSubmit,
  setFieldValue,
  responseError,
  values,
  isLoadingGeneral,
  isLoadingSubmit,
}: any) => {
  const { t } = useTranslation();
  return isLoadingGeneral ? (
    <CircularSpinner />
  ) : (
    <Box component="form" onSubmit={handleSubmit} sx={{ marginTop: '1rem' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <CustomInput
            field="guestName"
            errors={errors}
            label={t('parking_modal_add_car_input_guest_name', { ns: 'parking' })}
            placeholder={t('parking_modal_add_car_placeholder_guest_name', { ns: 'parking' })}
            setFieldValue={setFieldValue}
            autoFocus
            values={values}
            maxLength={50}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomInput
            field="parkingNumber"
            errors={errors}
            label={t('parking_modal_add_car_input_parking_number', { ns: 'parking' })}
            placeholder={t('parking_modal_add_car_placeholder_parking_number', { ns: 'parking' })}
            setFieldValue={setFieldValue}
            values={values}
            maxLength={50}
            regex={ALFANUMERICO_REGEX}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomInput
            field="ticketNumber"
            errors={errors}
            label={t('parking_modal_add_car_input_ticket_number', { ns: 'parking' })}
            placeholder={t('parking_modal_add_car_placeholder_ticket_number', { ns: 'parking' })}
            setFieldValue={setFieldValue}
            values={values}
            autoFocus
            maxLength={50}
            regex={ALFANUMERICO_REGEX}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneSelect values={values} errors={errors} setFieldValue={setFieldValue} />
        </Grid>
        {responseError && (
          <Grid container justifyContent="center" mt={2}>
            <FormHelperText error>{responseError}</FormHelperText>
          </Grid>
        )}
        <Grid container item xs={12} sm={12} md={12} justifyContent="flex-end">
          <LoadingButton
            loading={isLoadingSubmit}
            variant="contained"
            size="small"
            type="submit"
            sx={{ width: { xs: '100%', sm: 'auto' } }}
          >
            {t('save')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GuestForm;
