import { useState } from 'react';
import { Activity } from './activities-section/types';

export const useActivitiesPage = () => {
  const [subGridIsOpen, setSubGridIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Activity>();

  const openSubGrid = (row: Activity) => {
    setSubGridIsOpen(true);
    setSelectedRow(row);
  };

  const closeSubGrid = () => setSubGridIsOpen(false);

  return { subGridIsOpen, selectedRow, openSubGrid, closeSubGrid };
};
