import { LoadingButton } from '@mui/lab';
import { Box, FormHelperText, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PdfFileUploader from '@/src/components/pdf-file-uploader/PdfFileUploader';
import { PDFContainer } from './TermsConditions.styled';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import { TermsConditionsProps } from '../../types';

const TermsConditions = ({
  values,
  setFieldValue,
  setFieldError,
  handleSubmit,
  isLoading,
  loadingGeneral,
  textError,
  errors,
  disableSubmitButton,
  setDisableSubmitButton,
}: TermsConditionsProps) => {
  const { t } = useTranslation();
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Box component="form" onSubmit={handleSubmit}>
        {loadingGeneral ? (
          <CircularSpinner />
        ) : (
          <Grid item xs={12} textAlign="center">
            <Typography variant="body1" mt={2}>
              {t('activities_tyc_sub_title', { ns: 'bookings' })}
            </Typography>
            <PDFContainer isDocument={!!values.document}>
              <PdfFileUploader
                values={values}
                setFieldValue={setFieldValue}
                displayTitle={!values.document}
                setError={setFieldError}
                setDisableButton={setDisableSubmitButton}
              />
            </PDFContainer>
            {(errors.document || textError) && <FormHelperText error>{errors.document || textError}</FormHelperText>}
            <LoadingButton loading={isLoading} variant="contained" type="submit" disabled={disableSubmitButton}>
              {t('activities_tyc_button_upload_file', { ns: 'bookings' })}
            </LoadingButton>
          </Grid>
        )}
      </Box>
    </Grid>
  );
};

export default TermsConditions;
