import { Delete } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDelete from '../../hooks/useDelete';
import useSnackBar from '../../useSnackBar';
import DeleteDialog from './DeleteDialog';

interface CanDeleteProps {
  id: number | string;
  resource: string;
  fake: boolean;
  pathApi: string;
}
const CanDelete = ({ id, resource, fake, pathApi }: CanDeleteProps) => {
  const { mutateDelete, isLoading } = useDelete(fake, pathApi);
  const [deleteModal, setDeleteModal] = useState(false);
  const { t } = useTranslation();
  const { setSnackBarMessage } = useSnackBar();

  const onDelete = (elementId: number) =>
    mutateDelete(elementId, {
      onSuccess: () => {
        setSnackBarMessage(
          t('successful_deletion_snackbar_text', {
            item: t(`${resource}_modal_text`),
            pronoun: ['activities', 'agendas', 'feed', 'categories', 'news', 'construction_progress'].includes(resource)
              ? 'La'
              : 'El',
          }),
        );
      },
      onError: () => {
        setSnackBarMessage(
          t('error_deletion_snackbar_text', {
            item: t(`${resource}_modal_text`),
            pronoun: ['activities', 'agendas', 'feed', 'categories', 'news', 'construction_progress'].includes(resource)
              ? 'una'
              : 'un',
          }),
          'error',
        );
      },
    });
  return (
    <>
      <Tooltip title={t('deletion_dialog_form_title', { resource })}>
        <Box onClick={() => setDeleteModal(true)}>
          <Delete sx={{ color: 'secondary.main', cursor: 'pointer' }} />
        </Box>
      </Tooltip>
      <DeleteDialog
        title={t('deletion_dialog_form_title', {
          resource: `${resource}_modal_text`,
        })}
        contentText={t('deleting_dialog_form_content_can_delete', {
          item: `${resource}_modal_text`,
          pronoun: ['activities', 'agendas', 'feed', 'categories', 'news', 'construction_progress'].includes(resource)
            ? 'esta'
            : 'este',
        })}
        open={deleteModal}
        setOpen={setDeleteModal}
        onDelete={() => onDelete(id as number)}
        deleteStatus={isLoading}
      />
    </>
  );
};

export default CanDelete;
