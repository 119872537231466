import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import i18n from 'i18next';
import './styles.css';
import { getLanguage } from '@/src/utilities/storage';

interface DatePickerProps {
  value: number | null;
  fieldValue?: string | null;
  isReadOnly?: boolean;
  minDate?: number | Date | null;
  maxDate?: number | Date | null;
  onChange?: any;
  errorMessage?: any;
  placeholder?: string;
  required?: boolean;
  width?: any;
  minWidth?: any;
}

const CustomToolbar = (value: Date | null) => {
  const { t } = useTranslation('date');
  if (value) {
    const arrOfVals = String(value).split(' ');
    const format = `${t(`${arrOfVals[0]}`)}, ${t(`${arrOfVals[1]}`, {
      ns: 'date',
    })} ${arrOfVals[2]} - ${arrOfVals[3]}`;

    return (
      <Box component="div" sx={{ pl: 2.5, pt: 2 }}>
        <Typography variant="h5">{format}</Typography>
      </Box>
    );
  }
  return (
    <Box component="div" sx={{ pl: 2.5, pt: 2 }}>
      <Typography variant="h5">{t('select_date')}</Typography>
    </Box>
  );
};

export const CustomDatePicker = ({
  value,
  fieldValue,
  isReadOnly,
  minDate,
  maxDate,
  onChange,
  errorMessage,
  placeholder,
  required,
  width,
  minWidth,
}: DatePickerProps) => {
  const { t } = useTranslation('date');
  const locale = new Date(value ?? new Date().getTime());

  const lang = getLanguage()?.split('-')[0];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      <MobileDatePicker
        disabled={isReadOnly}
        inputFormat={lang === 'es' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
        dayOfWeekFormatter={(day: string) => day.charAt(0).toUpperCase()}
        ToolbarComponent={() =>
          CustomToolbar(
            String(value) === '' ? null : new Date(locale.getUTCFullYear(), locale.getUTCMonth(), locale.getUTCDate()),
          )
        }
        label={placeholder || `${t(fieldValue || '')}${required ? ' * ' : ''}`}
        value={value === null ? value : new Date(locale.getUTCFullYear(), locale.getUTCMonth(), locale.getUTCDate())}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth={!width}
            size="small"
            id="outlined-size-small"
            variant="outlined"
            error={errorMessage}
            helperText={errorMessage}
            sx={{
              width,
              minWidth,
              input: {
                WebkitTextFillColor: isReadOnly ? 'rgba(0,0,0,0.6) !important' : '',
                color: isReadOnly ? 'rgba(0,0,0,0.7) !important' : '',
                opacity: isReadOnly ? 0.7 : '',
                backgroundColor: isReadOnly ? 'rgba(0,0,0,0.05) ' : '',
              },
              label: {
                color: isReadOnly ? 'rgba(0,0,0,1) !important' : '',
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
