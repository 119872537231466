import { Grid, List, ListItem, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import St from './UserSecurityDetail.styled';
import ConfirmDialog from '@/src/components/confirm-dialog/ConfirmDialog';

const UserSecurityDetail = ({
  t,
  enabled,
  mfa_settings,
  isOpen,
  close,
  open,
  userName,
  onConfirmReset,
  isLoadingReset,
}: any) => (
  <Grid container item xs={12} sm={12} flexDirection="column">
    <Typography fontSize="16px" fontWeight="600" marginTop="10px">
      {t('security')}
    </Typography>
    <St.Divider />
    <Typography variant="subtitle2">
      {t(enabled ? 'security_detail_subtitle' : 'not_authenticated_user_message')}
    </Typography>
    <List
      sx={{
        listStyleType: 'disc',
        pl: 3,
        '& .MuiListItem-root': {
          display: 'list-item',
          padding: '0px',
        },
      }}
    >
      {mfa_settings?.map((type: string) => (
        <ListItem key={type}>
          <Typography variant="subtitle2">{type}</Typography>
        </ListItem>
      ))}
    </List>
    {enabled && (
      <St.ButtonContainer variant="button" onClick={open}>
        <LoadingButton size="small" variant="contained" onClick={open}>
          {t('reset')}
        </LoadingButton>
      </St.ButtonContainer>
    )}
    <ConfirmDialog
      title={t('reset')}
      contentText={t('reset_admin_mfa_modal_content', {
        resource: userName,
      })}
      open={isOpen}
      onConfirm={onConfirmReset}
      handleClose={close}
      isLoading={isLoadingReset}
    />
  </Grid>
);

export default UserSecurityDetail;
