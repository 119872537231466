import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import InfoLabel from '@/src/components/info-label/InfoLabel';
import CustomTextArea from '@/src/components/forms/text-area';
import TitleWithStatus from '@/src/components/title-with-status/TitleWithStatus';
import { approveVehicle, getVehicle, rejectVehicle } from '@/src/api/endpoints/houseHold';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import { VehiclesDetail, VehiclesModalsProps } from '../types';
import ApproveAndReject from '@/src/components/approve-reject/ApproveAndReject';
import ImageShow from '@/src/components/image-show/ImageShow';
import PdfShowFile from '@/src/components/pdf-show-file/PdfShowFile';
import { commonStatus } from '../../common/types';

export const VehiclesDetailModal = (props: VehiclesModalsProps) => {
  const { row, close, showButtons, setSnackBarMessage } = props;
  const [state, setState] = useState(false);
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery({
    queryKey: [`vehicle_${row.id}`],
    queryFn: () => getVehicle({ id: row?.vehicle?.id, residencyId: row?.residency?.id }),
    select: (response) => response.data.vehicle_residency as VehiclesDetail,
  });

  const { t } = useTranslation();
  const values = data?.vehicle;

  useEffect(
    () =>
      function cleanUp() {
        queryClient.resetQueries([`vehicle_${row?.id}`]);
      },
    [],
  );

  return (
    <Box component="div" sx={{ marginY: '5px' }}>
      <Grid container spacing={2}>
        <TitleWithStatus
          title={t('vehicles_detail', { ns: 'cdtModal' })}
          statusText={values ? values?.state?.name : ''}
          statusBg={values ? commonStatus[values?.state?.name.replaceAll(' ', '_').replaceAll('ó', 'o')] : ''}
          onClose={close}
        />
        <Grid item xs={12}>
          <Divider sx={{ mt: '-15px' }} />
        </Grid>
        {isLoading || !values ? (
          <CircularSpinner />
        ) : (
          <>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('user_detail_apartment', { ns: 'customerUsers' })}
                second={data?.residency?.property?.code ?? '-'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel first={t('vehicle_brand', { ns: 'customerUsers' })} second={values?.model?.brand?.name} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel first={t('vehicle_model', { ns: 'customerUsers' })} second={values?.model?.name} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel first={t('vehicle_color', { ns: 'customerUsers' })} second={values?.color?.name} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel first={t('vehicle_alias', { ns: 'customerUsers' })} second={values?.alias} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('fuel_type_value', { ns: 'customerUsers' })}
                second={values?.fuel_type?.name ?? '-'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel first={t('vehicle_plate', { ns: 'customerUsers' })} second={values?.license_plate} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel first={t('comments', { ns: 'customerUsers' })} second={values.comments ? '' : '-'} />
              {values.comments && (
                <CustomTextArea
                  minRows={3}
                  value={values?.comments}
                  maxLength={500}
                  disabled
                  style={{
                    borderRadius: '4px',
                    padding: '4px',
                    backgroundColor: 'transparent',
                    resize: 'none',
                    width: '100%',
                    height: '70px !important',
                    font: 'inherit',
                    letterSpacing: 'inherit',
                    border: 'none',
                    fontSize: '14px',
                    overflow: 'auto!important',
                    margin: '5px 0px',
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel first={t('is_rented', { ns: 'customerUsers' })} second={values?.is_rented ? t('yes') : 'No'} />
            </Grid>
            {values.file_url && (
              <Grid item xs={12}>
                <InfoLabel first={t('attachedFile', { ns: 'customerUsers' })} second="" />
                {values?.file_url.slice(-3, values?.file_url.length) === 'pdf' ? (
                  <PdfShowFile file={values?.file_url} />
                ) : (
                  <ImageShow
                    url={values?.file_url}
                    open={state}
                    handleOpen={() => setState(true)}
                    handleClose={() => setState(false)}
                  />
                )}
              </Grid>
            )}
            {values.rejection_reason && (
              <Grid item xs={12}>
                <InfoLabel first={t('rejection_reason_title', { ns: 'customerUsers' })} second="" />
                <CustomTextArea
                  placeholder={t('reason_rejection_placeholder', { ns: 'cdtModal' })}
                  minRows={3}
                  style={{ width: '100%', height: '40px', outline: 'none' }}
                  value={values?.rejection_reason}
                  maxLength={500}
                  disabled
                />
              </Grid>
            )}
            {showButtons && row?.vehicle?.state?.code !== 'Approved' && (
              <Grid item xs={12}>
                <ApproveAndReject
                  close={close}
                  fnApprove={approveVehicle}
                  fnReject={rejectVehicle}
                  row={row}
                  setSnackBarMessage={setSnackBarMessage}
                  resource="vehicle"
                  reasonRejection={values?.state?.code !== 'InReview'}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};
