import { useTranslation } from 'react-i18next';
import { Visibility } from '@mui/icons-material';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import AddIcon from '@mui/icons-material/Add';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { guestParkingStates, GuestsParking } from './types';
import {
  getDayHourYearDateEn,
  getDayHourYearDateEs,
  getFormattedDateByLanguague,
} from '@/src/utilities/helpers/dateParser';
import RequestProcessingModal from './request-processing-modal/RequestProcessingModal';
import RequestDetail from './request-detail/RequestDetail';
import TextLabel from '@/src/components/text-label/TextLabel';
import { ActionColumn, ActionHeader } from '@/src/components/custom-data-table/types/DataTableFormModal';
import GuestFormContainer from './form/GuestFormContainer';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import { getLanguage } from '@/src/utilities/storage';
import useParkingStates from '@/src/hooks/filters/parkingStates.hooks';

export const useColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const { parkingStates } = useParkingStates();
  const columns: CustomDataTableColumnsProps<GuestsParking>[] = [
    {
      id: 'internal_code',
      name: t('packages_page_table_column_idInternal', { ns: 'packages' }),
      selector: (row: GuestsParking) => row.id,
      sortField: 'id',
      sortable: true,
      maxWidth: '1%',
      filters: [
        {
          id: 'guests-parking-id',
          query: 'GuestParkingId',
          type: 'numeric',
        },
      ],
    },
    {
      id: 'parking_code',
      sortField: 'parking_code',
      name: t('guests_parking_number_table_col', { ns: 'parking' }),
      selector: (row: GuestsParking) => row.parking_code ?? '-',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'parking-code',
          query: 'ParkingCode',
          type: 'contains',
        },
      ],
      width: lang === 'es' ? '200px' : '150px',
    },
    {
      id: 'guest_name',
      sortField: 'guest_name',
      name: t('name'),
      selector: (row: GuestsParking) => row.guest_name,
      sortable: true,
      filters: [
        {
          id: 'guest-full-name',
          query: 'GuestName',
          type: 'contains',
        },
      ],
      minWidth: '150px',
    },
    {
      id: 'phone_number',
      sortField: 'phone_number',
      name: t('phone_number'),
      selector: (row: GuestsParking) => row.full_phone_number,
      sortable: true,
      filters: [
        {
          id: 'guest-full-phoneNumber',
          query: 'GuestFullPhoneNumber',
          type: 'contains',
        },
      ],
      width: '160px',
    },
    {
      id: 'guests_ticket_code',
      sortField: 'ticket_code',
      name: t('guests_ticket_code', { ns: 'parking' }),
      selector: (row: GuestsParking) => row.ticket_code ?? '-',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'ticket-Code',
          query: 'TicketCode',
          type: 'contains',
        },
      ],
      width: lang === 'es' ? '230px' : '160px',
    },
    {
      id: 'creation_date',
      sortField: 'creation_date',
      name: t('guests_parking_creation_date_table_col', { ns: 'parking' }),
      selector: (row: GuestsParking) =>
        getFormattedDateByLanguague(lang, getDayHourYearDateEs, getDayHourYearDateEn, row.creation_date),
      sortable: true,
      center: true,
      filters: [
        {
          id: 'guests-creation-date',
          query: 'CreationDate',
          type: 'date',
        },
      ],
      minWidth: lang === 'es' ? '180px' : '140px',
    },
    {
      id: 'request_date',
      sortField: 'request_date',
      name: t('guests_parking_request_date_table_col', { ns: 'parking' }),
      selector: (row: GuestsParking) =>
        row.request_date
          ? getFormattedDateByLanguague(lang, getDayHourYearDateEs, getDayHourYearDateEn, row.request_date)
          : '-',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'guests-request-date',
          query: 'RequestDate',
          type: 'date',
        },
      ],
      minWidth: lang === 'es' ? '230px' : '150px',
    },
    {
      id: 'guest_parking_state',
      sortField: 'guest_parking_state',
      name: t('guests_parking_status_table_col', { ns: 'parking' }),
      selector: (row: GuestsParking) => row.guest_parking_state?.name,
      sortable: true,
      center: true,
      cell: (row: GuestsParking) => (
        <TextLabel
          text={row.guest_parking_state?.name.toLocaleUpperCase()}
          color="#F7F7F7"
          padding="2px 12px"
          width="100px"
          bg={guestParkingStates[row.guest_parking_state?.name]}
        />
      ),
      filters: parkingStates,
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'detail',
      icon: (props: any) => <Visibility sx={props.sx} />,
      component: (props: any) => <RequestDetail {...props} />,
      width: { xs: '90%', md: '70%', lg: '760px' },
      customizedTitle: true,
    },
    {
      id: 'process',
      icon: (props: any) => <NoCrashIcon sx={props.sx} />,
      component: (props: any) => <RequestProcessingModal {...props} />,
      isHidden: (props: any) => ['Procesado', 'Processed'].includes(props.guest_parking_state?.name),
      width: '18%',
    },
  ];

  const headerActions: ActionHeader[] = [
    {
      id: 'guest-parking-create',
      component: () => (
        <CustomTableAction
          modalTitle="guest_parking"
          component={(props: any) => <GuestFormContainer {...props} />}
          Icon={() => <AddIcon />}
          type="create"
          width={{ xs: '90%', sm: '470px' }}
        />
      ),
    },
  ];

  return { columns, actionColumns, headerActions };
};
