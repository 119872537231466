export const capitalizeFirstLetter = (string: string) => `${string.charAt(0).toUpperCase()}${string?.slice(1)}`;

export const lowerFirstLetter = (string: string) => `${string.charAt(0).toLowerCase()}${string?.slice(1)}`;

export const formatPhoneNumber = (phoneNumber: string) => {
  const phoneNumberSplitted = phoneNumber.split(' ');
  const country = phoneNumberSplitted[0];
  const number = phoneNumberSplitted[1];

  switch (country) {
    case '+1':
      return `${country} (${number.slice(0, 3)}) ${number.slice(3, 7)}-${number.slice(7)}`;
    case '+549':
      return `${country.slice(0, 3)} ${country.charAt(3)} ${number.slice(0, 2)} ${number.slice(2, 6)} ${number.slice(
        6,
      )}`;
    default:
      return phoneNumber;
  }
};

export const toUpperCase = (str: string) => str.toUpperCase();

export const zeroFill = (value: string, width: number) => {
  const zeroQuantity = width - value.toString().length;
  if (zeroQuantity > 0) {
    return new Array(zeroQuantity + (/\./.test(value) ? 2 : 1)).join('0') + value;
  }
  return `${value}`;
};

export const isValidDigit = (keyCode: number) => (keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90);

export const physicalAddress = (
  streetName: string,
  streetNumber: string,
  apartment_number: string | null,
  floor_number: string | null,
) => {
  const endAddress = `${streetName || ''} ${streetNumber || ''} ${floor_number || ''}${
    apartment_number ? `-${apartment_number}` : ''
  }`;
  return endAddress;
};
