import { axiosAuthenticationInstance } from '../instances/authInstance';
import { CreateAdminUserRequest, EditAdminUserRequest, EditUserInfoRequest } from '../types/types';

export const createAdminUser = (req: CreateAdminUserRequest) =>
  axiosAuthenticationInstance.post(`/accounts/v1/admins`, req);
export const editAdminUser = (req: EditAdminUserRequest) =>
  axiosAuthenticationInstance.put(`/accounts/v1/admins/${req.id}`, req.body);
export const getAdminGroups = () => axiosAuthenticationInstance.get(`/accounts/v1/admin-groups`);
export const getUserInfo = () => axiosAuthenticationInstance.get('accounts/v1/admins/profile');
export const editUserInfo = (req: EditUserInfoRequest) => axiosAuthenticationInstance.put(`accounts/v1/admins`, req);
export const getAdminInfo = (id: string) => axiosAuthenticationInstance.get(`/accounts/v1/admins/${id}`);
