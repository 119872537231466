/* eslint-disable prettier/prettier */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import * as en from './en';
import * as es from './es';

const resources = {
  en,
  es,
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    ns: Object.keys(en),
    resources,
  });

export default i18n;
