import { Alert, AlertColor, Snackbar } from '@mui/material';
import React, { useState, useCallback } from 'react';

const useSnackBar = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  const setSnackBarMessage = useCallback((msj: string, sever: AlertColor = 'success') => {
    setOpen(true);
    setSeverity(sever);
    setMessage(msj);
  }, []);

  const closeModal = () => {
    setOpen(false);
  };

  const SnackBar = React.useMemo(() => {
    const SnackBarComponent = () => (
      <Snackbar
        open={open}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        autoHideDuration={6000}
        onClose={closeModal}
        message={message}
      >
        <Alert onClose={closeModal} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    );
    return SnackBarComponent;
  }, [open, severity, message]);

  return {
    SnackBar,
    setSnackBarMessage,
  };
};

export default useSnackBar;
