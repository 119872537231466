import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Box, Button, DialogContentText, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { deletePackage } from '@/src/api/endpoints/packages';
import { DeletePackageProps } from '../../types';

const DeletePackage = (props: DeletePackageProps) => {
  const { t } = useTranslation();
  const { row, close, setSnackBarMessage } = props;
  const [deletionFailed, setDeletionFailed] = React.useState<string | null>(null);
  const { mutate, isLoading } = useMutation(deletePackage, {
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successful_package_deletion_snackbar_text', { ns: 'packages' }));
    },
    onError: async () => {
      setDeletionFailed(t('failed_booking_cancelation_text', { ns: 'packages' }));
    },
  });
  return (
    <Grid>
      <DialogContentText id="alert-dialog-description" py={1}>
        {t('delete_package_header_text', { ns: 'packages' })}
      </DialogContentText>
      <ErrorText error={deletionFailed as string} />
      <Box component="div" sx={{ widht: '100%', textAlign: 'right', pt: 1 }}>
        <Button size="small" type="button" variant="outlined" color="secondary" onClick={close} sx={{ mr: 1 }}>
          {t('delete_dialog_cancel_button_text')}
        </Button>
        <LoadingButton
          loading={isLoading}
          size="small"
          type="button"
          variant="contained"
          onClick={() => mutate(row.internal_code as number)}
        >
          {t('delete_dialog_submit_button_text')}
        </LoadingButton>
      </Box>
    </Grid>
  );
};

export default DeletePackage;
