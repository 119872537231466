import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { DeleteCharge } from './DeleteCharge';
import { deleteCharge } from '@/src/api/endpoints/residents';
import { CustomDataTablePropsCharge } from '../../../residents/account-state-section/types';

const DeleteChargeContainer = ({ row, close, setSnackBarMessage }: CustomDataTablePropsCharge) => {
  const { t } = useTranslation();
  const resource = 'charge';
  const { mutate } = useMutation(deleteCharge, {
    onError: async () => {
      close();
      setSnackBarMessage(t('delete_charge_message_error', { ns: 'residents' }), 'error');
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('delete_charge_message_success', { ns: 'residents' }));
    },
  });

  const { id } = row;

  const childProps = {
    onConfirm: () => mutate(id),
    handleClose: () => close(),
    resource,
  };

  return <DeleteCharge {...childProps} />;
};

export default DeleteChargeContainer;
