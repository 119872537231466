import { axiosAuthenticationInstance } from '../instances/authInstance';
import { CreatePaymentRequest, UpdatePaymentRequest } from '../types/types';

export const createPayment = (req: CreatePaymentRequest) =>
  axiosAuthenticationInstance.post(`/payments/v1/payments`, req);
export const editPayment = (req: UpdatePaymentRequest) =>
  axiosAuthenticationInstance.put(`/payments/v1/payment/${req.id}`, req.body);
export const getPayments = () => axiosAuthenticationInstance.get(`/payments/v1/payments`);
export const getPaymentById = (id: string) => axiosAuthenticationInstance.get(`/payments/v1/payments/${id}`);

export const getAccountBalance = (residencyId: number) =>
  axiosAuthenticationInstance.get(`payments/v1/residencies/${residencyId}/balances`);

export const getPaymentStates = () => axiosAuthenticationInstance.get(`/payments/v1/payment-states`);
