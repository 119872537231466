import { axiosAuthenticationInstance } from '../instances/authInstance';
import { CreatePerksBenefitsRequest, EditPerksBenefitsRequest } from '../types/types';

export const getPerksBenefitsCustomer = () => axiosAuthenticationInstance.get(`feed/v1/customers/perks-benefits`);
export const getPerksBenefitsByIdCustomer = (id: number) =>
  axiosAuthenticationInstance.get(`feed/v1/customers/perks-benefits/${id}`);
export const getPerksBenefits = () => axiosAuthenticationInstance.get(`feed/v1/perks-benefits`);
export const getPerksBenefitsById = (id: number) => axiosAuthenticationInstance.get(`feed/v1/perks-benefits/${id}`);
export const createPerkBenefit = (req: CreatePerksBenefitsRequest) =>
  axiosAuthenticationInstance.post('feed/v1/perks-benefits', req);
export const editPerkBenefit = (req: EditPerksBenefitsRequest) =>
  axiosAuthenticationInstance.put(`feed/v1/perks-benefits/${req.id}`, req.body);
export const deletePerkBenefit = (id: number) => axiosAuthenticationInstance.delete(`feed/v1/perks-benefits/${id}`);
