import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import DownloadIcon from '@mui/icons-material/Download';
import { VisibilityRounded } from '@mui/icons-material';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import DocumentsPage from './DocumentsPage';
import { DocumentType } from './types';
import { ActionHeader } from '../residents/residents-detail-section/types';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import DocumentsSectionForm from './documents-form';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';
import DocumentsDetail from './documents-detail';
import { handleDownloadDocument } from '@/src/utilities/helpers/downloadHelpers';
import useDocumentsCategories from '@/src/hooks/filters/documentsCategories.hooks';
import useDocumentsStates from '@/src/hooks/filters/documentsStates.hooks copy';

export const DocumentsPageContainer = () => {
  const { t } = useTranslation();
  const { categoriesList } = useDocumentsCategories();
  const { documentsStates } = useDocumentsStates();

  const columns: CustomDataTableColumnsProps<DocumentType>[] = [
    {
      id: 'description',
      name: t('documents_list_description_column', { ns: 'documents' }),
      selector: (row) => row.description,
      sortField: 'description',
      sortable: true,
      filters: [
        {
          id: 'description',
          query: 'description',
          type: 'contains',
        },
      ],
    },
    {
      id: 'category',
      name: t('documents_list_category_column', { ns: 'documents' }),
      selector: (row) => row?.category?.name,
      sortField: 'category_name',
      sortable: true,
      filters: categoriesList,
    },
    {
      id: 'is_public',
      name: t('documents_list_is_public_column', { ns: 'documents' }),
      selector: (row) =>
        row.is_public
          ? (t('documents_list_is_public_column_public', { ns: 'documents' }) as string)
          : (t('documents_list_is_public_column_targeted', { ns: 'documents' }) as string),
      sortField: 'is_public',
      sortable: true,
      filters: documentsStates,
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'detail',
      icon: (props: any) => <VisibilityRounded sx={props.sx} />,
      component: (props: any) => <DocumentsDetail {...props} />,
      width: { xs: '100%', sm: '500px', lg: '800px' },
    },
    {
      id: 'download',
      icon: (props: any) => <DownloadIcon sx={props.sx} />,
      onIconClick: (row: any) => handleDownloadDocument(row?.file_url),
    },
  ];

  const headerActions: ActionHeader[] = [
    {
      id: 'document-create',
      component: () => (
        <CustomTableAction
          modalTitle="document"
          component={(props: any) => <DocumentsSectionForm {...props} />}
          Icon={() => <AddIcon />}
          type="create"
          width="60%"
        />
      ),
    },
  ];

  const childProps = {
    columns,
    headerActions,
    actionColumns,
    t,
  };

  return <DocumentsPage {...childProps} />;
};

export default DocumentsPageContainer;
