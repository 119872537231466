import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import CategoriesPage from './CategoriesPage';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import { ActionHeader } from '../../residents/residents-detail-section/types';
import { Category } from './types';
import CategoriesForm from './categories-form';

const CategoriesContainer = () => {
  const { t } = useTranslation();

  const columns: CustomDataTableColumnsProps<Category>[] = [
    {
      id: 'name',
      name: t('categorie_name', { ns: 'categories' }),
      selector: (row) => row.name,
      sortField: 'name',
      sortable: true,
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'edit',
      icon: (props: any) => <EditIcon sx={props.sx} />,
      component: (props: any) => <CategoriesForm isEdit {...props} />,
    },
  ];

  const headerActions: ActionHeader[] = [
    {
      id: 'categories',
      component: () => (
        <CustomTableAction
          modalTitle="categories"
          component={(props: any) => <CategoriesForm {...props} />}
          Icon={() => <AddIcon />}
          type="create"
          width={{ xs: '50%', sm: '40%' }}
        />
      ),
    },
  ];

  const childProps = {
    columns,
    actionColumns,
    headerActions,
  };

  return <CategoriesPage {...childProps} />;
};

export default CategoriesContainer;
