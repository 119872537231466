import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  CardMedia,
  FormControlLabel,
} from '@mui/material';
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded';
import { LoadingButton } from '@mui/lab';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { useQueryClient } from '@tanstack/react-query';
import { VehiclesModalsProps } from '../types';
import TitleWithStatus from '@/src/components/title-with-status/TitleWithStatus';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import CustomInput from '@/src/components/forms/CustomInput';
import { ErrorHelper } from '@/src/components/error/error-helper/ErrorHelper';
import CustomTextArea from '@/src/components/forms/text-area';
import { GridImage } from '@/src/pages/bookings/amenities/abm/create-edit-amenity/CreateEditAmenity.styled';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { commonStatus } from '../../common/types';

export const VehicleForm = (props: VehiclesModalsProps) => {
  const {
    isEdit,
    row,
    close,
    errorMessage,
    isSubmitLoading,
    loadingGeneral,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    getUrlIn64,
    isLoadingImage,
    deleteImage,
    wrongImage,
    brandsData,
    modelsData,
    colorsData,
    fuelData,
    setBrandId,
  } = props;
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  useEffect(
    () =>
      function cleanUp() {
        queryClient.resetQueries([`vehicle_${row?.id}`]);
      },
    [],
  );

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ marginY: '5px' }}>
      <Grid container spacing={2}>
        <TitleWithStatus
          title={isEdit ? t('vehicles_edit', { ns: 'cdtModal' }) : t('vehicles_create', { ns: 'cdtModal' })}
          statusText={values ? values?.state?.name : ''}
          statusBg={values ? commonStatus[values?.state?.name?.replaceAll(' ', '_').replaceAll('ó', 'o')] : ''}
          onClose={close}
        />
        <Grid item xs={12}>
          <Divider sx={{ mt: '-15px' }} />
        </Grid>
        {isSubmitLoading || loadingGeneral || !values ? (
          <CircularSpinner />
        ) : (
          <>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="brand"
                sx={{ width: '100%' }}
                size="small"
                value={brandsData?.find((property) => values?.brand?.id === property?.id) ?? null}
                getOptionLabel={(option) => option?.name ?? ''}
                options={brandsData ?? []}
                onChange={(e, value) => {
                  setFieldValue('brand', value);
                  setBrandId(value?.id);
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    sx={{ width: '100%' }}
                    variant="outlined"
                    label={t('vehicle_brand', { ns: 'customerUsers' })}
                    placeholder={t('vehicle_form_brand_field_placeholder', { ns: 'customerUsers' })}
                    error={errors.brand !== undefined}
                    helperText={errors.brand?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="model"
                sx={{ width: '100%' }}
                size="small"
                value={modelsData?.find((model) => values?.model?.id === model?.id) ?? null}
                getOptionLabel={(option) => option?.name ?? ''}
                options={modelsData ?? []}
                onChange={(e, value) => {
                  setFieldValue('model', value);
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    sx={{ width: '100%' }}
                    variant="outlined"
                    label={t('vehicle_model', { ns: 'customerUsers' })}
                    placeholder={t('vehicle_form_model_field_placeholder', { ns: 'customerUsers' })}
                    error={errors.model !== undefined}
                    helperText={errors.model?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                field="alias"
                errors={errors}
                label={t('vehicle_alias', { ns: 'customerUsers' })}
                placeholder={t('vehicle_form_alias_field_placeholder', { ns: 'customerUsers' })}
                setFieldValue={setFieldValue}
                values={values}
                autoFocus
                maxLength={50}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="color"
                sx={{ width: '100%' }}
                size="small"
                value={colorsData?.find((color) => values?.color?.id === color?.id) ?? null}
                getOptionLabel={(option) => option?.name ?? ''}
                options={colorsData ?? []}
                onChange={(e, value) => {
                  setFieldValue('color', value);
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    sx={{ width: '100%' }}
                    variant="outlined"
                    label={t('vehicle_color', { ns: 'customerUsers' })}
                    placeholder={t('vehicle_form_color_field_placeholder', { ns: 'users' })}
                    error={errors.color !== undefined}
                    helperText={errors.color?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="fuelType"
                sx={{ width: '100%' }}
                size="small"
                value={fuelData?.find((fuel) => values?.fuelType?.id === fuel?.id) ?? null}
                getOptionLabel={(option) => option?.name ?? ''}
                options={fuelData ?? []}
                onChange={(e, value) => {
                  setFieldValue('fuelType', value);
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    sx={{ width: '100%' }}
                    variant="outlined"
                    label={t('vehicle_fuelType', { ns: 'customerUsers' })}
                    placeholder={t('vehicle_form_fuelType_field_placeholder', { ns: 'users' })}
                    error={errors.fuelType !== undefined}
                    helperText={errors.fuelType?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                field="licensePlate"
                errors={errors}
                label={t('vehicle_licensePlate', { ns: 'customerUsers' })}
                placeholder={t('vehicle_form_licensePlate_field_placeholder', { ns: 'customerUsers' })}
                setFieldValue={setFieldValue}
                values={values}
                autoFocus
                maxLength={50}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              sx={{ alignItems: 'center', width: '100%', marginBottom: { xs: '5px', sm: 0 } }}
            >
              <FormControlLabel
                onChange={() => {
                  setFieldValue('rentedCar', !values.rentedCar);
                }}
                control={<Checkbox checked={!!values.rentedCar} />}
                label={t('rentedCar', { ns: 'customerUsers' })}
              />
            </Grid>
            <Grid item xs={12} sm={6} display="flex">
              <CustomTextArea
                id="notes"
                placeholder={t('vehicle_form_notes_field_placeholder', { ns: 'customerUsers' })}
                minRows={3}
                style={{ width: '100%', height: '40px' }}
                value={values.notes}
                onChange={(e) => {
                  setFieldValue('notes', e.target.value);
                }}
                maxLength={500}
              />
            </Grid>
            {values.attachedFile ? (
              <Grid
                item
                xs={24}
                sm={12}
                display="flex"
                sx={{ alignItems: 'center', width: '100%', marginBottom: { xs: '5px', sm: 0 } }}
              >
                <Box
                  component="div"
                  sx={{
                    borderRadius: '20px',
                    position: 'relative',
                  }}
                >
                  <IconButton
                    onClick={() => deleteImage()}
                    sx={{
                      position: 'absolute',
                      top: -15,
                      right: -26,
                    }}
                  >
                    <CancelRoundedIcon />
                  </IconButton>
                  <CardMedia
                    component="img"
                    height={130}
                    image={values.attachedFile}
                    alt="images"
                    sx={{ borderRadius: '10px' }}
                  />
                </Box>
              </Grid>
            ) : (
              <GridImage
                item
                xs={24}
                sm={12}
                display="flex"
                sx={{ alignItems: 'center', width: '100%', marginBottom: { xs: '5px', sm: 0 } }}
              >
                {isLoadingImage ? (
                  <CircularSpinner />
                ) : (
                  <InputLabel htmlFor="imageHeader" style={{ margin: 'auto' }}>
                    <IconButton>
                      <InputLabel htmlFor="imageHeader">
                        <input
                          id="imageHeader"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => getUrlIn64(e)}
                          style={{ display: 'none' }}
                          type="file"
                          accept=".jpg, .jpeg, .HEIC, .png, .svg"
                        />
                        <AddPhotoAlternateRoundedIcon fontSize="large" />
                      </InputLabel>
                    </IconButton>
                  </InputLabel>
                )}
                <ErrorText error={(wrongImage?.code as string) || (errors.attachedFile as string)} />
              </GridImage>
            )}
          </>
        )}
        {errorMessage && (
          <Grid item xs={12} sm={8} md={6}>
            <ErrorHelper error={errorMessage} />
          </Grid>
        )}
        <Grid container item xs={12} justifyContent="flex-end">
          <LoadingButton
            loading={isSubmitLoading}
            variant="contained"
            size="small"
            type="submit"
            sx={{ width: { xs: '100%', sm: 'auto' } }}
          >
            {isEdit
              ? t('form_button_submit_text', { ns: 'users' })
              : t('form_button_submit_text_create', { ns: 'users' })}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};
