import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

export const PetsRequestPage = () => {
  const { columnsPetsRequests, actionColumnsPetsRequestPage } = useColumns();
  const { t } = useTranslation();
  return (
    <CustomDataTable
      title={t('pets_requests', { ns: 'customerUsers' })}
      columns={columnsPetsRequests}
      resource="pet_residencies"
      pathApi="pets/v1/pet-residencies"
      listName="pet_residencies"
      extraFilters={[
        { query: 'PetStateCodes', queryValue: 'InReview' },
        { query: 'PetStateCodes', queryValue: 'Rejected' },
      ]}
      canExport
      canSearch
      customActionColumns={actionColumnsPetsRequestPage}
      defaultSortFieldId="pet_creation_date"
      defaultSortAsc
    />
  );
};
