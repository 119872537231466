import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormBox = styled('form')(({ theme }) => ({
  padding: '13px',
  display: 'flex',
  flexWrap: 'nowrap',
  gap: '5px',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}));

export const CustomBox = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    width: { xs: '220px', md: '20%' },
    gap: { xs: 0, md: '5px' },
    justifyContent: { sm: 'space-between', lg: 'flex-start' },
  }),
);
