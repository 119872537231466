import React from 'react';
import { useTranslation } from 'react-i18next';
import { useColumns } from './useColumns';
import { UsersSection } from './users-section/UsersSection';
import { UsersDetailSection } from './users-detail-section/UsersDetailSection';
import { BlacklistSection } from './blacklist-section/BlacklistSection';
import ResidentsDetailSection from '../../residents/residents-detail-section';

export const UsersPage = ({ isBlacklist }: { isBlacklist?: boolean }) => {
  const {
    isDetailSectionOpen,
    openDetailSection,
    closeDetailSection,
    personSelected,
    openResidentSection,
    closeResidentSection,
    isResidentSectionOpen,
    residentDetail,
  } = useColumns(isBlacklist);
  const { t } = useTranslation();

  if (isResidentSectionOpen && residentDetail)
    return <ResidentsDetailSection selectedRow={residentDetail} closeSubGrid={closeResidentSection} />;

  if (isDetailSectionOpen)
    return (
      <UsersDetailSection
        closeDetailSecion={closeDetailSection}
        openResidentSection={openResidentSection}
        personSelected={personSelected}
        t={t}
      />
    );

  return isBlacklist ? (
    <BlacklistSection openDetailSection={openDetailSection} />
  ) : (
    <UsersSection openDetailSection={openDetailSection} />
  );
};
