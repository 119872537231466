import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterSearch from './FilterSearch';
import { FilterByColumn } from '../../../types/CustomDataTableColumnProps';

const FilterSearchContainer = (props: FilterSearchContainerProps) => {
  const { filter } = props;
  const { t } = useTranslation();
  const allFilters = Array.isArray(filter?.value) ? filter?.value : [];

  const [currentValue, setCurrentValue] = React.useState('');
  const [visibleItemCount, setVisibleItemCount] = React.useState<number>(30);

  const loadMoreData = () => setVisibleItemCount((prevCount) => prevCount + 30);

  const filters: FilterByColumn[] = React.useMemo(() => {
    if (currentValue === '' || !currentValue) {
      return allFilters;
    }

    const lowercasedValue = currentValue?.toLowerCase();
    const filteredArray = allFilters?.filter((filterValue) => {
      const label = filterValue?.label;

      if (Array.isArray(label)) {
        return label?.some((item) => item.toLowerCase().includes(lowercasedValue));
      }
      return label?.toLowerCase().includes(lowercasedValue);
    });

    setVisibleItemCount(30);
    return filteredArray;
  }, [currentValue]);

  const childProps = {
    ...props,
    t,
    handleChange: (value: string) => setCurrentValue(value),
    currentValue,
    isAllDataVisible: filters?.length <= visibleItemCount,
    filters: filters.slice(0, visibleItemCount),
    showSearchBar: allFilters?.length >= 30,
    loadMoreData,
  };

  return <FilterSearch {...childProps} />;
};

interface FilterSearchContainerProps {
  setLocalFilterString: React.Dispatch<React.SetStateAction<string>>;
  filter: FilterByColumn;
  localFilterString: string;
}

export default FilterSearchContainer;
