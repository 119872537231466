import { useTranslation } from 'react-i18next';
import { CheckOutComponent } from './CheckOut';
import useCheckOut from '@/src/components/custom-data-table/hooks/useCheckOut';
import useSnackBar from '@/src/components/custom-data-table/useSnackBar';

const CheckOutContainer = ({ id, close, fake }: CheckOutContainerProps) => {
  const { t } = useTranslation();
  const { mutateCheckOut } = useCheckOut(fake);
  const { setSnackBarMessage } = useSnackBar();

  const onConfirm = (elementId: number) =>
    mutateCheckOut(elementId, {
      onSuccess: () => {
        setSnackBarMessage(t('successful_checkout_snackbar_text'));
      },
      onError: () => {
        setSnackBarMessage(t('error_checkout_snackbar_text'), 'error');
      },
    });

  const handleCloseDisable = () => {
    close();
  };

  const childProps = {
    id,
    onConfirm,
    handleClose: handleCloseDisable,
  };

  return <CheckOutComponent {...childProps} />;
};

interface CheckOutContainerProps {
  id: number;
  fake?: boolean;
  close: any;
}

export default CheckOutContainer;
