import EditIcon from '@mui/icons-material/Edit';
import {
  AlertColor,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import parse from 'html-react-parser';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { getMonthDayYearValue } from '@/src/utilities/helpers/dateParser';
import CustomLoader from '@/src/components/custom-loader';
import { FadeIn } from '@/src/components/animations';
import { LoaderContainer } from '@/src/components/loader-container/LoaderContainer';
import RenderMarketImages from '../../render-images/RenderImages';
import DeleteDialog from '@/src/components/dialogs/DeleteDialog';
import PdfShowFile from '@/src/components/pdf-show-file/PdfShowFile';
import CustomCardMedia from '@/src/components/custom-card-media/CustomCardMedia';
import { CardAvatar } from '../../marketing-card/MarketingCard.styled';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { ArticleByIdBody } from '../types';
import { getTenant } from '@/src/utilities/helpers/utils';
import MarketingMaterialsModal from '../../marketing-materials-modal';

const MarketingDetail = (props: Props) => {
  const {
    isLoading,
    dataById,
    setOpenEditInformation,
    setDeleteModal,
    deleteModal,
    deleteMutation,
    onCloseArticleSelected,
    cleanHTML,
    openEditInformation,
    refetch,
    setSnackBarMessage,
    resource,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ paddingRight: '20px', overflow: 'auto', ml: '5px' }}>
        {isLoading ? (
          <LoaderContainer>
            <CustomLoader />
          </LoaderContainer>
        ) : (
          <FadeIn>
            <Card sx={{ borderRadius: '5px' }}>
              <CardHeader
                avatar={<CardAvatar aria-label="card-label">{getTenant()}</CardAvatar>}
                title={dataById?.marketing_material?.title}
                titleTypographyProps={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                subheader={getMonthDayYearValue(new Date(dataById?.marketing_material?.date ?? Date.now()).toString())}
                action={
                  <Box sx={{ width: 'max-content' }}>
                    <Tooltip title={t('edit', { ns: 'cdtModal' })}>
                      <IconButton
                        aria-label="settings"
                        sx={{ color: 'secondary.main' }}
                        onClick={() => setOpenEditInformation(true)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('deletion_dialog_form_title', { resource: 'marketing' })}>
                      <IconButton sx={{ color: 'secondary.main' }} onClick={() => setDeleteModal(true)}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                    <DeleteDialog
                      title={t('deletion_dialog_form_title', {
                        resource: 'marketing_modal_text',
                      })}
                      contentText={t('deleting_dialog_form_content_pronoun', {
                        item: `${resource}_modal_text`,
                        pronoun: ['marketing_material'].includes(resource) ? 'este' : 'esta',
                        resource: 'marketing_material_modal_text',
                      })}
                      open={deleteModal}
                      setOpen={setDeleteModal}
                      onDelete={() => deleteMutation(dataById?.marketing_material?.id)}
                      // eslint-disable-next-line no-restricted-globals
                      deleteStatus={status}
                    />
                    <Tooltip title={t('close')}>
                      <IconButton
                        onClick={onCloseArticleSelected}
                        aria-label="settings"
                        sx={{ color: 'secondary.main' }}
                      >
                        <ClearRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                }
              />
              {dataById?.marketing_material?.cover_image_url && (
                <CustomCardMedia
                  src={dataById?.marketing_material?.cover_image_url ?? ''}
                  alt="cover-image"
                  height="250px"
                />
              )}
              <CardContent>
                <Typography
                  variant="subtitle1"
                  sx={{ padding: '8px 0 ', fontSize: '1.10rem', color: 'rgba(0, 0, 0, 0.6)' }}
                >
                  {dataById?.marketing_material?.subtitle}
                </Typography>
                {parse(cleanHTML)}
                {dataById?.marketing_material.document_url && (
                  <PdfShowFile file={dataById?.marketing_material.document_url} />
                )}
                <RenderMarketImages data={dataById?.marketing_material.publication_images} />
                {dataById?.marketing_material?.video_url && (
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <FadeIn duration="3s">
                      <CardMedia
                        sx={{
                          border: 'none',
                          width: 'auto',
                          height: 'auto',
                        }}
                        component="iframe"
                        src={dataById?.marketing_material?.video_url ?? ''}
                      />
                    </FadeIn>
                  </Box>
                )}
              </CardContent>
            </Card>
          </FadeIn>
        )}
      </Box>
      {openEditInformation && (
        <MarketingMaterialsModal
          dataById={dataById}
          refetchDataById={refetch}
          openModal={openEditInformation}
          setOpenModal={setOpenEditInformation}
          setSnackBarMessage={setSnackBarMessage}
          groupName="owning"
        />
      )}
    </>
  );
};

const propTypes = {};

const defaultProps = {};

interface ExtraProps {
  isLoading: boolean;
  dataById: ArticleByIdBody;
  setOpenEditInformation: (setOpen: boolean) => void;
  deleteModal: boolean;
  setDeleteModal: (setDelete: boolean) => void;
  deleteMutation: (id: number) => void;
  onCloseArticleSelected: () => void;
  cleanHTML: string;
  openEditInformation: boolean;
  refetch: () => void;
  setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
  resource: string;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
MarketingDetail.propTypes = propTypes;
MarketingDetail.defaultProps = defaultProps;

export default MarketingDetail;
