import { useTranslation } from 'react-i18next';
import CancelReservationIcon from '@mui/icons-material/DoDisturb';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { ReservationColumns } from './types';
import { getLanguage } from '@/src/utilities/storage';
import CancelReservation from './CancelReservation';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';
import useActivities from '@/src/hooks/filters/activities.hooks';
import useAgendasFilter from '@/src/hooks/filters/agendas.hooks';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';
import useBookingStatesFilters from '@/src/hooks/filters/bookingStates.hooks';

export const useColumns = () => {
  const { t } = useTranslation('bookings');
  const lang = getLanguage()?.split('-')[0];
  const { activities } = useActivities();
  const { agendaList } = useAgendasFilter();
  const { apartments } = useApartmentsFilters();
  const { bookingStates } = useBookingStatesFilters();
  const columns: CustomDataTableColumnsProps<ReservationColumns>[] = [
    {
      id: 'id',
      name: t('id'),
      selector: (row) => row?.id ?? '',
      sortField: 'id',
      sortable: true,
      right: true,
      maxWidth: '1%',
      filters: [
        {
          id: 'booking_Id',
          query: 'BookingId',
          type: 'numeric',
        },
      ],
    },
    {
      id: 'agenda',
      name: t('reservations_page_agenda'),
      selector: (row) => row?.agenda?.name ?? '',
      sortField: 'agenda',
      sortable: true,
      filters: agendaList,
    },
    {
      id: 'activity',
      name: t('reservations_page_activity'),
      selector: (row) => row?.activity?.description ?? '',
      sortField: 'activity',
      sortable: true,
      filters: activities,
    },
    {
      id: 'customer_full_name',
      name: t('reservations_page_name_surname'),
      selector: (row) => `${row?.customer?.name} ${row?.customer?.surname}`,
      sortField: 'customer_full_name',
      sortable: true,
      filters: [
        {
          id: 'customer_name',
          query: 'CustomerFullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'property_code',
      name: t('reservations_page_functional_unit'),
      selector: (row) => row?.customer?.property?.code ?? '',
      sortField: 'property_code',
      sortable: true,
      right: true,
      maxWidth: '160px',
      filters: apartments,
    },
    {
      id: 'date',
      name: t('reservations_page_date'),
      selector: (row) =>
        getFormattedDateByLanguague(lang, getFormattedDateFullYearEs, getFormattedDateFullYearEn, row?.date),
      sortField: 'date',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'reservation-date',
          query: 'BookingDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'selected_time_from',
      name: t('cancellations_page_selected_time_from'),
      selector: (row) => `${row?.from}hs`,
      sortField: 'selected_time',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'selected_time_from',
          query: 'BookingTimeFrom',
          type: 'time',
        },
      ],
    },
    {
      id: 'selected_time_to',
      name: t('cancellations_page_selected_time_to'),
      selector: (row) => `${row?.to}hs`,
      sortField: 'selected_time',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'selected_time_to',
          query: 'BookingTimeTo',
          type: 'time',
        },
      ],
    },
    {
      id: 'booking_state',
      name: t('cancellations_page_booking_state'),
      selector: (row) => `${row?.state?.name}`,
      sortField: 'booking_state',
      sortable: true,
      center: true,
      filters: bookingStates,
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'cancel',
      icon: (props: any) => <CancelReservationIcon sx={props.sx} />,
      component: (props: any) => <CancelReservation {...props} />,
      width: '20%',
    },
  ];

  return {
    columns,
    actionColumns,
  };
};
