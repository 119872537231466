import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type IntialState = {
  loginStatus: string;
  setLoginStatus: Dispatch<SetStateAction<string>>;
  isPasswordUpdated: string;
  setIsPasswordUpdated: Dispatch<SetStateAction<string>>;
  ResetStatus: () => void;
  noUserAllowedErrorMessage: { code: string } | null;
  setNoUserAllowedErrorMessage: Dispatch<SetStateAction<{ code: string } | null>>;
  prevUrl: string | null;
  setPrevUrl: (param: string | null) => void;
};

export const AuthContext = createContext({} as IntialState);

const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [loginStatus, setLoginStatus] = useState(localStorage.getItem('loginStatus') ?? 'idle');
  const [prevUrl, setPrevUrl] = useState<string | null>(null);
  const [isPasswordUpdated, setIsPasswordUpdated] = useState('idle');
  const [noUserAllowedErrorMessage, setNoUserAllowedErrorMessage] = useState<{ code: string } | null>(null);
  const ResetStatus = () => {
    setIsPasswordUpdated('idle');
    setLoginStatus('idle');
  };

  useEffect(() => {
    if (loginStatus === 'authenticated' && prevUrl) {
      navigate(prevUrl);
    }
    return () => setPrevUrl(null);
  }, [loginStatus]);

  const memoedValue = useMemo(() => {
    const value = {
      loginStatus,
      setLoginStatus,
      isPasswordUpdated,
      setIsPasswordUpdated,
      ResetStatus,
      noUserAllowedErrorMessage,
      setNoUserAllowedErrorMessage,
      prevUrl,
      setPrevUrl,
    };
    return value;
  }, [
    loginStatus,
    setLoginStatus,
    isPasswordUpdated,
    setIsPasswordUpdated,
    ResetStatus,
    noUserAllowedErrorMessage,
    setNoUserAllowedErrorMessage,
  ]);

  return <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
