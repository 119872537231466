import {
  DeliveryDining,
  DynamicFeed,
  Event,
  GroupAddRounded,
  Groups3Rounded,
  LocalActivity,
  LocalShipping,
  PeopleAlt,
  Person,
  HeadsetMic,
  EventBusy,
  TypeSpecimen,
  Pets,
  TimeToLeave,
  Paid,
  RequestQuoteTwoTone,
  DomainDisabled,
} from '@mui/icons-material';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TodayIcon from '@mui/icons-material/Today';
import ArticleIcon from '@mui/icons-material/Article';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import BlockIcon from '@mui/icons-material/Block';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ConstructionIcon from '@mui/icons-material/Construction';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CottageIcon from '@mui/icons-material/Cottage';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { useGetCounters } from '@/src/hooks/common/items-w-counter/useGetCounters';
// import { FirebaseContext } from '@/src/context/firebase.context';

export interface SubMenuItemProp {
  action: string;
  icon:
    | (OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> & {
        muiName: string;
      })
    | (() => JSX.Element)
    | any;
  id: string;
  navLink: string;
  resource: string;
  title: string;
}

export type VerticalMenuItemProp = {
  action: string;
  icon:
    | (OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> & {
        muiName: string;
      })
    | (() => JSX.Element)
    | any;
  id: string;
  navLink: string;
  resource: string;
  title: string;
  subMenus: Array<SubMenuItemProp> | null | undefined;
  mr?: number;
};
export const useVerticalMenu = () => {
  // const { countersData } = React.useContext(FirebaseContext);
  const { countersData } = useGetCounters(true);
  const verticalMenus: Array<VerticalMenuItemProp> = [
    {
      action: 'read',
      icon: Person,
      id: 'administrators',
      navLink: '/administrators',
      resource: 'administrators',
      title: 'administrators',
      subMenus: null,
    },
    {
      action: 'read',
      icon: Groups3Rounded,
      id: 'residents',
      navLink: '/residents',
      resource: 'people',
      title: 'residents',
      subMenus: null,
      mr: 0,
    },
    {
      action: 'read',
      icon: ContactPageIcon,
      id: 'potential-customers',
      navLink: '/potential-customers',
      resource: 'potential-customers',
      title: 'potential_customers',
      subMenus: null,
      mr: 0.2,
    },
    {
      action: 'read',
      icon: countersData['/household'] ?? null,
      id: 'household',
      navLink: '/household',
      resource: 'customers',
      title: 'household',
      // EDITED
      subMenus: [
        {
          action: 'read',
          icon: PeopleAlt,
          id: 'users',
          navLink: '/household/users',
          resource: 'customers',
          title: 'users',
        },

        {
          action: 'read',
          icon: countersData['/household/relatives-requests'] ?? null,
          id: 'relatives-requests',
          navLink: '/household/relatives-requests',
          resource: 'customers',
          title: 'relatives_requests',
        },
        {
          action: 'read',
          icon: Pets,
          id: 'pets',
          navLink: '/household/pets',
          resource: 'customers',
          title: 'pets',
        },
        {
          action: 'read',
          icon: countersData['/household/pets-requests'] ?? null,
          id: 'pets-requests',
          navLink: '/household/pets-requests',
          resource: 'customers',
          title: 'pets_requests',
        },
        {
          action: 'read',
          icon: TimeToLeave,
          id: 'vehicles',
          navLink: '/household/vehicles',
          resource: 'customers',
          title: 'vehicles',
        },
        {
          action: 'read',
          icon: countersData['/household/vehicles-requests'] ?? null,
          id: 'vehicles-requests',
          navLink: '/household/vehicles-requests',
          resource: 'customers',
          title: 'vehicles_requests',
        },
        {
          action: 'read',
          icon: BlockIcon,
          id: 'blacklist',
          navLink: '/household/blacklist',
          resource: 'customers',
          title: 'blacklist',
        },
      ],
      mr: 0,
    },
    {
      action: 'read',
      icon: LocalShipping,
      id: 'packages',
      navLink: '/packages',
      resource: 'packages',
      title: 'packages',
      subMenus: null,
      mr: 0,
    },
    {
      action: 'read',
      icon: DeliveryDining,
      id: 'delivery',
      navLink: '/delivery',
      resource: 'delivery',
      title: 'delivery',
      subMenus: null,
      mr: 0.2,
    },
    {
      action: 'read',
      icon: GroupAddRounded,
      id: 'guests',
      navLink: '/guests',
      resource: 'guests',
      title: 'guests',
      subMenus: null,
      mr: 0,
    },
    {
      action: 'read',
      icon: countersData['/parking'] ?? null,
      id: 'parking',
      navLink: '/parking',
      resource: 'parking',
      title: 'parking',
      mr: 10,
      subMenus: [
        {
          action: 'read',
          icon: countersData['/parking/residents'] ?? null,
          id: 'residents',
          navLink: '/parking/residents',
          resource: 'parking_residents',
          title: 'residents',
        },
        {
          action: 'read',
          icon: countersData['/parking/guests'] ?? null,
          id: 'guests',
          navLink: '/parking/guests',
          resource: 'parking_guests',
          title: 'guests',
        },
        {
          action: 'read',
          icon: HeadsetMic,
          id: 'attendants',
          navLink: '/parking/attendants',
          resource: 'attendants',
          title: 'attendants',
        },
      ],
    },
    {
      action: 'read',
      icon: AssignmentIndIcon,
      id: 'documents',
      navLink: '/documents',
      resource: 'documents',
      title: 'documents',
      mr: 0.2,
      subMenus: null,
    },
    {
      action: 'read',
      icon: DynamicFeed,
      id: 'news',
      navLink: '/news',
      resource: 'news',
      title: 'news',
      mr: 0.2,
      subMenus: null,
    },
    {
      action: 'read',
      icon: Diversity3Icon,
      id: 'community',
      navLink: '/community',
      resource: 'community',
      title: 'community',
      mr: 0.2,
      subMenus: null,
    },
    {
      action: 'read',
      icon: CardGiftcardIcon,
      id: 'marketing-materials',
      navLink: '/marketing-materials',
      resource: 'marketing',
      title: 'marketing_materials',
      mr: 0.2,
      subMenus: [
        {
          action: 'read',
          icon: CorporateFareIcon,
          id: 'living',
          navLink: '/marketing-materials/living',
          resource: 'marketing',
          title: 'marketing_materials_living',
        },
        {
          action: 'read',
          icon: CottageIcon,
          id: 'owning',
          navLink: 'marketing-materials/owning',
          resource: 'marketing',
          title: 'marketing_materials_owning',
        },
      ],
    },
    {
      action: 'read',
      icon: TodayIcon,
      id: 'events',
      navLink: '/events',
      resource: 'events',
      title: 'events',
      mr: 0.2,
      subMenus: [
        {
          action: 'read',
          icon: CalendarViewMonthIcon,
          id: 'events',
          navLink: 'events/categories',
          resource: 'events',
          title: 'events_categories',
        },
        {
          action: 'read',
          icon: DateRangeIcon,
          id: 'events',
          navLink: 'events/events',
          resource: 'events_publications',
          title: 'events_publications',
        },
      ],
    },
    {
      action: 'read',
      icon: LoyaltyIcon,
      id: 'perks-benefits',
      navLink: '/perks-benefits',
      resource: 'perks',
      title: 'perks_benefits',
      mr: 0.2,
      subMenus: null,
    },
    {
      action: 'read',
      icon: ConstructionIcon,
      id: 'construction-progress',
      navLink: '/construction-progress',
      resource: 'construction',
      title: 'construction_progress',
      mr: 0.2,
      subMenus: null,
    },
    {
      action: 'read',
      icon: countersData['/bookings'] ?? null,
      id: 'bookings',
      navLink: '/bookings',
      resource: 'bookings',
      title: 'bookings',
      mr: 0.6,
      subMenus: [
        {
          action: 'read',
          icon: LocalActivity,
          id: 'amenities',
          navLink: '/bookings/amenities',
          resource: 'amenities',
          title: 'amenities',
        },
        {
          action: 'read',
          icon: Event,
          id: 'activities',
          navLink: '/bookings/activities',
          resource: 'activities',
          title: 'activities',
        },
        {
          action: 'read',
          icon: countersData['/bookings/reservations'] ?? null,
          id: 'reservations',
          navLink: '/bookings/reservations',
          resource: 'reservations',
          title: 'reservations',
        },
        {
          action: 'read',
          icon: EventBusy,
          id: 'cancellations',
          navLink: '/bookings/cancellations',
          resource: 'cancellations',
          title: 'cancellations',
        },
        {
          action: 'read',
          icon: HistoryToggleOffIcon,
          id: 'schedules',
          navLink: '/bookings/schedules',
          resource: 'schedules',
          title: 'schedules',
        },
      ],
    },
    {
      action: 'read',
      icon: Paid,
      id: 'charges',
      navLink: '/charges',
      resource: 'charges',
      title: 'charges',
      mr: 0.6,
      subMenus: [
        {
          action: 'read',
          icon: RequestQuoteTwoTone,
          id: 'list',
          navLink: '/charges/list',
          resource: 'charges',
          title: 'list',
        },
        {
          action: 'read',
          icon: ArticleIcon,
          id: 'concepts',
          navLink: '/charges/concepts',
          resource: 'charges',
          title: 'concepts',
        },
        {
          action: 'read',
          icon: MonetizationOnIcon,
          id: 'payments',
          navLink: '/charges/payments',
          resource: 'charges',
          title: 'payments',
        },
      ],
    },
    {
      action: 'read',
      icon: countersData['/tickets'] ?? null,
      id: 'tickets',
      navLink: '/tickets',
      resource: 'tickets',
      title: 'tickets',
      mr: 2,
      subMenus: [
        {
          action: 'read',
          icon: countersData['/tickets/list'] ?? null,
          id: 'list',
          navLink: '/tickets/list',
          resource: 'tickets_list',
          title: 'list',
        },
        {
          action: 'read',
          icon: TypeSpecimen,
          id: 'categories',
          navLink: '/tickets/categories',
          resource: 'categories',
          title: 'categories',
        },
      ],
    },
  ];
  return { verticalMenus };
};
