import { axiosAuthenticationInstance } from '../instances/authInstance';
import { CreateCategorieRequest, EditCategorieRequest } from '../types/types';

// CATEGORIES
export const getCategories = () => axiosAuthenticationInstance.get('feed/v1/event-categories');
export const createCategory = (req: CreateCategorieRequest) =>
  axiosAuthenticationInstance.post('feed/v1/event-categories', req);
export const editCategory = (req: EditCategorieRequest) =>
  axiosAuthenticationInstance.put(`feed/v1/event-categories/${req.id}`, req.body);
export const deleteCategory = (id: number) => axiosAuthenticationInstance.delete(`feed/v1/event-categories/${id}`);
