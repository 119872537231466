import React from 'react';
import { SelectedResident, SelectedRow } from './types';

export const useColumns = (isBlacklist?: boolean) => {
  const [isDetailSectionOpen, setIsDetailSectionOpen] = React.useState(false);
  const [personSelected, setPersonSelected] = React.useState<SelectedRow | null>(null);
  const [isResidentSectionOpen, setIsResidentSectionOpen] = React.useState(false);
  const [residentDetail, setResidentDetail] = React.useState<SelectedResident | null>(null);

  React.useEffect(() => {
    setIsDetailSectionOpen(false);
    setPersonSelected(null);
  }, [isBlacklist]);

  const openDetailSection = (value: SelectedRow) => {
    setPersonSelected(value);
    setIsDetailSectionOpen(true);
  };

  const closeDetailSection = () => {
    setIsDetailSectionOpen(false);
    setPersonSelected(null);
  };

  const openResidentSection = (row: SelectedResident) => {
    setResidentDetail(row);
    setIsResidentSectionOpen(true);
  };

  const closeResidentSection = () => {
    setIsResidentSectionOpen(false);
    setResidentDetail(null);
  };

  return {
    isDetailSectionOpen,
    openDetailSection,
    closeDetailSection,
    personSelected,
    isResidentSectionOpen,
    openResidentSection,
    closeResidentSection,
    residentDetail,
  };
};
