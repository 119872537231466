import { useMutation } from '@tanstack/react-query';
import { axiosAuthenticationInstance } from '@/src/api/instances/authInstance';
import axiosAnonInstanceFake from '@/src/api/instances/anonInstancesFake';

const useCheckOut = (fake?: boolean) => {
  const instance = fake ? axiosAnonInstanceFake : axiosAuthenticationInstance;
  const checkOutResidence = (id: number) =>
    instance.post(`check-ins/v1/property-residents/check-out`, { property_resident_id: Number(id) });
  const { mutate: mutateCheckOut, isLoading } = useMutation(checkOutResidence);

  return { mutateCheckOut, isLoading };
};

export default useCheckOut;
