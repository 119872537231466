import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const PDFContainer = styled(Box)<{ isDocument: boolean }>(
  ({ isDocument, theme }: { isDocument: boolean; theme: any }) => ({
    width: '100px',
    minWidth: '90px',
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    margin: `${isDocument ? 'auto' : '1rem auto .5rem'}`,
    height: `${isDocument ? 'auto' : '100px'}`,
    padding: '.5rem',
    border: `${!isDocument ? `3px dashed ${theme.palette.secondary.main}` : ''}`,
    borderRadius: '6px',
  }),
);
