import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';

export const CodeHorizontalSplitIcon = styled(HorizontalSplitIcon)(() => ({
  color: 'rgba(0, 0, 0, 0.6)',
  position: 'absolute',
  right: 8,
  transform: 'rotateZ(90deg)',
}));

export const LoadGridPackageCode = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
}));
