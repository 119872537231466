import {
  IconButton,
  Tooltip,
  Box,
  FormHelperText,
  Modal,
  Typography,
  Button,
  Grid,
  Alert,
  Snackbar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// eslint-disable-next-line import/no-extraneous-dependencies
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { ErrorHelper } from '@/src/components/error/error-helper/ErrorHelper';
import { MfaModalType } from '../types';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import CopytoClipboard from './CopytoClipboardInput/CopytoClipboard';
import './styless.css';
import St from './MfaModal.styled';
import CodeInput from '@/src/components/code-input/CodeInput';

const MfaModal = ({
  isOpen,
  open,
  close,
  securityCode,
  setSnackBarMessage,
  closeModal,
  openSnack,
  message,
  severity,
  setSecurityCode,
  errorMessage,
  handleSubmit,
  values,
  setFieldValue,
  errors,
  isLoading,
}: MfaModalType) => {
  const { t } = useTranslation();
  const disableOnClose = false;
  const width = '40%';
  const GoogleAuthenticathorPath =
    'https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=es';
  const Authy = 'https://authy.com/';

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '100px', sx: '1100px', md: '1000px' },
    minWidth: `${width ? '305px' : '400px'}`,
    height: 'max-content',
    maxHeight: { xs: '98%', sm: '95%' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px',
    p: 2,
    overflow: 'auto',
  };

  return (
    <>
      <Button variant="outlined" color="secondary" size="small" onClick={() => open()}>
        <Tooltip title={t('activate_Authentication', { ns: 'security' })}>
          <Box>{t(`activate`)}</Box>
        </Tooltip>
      </Button>
      {isOpen && (
        <Modal
          open={isOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowY: 'auto', mt: '10px', mb: '10px' }}
          onClose={
            disableOnClose
              ? () => {}
              : () => {
                  close();
                  closeModal();
                  setSecurityCode();
                }
          }
        >
          <Box component="form" onSubmit={handleSubmit} sx={style}>
            <>
              <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mt: '20px' }}>
                {t(`activate_Multifactor_Authentication`, { ns: 'security' })}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={close}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              {securityCode ? (
                <Grid container spacing={3} marginTop={0} direction="column">
                  <Grid container item xs={12}>
                    <Grid container item xs={12} sm={6} md={6} display="flex" m="auto" direction="column">
                      <QRCode
                        size={256}
                        style={{
                          height: '200px',
                          maxWidth: '50%',
                          width: '50%',
                          margin: '15px 80px',
                          // paddingTop: '5px',
                        }}
                        value={securityCode || ''}
                        viewBox="0 0 256 256"
                      />
                      <Grid container item xs={12} sm={6} md={6} display="flex" m="auto" direction="column">
                        <Typography sx={{ alignSelf: 'center', width: '100%', marginBottom: { xs: '5px', sm: 0 } }}>
                          {t('copy_the_code_manually', { ns: 'security' })}
                        </Typography>
                        <CopytoClipboard text={securityCode} setSnackBarMessage={setSnackBarMessage} />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      display="flex"
                      direction="column"
                      width="fit-content"
                      gap={1}
                    >
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: t('authentication_modal_explanation', {
                            ns: 'auth',
                            GoogleAuthenticathorPath,
                            Authy,
                          }),
                        }}
                      />
                      <CodeInput
                        onChange={(e) => {
                          setFieldValue('code', e);
                        }}
                        value={values?.code}
                      />
                      {errors.code && (
                        <Typography
                          sx={{
                            alignSelf: 'center',
                            width: '100%',
                            marginBottom: { xs: '5px', sm: 0 },
                            color: 'red',
                          }}
                        >
                          {t('required_field', { ns: 'errors' })}
                        </Typography>
                      )}
                      {errorMessage && (
                        <Grid>
                          {errorMessage.code === 'ValueAlreadyExists' ? (
                            <FormHelperText
                              error={errorMessage !== null}
                              sx={{
                                alignSelf: 'center',
                                width: '100%',
                                marginBottom: { xs: '5px', sm: 0 },
                                color: 'blue',
                                fontSize: '20px',
                              }}
                            >
                              {t(`${errorMessage.code}${errorMessage.property_name}`, { ns: 'errors' })}
                            </FormHelperText>
                          ) : (
                            <ErrorHelper error={errorMessage} />
                          )}
                        </Grid>
                      )}
                    </Grid>
                    <Grid container item xs={12} justifyContent="flex-end">
                      <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        size="small"
                        type="submit"
                        sx={{ width: { xs: '100%', sm: 'auto' } }}
                      >
                        {t(`activate`, { ns: 'security' })}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <CircularSpinner size={25} />
              )}
            </>
          </Box>
        </Modal>
      )}
      <Snackbar
        open={openSnack}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        autoHideDuration={50000}
        onClose={closeModal}
        message={message}
      >
        <Alert onClose={closeModal} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MfaModal;
