import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Button, DialogContentText, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { cancelReservation } from '@/src/api/endpoints/bookings';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { CancelReservationProps } from './types';
import { ModalButtonsContainer } from './CancelReservation.styled';

const CancelReservation = (props: CancelReservationProps) => {
  const { t } = useTranslation();
  const { row, close, setSnackBarMessageSuccess } = props;
  const [deletionFailed, setDeletionFailed] = React.useState<string | null>(null);
  const { mutate, isLoading } = useMutation(cancelReservation, {
    onSuccess: async () => {
      close();
      setSnackBarMessageSuccess(t('successful_booking_cancelation_snackbar_text', { ns: 'bookings' }));
    },
    onError: async () => {
      setDeletionFailed(t('failed_booking_cancelation_text', { ns: 'bookings' }));
    },
  });
  return (
    <Grid>
      <DialogContentText id="alert-dialog-description" sx={{ pb: 1 }}>
        {t('cancel_reservation_header_text', { ns: 'bookings' })}
      </DialogContentText>
      <ErrorText error={deletionFailed as string} />
      <ModalButtonsContainer component="div">
        <Button size="small" type="button" variant="outlined" color="secondary" onClick={close} sx={{ mr: 1 }}>
          {t('delete_dialog_cancel_button_text')}
        </Button>
        <LoadingButton
          loading={isLoading}
          size="small"
          type="button"
          variant="contained"
          onClick={() => mutate(row.id)}
        >
          {t('delete_dialog_submit_button_text')}
        </LoadingButton>
      </ModalButtonsContainer>
    </Grid>
  );
};

export default CancelReservation;
