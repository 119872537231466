import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import InfoLabel from '@/src/components/info-label/InfoLabel';

export const PotentialCustomerDetail = (props: Props) => {
  const { interestedData, isContentLoaded } = props;
  const { t } = useTranslation('interested');

  return (
    <Box sx={{ mt: '5px' }}>
      {!isContentLoaded ? (
        <CircularSpinner />
      ) : (
        <Grid xs={12} container spacing={2} item sx={{ marginY: '1rem' }}>
          <Grid item xs={12} sm={6}>
            <InfoLabel
              first={t('interested_detail_name_field_label', { ns: 'interested' })}
              second={interestedData?.name ? interestedData?.name : '-'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoLabel
              first={t('interested_detail_nationality_field_label', { ns: 'interested' })}
              second={interestedData?.nationality_country?.name ? interestedData?.nationality_country?.name : '-'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoLabel
              first={t('interested_detail_email_field_label', { ns: 'interested' })}
              second={interestedData?.email ? interestedData?.email : '-'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoLabel
              first={t('interested_detail_phoneNumber_field_label', { ns: 'interested' })}
              second={interestedData?.phone_number ? interestedData?.phone_number : '-'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoLabel
              first={t('interested_detail_interestedIn_field_label', { ns: 'interested' })}
              second={interestedData?.interest?.interest ? interestedData?.interest?.interest : '-'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoLabel
              first={t('interested_detail_roomsAmount_field_label', { ns: 'interested' })}
              second={interestedData?.room_type?.description ? interestedData?.room_type?.description : '-'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoLabel
              first={t('interested_detail_priceRange_field_label', { ns: 'interested' })}
              second={
                interestedData?.price_range?.min_price && interestedData?.price_range?.max_price
                  ? `${interestedData?.price_range?.min_price} - ${interestedData?.price_range?.max_price}`
                  : '-'
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoLabel
              first={t('interested_detail_agentName_field_label', { ns: 'interested' })}
              second={interestedData?.agent_name ? interestedData?.agent_name : '-'}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const propTypes = {};

const defaultProps = {};

interface ExtraProps {
  interestedData: any;
  isContentLoaded: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
PotentialCustomerDetail.propTypes = propTypes;
PotentialCustomerDetail.defaultProps = defaultProps;

export default PotentialCustomerDetail;
