import { Box, Grid, IconButton, Typography, Tooltip, FormHelperText } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { FormikErrors, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomInput from '@/src/components/forms/CustomInput';
import PdfFileUploader from '@/src/components/pdf-file-uploader/PdfFileUploader';
import { CustomDatePicker } from '@/src/components/date-picker/DatePicker';
import VideoUploader from '@/src/components/video-uploader/VideoUploader';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import CustomEditor from '@/src/components/custom-editor/CustomEditor';
import {
  AttachedImagesContainer,
  AttachedImageUploaderContainer,
  AttachedLoadedImagesContainer,
  CoverImageContainer,
  DeleteAttachedImageIcon,
  SubmitButton,
} from '../NewsForm.styled';
import CustomCardMedia from '@/src/components/custom-card-media/CustomCardMedia';
import { handlerDateSelector } from '@/src/utilities/helpers/utils';
import CustomTabPanel from '../custom-tab-panel';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';

const NewsFormInformation = (props: Props) => {
  const {
    tabValue,
    values,
    deleteImage,
    ImageUploader,
    wrongCoverImages,
    errors,
    setFieldValue,
    setDisableButton,
    setFieldError,
    AttachedImagesUploader,
    attachedImagesLength,
    errorMessage,
    wrongAttachedImages,
    isLoadingSubmit,
  } = props;

  const { t } = useTranslation();

  return (
    <CustomTabPanel value={tabValue} index={0}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography component="span" variant="subtitle2">
            {t('attached_header_image', { ns: 'events' })}
          </Typography>
        </Grid>
        {values.cover_image_url ? (
          <Grid item xs={12} sm={12} md={12} sx={{ position: 'relative' }}>
            <Tooltip title={t('remove_item_tooltip_msn', { ns: 'news' })}>
              <IconButton
                onClick={() => deleteImage('cover_image_url')}
                sx={{ position: 'absolute', right: -15, top: -14 }}
              >
                <CancelRoundedIcon />
              </IconButton>
            </Tooltip>
            <CustomCardMedia src={values.cover_image_url} alt="cover-image" height="180px" />
          </Grid>
        ) : (
          <CoverImageContainer>
            <ImageUploader />
            <ErrorText error={wrongCoverImages?.code} />
            {errors?.cover_image_url && <ErrorText error={t('attach_header_image', { ns: 'news' })} />}
          </CoverImageContainer>
        )}
        <Grid item xs={12} sm={6} md={6}>
          <CustomInput
            field="title"
            noError
            label={t('title')}
            placeholder={t('enter_title', { ns: 'news' })}
            setFieldValue={setFieldValue}
            values={values}
            autoFocus
            maxLength={100}
            required
          />
          <ErrorText error={errors && errors.title ? t('enter_title', { ns: 'news' }) : null} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomDatePicker
            value={values.start_date}
            minDate={new Date()}
            onChange={(newValue: any): void => handlerDateSelector(newValue, setFieldValue, 'start_date')}
            fieldValue="start_date"
            placeholder={t('start_date', { ns: 'news' })}
            errorMessage={errors.start_date as string}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CustomInput
            field="subtitle"
            noError
            label={t('subtitle', { ns: 'news' })}
            placeholder={t('enter_subtitle', { ns: 'news' })}
            setFieldValue={setFieldValue}
            values={values}
            maxLength={100}
            required
          />
          <ErrorText error={errors && errors.subtitle ? t('enter_subtitle', { ns: 'news' }) : null} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CustomEditor values={values} valueName="body" setFieldValue={setFieldValue} />
          {errors.body && <ErrorText error={t('enter_message', { ns: 'news' })} />}
        </Grid>
        <Grid item xs={12} md={6} sx={{ height: 'auto' }}>
          <PdfFileUploader
            values={values}
            setFieldValue={setFieldValue}
            setDisableButton={setDisableButton}
            setError={setFieldError}
          />
          {errors?.document && <FormHelperText error>{`${errors?.document}`}</FormHelperText>}
        </Grid>
        <Grid item xs={12} md={6} sx={{ height: 'auto' }}>
          <VideoUploader
            values={values}
            setFieldValue={setFieldValue}
            setDisableButton={setDisableButton}
            setError={setFieldError}
          />
          {errors.video && <ErrorText error={errors.video as string} />}
        </Grid>
        <Grid item xs={12} sm={12} md={12} mt={4} sx={{ height: 135, minHeight: 'fit-content' }}>
          <Typography variant="subtitle2">{t('news_form_add_images_label', { ns: 'news' })}</Typography>

          <AttachedLoadedImagesContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <AttachedImagesUploader disabled={attachedImagesLength >= 4} />
              <ErrorText error={wrongAttachedImages?.code || errors.publication_image_urls} />
            </Box>
            <AttachedImageUploaderContainer
              className="AttachedImageUploaderContainer"
              justifyContent={`${attachedImagesLength > 3 ? 'space-between' : 'flex-start'}`}
            >
              {values.publication_image_urls.map((images: any, i: number) => (
                <AttachedImagesContainer key={i} width={{ xs: '100%', lg: '25%' }}>
                  <DeleteAttachedImageIcon
                    onClick={() => deleteImage('publication_image_urls', images?.image_url ?? images)}
                  >
                    <CancelRoundedIcon />
                  </DeleteAttachedImageIcon>
                  <CustomCardMedia src={images?.image_url ?? images} alt={`attached-image-${i}`} height="128px" />
                </AttachedImagesContainer>
              ))}
            </AttachedImageUploaderContainer>
          </AttachedLoadedImagesContainer>
        </Grid>
      </Grid>
    </CustomTabPanel>
  );
};

const propTypes = {};

const defaultProps = {};

interface ExtraProps {
  tabValue: number;
  values: FormikValues;
  deleteImage: (value: any, url?: string) => void;
  ImageUploader: ({ disabled }: { disabled?: boolean }) => JSX.Element;
  wrongCoverImages: { code: any; error_messages: any } | null | undefined;
  errors: FormikErrors<FormikValues>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
  setFieldError: (field: string, value: string | undefined) => void;
  AttachedImagesUploader: ({ disabled }: { disabled?: boolean }) => JSX.Element;
  attachedImagesLength: any;
  errorMessage: string | null;
  wrongAttachedImages: { code: any; error_messages: any } | null | undefined;
  isLoadingSubmit: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
NewsFormInformation.propTypes = propTypes;
NewsFormInformation.defaultProps = defaultProps;

export default NewsFormInformation;
