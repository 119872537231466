import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';
import { ResidentsSectionProps } from './types';

export const ResidentsSection = ({ openSubGrid }: ResidentsSectionProps) => {
  const { columns, headerActions, actionColumns, queryFilters } = useColumns({ openSubGrid });
  const { t } = useTranslation();
  return (
    <CustomDataTable
      title={t('users_reg', { ns: 'residents' })}
      columns={columns}
      customActionColumns={actionColumns}
      resource="property_residents"
      pathApi="master/v1/property-residents"
      listName="property_residents"
      defaultSortFieldId="phone_number"
      extraFilters={queryFilters}
      canSearch
      canExport
      defaultSortAsc
      extraActionsInHeader={headerActions}
    />
  );
};
