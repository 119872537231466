import React from 'react';
import { CardMedia } from '@mui/material';

interface CustomCardMediaProps {
  src: string;
  alt: string;
  height?: string | object;
  component?: 'img' | 'iframe';
  width?: string;
}
const CustomCardMedia = ({ src, alt, height, component = 'img', width = '100%' }: CustomCardMediaProps) => (
  <CardMedia
    sx={{
      borderRadius: '5px',
      maxHeight: { height },
      height: { height },
      minWidth: { width },
      objectFit: 'contain',
      padding: '0.5em',
    }}
    component={component}
    image={src}
    alt={alt}
  />
);

export default CustomCardMedia;
