import { AlertColor } from '@mui/material';
import { createContext, useContext, useMemo, useState, useEffect } from 'react';

interface CustomDataTableContextProps {
  needRefresh: boolean;
  setNeedRefresh: (value: boolean) => void;
  openSnackBar: boolean;
  setOpenSnackBar: (value: boolean) => void;
  severity: AlertColor;
  setSeverity: (value: AlertColor) => void;
  message: string;
  setMessage: (value: string) => void;
  openCreateModal: boolean;
  setOpenCreateModal: (value: boolean) => void;
  showDisabled: boolean;
  setShowDisabled: (value: boolean) => void;
  filterString: string;
  setFilterString: (value: string) => void;
}

export const CustomDataTableContext = createContext<CustomDataTableContextProps>({} as CustomDataTableContextProps);

export const useCustomDataTable = () => useContext(CustomDataTableContext);

export const CustomDataTableProvider = ({ children }: any) => {
  const [needRefresh, setNeedRefresh] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [message, setMessage] = useState('');
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);
  const [filterString, setFilterString] = useState('');

  const MemoValue = useMemo(() => {
    const value = {
      severity,
      message,
      needRefresh,
      openCreateModal,
      openSnackBar,
      setSeverity,
      setMessage,
      setNeedRefresh,
      setOpenCreateModal,
      setOpenSnackBar,
      setShowDisabled,
      showDisabled,
      filterString,
      setFilterString,
    };
    return value;
  }, [
    severity,
    message,
    needRefresh,
    openCreateModal,
    openSnackBar,
    setSeverity,
    setMessage,
    setNeedRefresh,
    setOpenCreateModal,
    setOpenSnackBar,
    setShowDisabled,
    showDisabled,
    filterString,
    setFilterString,
  ]);

  return <CustomDataTableContext.Provider value={MemoValue}>{children}</CustomDataTableContext.Provider>;
};
