import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridImage = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    minWidth: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto .5rem',
  }),
);
