import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

export const Delivery = () => {
  const { columns, headerActions, actionColumns } = useColumns();
  return (
    <CustomDataTable
      title="Delivery"
      columns={columns}
      resource="authorizations"
      pathApi="deliveries/v1/authorizations"
      listName="authorizations"
      defaultSortFieldId="id"
      defaultSortAsc={false}
      extraActionsInHeader={headerActions}
      canSearch
      canExport
      customActionColumns={actionColumns}
    />
  );
};
