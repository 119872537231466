import { Grid } from '@mui/material';
import InfoLabel from '@/src/components/info-label/InfoLabel';
import CustomLoader from '@/src/components/custom-loader';
import St from './AdministratorDetail.styled';
import SecurityDetail from '../../../../components/user-security-detail';

const AdministratorDetail = ({
  t,
  adminInfo,
  isLoadingGetAdminInfo,
  creationDate,
  lang,
  getFormattedDateByLanguague,
  getFormattedDateFullYearEs,
  getFormattedDateFullYearEn,
  isSuperAdmin,
  mfaAuthInfo,
  onConfirmReset,
  isLoadingReset,
}: any) => (
  <St.Content component="div" sx={{ marginY: '16px' }}>
    {isLoadingGetAdminInfo ? (
      <St.BoxLoader>
        <CustomLoader />
      </St.BoxLoader>
    ) : (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InfoLabel first={t('name')} second={`${adminInfo?.name} ${adminInfo?.surname}`} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoLabel first={t('creation_date')} second={creationDate} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoLabel first={t('phone_number')} second={`${adminInfo?.country_code} ${adminInfo?.phone_number}`} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoLabel first={t('email')} second={`${adminInfo?.email}`} />
        </Grid>
        {adminInfo?.disabled_date && (
          <Grid item xs={12} sm={6}>
            <InfoLabel
              first={t('disabled_date', {
                ns: 'customerUsers',
              })}
              second={getFormattedDateByLanguague(
                lang,
                getFormattedDateFullYearEs,
                getFormattedDateFullYearEn,
                adminInfo?.disabled_date,
              )}
            />
          </Grid>
        )}
        {isSuperAdmin && (
          <SecurityDetail
            {...mfaAuthInfo}
            userName={`${adminInfo?.name} ${adminInfo?.surname}`}
            onConfirmReset={onConfirmReset}
            isLoadingReset={isLoadingReset}
          />
        )}
      </Grid>
    )}
  </St.Content>
);

export default AdministratorDetail;
