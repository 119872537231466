// import { useQuery } from '@tanstack/react-query';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getPaymentById } from '@/src/api/endpoints/payments';
import { PaymentDetailBody, ChargeDetailBody, paymentsStates } from '../types';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import { ChargesDataTable } from './ChargesDataTable';
import InfoLabel from '@/src/components/info-label/InfoLabel';
import {
  getFormattedDateFullYearEn,
  getFormattedDateByLanguague,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import TitleWithStatus from '@/src/components/title-with-status/TitleWithStatus';

const PaymentDetail = (props: any) => {
  const { PaymentId, columnsCharges, close, selectedRow } = props;
  const { t } = useTranslation();
  const { data: values, isLoading } = useQuery(['getPayment'], () => getPaymentById(PaymentId), {
    select: (data) => data?.data,
  });
  const paymentData: PaymentDetailBody = values?.payment;
  const chargesData: ChargeDetailBody[] = paymentData?.charges;
  const lang = getLanguage()?.split('-')[0];
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  const style = {
    container: {
      gap: { xs: 3, md: '8px' },
      marginTop: '8px',
    },
  };

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setIsContentLoaded(true);
      }, 1000);
    }
  }, [values]);

  return (
    <Box component="div" sx={style.container}>
      <Grid container spacing={2} mb={2}>
        <TitleWithStatus
          title={`${t('info_payment_title', { ns: 'charges' })}`}
          statusText={selectedRow?.payment_state.name}
          statusBg={paymentsStates[selectedRow?.payment_state.code]}
          onClose={close}
        />
        {!isContentLoaded ? (
          <CircularSpinner />
        ) : (
          <>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_info_payment_input_operation_date', { ns: 'charges' })}
                second={
                  paymentData?.creation_date &&
                  getFormattedDateByLanguague(
                    lang,
                    getFormattedDateFullYearEs,
                    getFormattedDateFullYearEn,
                    paymentData?.creation_date,
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel first={t('apartment', { ns: 'charges' })} second={paymentData?.residency?.property?.code} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel first={t('total_amount')} second={`$${paymentData?.amount}`} />
            </Grid>
            {paymentData?.amount_paid !== null ?? (
              <Grid item xs={12} sm={6}>
                <InfoLabel first={t('amount_paid', { ns: 'charges' })} second={`$${paymentData?.amount_paid}`} />
              </Grid>
            )}
            <Grid item xs={12}>
              <ChargesDataTable title={t('charges', { ns: 'charges' })} columns={columnsCharges} data={chargesData} />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default PaymentDetail;
