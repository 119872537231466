import { useTranslation } from 'react-i18next';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PotentialCustomerPage from './PotentialCustomerPage';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { Interested } from './types';
import PotentialCustomerForm from './potential-Customer-form';
import PotentialCustomerDetail from './potential-Customer-detail';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';
import useCountriesFilters from '@/src/hooks/filters/countries.hooks';
import usePriceFilters from '@/src/hooks/filters/priceRange.hooks';
import useRoomFilters from '@/src/hooks/filters/room.hooks';
import useInterestedFilters from '@/src/hooks/filters/interested.hooks';
import { getLanguage } from '@/src/utilities/storage';

export const PotentialCustomerPageContainer = () => {
  const { t } = useTranslation();
  const { countries } = useCountriesFilters();
  const { roomTypes } = useRoomFilters();
  const { interestedIn } = useInterestedFilters();
  const { priceRange } = usePriceFilters();
  const lang = getLanguage()?.split('-')[0];

  const columns: CustomDataTableColumnsProps<Interested>[] = [
    {
      id: 'name',
      name: t('interested_list_name_column', { ns: 'interested' }),
      selector: (row) => row.name,
      sortField: 'name',
      center: true,
      sortable: true,
      width: lang === 'en' ? '11%' : '8%',
      filters: [
        {
          id: 'name',
          query: 'Name',
          type: 'contains',
        },
      ],
    },
    {
      id: 'nationality',
      name: t('interested_list_nationality_column', { ns: 'interested' }),
      selector: (row) => row?.nationality_country?.name,
      sortable: false,
      width: lang === 'en' ? '11%' : '8%',
      center: true,
      filters: countries,
    },
    {
      id: 'phoneNumber',
      name: t('interested_list_phoneNumber_column', { ns: 'interested' }),
      selector: (row) => row.phone_number,
      sortField: 'phone_number',
      sortable: true,
      width: lang === 'en' ? '11%' : '15%',
      center: true,
      filters: [
        {
          id: 'phoneNumber',
          query: 'PhoneNumber',
          type: 'contains',
        },
      ],
    },
    {
      id: 'interestedIn',
      name: t('interested_list_interestedIn_column', { ns: 'interested' }),
      selector: (row) => row?.interest?.description,
      sortField: 'interest',
      sortable: true,
      width: lang === 'en' ? '11%' : '10%',
      center: true,
      filters: interestedIn,
    },
    {
      id: 'roomsAmount',
      name: t('interested_list_roomsAmount_column', { ns: 'interested' }),
      selector: (row) => row?.room_type?.description,
      sortField: 'room_type',
      sortable: true,
      width: '15%',
      center: true,
      filters: roomTypes,
    },
    {
      id: 'priceRange',
      name: t('interested_list_priceRange_column', { ns: 'interested' }),
      selector: (row) => `${row.price_range.min_price} - ${row.price_range.max_price}`,
      // sortField: 'priceRange',
      sortable: false, // Deberia o separarse las columnas o que haya un unico sortField
      width: lang === 'en' ? '11%' : '10%',
      center: true,
      filters: priceRange,
    },
    {
      id: 'agentName',
      name: t('interested_list_agentName_column', { ns: 'interested' }),
      selector: (row) => row.agent_name ?? '-',
      sortField: 'agentName',
      sortable: true,
      width: lang === 'en' ? '11%' : '10%',
      center: true,
      filters: [
        {
          id: 'agentName',
          query: 'AgentName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'email',
      name: t('interested_list_email_column', { ns: 'interested' }),
      selector: (row) => row.email,
      sortField: 'email',
      sortable: true,
      width: lang === 'en' ? '11%' : '12%',
      center: true,
      filters: [
        {
          id: 'email',
          query: 'Email',
          type: 'contains',
        },
      ],
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'detail',
      icon: (props: any) => <VisibilityRoundedIcon sx={props.sx} />,
      component: (props: any) => <PotentialCustomerDetail {...props} />,
      width: { xs: '90%', sm: '80%', lg: '60%' },
    },
    {
      id: 'assign',
      icon: (props) => <AssignmentIndIcon sx={props.sx} />,
      component: (props: any) => <PotentialCustomerForm {...props} />,
      width: { xs: '90%', sm: '80%', lg: '60%' },
    },
  ];
  const childProps = {
    columns,
    // headerActions,
    actionColumns,
    t,
  };

  return <PotentialCustomerPage {...childProps} />;
};

export default PotentialCustomerPageContainer;
