import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterColumnDate from './FilterColumnDate';
import { FilterByColumn } from '../../../types/CustomDataTableColumnProps';

const FilterColumnDateContainer = (props: FilterColumnDateContainerProps) => {
  const { setLocalFilterString, filter, localFilterString } = props;
  const [dateStartValue, setDateStartValue] = React.useState<number | null>(null);
  const [dateEndValue, setDateEndValue] = React.useState<number | null>(null);
  const [dateNullValue, setDateNullValue] = React.useState<boolean>(
    localFilterString.includes(`${filter.query}NeverExpires=true`),
  );
  const { t } = useTranslation('cdtModal');

  const handleFilters = (query: string, value: number | boolean) => {
    setLocalFilterString((prev) => {
      const selectedValue = `&${query}=${value}`;
      if (prev.includes(query)) {
        const variables = prev.split('&');
        variables.shift();
        let str = '';
        variables.forEach((i) => {
          if (i.includes(query)) {
            str += `&${query}=${value}`;
          } else if (i !== '') {
            str += `&${i}`;
          }
        });
        return str;
      }
      return prev.concat(selectedValue);
    });
  };

  const onChange = (e: any, type: 'start' | 'end') => {
    if (e && e.$d) {
      const getMillis = new Date(e.$d);
      const offset = getMillis.getTimezoneOffset();
      if (offset < 0) {
        getMillis.setMinutes(Math.abs(offset));
      }
      const selectedTime = getMillis.getTime();
      if (type === 'start') {
        handleFilters(`${filter.query}Start`, selectedTime);
        setDateStartValue(selectedTime);
        if (dateEndValue !== null && selectedTime > dateEndValue) {
          setDateEndValue(selectedTime);
        }
      } else {
        handleFilters(`${filter.query}End`, selectedTime);
        setDateEndValue(selectedTime);
      }
    } else {
      type === 'start' ? setDateStartValue(null) : setDateEndValue(null);
    }
  };

  const getInitialValues = () => {
    const variables = localFilterString.split('&');
    variables.shift();
    variables.forEach((v) => {
      if (v.includes(`${filter.query}Start`)) {
        setDateStartValue(Number(v.split('=')[1]));
      } else if (v.includes(`${filter.query}End`)) {
        setDateEndValue(Number(v.split('=')[1]));
      }
    });
  };

  React.useEffect(() => {
    getInitialValues();
  }, []);

  const clearNullable = () => {
    setLocalFilterString((prev) => {
      const selectedValue = `&${filter.query}NeverExpires=true`;
      if (prev.includes(selectedValue)) {
        return prev.replace(selectedValue, '');
      }
      return prev;
    });
  };

  const handleCheckNullable = (value: boolean) => {
    setDateNullValue(value);
    value ? handleFilters(`${filter.query}NeverExpires`, value) : clearNullable();
  };

  const childProps = {
    t,
    filter,
    dateStartValue,
    dateEndValue,
    dateNullValue,
    onChange,
    handleCheckNullable,
  };

  return <FilterColumnDate {...childProps} />;
};

interface FilterColumnDateContainerProps {
  setLocalFilterString: React.Dispatch<React.SetStateAction<string>>;
  filter: FilterByColumn;
  localFilterString: string;
}

export default FilterColumnDateContainer;
