import React from 'react';
import { useTranslation } from 'react-i18next';
import useBreakpoints from '@/src/hooks/common/useBreakpoints';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import CustomDataTableForDocuments from './CustomDataTableForDocuments';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { ResidenciesType } from '../../types';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';

const CustomDataTableForDocumentsContainer = (props: Props) => {
  const { title, data, isLoading } = props;
  const { t } = useTranslation();
  const { matchesSm } = useBreakpoints();
  const pageSize: number = Math.ceil((window.innerHeight - (matchesSm ? 350 : 260)) / 30) - 1;
  const lang = getLanguage()?.split('-')[0];

  const columnsResidencies: CustomDataTableColumnsProps<ResidenciesType>[] = [
    {
      id: 'from',
      sortField: 'from',
      name: t('from'),
      selector: (row) =>
        row.from
          ? getFormattedDateByLanguague(lang, getFormattedDateFullYearEs, getFormattedDateFullYearEn, row.from)
          : '-',
      sortable: true,
      center: true,
    },
    {
      id: 'to',
      sortField: 'to',
      name: t('to'),
      selector: (row) =>
        row.to
          ? getFormattedDateByLanguague(lang, getFormattedDateFullYearEs, getFormattedDateFullYearEn, row.to)
          : '-',
      sortable: true,
      center: true,
    },
    {
      id: 'property',
      sortField: 'property',
      name: t('documents_apartment', { ns: 'documents' }),
      selector: (row) => row?.property?.code,
      sortable: true,
      center: true,
    },
    {
      id: 'check_out_date',
      sortField: 'check_out_date',
      name: t('documents_check_out_date', { ns: 'documents' }),
      selector: (row) => (row?.check_out_date ? row?.check_out_date : '-'),
      sortable: true,
      center: true,
    },
  ];

  const childProps = {
    title,
    columnsResidencies,
    data,
    isLoading,
    pageSize,
    t,
  };

  return <CustomDataTableForDocuments {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface ExtraProps {
  title: string;
  data: ResidenciesType[];
  isLoading: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
CustomDataTableForDocumentsContainer.propTypes = propTypes;
CustomDataTableForDocumentsContainer.defaultProps = defaultProps;

export default CustomDataTableForDocumentsContainer;
