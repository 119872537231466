import ReactCodeInput from 'react-code-input';
import './CodeInput.css';

interface CodeInputProps {
  onChange?: ((value: string) => void) | undefined;
  value?: string | undefined;
  fields?: number | undefined;
}

const CodeInput = ({ onChange, value, fields = 6 }: CodeInputProps) => (
  <ReactCodeInput
    name="bz-code"
    inputMode="numeric"
    type="number"
    fields={fields}
    onChange={onChange}
    value={value}
    className="code-input"
  />
);

export default CodeInput;
