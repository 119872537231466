import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

export const AmenitiesPage = () => {
  const { columns, headerActions, actionColumns } = useColumns();
  const { t } = useTranslation();

  return (
    <CustomDataTable
      title={t('amenities')}
      columns={columns}
      resource="activities"
      pathApi="/bookings/v1/business-activities"
      listName="activities"
      defaultSortFieldId="id"
      defaultSortAsc={false}
      canSearch
      extraActionsInHeader={headerActions}
      customActionColumns={actionColumns}
      canExport
    />
  );
};
