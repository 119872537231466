import React from 'react';
import { Grid, Box, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { PhoneSearchProps } from '../../types';

import PhoneSelect from '@/src/components/phone-select/PhoneSelect';
import { BoxSearchComponent } from './SearchComponent.styled';

const SearchComponent = ({ values, setFieldValue, errors, handleSubmit, responseError }: PhoneSearchProps) => {
  const { t } = useTranslation();
  return (
    <Box component="form" onSubmit={handleSubmit} mt={1}>
      <Grid container spacing={1}>
        <Grid xs={12} item>
          <PhoneSelect
            errors={errors}
            values={values}
            setFieldValue={setFieldValue}
            isDisabled={values.showOtherInputs}
            autoFocusFirstInput
          />
        </Grid>
        {responseError && (
          <Grid xs={12} item>
            <FormHelperText error>{responseError}</FormHelperText>
          </Grid>
        )}
        <BoxSearchComponent>
          <LoadingButton type="submit" color="primary" variant="contained" sx={{ width: { xs: '100%', sm: 'auto' } }}>
            {t('continue_button', { ns: 'residents' })}
          </LoadingButton>
        </BoxSearchComponent>
      </Grid>
    </Box>
  );
};

export default SearchComponent;
