import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { confirmPackages } from '@/src/api/endpoints/packages';
import { DataTableFormModal } from '@/src/components/custom-data-table/types/DataTableFormModal';
import { useDeliveryPackage } from '../../hooks/useDeliverPackage';
import DeliverPackageForm from './DeliverPackageForm';
import { validate } from './DeliverPackageForm.validate';
import { isValidDigit } from '@/src/utilities/helpers/stringsHelper';
import { Carrier } from '../../types';

const DeliverPackageFormContainer = ({ close, setSnackBarMessage }: DataTableFormModal) => {
  const { t } = useTranslation();
  const { handleToggle, handleToggleAll, getPackagesByFunctionalUnit, detail, isLoading } = useDeliveryPackage();
  const [scannedBarcode, setScannedBarcode] = useState('');
  const [barcode, setBarcode] = useState('');

  const handleScannedBarcode = () => {
    if (detail.packages?.length > 0) {
      const aux = detail.packages.filter((item: any) => item.internal_code === Number(scannedBarcode));
      if (aux.length > 0) {
        aux.map((value: any) => handleToggle(value.internal_code));
      } else {
        setSnackBarMessage(t('modal_deliver_package_no_scanned_code', { ns: 'packages' }), 'error');
      }
    }
    setScannedBarcode('');
  };

  const onKeyPressed = (e: any) => {
    const { keyCode } = e;
    if (keyCode === 13) {
      setScannedBarcode(barcode);
      setBarcode('');
    } else if (isValidDigit(keyCode)) {
      setBarcode(barcode + e.key);
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', onKeyPressed, false);

    return () => {
      document.removeEventListener('keydown', onKeyPressed, false);
    };
  });

  useEffect(() => {
    if (scannedBarcode.length > 0) {
      handleScannedBarcode();
    }
  }, [scannedBarcode]);

  const { mutate, isLoading: isLoadingSubmit } = useMutation(confirmPackages);
  const sendPackageDelivery = ({ userId, packages }: { userId: string; packages: Array<Carrier> }) => {
    if (packages.filter((el) => el.status === true).length < 1)
      return setSnackBarMessage(t('modal_deliver_package_at_least_one_package', { ns: 'packages' }), 'error');
    const packagesToDeliver = {
      user_id: userId,
      package_ids: packages.filter((el) => el.status === true).map((el) => el.internal_code),
    };
    mutate(packagesToDeliver, {
      onError: () => {
        setSnackBarMessage(t('error'), 'error');
      },
      onSuccess: () => {
        close();
        setSnackBarMessage(t('success'));
      },
    });
  };

  const getInitialValues = () => ({
    propertyCode: '',
    customerCode: '',
  });

  const onSubmit = (values: any) => {
    getPackagesByFunctionalUnit({
      propertyCode: values.propertyCode.trim(),
      customerCode: values.customerCode.trim(),
    });
    setBarcode('');
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate(t),
  });

  const childProps = {
    handleSubmit,
    values,
    setFieldValue,
    errors,
    isLoadingSubmit,
    sendPackageDelivery,
    closeModal: close,
    handleToggle,
    handleToggleAll,
    detail,
    isLoading,
    number: detail.packages?.filter((el: any) => el.status === true).length,
    searchDone: detail.packages?.length > 0,
  };

  return <DeliverPackageForm {...childProps} />;
};

export default DeliverPackageFormContainer;
