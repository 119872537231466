import { useQuery } from '@tanstack/react-query';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getFamilyTypes } from '@/src/api/endpoints/houseHold';

const useRelationshipTypesFilters = () => {
  const getRelationshipTypesMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.code,
          query: 'RelationshipTypeCodes',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: relationshipTypes, isLoading } = useQuery(['getFamilyTypes'], getFamilyTypes, {
    select: (response) => getRelationshipTypesMapper(response?.data?.relationship_types),
  });

  return { relationshipTypes, isLoading };
};

export default useRelationshipTypesFilters;
