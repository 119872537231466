import { Box, Tabs, Tab, Grid } from '@mui/material';
import React from 'react';

import { NewsFormProps } from '../../types';
import NewsFormInformation from './news-form-information-tab';
import NewsFormAddressee from './news-form-addressee-tab';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { SubmitButton } from './NewsForm.styled';

const NewsForm = ({
  handleSubmit,
  values,
  setFieldValue,
  setFieldError,
  errors,
  isLoadingSubmit,
  errorMessage,
  t,
  handleChange,
  tabValue,
  a11yProps,
  disableButton,
  setDisableButton,
}: NewsFormProps) => (
  <Box component="form" onSubmit={handleSubmit} sx={{ mt: '5px' }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
        <Tab label={`${t('news_form_first_tab_title', { ns: 'news' })}`} {...a11yProps(0)} />
        <Tab label={`${t('news_form_second_tab_title', { ns: 'news' })}`} {...a11yProps(1)} />
      </Tabs>
    </Box>
    <NewsFormInformation
      tabValue={tabValue}
      values={values}
      errors={errors}
      setFieldValue={setFieldValue}
      setFieldError={setFieldError}
      errorMessage={errorMessage}
      isLoadingSubmit={isLoadingSubmit}
      setDisableButton={setDisableButton}
    />
    {/* Second tab */}
    <NewsFormAddressee tabValue={tabValue} values={values} errors={errors} setFieldValue={setFieldValue} />
    {errorMessage && (
      <Grid xs={6} item>
        <ErrorText error={errorMessage} />
      </Grid>
    )}
    <Grid container item xs={errorMessage ? 6 : 12} justifyContent="flex-end">
      <SubmitButton loading={isLoadingSubmit} variant="contained" type="submit" disabled={disableButton}>
        {t('post', { ns: 'news' })}
      </SubmitButton>
    </Grid>
  </Box>
);

export default React.memo(NewsForm);
