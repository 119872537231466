import React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getChargeById } from '@/src/api/endpoints/residents';

export const useChargeInfo = (id: number) => {
  const queryClient = useQueryClient();
  const { data: values, isLoading } = useQuery(['getChargeInfo', id], () => getChargeById(id), {
    select: (data) => data?.data.charge,
  });

  const memoizedResetQueries = React.useMemo(() => queryClient.resetQueries(['getChargeInfo', id]), []);
  React.useEffect(() => {
    memoizedResetQueries;
  }, []);

  return { values, isLoading };
};
