import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormikValues, useFormik } from 'formik';
import AdministratorEditForm from './AdministratorFormEdit';
import { validate } from './AdministratorFormEdit.validate';
import { getAdminGroups, getAdminInfo, editAdminUser } from '@/src/api/endpoints/admin-users';
import { Activities, AdminEditContainerProps, AdminUsers } from '../../types';
import { getAmenities } from '@/src/api/endpoints/bookings';

const AdministratorEditFormContainer = ({ row, close, setSnackBarMessage }: AdminEditContainerProps) => {
  const { data: adminInfo, isLoading: isLoadingGetAdminInfo } = useQuery(
    [`getAdminInfo_${row.id}`],
    () => getAdminInfo(row.id),
    {
      select: (data) => data?.data?.admin,
    },
  );

  const { data: response, isLoading: loading } = useQuery(['getAdminGroups'], () => getAdminGroups());
  const adminUserGroups: Array<AdminUsers> | undefined | null = response?.data.groups;
  const { data: amenitiesData, isLoading: getAmenitiesLoading } = useQuery(['activities'], () =>
    getAmenities({ page: 0 }),
  );
  const amenitiesList: { activities: Array<Activities> | undefined | null; totalCount: number } = amenitiesData?.data;

  const [errorMessage, setErrorMessage] = useState<{ code: string; message: string; property_name: string } | null>(
    null,
  );

  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(editAdminUser, {
    onError: async (e: any) => {
      setErrorMessage(e.response.data.error_messages[0]);
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successful_user_edition_snackbar_text', { ns: 'users' }));
    },
  });

  const getInitialValues = () => ({
    adminGroups: adminInfo?.groups,
    countryCode: adminInfo?.country_code,
    countryIsoCode: adminInfo?.country_iso_code,
    email: adminInfo?.email.trim(),
    name: adminInfo?.name,
    notes: adminInfo?.notes,
    phoneNumber: adminInfo?.phone_number,
    surname: adminInfo?.surname,
    activityIds: adminInfo?.booking_activities,
  });

  const onSubmit = (data: FormikValues) => {
    const body = {
      country_iso_code: data?.countryIsoCode,
      country_code: data.countryCode,
      email: data.email,
      group_ids: data.adminGroups?.map((x: { id: number; name: string }) => x.id),
      name: data.name,
      notes: data.notes,
      password: data.password,
      phone_number: data.phoneNumber,
      surname: data.surname,
      activity_ids: data.activityIds?.map((x: { id: number; name: string }) => x.id),
    };
    mutate({ body, id: adminInfo?.user_id });
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate({ t }),
  });

  const childProps = {
    adminUserGroups,
    amenitiesList,
    getAmenitiesLoading,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    loading: isLoadingGetAdminInfo || loading,
    errorMessage,
    isSubmitLoading: isLoading,
    showAmenitiesSelector: values?.adminGroups?.find((rol: AdminUsers) => rol.name === 'Bookings') ?? false,
  };
  return <AdministratorEditForm {...childProps} />;
};

export default AdministratorEditFormContainer;
