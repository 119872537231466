import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { Concept } from './types';
import { ActionColumn, ActionHeader } from '@/src/components/custom-data-table/types/DataTableFormModal';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import ConceptsForm from './concepts-form';

export const useColumns = () => {
  const { t } = useTranslation();
  const columns: CustomDataTableColumnsProps<Concept>[] = [
    {
      id: 'id',
      sortField: 'id',
      name: t('id'),
      selector: (row) => row.id,
      sortable: true,
      maxWidth: '1%',
      filters: [
        {
          id: 'charge-concept-id',
          query: 'ChargeConceptId',
          type: 'numeric',
        },
      ],
    },
    {
      id: 'name',
      sortField: 'name',
      name: t('title'),
      selector: (row) => row.name,
      sortable: true,
      filters: [
        {
          id: 'title',
          query: 'Name',
          type: 'contains',
        },
      ],
    },
  ];

  const headerActions: ActionHeader[] = [
    {
      id: 'concepts',
      component: () => (
        <CustomTableAction
          modalTitle="concepts"
          component={(props: any) => <ConceptsForm {...props} />}
          Icon={() => <AddIcon />}
          type="create"
          width={{ xs: '90%', lg: '20%', xl: '20%' }}
        />
      ),
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'edit',
      icon: (props: any) => <ModeEditIcon sx={props.sx} />,
      component: (props: any) => <ConceptsForm {...props} isEdit />,
      width: { xs: '90%', lg: '20%', xl: '20%' },
    },
  ];

  return { columns, headerActions, actionColumns };
};
