import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createGuestVehicle } from '@/src/api/endpoints/parking';
import { validate } from './Guest.FormValidate';
import { GuestCarEntryRequest, GuestFormProps } from '../types';
import GuestForm from './GuestForm';

const GuestFormContainer = ({ close, setSnackBarMessage }: GuestFormProps) => {
  const { t } = useTranslation();
  const [responseError, setResponseError] = useState('');
  const { mutate: createGuestVehicleMutate, isLoading: isLoadingCreate } = useMutation(createGuestVehicle);

  const getInitialValues = () => ({
    ticketNumber: '',
    parkingNumber: '',
    guestName: '',
    countryCode: '',
    countryIsoCode: '',
    phoneNumber: '',
  });

  const onSubmit = (data: any) => {
    setResponseError('');
    const info: GuestCarEntryRequest = {
      ticket_code: data.ticketNumber,
      parking_code: data.parkingNumber,
      guest_name: data.guestName,
      country_code: data.countryCode,
      country_iso_code: data.countryIsoCode,
      phone_number: data.phoneNumber,
    };
    createGuestVehicleMutate(info, {
      onSuccess: () => {
        close();
        setSnackBarMessage(t('success'));
      },
      onError: () => {
        setResponseError(t('error'));
      },
    });
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate(t),
  });

  const childProps = {
    values,
    setFieldValue,
    errors,
    handleSubmit,
    responseError,
    isLoadingSubmit: isLoadingCreate,
    close,
  };

  return <GuestForm {...childProps} />;
};

export default GuestFormContainer;
