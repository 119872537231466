import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

export const ReservationsPage = () => {
  const { columns, actionColumns } = useColumns();
  const { t } = useTranslation();
  return (
    <CustomDataTable
      title={t('reservations_page_list_title', { ns: 'bookings' })}
      columns={columns}
      resource="bookings"
      pathApi="/bookings/v1/bookings"
      listName="bookings"
      canSearch
      defaultSortFieldId="id"
      defaultSortAsc={false}
      canExport
      extraFilters={[{ query: 'startDate', queryValue: new Date().getTime() }]}
      customActionColumns={actionColumns}
    />
  );
};
