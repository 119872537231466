import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const InputFlagContainer = styled(Box)`
  display: flex;
  gap: 16px;
  margin-top: 8px;
`;

export const InputFlagWrapper = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    width: { xs: '85%', md: '90%' },
  }),
);

export const FlagWrapper = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',

    width: { xs: '15%', md: '10%' },
  }),
);
