import React, { useEffect } from 'react';
import { Box, TextField, Autocomplete, Grid, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import { countries } from './Countries';
import { ErrorText } from '../error/error-text/ErrorText';
import { PhoneSelectProps } from './types';

const PhoneSelect = (props: PhoneSelectProps) => {
  const { t } = useTranslation();
  const { values, errors, isDisabled, setFieldValue, autoFocusFirstInput } = props;

  const countryCode = /[^+0-9]/;
  const numberRegex = /[^0-9]/;

  const filterOptions = (options: { phone: string; code: string; label: string }[], state: any) => {
    const inputValue = state.inputValue.toUpperCase();

    return options.filter((option) => option.code.includes(inputValue) || option.phone.includes(inputValue));
  };

  useEffect(() => {
    if (!values.countryIsoCode && values.countryCode) {
      const codes = countries.filter((country) => country.phone === values.countryCode);
      setFieldValue('countryIsoCode', codes[0].code);
    }
  }, [values.countryIsoCode, values.countryCode]);

  const handleCountryCode = (value: { phone: string; code: string; label: string } | null) => {
    if (value) {
      setFieldValue('countryCode', value.phone.replace(countryCode, ''));
      setFieldValue('countryIsoCode', value.code.trim());
    } else {
      setFieldValue('countryCode', '');
      setFieldValue('countryIsoCode', '');
    }
  };
  const handlePhoneNumber = (value: string) => {
    setFieldValue('phoneNumber', value.replace(numberRegex, ''));
  };
  const customInputStyles = {
    fieldset: {
      backgroundColor: isDisabled ? 'rgba(0, 0, 0, 0.05)' : '',
    },
    input: {
      WebkitTextFillColor: isDisabled ? 'rgba(0,0,0,0.6) !important' : '',
      color: isDisabled ? 'rgba(0,0,0,0.6) !important' : '',
      opacity: isDisabled ? 0.7 : '',
      // backgroundColor: isDisabled ? 'rgba(0,0,0,0.05) ' : '',
    },
    label: {
      color: isDisabled ? 'rgba(0,0,0,1) !important' : '',
    },
  };

  return (
    <Grid
      sx={{
        borderBottom: 'none',
        borderRadius: '5px',
        display: { md: 'flex', sm: 'inline' },
        maxWidth: '100%',
        '& .MuiOutlinedInput-root': {
          paddingLeft: 0,
        },
      }}
    >
      <TextField
        size="small"
        id="outlined-basic-phone-number"
        sx={{ ...customInputStyles, width: '100%' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Autocomplete
                id="country-select-demo"
                size="small"
                sx={{
                  width: { lg: '150px', md: '50%' },
                  height: 'max-content',
                  backgroundColor: isDisabled ? '' : '',
                  paddingLeft: 0,
                  marginRight: 2,
                }}
                options={countries || [{ phone: '', code: '', label: '' }]}
                disabled={isDisabled}
                autoHighlight
                autoSelect
                filterOptions={filterOptions}
                value={
                  values.countryCode && values.countryIsoCode
                    ? { phone: values.countryCode ?? '', code: values.countryIsoCode ?? '', label: '' }
                    : null
                }
                isOptionEqualToValue={(option: { label: string; phone: string; code: string }) => {
                  if (values?.countryIsoCode === '') return option.code === 'AR';
                  return option.code === values.countryIsoCode;
                }}
                renderOption={(propss, option) => (
                  <Box component="li" sx={{ '& > img': { flexShrink: 0, mr: 2 } }} {...propss}>
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option?.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option?.code.toLowerCase()}.png 2x`}
                      alt="flag_icon"
                    />
                    ({option.code}) {option.phone}
                  </Box>
                )}
                getOptionLabel={(option) => option.phone ?? ''}
                onChange={(e, value) => handleCountryCode(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    value={values?.country_code}
                    autoFocus={autoFocusFirstInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {values.countryIsoCode ? (
                            <img
                              loading="lazy"
                              width="22px"
                              src={`https://flagcdn.com/w20/${values?.countryIsoCode?.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${values?.countryIsoCode?.toLowerCase()}.png 2x`}
                              alt="flag_icon"
                              style={{ marginLeft: 5 }}
                            />
                          ) : (
                            <LanguageIcon />
                          )}
                        </InputAdornment>
                      ),
                      ref: params.InputProps.ref,
                      endAdornment: params.InputProps.endAdornment,
                      className: params.InputProps.className,
                    }}
                    sx={{
                      width: '170px',
                      '& fieldset': {
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderBottom: 'none',
                        borderRadius: 0,
                        width: '170px',
                        paddingLeft: '20px',
                      },
                      input: {
                        WebkitTextFillColor: isDisabled ? 'rgba(0,0,0,0.6) !important' : '',
                        maxLength: 4,
                        borderLeft: 'none',
                        borderBottom: 'none',
                        borderTop: 'none',
                      },
                      label: {
                        color: isDisabled ? 'rgba(0,0,0,1) !important' : '',
                      },
                    }}
                  />
                )}
              />
            </InputAdornment>
          ),
        }}
        type="number"
        label={t('phone_select_phone_number_field_label')}
        variant="outlined"
        autoComplete="no"
        value={values?.phoneNumber || ''}
        error={!!errors?.phoneNumber || !!errors?.countryCode}
        disabled={isDisabled}
        onChange={(e) => handlePhoneNumber(e.target.value)}
        helperText={<ErrorText error={errors?.phoneNumber as string} />}
      />
    </Grid>
  );
};

export default PhoneSelect;
