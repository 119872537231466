import { Box, Divider, Grid, Typography } from '@mui/material';
import { ChargeInfoProps } from '../../../residents/account-state-section/types';
import InfoLabel from '@/src/components/info-label/InfoLabel';

const Subtitle = ({ text }: { text: string }) => (
  <Box width="100%">
    <Typography variant="subtitle1" fontWeight={600}>
      {text}
    </Typography>
    <Divider />
  </Box>
);

const ChargeInfo = ({
  chargeInfoItems,
  propertyInfoItems,
  isLoading,
  values,
  t,
  noDataComponent,
  getFormattedDateNumbeByLanguague,
}: ChargeInfoProps) => (
  <Box sx={{ marginY: '5px' }}>
    <Grid container spacing={2} paddingTop={2}>
      {!values || isLoading ? (
        <Grid item lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center">
          {noDataComponent}
        </Grid>
      ) : (
        <>
          {chargeInfoItems.map((item, index) => (
            <Grid key={index} item lg={6} md={12} sm={12} xs={12} display="flex" alignItems="center">
              <InfoLabel first={item.label} second={item.value} />
            </Grid>
          ))}
          <Grid item lg={12} md={12} sm={12} xs={12} display="flex" alignItems="center">
            <Subtitle text={t('detail_apartment', { ns: 'residents' })} />
          </Grid>
          {propertyInfoItems.map((item, index) => (
            <Grid key={index} item lg={6} md={12} sm={12} xs={12} display="flex" alignItems="center">
              <InfoLabel first={item.label} second={item.value} />
            </Grid>
          ))}
          {values?.property_resident?.check_out_date && (
            <Grid item lg={6} md={12} sm={12} xs={12} display="flex" alignItems="center">
              <InfoLabel
                first={t('check_out_date_column', { ns: 'residents' })}
                second={getFormattedDateNumbeByLanguague(values.property_resident.check_out_date)}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  </Box>
);

export default ChargeInfo;
