import { Card, Box, styled, Typography } from '@mui/material';

export default {
  Card: styled(Card)(() => ({
    height: 'auto',
    borderRadius: '20px',
  })),
  Content: styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  })),
  Amount: styled(Typography)(() => ({
    fontWeight: '500',
    fontSize: '30px',
  })),
  Result: styled(Box)(() => ({
    backgroundColor: '#5A5A5A',
    padding: '16px',
  })),
  SalaryContent: styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  })),
  TextContainer: styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '45px',
  })),
  TotalText: styled(Typography)(() => ({
    fontSize: '14px',
    color: '#D3D3D3',
    fontWeight: '500',
  })) as typeof Typography,
  Caption: styled(Typography)(() => ({
    fontSize: '10px',
    color: '#D3D3D3',
  })) as typeof Typography,
  TotalAmount: styled(Typography)(() => ({
    fontSize: '24px',
    color: 'white',
    fontWeight: '600',
  })) as typeof Typography,
};
