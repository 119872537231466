import * as Yup from 'yup';
import { TProp } from '@/src/utilities/helpers/commonTypes';

export const validate = ({ t }: TProp) =>
  Yup.lazy(() =>
    Yup.object().shape({
      activity_id: Yup.number().min(1, t('required_field', { ns: 'errors' })),
      name: Yup.string().required(t('required_field', { ns: 'errors' })),
      min_reservation_time: Yup.number().required(t('required_field', { ns: 'errors' })),
      max_reservation_time: Yup.number()
        .required(t('required_field', { ns: 'errors' }))
        .test(
          'min_reservation_time',
          t('agendas_form_max_reservation_time_greater_than_min_reservation_time_validation', { ns: 'bookings' }),
          (value, context) => value != null && value > context.parent.min_reservation_time,
        ),
    }),
  );
