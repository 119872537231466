import { useTranslation } from 'react-i18next';
import { Visibility, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { ActionColumn, CustomIconProps } from '@/src/components/custom-data-table/types/DataTableFormModal';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import TextLabel from '@/src/components/text-label/TextLabel';
import { commonStatus } from '../common/types';
import { PetColumnRow } from './types';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import PetsForm from './pets-form';
import DeletePet from './delete-pet';
import { PetDetailModal } from './pet-detail/PetDetailModal';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';
import useSexesFilters from '@/src/hooks/filters/sexes.hooks';
import useResidencyFilters from '@/src/hooks/filters/residencyTypes.hooks';
import usePetTypesAndBreedsFilters from '@/src/hooks/filters/typesAndBreeds.hooks';

export const useColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const { apartments } = useApartmentsFilters();
  const { sexes } = useSexesFilters();
  const { residencyTypes } = useResidencyFilters();
  const { breeds, petTypes } = usePetTypesAndBreedsFilters();

  const columnsPetsRequests: CustomDataTableColumnsProps<PetColumnRow>[] = [
    {
      id: 'creation_date',
      sortField: 'pet_creation_date',
      name: t('creation_date', { ns: 'customerUsers' }),
      selector: (row) =>
        row.pet?.creation_date
          ? getFormattedDateByLanguague(
              lang,
              getFormattedDateFullYearEs,
              getFormattedDateFullYearEn,
              row.pet?.creation_date,
            )
          : '-',
      sortable: true,
      minWidth: lang === 'en' ? '144px' : '180px',
      right: true,
      filters: [
        {
          id: 'pets-creation-date',
          query: 'PetCreationDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'apartment',
      sortField: 'residency_property_code',
      name: t('user_detail_apartment', { ns: 'customerUsers' }),
      selector: (row) => row.residency?.property?.code,
      sortable: true,
      minWidth: 'min-content',
      right: true,
      filters: apartments,
    },
    {
      id: 'resident',
      sortField: 'holder_full_name',
      name: t('resident', { ns: 'customerUsers' }),
      selector: (row) =>
        row.residency?.holder ? `${row.residency?.holder?.name} ${row.residency?.holder?.surname}` : '-',
      sortable: false,
      minWidth: lang === 'en' ? '136px' : '148px',
      right: true,
      filters: [
        {
          id: 'resident-full-name',
          query: 'HolderFullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'residentType',
      sortField: 'residency_type',
      name: t('resident_type', { ns: 'customerUsers' }),
      selector: (row) => row.residency?.type?.name ?? '-',
      sortable: false,
      minWidth: lang === 'en' ? '120px' : '128px',
      right: true,
      filters: residencyTypes,
    },
    {
      id: 'name',
      sortField: 'pet_name',
      name: t('name'),
      selector: (row) => row.pet?.name,
      sortable: true,
      minWidth: '120px',
      right: true,
      filters: [
        {
          id: 'pet-name',
          query: 'PetName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'type',
      sortField: 'pet_type',
      name: t('kind', { ns: 'customerUsers' }),
      selector: (row) => row.pet?.type?.name,
      sortable: true,
      filters: petTypes,
      minWidth: '120px',
      // right: true,
    },
    {
      id: 'breed',
      sortField: 'pet_breed',
      name: t('breed', { ns: 'customerUsers' }),
      selector: (row) => row.pet?.breed?.name,
      sortable: true,
      filters: breeds,
      minWidth: '100px',
      // right: true,
    },
    {
      id: 'birthday',
      sortField: 'pet_birthdate',
      name: t('birthday', { ns: 'customerUsers' }),
      selector: (row) => row.pet?.birthday,
      sortable: true,
      // minWidth: 'min-content',
      // right: true,
      filters: [
        {
          id: 'birthday',
          query: 'Birthday',
          type: 'min-max',
        },
      ],
    },
    {
      id: 'sex',
      sortField: 'pet_sex',
      name: t('sex', { ns: 'customerUsers' }),
      selector: (row) => row.pet?.sex?.name,
      sortable: true,
      minWidth: 'min-content',
      right: true,
      filters: sexes,
    },
    {
      id: 'is_sterialized',
      sortField: 'pet_is_sterilized',
      name: t('is_sterialized', { ns: 'customerUsers' }),
      selector: (row) => (row.pet?.is_sterilized ? `${t('yes')}` : 'No'),
      cell: (row) => <Checkbox checked={row.pet?.is_sterilized} disabled sx={{ padding: '0px' }} />,
      minWidth: 'min-content',
      right: true,
      sortable: false,
    },
    {
      id: 'is_service_animal',
      sortField: 'pet_is_service_animal',
      name: t('is_service_animal', { ns: 'customerUsers' }),
      selector: (row) => (row.pet?.is_service_animal ? `${t('yes')}` : 'No'),
      cell: (row) => <Checkbox checked={row.pet?.is_service_animal} disabled sx={{ padding: '0px' }} />,
      // center: true,
      // minWidth: lang === 'en' ? '136px' : '160px',
      minWidth: 'min-content',
      right: true,
      sortable: false,
    },
    {
      cell: (row) => (
        <TextLabel
          text={row.pet?.state?.name.toLocaleUpperCase()}
          color="#F7F7F7"
          padding="2px 12px"
          width="100px"
          bg={commonStatus[row.pet?.state?.code.replaceAll(' ', '_').replaceAll('ó', 'o')]}
        />
      ),
      id: 'status',
      sortField: 'pet_state',
      name: t('status'),
      selector: (row) => row.pet?.state?.name,
      sortable: false,
      minWidth: 'min-content',
      right: true,
    },
  ];

  const columnsPets: CustomDataTableColumnsProps<PetColumnRow>[] = [
    {
      id: 'creation_date',
      sortField: 'pet_creation_date',
      name: t('creation_date', { ns: 'customerUsers' }),
      selector: (row) =>
        row.pet?.creation_date
          ? getFormattedDateByLanguague(
              lang,
              getFormattedDateFullYearEs,
              getFormattedDateFullYearEn,
              row.pet?.creation_date,
            )
          : '-',
      sortable: true,
      filters: [
        {
          id: 'pets-creation-date',
          query: 'PetCreationDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'apartment',
      sortField: 'residency_property_code',
      name: t('user_detail_apartment', { ns: 'customerUsers' }),
      selector: (row) => row.residency?.property?.code,
      width: lang === 'es' ? '250px' : '200px',
      sortable: false,
      center: true,
      filters: apartments,
    },
    {
      id: 'residentType',
      sortField: 'residency_type',
      minWidth: lang === 'en' ? '120px' : '128px',
      name: t('resident_type', { ns: 'customerUsers' }),
      selector: (row) => row.residency?.type?.name ?? '-',
      sortable: false,
      filters: residencyTypes,
    },
    {
      id: 'resident',
      sortField: 'holder_full_name',
      name: t('resident', { ns: 'customerUsers' }),
      selector: (row) =>
        row.residency?.holder ? `${row.residency?.holder?.name} ${row.residency?.holder?.surname}` : '-',
      sortable: false,
      minWidth: '100px',
      filters: [
        {
          id: 'holder-full-name',
          query: 'HolderFullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'name',
      sortField: 'pet_name',
      name: t('name'),
      selector: (row) => row.pet?.name,
      center: true,
      sortable: true,
      minWidth: '100px',
      filters: [
        {
          id: 'pet-name',
          query: 'PetName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'type',
      sortField: 'pet_type',
      name: t('kind', { ns: 'customerUsers' }),
      selector: (row) => row.pet?.type?.name,
      sortable: true,
      filters: petTypes,
    },
    {
      id: 'breed',
      sortField: 'pet_breed',
      name: t('breed', { ns: 'customerUsers' }),
      selector: (row) => row.pet?.breed?.name,
      sortable: true,
      filters: breeds,
    },
    {
      id: 'birthday',
      sortField: 'pet_birthdate',
      name: t('birthday', { ns: 'customerUsers' }),
      selector: (row) => row.pet?.birthday,
      width: '80px',
      sortable: true,
      filters: [
        {
          id: 'birthday',
          query: 'Birthday',
          type: 'min-max',
        },
      ],
    },
    {
      id: 'sex',
      sortField: 'pet_sex',
      name: t('sex', { ns: 'customerUsers' }),
      selector: (row) => row.pet?.sex?.name,
      sortable: true,
      filters: sexes,
    },
    {
      id: 'is_sterialized',
      sortField: 'pet_is_sterilized',
      name: t('is_sterialized', { ns: 'customerUsers' }),
      selector: (row) => (row.pet?.is_sterilized ? `${t('yes')}` : 'No'),
      cell: (row) => <Checkbox checked={row.pet?.is_sterilized} disabled sx={{ padding: '0px' }} />,
      center: true,
      sortable: false,
    },
    {
      id: 'is_service_animal',
      sortField: 'pet_is_service_animal',
      name: t('is_service_animal', { ns: 'customerUsers' }),
      selector: (row) => (row.pet?.is_service_animal ? `${t('yes')}` : 'No'),
      cell: (row) => <Checkbox checked={row.pet?.is_service_animal} disabled sx={{ padding: '0px' }} />,
      center: true,
      sortable: false,
    },
  ];

  const actionColumnsPetsRequestPage: Array<ActionColumn> = [
    {
      id: 'detail',
      icon: ({ sx }: CustomIconProps) => <Visibility sx={sx} />,
      component: (props: any) => <PetDetailModal {...props} showButtons />,
      customizedTitle: true,
      width: { xs: '90%', sm: '500px', md: '550px' },
    },
  ];

  const actionColumnsPetsPage: Array<ActionColumn> = [
    {
      id: 'detail',
      icon: ({ sx }: CustomIconProps) => <Visibility sx={sx} />,
      component: (props: any) => <PetDetailModal {...props} />,
      customizedTitle: true,
      width: { xs: '90%', sm: '500px', md: '550px' },
    },
    {
      id: 'edit',
      icon: (props) => <EditIcon sx={props.sx} />,
      component: (props: any) => <PetsForm {...props} isEditForm />,
      width: { xs: '90%', sm: '80%' },
    },
    {
      id: 'delete',
      icon: (props) => <DeleteIcon sx={props.sx} />,
      component: (props: any) => <DeletePet {...props} />,
      width: { xs: '90%', sm: '500px', md: '550px' },
    },
  ];

  return {
    columnsPetsRequests,
    actionColumnsPetsRequestPage,
    columnsPets,
    actionColumnsPetsPage,
  };
};
