import React from 'react';
import { TFunction } from 'i18next';
import AlertNoData from './AlertNoData';

const NoDataComponent = ({ finalColumns, t }: { finalColumns: any[]; t: TFunction }) => (
  <div style={{ width: '100%' }}>
    <div className="rdt_TableHead">
      <div className="rdt_TableHeadRow">
        {finalColumns.map((column, index) => (
          <div key={index} className="rdt_TableCol">
            <div key={index} className="rdt_TableHeadRow">
              {column.name}
            </div>
          </div>
        ))}
      </div>
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <AlertNoData title={t('no_data_was_found')} />
    </div>
  </div>
);

export default NoDataComponent;
