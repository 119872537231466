import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, CardHeader, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import CustomEditor from '@/src/components/custom-editor/CustomEditor';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';

export const Notes = ({ row, handleSubmit, values, setFieldValue, errors, isLoadingSubmit, isLoading }: any) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {t('view_notes', { ns: 'cdtModal' })}
            {row?.person?.has_note && <FiberManualRecordIcon sx={{ color: '#516F3F', marginLeft: '10px' }} />}
          </span>
        }
      />
      <Divider />
      <CardContent>
        <Box component="form" onSubmit={handleSubmit}>
          {isLoading ? (
            <CircularSpinner />
          ) : (
            <>
              <CustomEditor values={values} valueName="note" setFieldValue={setFieldValue} />
              {errors.body && <ErrorText error={t('enter_message', { ns: 'news' })} />}
              <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                <LoadingButton
                  loading={isLoadingSubmit}
                  variant="contained"
                  type="submit"
                  sx={{ width: { xs: '100%' } }}
                >
                  {t('update_note', { ns: 'residents' })}
                </LoadingButton>
              </Box>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
