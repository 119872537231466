import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik, FormikValues } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import CreateEditActivity from './CreateEditAmenity';
import { createAmenity, getAmenity, editAmenity } from '@/src/api/endpoints/bookings';
import getEnvVariables from '../../../../../utilities/helpers/getEnvVariables';
import { useGenerateImageUrl } from '@/src/hooks/common/useGenerateImageUrl';
import { CreateEditProps } from '../../types';

const { VITE_TENANT_ID } = getEnvVariables();

const CreateEditComponentContainer = (props: CreateEditProps) => {
  const { t } = useTranslation();
  const { row, close, setSnackBarMessage } = props;

  const [creationFailed, setCreationFailed] = React.useState<string | null>(null);

  const { getUrl, isLoadingImage, isUploadingImage, errorSubmittingImage, url } = useGenerateImageUrl();

  const { mutate: createAnAmenity, isLoading: isCreationLoading } = useMutation(createAmenity, {
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successful_amenity_creation_snackbar_text', { ns: 'bookings' }));
    },
    onError: async () => {
      setCreationFailed(t('failed_amenity_creation_snackbar_text', { ns: 'bookings' }));
    },
  });

  const { mutate: editAnAmenity, isLoading: isEditionLoading } = useMutation(editAmenity, {
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successful_amenity_edit_snackbar_text', { ns: 'bookings' }));
    },
    onError: async () => {
      setCreationFailed(t('failed_amenity_edit_snackbar_text', { ns: 'bookings' }));
    },
  });

  const { data: dataId, isLoading } = useQuery({
    queryKey: [`activity_${row?.id}`],
    // eslint-disable-next-line react/prop-types
    queryFn: () => (row ? getAmenity(row?.id) : null),
  });

  const getInitialValues = () => ({
    id: dataId && dataId.data.activity.id ? dataId.data.activity.id : null,
    description: dataId && dataId.data.activity.description ? dataId.data.activity.description : '',
    document_url: dataId && dataId.data.activity.icon_url ? dataId.data.activity.icon_url : '',
    isEdit: !!dataId,
    formButtonText: row ? t('save') : t('add_button_text'),
  });

  const getValidationSchema = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        description: Yup.string().required(t('required_field', { ns: 'errors' })),
        document_url: Yup.string().required(t('required_field', { ns: 'errors' })),
      }),
    );

  const onSubmit = (data: FormikValues) => {
    if (data.isEdit) {
      const putData = {
        business_guid: VITE_TENANT_ID,
        description: data.description,
        image_url: data.document_url,
      };

      editAnAmenity({ id: dataId?.data.activity.id, body: putData });
      return;
    }

    if (!data.esEdit) {
      const postData = {
        business_guid: VITE_TENANT_ID,
        description: data.description,
        image_url: data.document_url,
      };
      createAnAmenity(postData);
    }
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema(),
  });

  useEffect(() => {
    if (url) {
      setFieldValue('document_url', url as string);
    }
  }, [url]);

  const deleteImage = () => {
    setFieldValue('document_url', '');
  };

  const childProps = {
    handleSubmit,
    values,
    setFieldValue,
    errors,
    getUrlIn64: getUrl,
    isLoadingImage,
    isUploadingImage,
    deleteImage,
    wrongImage: errorSubmittingImage,
    isLoadingGeneral: isLoading,
    isCreateEditLoading: isCreationLoading || isEditionLoading,
    creationFailed,
  };

  return <CreateEditActivity {...childProps} />;
};

export default CreateEditComponentContainer;
