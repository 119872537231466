import { styled } from '@mui/material/styles';
import CustomCardMedia from '@/src/components/custom-card-media/CustomCardMedia';

export const CardImages = styled(CustomCardMedia)(() => ({
  display: 'block',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  borderRadius: '5px',
  height: '200px',
  width: '175px',
  objectFit: 'cover',
}));
