import { Box, Grid, Typography } from '@mui/material';
import { TFunction } from 'react-i18next';
import BackToBaseButton from '@/src/components/custom-data-table/components/BackToBaseButton';
import { SelectedResident, SelectedRow } from '../types';
import CustomDataTableForUser from './custom-data-table-for-user/CustomDataTableForUser';
import { useColumns } from './useColumns';
import UserCard from './user-card';

export const UsersDetailSection = ({
  closeDetailSecion,
  personSelected,
  t,
  openResidentSection,
}: {
  closeDetailSecion: () => void;
  personSelected: SelectedRow | null;
  t: TFunction;
  openResidentSection: (row: SelectedResident) => void;
}) => {
  const { columnsResidencies, actionColumnResidencies, residencies, isLoading, userCardData } = useColumns(
    personSelected,
    openResidentSection,
  );
  return (
    <Box>
      <Box display="flex" marginBottom="20px">
        <BackToBaseButton backToBase={closeDetailSecion} />
        <Typography variant="h4">{t('customers_view', { ns: 'cdtModal' })}</Typography>
      </Box>
      {personSelected && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <UserCard values={userCardData} isLoading={isLoading} />
          </Grid>
          <Grid item xs={12} md={8}>
            {residencies && (
              <CustomDataTableForUser
                title={t('residencies', { ns: 'customerUsers' })}
                columns={columnsResidencies}
                data={residencies}
                isLoading={isLoading}
                customActionColumns={actionColumnResidencies}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
