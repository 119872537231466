import i18n from '../i18n';

export const EXAMPLE_CONSTANT: { [key: string]: string } = {
  FIRST_VALUE: 'firstValue',
};

export const SIGNATURE_BASE_MESSAGE = (address: string, nonce: string) =>
  `The Alien Labs.\n\nWallet address:\n${address}\n\nNonce:\n${nonce}`;

export const SIGNATURE_BASE_STATEMENT = `The Alien Labs. This request will not trigger a blockchain transaction or cost any gas fees.`;

export const tCommon = { ns: 'common' };
export const tCommonGrid = { ns: 'commonGrid' };
export const tErrorsContext = { ns: 'errors' };
export const tRequiredFieldError = i18n.t('required_field', { ns: 'errors' });
