import { Box, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default {
  Content: styled(Box)(() => ({
    width: '100%',
  })),
  BoxLoader: styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  })),
};

export const SubmitButton = styled(LoadingButton)(({ theme }) =>
  theme.unstable_sx({
    width: { xs: '100%', sm: 'auto' },
  }),
);
