import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  getDayHourYearDateEn,
  getDayHourYearDateEs,
  getFormattedDateByLanguague,
} from '@/src/utilities/helpers/dateParser';
import InfoLabel from '@/src/components/info-label/InfoLabel';
import TitleWithStatus from '@/src/components/title-with-status/TitleWithStatus';
import { getLanguage } from '@/src/utilities/storage';
import { guestParkingStates } from '../types';

const RequestDetail = ({ row, close }: any) => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 0.5, ml: 0.5 }}>
      <TitleWithStatus
        title={t('guest_parking_car_detail', { ns: 'cdtModal' })}
        statusText={row.guest_parking_state.name}
        statusBg={guestParkingStates[row.guest_parking_state.name]}
        onClose={close}
        addMargin={false}
      />
      <Typography variant="subtitle1" fontWeight="600">
        {t('guest_car_detail_sub_title1', { ns: 'parking' })}
      </Typography>
      <Divider sx={{ mb: '5px' }} />
      <Grid container rowSpacing={0.5}>
        <Grid item xs={12} sm={6}>
          <InfoLabel first="name" second={row.guest_name} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoLabel first="parking_number" second={row.parking_code} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoLabel first="ticket_code" second={row.ticket_code ?? '-'} />
        </Grid>
      </Grid>
      <Typography variant="subtitle1" fontWeight="600">
        {t('guest_car_detail_sub_title2', { ns: 'parking' })}
      </Typography>
      <Divider sx={{ mt: '2px', mb: '5px' }} />
      <Grid container rowSpacing={0.5} columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <InfoLabel first="Id" second={row.id} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoLabel
            first={t('creation_date', { ns: 'parking' })}
            second={getFormattedDateByLanguague(lang, getDayHourYearDateEs, getDayHourYearDateEn, row.creation_date)}
          />
        </Grid>
        {['Requested', 'Solicitado', 'Processed', 'Procesado'].includes(row.guest_parking_state.name) && (
          <Grid item xs={12} sm={6}>
            <InfoLabel
              first={t('request_date', { ns: 'parking' })}
              second={
                row.request_date !== null
                  ? getFormattedDateByLanguague(lang, getDayHourYearDateEs, getDayHourYearDateEn, row.request_date)
                  : '-'
              }
            />
          </Grid>
        )}
        {['Processed', 'Procesado'].includes(row.guest_parking_state.name) && (
          <>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('withdrawal_date', { ns: 'parking' })}
                second={getFormattedDateByLanguague(
                  lang,
                  getDayHourYearDateEs,
                  getDayHourYearDateEn,
                  row.processing_date,
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel first="valet_email" second={row.processing_date_user_name} />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default RequestDetail;
