import { AlertColor } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import MfaModal from './MfaModal';
import useDialog from '@/src/hooks/common/useDialog';
import { useGetUserInfo } from '@/src/pages/user-profile/hooks/useGetUserInfo';
import { validate } from './MfaModal.validate';
import { BodyAuthenticateUser } from '../types';
import { enableAuthentication } from '@/src/api/endpoints/accounts-security';

const MfaModalContainer = (props: any) => {
  const { refetchMfa } = props;
  const { t } = useTranslation();
  const { isOpen, open, close } = useDialog();
  const { onGetSecurityCode, securityCode, setSecurityCode } = useGetUserInfo();
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [errorMessage, setErrorMessage] = useState<{ code: string; message: string; property_name: string } | null>(
    null,
  );
  const deviceName =
    navigator.appVersion.length > 103 ? navigator.appVersion.slice(104) : navigator.appVersion.slice(73, 89);

  const setSnackBarMessage = useCallback((msj: string, sever: AlertColor = 'success') => {
    setOpenSnack(true);
    setSeverity(sever);
    setMessage(msj);
  }, []);

  const closeModal = () => {
    setOpenSnack(false);
  };

  const { mutate, isLoading } = useMutation(enableAuthentication, {
    onError: async (e: any) => {
      setErrorMessage(e.response.data.error_messages[0]);
    },
    onSuccess: async () => {
      close();
      refetchMfa();
      setSnackBarMessage(t('successful_user_authentication_snackbar_text', { ns: 'security' }));
    },
  });

  const getInitialValues = () => ({
    code: '',
  });

  const onSubmit = (data: FormikValues) => {
    const body: BodyAuthenticateUser = {
      code: data.code,
      device_name: deviceName,
    };
    mutate(body);
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate({ t }),
  });

  useEffect(() => {
    if (isOpen) {
      onGetSecurityCode();
    } else {
      setFieldValue('code', '');
      setErrorMessage({ code: '', message: '', property_name: '' });
    }
  }, [isOpen]);

  const childProps = {
    isOpen,
    open,
    close,
    securityCode,
    setSnackBarMessage,
    closeModal,
    openSnack,
    message,
    severity,
    setSecurityCode,
    errorMessage,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    isLoading,
  };

  return <MfaModal {...childProps} />;
};

export default MfaModalContainer;
