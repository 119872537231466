import { useQuery } from '@tanstack/react-query';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getDocumentsType } from '@/src/api/endpoints/documents';

const useDocumentsCategories = () => {
  const getCategoryMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.name,
          query: 'CategoryName',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: categoriesList } = useQuery(['getCategories'], getDocumentsType, {
    select: (response: any) => getCategoryMapper(response?.data?.categories),
  });

  return { categoriesList };
};

export default useDocumentsCategories;
