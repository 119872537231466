import { useTranslation } from 'react-i18next';
import { useAdmission, useDeliveryInfo } from '../hook/useDeliveryEndpoints';
import DeliveryInfo from './DeliveryInfo';
import { Delivery } from '../types';
import { getLanguage } from '@/src/utilities/storage';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import AlertNoData from '@/src/components/custom-data-table/components/AlertNoData';

type CustomDataPropsType = {
  deliveryId: number;
  close: () => void;
  row: Delivery;
  setSnackBarMessage: (msj: string, sever?: string) => void;
};

const DeliveryInfoContainer = ({ row, deliveryId, close, setSnackBarMessage }: CustomDataPropsType) => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const { values, isLoading } = useDeliveryInfo(deliveryId);

  const { mutate, isLoadingAdmission } = useAdmission();

  const handleAdmission = () => {
    mutate(deliveryId, {
      onSuccess: () => {
        close();
        setSnackBarMessage(t('success'));
      },
      onError: () => {
        setSnackBarMessage(t('error'), 'error');
      },
    });
  };

  const noDataComponent = isLoading ? <CircularSpinner /> : <AlertNoData title={t('something_went_wrong')} />;

  const childProps = {
    row,
    handleClose: close,
    values,
    handleAdmission,
    isLoading,
    isLoadingAdmission,
    isDelivered: ['Delivered', 'Entregado'].includes(values?.authorization_state.name),
    t,
    lang,
    noDataComponent,
  };

  return <DeliveryInfo {...childProps} />;
};

export default DeliveryInfoContainer;
