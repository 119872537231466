import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Autocomplete, Box, Card, CardActions, CardContent, CardHeader, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useSnackBar from '@/src/components/custom-snackbar/useSnackBar';
import getEnvVariables from '@/src/utilities/helpers/getEnvVariables';
import { useGetTowers } from './hook/useGetTowers';
import { useGetStyles } from './hook/useGetStyles';

interface Tower {
  id: number;
  name: string;
  client_tenant_code: string;
  has_children: boolean;
  logo_url?: string;
}

interface FormValues {
  firstTowerInstance: Tower | null;
  secondTowerInstance: Tower | null;
  thirdTowerInstance: Tower | null;
  codeTenant: string;
}

const getInitialValues = (): FormValues => ({
  firstTowerInstance: null,
  secondTowerInstance: null,
  thirdTowerInstance: null,
  codeTenant: '',
});

const towerSchema = Yup.object()
  .shape({
    client_tenant_code: Yup.string(),
    has_children: Yup.boolean(),
    id: Yup.number(),
    logo_url: Yup.string(),
    name: Yup.string(),
  })
  .nullable();

const getValidationSchema = () =>
  Yup.object().shape({
    firstTowerInstance: towerSchema,
    secondTowerInstance: towerSchema,
    thirdTowerInstance: towerSchema,
    codeTenant: Yup.string(),
  });

export const BuildingSwap = () => {
  const { t } = useTranslation('auth');
  const { SnackBar, setSnackBarMessage } = useSnackBar();
  const { VITE_TENANT_ID } = getEnvVariables();
  const [currentTenantCode, setCurrentTenantCode] = useState<string | null>(null);
  const [firstTowersGroup, setFirstTowersGroup] = useState<Tower[] | null>(null);
  const [secondTowersGroup, setSecondTowersGroup] = useState<Tower[] | null>(null);
  const [thirdTowersGroup, setThirdTowersGroup] = useState<Tower[] | null>(null);

  const { towers, refetch } = useGetTowers(currentTenantCode || VITE_TENANT_ID);
  const { styles, refetch: refetchStyles } = useGetStyles(currentTenantCode || VITE_TENANT_ID);

  const onSubmit = (data: FormValues) => {
    localStorage.setItem('vite_tenant_id', data.codeTenant);
    localStorage.setItem('tower_styles', styles);
    setSnackBarMessage(t('building_swap_succesfull'));
  };

  const { handleSubmit, values, setFieldValue } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validationSchema: getValidationSchema(),
  });

  useEffect(() => {
    if (!towers) return;

    const { firstTowerInstance, secondTowerInstance, thirdTowerInstance } = values;
    const { children } = towers;

    if (firstTowerInstance === null) {
      setFirstTowersGroup(children);
    }
    if (secondTowerInstance === null) {
      setSecondTowersGroup(children);
    }
    if (thirdTowerInstance === null) {
      setThirdTowersGroup(children);
    }
  }, [towers, values]);

  const handleTowersChange = async (tenantCode: string) => {
    setCurrentTenantCode(tenantCode);
  };

  useEffect(() => {
    const { firstTowerInstance, secondTowerInstance, thirdTowerInstance } = values;

    [firstTowerInstance, secondTowerInstance, thirdTowerInstance].forEach((instance) => {
      if (instance?.client_tenant_code) {
        handleTowersChange(instance.client_tenant_code);
      }
    });
  }, [values.firstTowerInstance, values.secondTowerInstance, values.thirdTowerInstance]);

  useEffect(() => {
    if (currentTenantCode) {
      refetch();
      refetchStyles();
    }
  }, [currentTenantCode, refetch, refetchStyles]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Card sx={{ my: 0, mx: { xs: '2%', lg: '10%' }, borderRadius: '.428rem' }}>
        <CardHeader
          title={t('swap_building_screen_title')}
          titleTypographyProps={{ variant: 'h5' }}
          sx={{ borderBottom: `1px solid #ebe9f1`, width: '100%' }}
        />
        <SnackBar />
        <CardContent sx={{ paddingBottom: '0px' }}>
          <Autocomplete
            id="first-tower"
            sx={{ width: '100%' }}
            size="small"
            value={firstTowersGroup?.find((tower) => values.firstTowerInstance?.id === tower.id) ?? null}
            getOptionLabel={(option) => option.name}
            options={firstTowersGroup ?? []}
            onChange={(e, value) => {
              setFieldValue('firstTowerInstance', value);
              setFieldValue('secondTowerInstance', null);
              setFieldValue('thirdTowerInstance', null);
              setFieldValue('codeTenant', value?.client_tenant_code ?? '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ width: '100%' }}
                variant="outlined"
                label={t('tower')}
                placeholder={t('tower_autocomplete_placeholder')}
              />
            )}
          />
          {values.firstTowerInstance?.has_children && (
            <Autocomplete
              id="second-tower"
              sx={{ width: '100%', paddingTop: '20px' }}
              size="small"
              value={secondTowersGroup?.find((tower) => values.secondTowerInstance?.id === tower.id) ?? null}
              getOptionLabel={(option) => option.name}
              options={secondTowersGroup ?? []}
              onChange={(e, value) => {
                setFieldValue('secondTowerInstance', value);
                setFieldValue('thirdTowerInstance', null);
                setFieldValue('codeTenant', value?.client_tenant_code ?? '');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: '100%' }}
                  variant="outlined"
                  label={t('tower')}
                  placeholder={t('tower_autocomplete_placeholder')}
                />
              )}
            />
          )}
          {values.secondTowerInstance?.has_children && (
            <Autocomplete
              id="third-tower"
              sx={{ width: '100%', paddingTop: '20px' }}
              size="small"
              value={thirdTowersGroup?.find((tower) => values.thirdTowerInstance?.id === tower.id) ?? null}
              getOptionLabel={(option) => option.name}
              options={thirdTowersGroup ?? []}
              onChange={(e, value) => {
                setFieldValue('thirdTowerInstance', value);
                setFieldValue('codeTenant', value?.client_tenant_code ?? '');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: '100%' }}
                  variant="outlined"
                  label={t('tower')}
                  placeholder={t('tower_autocomplete_placeholder')}
                />
              )}
            />
          )}
        </CardContent>
        <CardActions>
          <Grid container direction="row" justifyContent="flex-end" gap={1}>
            <LoadingButton variant="contained" size="small" type="submit" sx={{ width: { xs: '100%', sm: 'auto' } }}>
              {t('change')}
            </LoadingButton>
          </Grid>
        </CardActions>
      </Card>
      <Grid container item xs={12} justifyContent="flex-end" />
    </Box>
  );
};

export default BuildingSwap;
