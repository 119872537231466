import { Box, Button, FormHelperText, Grid, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { createPackage } from '@/src/api/endpoints/packages';
import InfoLabel from '@/src/components/info-label/InfoLabel';

const LoadPackageFormStep4 = ({
  values,
  setSnackBarMessage,
  close,
  t,
  validateForm,
  setFieldValue,
  setCurrentStep,
}: any) => {
  const { mutate: createPackageMutate, isLoading } = useMutation(createPackage);
  const [responseError, setResponseError] = useState('');

  const onSubmit = (data: any) => {
    setResponseError('');
    createPackageMutate(
      {
        carrier_id: data.carrier_id,
        carrier_name: data.carrier_name,
        property_code: data.property_code.toUpperCase(),
        carrier_code: data.carrier_code,
        is_expirable: data.is_expirable,
      },
      {
        onError: ({ response }: any) => {
          setResponseError(
            t(response.data.error_messages[0].code, {
              resource: response.data.error_messages[0].message,
              item: t(response.data.error_messages[0].property_name.toLowerCase(), { ns: 'packages' }),
              ns: 'errors',
            }),
          );
        },
        onSuccess: () => {
          close();
          setSnackBarMessage(t('success'));
        },
      },
    );
  };

  return (
    <Box component="form">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ padding: '10px 0 0' }}>
            {t('load_package_step_4_subtitle', { ns: 'packages' })}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoLabel
            first={t('modal_load_package_input_carrier_code', { ns: 'packages' })}
            second={values?.carrier_code}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoLabel
            first={t('modal_load_package_step_4_input_company', { ns: 'packages' })}
            second={values?.carrier_name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoLabel
            first={t('modal_load_package_input_property_code', { ns: 'packages' })}
            second={values?.property_code}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoLabel
            first={t('load_package_step_1_is_expirable', { ns: 'packages' })}
            second={values?.is_expirable ? `${t('yes')}` : 'No'}
          />
        </Grid>
        {responseError && (
          <Grid container justifyContent="center" mt={2}>
            <FormHelperText error>{responseError}</FormHelperText>
          </Grid>
        )}
        <Grid container item xs={12} justifyContent="flex-end">
          <Button
            sx={{ ml: { sm: '10px', xs: '0px' }, mt: { sm: '0px', xs: '10px' }, width: { xs: '100%', sm: 'auto' } }}
            type="button"
            onClick={() => {
              setFieldValue('property_code', values.property_code.split('-')[0]);
              setCurrentStep(2);
            }}
            variant="outlined"
            size="small"
            disabled={isLoading}
          >
            {t('back')}
          </Button>
          <LoadingButton
            loading={isLoading}
            sx={{ ml: { sm: '10px', xs: '0px' }, mt: { sm: '0px', xs: '10px' }, width: { xs: '100%', sm: 'auto' } }}
            type="button"
            onClick={() =>
              validateForm().then((formErrors: any) => {
                if (Object.entries(formErrors).length === 0) onSubmit(values);
              })
            }
            variant="contained"
            size="small"
          >
            {t('modal_load_package_save_and_print_button_text', { ns: 'packages' })}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};
export default LoadPackageFormStep4;
