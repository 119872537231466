import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormikValues, useFormik } from 'formik';
import PetsForm from './PetsForm';
import { validate } from './PetsForm.validate';
import { editPet, getPet, getPetTypes, getPetSexes, getPetBreeds, createPet } from '@/src/api/endpoints/houseHold';
import { DataListElement, PetsFormContainerProps } from '../types';
import useImageUploader from '@/src/components/image-uploader/useImageUploader';

const PetsFormContainer = (props: PetsFormContainerProps) => {
  const { row, residentRow, close, setSnackBarMessage, isEditForm } = props;
  const [breeds, setBreeds] = React.useState<DataListElement[] | undefined>();
  const { data: petInfo, isLoading: isLoadingPetInfo } = useQuery(
    [`getPetInfo_${row?.pet?.id ?? ''}`],
    isEditForm ? () => getPet({ id: row?.pet?.id, residencyId: row?.residency?.id }) : () => {},
    {
      select: (data: any) => data?.data?.pet_residency ?? null,
      enabled: isEditForm,
    },
  );

  const { data: sexes } = useQuery([`sexes`], () => getPetSexes(), {
    select: (response) => response?.data?.sexes,
  });

  const { data: types } = useQuery([`types`], () => getPetTypes(), {
    select: (response) => response?.data?.types,
  });

  const [errorMessage, setErrorMessage] = useState<{ code: string; message: string; property_name: string } | null>(
    null,
  );

  const { t } = useTranslation('residents');

  const options = {
    onError: async (e: any) => {
      setErrorMessage(e.response.data.error_messages[0]);
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successful_pet_edition_snackbar_text', { ns: 'residents' }));
    },
  };

  const { mutate: mutateEdit, isLoading: isLoadingEdit } = useMutation(editPet, options);
  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(createPet, options);

  const getInitialValues = () => ({
    name: petInfo?.pet?.name ?? '',
    breed: petInfo?.pet?.breed ?? null,
    type: petInfo?.pet?.type ?? null,
    birthday: petInfo?.pet?.birthday ?? '',
    sex: petInfo?.pet?.sex ?? null,
    isSterilized: petInfo?.pet?.is_sterilized ?? false,
    isServiceAnimal: petInfo?.pet?.is_service_animal ?? false,
    lastVaccinationDate: petInfo?.pet?.last_vaccination_date ?? null,
    comments: petInfo?.pet?.comments ?? '',
    imageUrl: petInfo?.pet?.image_url,
    document: petInfo?.pet?.service_animal_file_url ?? '',
  });

  const onSubmit = (data: FormikValues) => {
    const body = {
      breed_id: breeds?.find((b: any) => b?.name === data.breed?.name)?.id,
      name: data.name,
      birthday: data.birthday,
      sex_id: sexes?.find((s: any) => s?.name === data?.sex?.name)?.id,
      last_vaccination_date: data?.lastVaccinationDate,
      comments: data?.comments,
      sterilized: data?.isSterilized,
      is_service_animal: data?.isServiceAnimal,
      image_url: data?.imageUrl,
      ...(data?.document
        ? {
            service_animal_file_url: data?.document,
          }
        : {}),
      ...(isEditForm
        ? {}
        : {
            residency_id: residentRow?.residency?.id,
          }),
    };

    if (isEditForm) {
      mutateEdit({ body, id: row.pet?.id });
    } else {
      mutateCreate(body);
    }
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate({ t }),
  });

  const selectedType = types?.find((type: any) => type.name === values?.type?.name);

  const { data: breedsData } = useQuery([`breeds_${selectedType?.id ?? 0}`], () => getPetBreeds(values?.type?.id), {
    enabled: !!values?.type?.id,
    select: (response) => response?.data?.breeds,
    onSuccess: (data) => setBreeds(data),
  });

  const { ImageUploader: AttachedImagesUploader } = useImageUploader({
    htmlFor: 'attached-images-urls',
    values,
    valueName: 'imageUrl',
    setFieldValue,
  });

  const deleteImage = () => {
    setFieldValue('imageUrl', '');
  };

  const childProps = {
    ...props,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    loading: isLoadingPetInfo,
    errorMessage,
    isSubmitLoading: isLoadingEdit || isLoadingCreate,
    setSnackBarMessage,
    sexes,
    types,
    breeds: breedsData,
    t,
    AttachedImagesUploader,
    deleteImage,
  };

  return <PetsForm {...childProps} />;
};

export default PetsFormContainer;
