import i18n from 'i18next';
import { getsLanguages } from '@/src/api/endpoints/master';

export const isAuthenticated = () => localStorage.getItem('tokenId');

export const handleLanguageOnLogin = async (userData: any) => {
  const { data: languageData } = await getsLanguages();

  if (languageData) {
    const selectedLanguage = languageData.languages.find(
      (language: { id: number; code: string }) => language.id === userData.user.language_id,
    );
    i18n.changeLanguage(selectedLanguage.code.split('-')[0]);
  } else {
    i18n.changeLanguage(i18n.language.split('-')[0]);
  }
};
