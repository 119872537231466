import { axiosAuthenticationInstance } from '../instances/authInstance';
import { CreateConstructionRequest, EditConstructionRequest } from '../types/types';

export const getConstructionProgressCustomer = () =>
  axiosAuthenticationInstance.get(`feed/v1/customers/construction-progresses`);
export const getConstructionProgressByIdCustomer = (id: number) =>
  axiosAuthenticationInstance.get(`feed/v1/customers/construction-progresses/${id}`);
export const getConstructionProgress = () => axiosAuthenticationInstance.get(`feed/v1/construction-progresses`);
export const getConstructionProgressById = (id: number) =>
  axiosAuthenticationInstance.get(`feed/v1/construction-progresses/${id}`);
export const createConstructionProgresses = (req: CreateConstructionRequest) =>
  axiosAuthenticationInstance.post('feed/v1/construction-progresses', req);
export const editConstructionProgresses = (req: EditConstructionRequest) =>
  axiosAuthenticationInstance.put(`feed/v1/construction-progresses/${req.id}`, req.body);
export const deleteConstructionProgresses = (id: number) =>
  axiosAuthenticationInstance.delete(`feed/v1/construction-progresses/${id}`);
