import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { FilterModalProps } from '../../../types/DataTableFormModal';

const FilterModal = (props: FilterModalProps) => {
  const { filterComponentsList, onSubmit, t } = props;
  return (
    <Grid container spacing={3} marginTop={0} direction="column">
      {filterComponentsList.map((item) => (
        <Grid container item xs={12} key={item.id} alignItems="center">
          {item.title && (
            <Grid item xs={12} sm={4} md={3} key={item.id}>
              <Typography sx={{ alignSelf: 'center', width: '100%', marginBottom: { xs: '5px', sm: 0 } }}>
                {t(`${item.title}`, { ns: 'cdtModal' })}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={item.title ? 8 : 12} md={item.title ? 9 : 12}>
            <item.component />
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Button onClick={onSubmit}>{t('filter_submit', { ns: 'cdtModal' })}</Button>
      </Grid>
    </Grid>
  );
};

export default FilterModal;
