import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Box, CardMedia, Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TitleWithStatus from '@/src/components/title-with-status/TitleWithStatus';
import InfoLabel from '@/src/components/info-label/InfoLabel';
import ApproveAndReject from '@/src/components/approve-reject/ApproveAndReject';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEs,
  getFormattedDateFullYearEn,
} from '../../../../utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import { approvePet, getPet, rejectPet } from '@/src/api/endpoints/houseHold';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import CustomTextArea from '@/src/components/forms/text-area';
import { PetDetail, PetDetailModalProps } from '../types';
import { FadeIn } from '@/src/components/animations';
import ImageShow from '@/src/components/image-show/ImageShow';
import PdfShowFile from '@/src/components/pdf-show-file/PdfShowFile';
import { commonStatus } from '../../common/types';

export const PetDetailModal = (props: PetDetailModalProps) => {
  const { row, close, showButtons } = props;
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const [state, setState] = useState(false);
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: [`pet_${row.pet?.id}`],
    queryFn: () => getPet({ id: row.pet?.id, residencyId: row.residency.id }),
    select: (response) => response.data.pet_residency as PetDetail,
  });
  const values = data && data.pet;

  useEffect(
    () =>
      function cleanUp() {
        queryClient.resetQueries([`pet_${row.pet?.id}`]);
      },
    [],
  );

  return (
    <Box component="div" sx={{ marginY: '5px' }}>
      <Grid container spacing={2}>
        <TitleWithStatus
          title={t('pets_detail_view_request', { ns: 'cdtModal' })}
          statusText={values ? values.state.name : ''}
          statusBg={values ? commonStatus[values.state.name.replaceAll(' ', '_').replaceAll('ó', 'o')] : ''}
          onClose={close}
        />
        <Grid item xs={12}>
          <Divider sx={{ mt: '-15px' }} />
        </Grid>
        {isLoading || !values ? (
          <CircularSpinner />
        ) : (
          <>
            <Grid item container xs={12} sm={6} spacing={1}>
              <Grid item xs={12}>
                <InfoLabel
                  first={t('property_code', { ns: 'customerUsers' })}
                  second={data.residency?.property?.code ?? '-'}
                />
              </Grid>
              <Grid item xs={12}>
                <InfoLabel first={t('name')} second={values?.name} />
              </Grid>
              <Grid item xs={12}>
                <InfoLabel first={t('kind', { ns: 'customerUsers' })} second={values?.type.name} />
              </Grid>
              <Grid item xs={12}>
                <InfoLabel first={t('breed', { ns: 'customerUsers' })} second={values?.breed.name} />
              </Grid>
              <Grid item xs={12}>
                <InfoLabel first={t('sex', { ns: 'customerUsers' })} second={values?.sex.name} />
              </Grid>
              <Grid item xs={12}>
                <InfoLabel first={t('birthday', { ns: 'customerUsers' })} second={values?.birthday} />
              </Grid>
              <Grid item xs={12}>
                <InfoLabel
                  first={t('is_service_animal', { ns: 'customerUsers' })}
                  second={values?.is_service_animal ? t('yes') : 'No'}
                />
              </Grid>
              <Grid item xs={12}>
                <InfoLabel
                  first={t('is_sterialized', { ns: 'customerUsers' })}
                  second={values?.sterilized ? t('yes') : 'No'}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'column' } }}>
              <Grid item xs={12}>
                <InfoLabel first={t('image', { ns: 'customerUsers' })} second="" />
                <FadeIn duration="3s">
                  <CardMedia
                    sx={{ borderRadius: '15px' }}
                    component="img"
                    height="190"
                    image={values?.image_url}
                    alt="cover-image"
                  />
                </FadeIn>
              </Grid>
              <Grid item xs={12} sx={{ mt: { xs: 0, sm: 2 }, mb: { xs: 1, sm: 1 } }}>
                <InfoLabel
                  first={t('last_vaccination_date', { ns: 'customerUsers' })}
                  second={getFormattedDateByLanguague(
                    lang,
                    getFormattedDateFullYearEs,
                    getFormattedDateFullYearEn,
                    values?.last_vaccination_date,
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InfoLabel first={t('comments', { ns: 'customerUsers' })} second="" />
              <CustomTextArea
                placeholder={t('comments', { ns: 'customerUsers' })}
                minRows={3}
                value={values?.comments}
                maxLength={500}
                disabled
              />
            </Grid>
            {values?.service_animal_file_url && (
              <Grid item xs={12}>
                <InfoLabel first={t('attachedFile', { ns: 'customerUsers' })} second="" />
                {values?.service_animal_file_url.slice(-3, values?.service_animal_file_url.length) === 'pdf' ? (
                  <PdfShowFile file={values?.service_animal_file_url} />
                ) : (
                  <ImageShow
                    url={values?.service_animal_file_url}
                    open={state}
                    handleOpen={() => setState(true)}
                    handleClose={() => setState(false)}
                  />
                )}
              </Grid>
            )}
            {values?.rejection_reason && (
              <Grid item xs={12}>
                <InfoLabel first={t('rejection_reason_title', { ns: 'customerUsers' })} second="" />
                <CustomTextArea
                  placeholder={t('reason_rejection_placeholder', { ns: 'cdtModal' })}
                  minRows={3}
                  style={{ width: '100%', height: '40px', outline: 'none' }}
                  value={values?.rejection_reason}
                  maxLength={500}
                  disabled
                />
              </Grid>
            )}
            {showButtons && row.pet.state.code !== 'Approved' && (
              <Grid item xs={12}>
                <ApproveAndReject
                  {...props}
                  resource="pet"
                  fnApprove={approvePet}
                  fnReject={rejectPet}
                  reasonRejection={values?.state.code !== 'InReview'}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};
