import {
  IconButton,
  Tooltip,
  Box,
  FormHelperText,
  Modal,
  Typography,
  Button,
  Grid,
  Snackbar,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { ErrorHelper } from '@/src/components/error/error-helper/ErrorHelper';
import { DeactivateModalType } from '../types';
import CodeInput from '@/src/components/code-input/CodeInput';

const DeactivateModal = ({
  isOpen,
  open,
  close,
  errorMessage,
  handleSubmit,
  values,
  setFieldValue,
  errors,
  openSnack,
  closeModal,
  message,
  severity,
}: DeactivateModalType) => {
  const { t } = useTranslation();
  const disableOnClose = false;
  const width = '40%';
  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '100px', sx: '1100px', md: '1000px' },
    minWidth: `${width ? '305px' : '400px'}`,
    height: 'max-content',
    maxHeight: { xs: '98%', sm: '95%' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px',
    p: 2,
    overflow: 'auto',
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={() => open()}
        sx={{ width: '25%', marginLeft: 'auto' }}
      >
        <Tooltip title={t('deactivate_Authentication', { ns: 'security' })}>
          <Box>{t(`deactivate`)}</Box>
        </Tooltip>
      </Button>
      {isOpen && (
        <Modal
          open={isOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowY: 'auto', mt: '10px', mb: '10px' }}
          onClose={
            disableOnClose
              ? () => {}
              : () => {
                  close();
                }
          }
        >
          <Box component="form" onSubmit={handleSubmit} sx={style}>
            <>
              <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mt: '20px' }}>
                {t(`deactivate_Multifactor_Authentication`, { ns: 'security' })}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={close}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              <Grid container spacing={3} marginTop={0} direction="column">
                <Grid container item xs={12}>
                  <Grid container item xs={12} sm={12} md={12} display="flex" direction="column" width="fit-content">
                    <Typography sx={{ alignSelf: 'center', width: '100%', marginBottom: { xs: '5px', sm: 0 } }}>
                      {t('deactivation_text', { ns: 'security' })}
                    </Typography>
                    <CodeInput
                      onChange={(e) => {
                        setFieldValue('code', e);
                      }}
                      value={values?.code}
                    />
                    {errors.code && (
                      <Typography
                        sx={{
                          alignSelf: 'center',
                          width: '100%',
                          marginBottom: { xs: '5px', sm: 0 },
                          color: 'red',
                        }}
                      >
                        {t('required_field', { ns: 'errors' })}
                      </Typography>
                    )}
                  </Grid>
                  {errorMessage && (
                    <Grid item xs={12} sm={8} md={6}>
                      {errorMessage.code === 'ValueAlreadyExists' ? (
                        <FormHelperText error={errorMessage !== null}>
                          {t(`${errorMessage.code}${errorMessage.property_name}`, { ns: 'errors' })}
                        </FormHelperText>
                      ) : (
                        <ErrorHelper error={errorMessage} />
                      )}
                    </Grid>
                  )}
                  <Grid container item xs={12} justifyContent="flex-end" gap="10px">
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="small"
                      onClick={() => close()}
                      sx={{ width: { xs: '100%', sm: 'auto' } }}
                    >
                      {t(`cancel_deactivate_modal`, { ns: 'security' })}
                    </Button>
                    <LoadingButton
                      loading={false}
                      variant="contained"
                      size="small"
                      type="submit"
                      sx={{ width: { xs: '100%', sm: 'auto' } }}
                    >
                      {t(`deactivate`, { ns: 'security' })}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </Box>
        </Modal>
      )}
      <Snackbar
        open={openSnack}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        autoHideDuration={50000}
        onClose={closeModal}
        message={message}
      >
        <Alert onClose={closeModal} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DeactivateModal;
