import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PotentialCustomerForm from './PotentialCustomerForm';
import { assignInterested } from '@/src/api/endpoints/interested';
import { Interested, InterestedBody } from '../types';
import { tRequiredFieldError } from '@/src/utilities/helpers/appConstants';
import { PropertyType } from '@/src/pages/household/vehicles/types';
import { getProperties } from '@/src/api/endpoints/residents';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { getCities, getCountries, getStates } from '@/src/api/endpoints/master';

const PotentialCustomerFormContainer = (props: Props) => {
  const { close, setSnackBarMessage, row } = props;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const { t } = useTranslation();

  const { mutate } = useMutation(assignInterested);
  const [isLoading, setIsLoading] = useState(false);
  const { data: response } = useQuery(['getProperties'], () => getProperties());
  const propertiesList: Array<PropertyType> | undefined | null = response?.data.properties;
  const properties = propertiesList?.map((prop) => prop.code);

  const currentTime = new Date();
  const currentYear = currentTime.getFullYear();
  const currentMonth = currentTime.getMonth();
  const currentDay = currentTime.getDate();

  const lastYear = `${currentYear - 1}/${currentMonth}/${currentDay}`;
  const minValue = new Date(lastYear).getTime();

  const getTomorrow = (value: number) => {
    if (value) {
      const tmrwDt = new Date(value);
      const tmrw = tmrwDt.setDate(tmrwDt.getDate() + 1);
      return tmrw;
    }
    return value;
  };

  const getInitialValues = (data: any) => ({
    id: data?.id,
    country: {
      id: data?.nationality_country?.id ? data?.nationality_country?.id : '',
      name: data?.nationality_country?.name ? data?.nationality_country?.name : '',
    },
    state: { id: '', name: '' },
    city: { id: '', name: '' },
    from: '',
    to: '',
    residency_type: '',
    property_code: '',
    has_deposit: false,
    deposit_amount: '',
  });

  const getValidationSchema = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        country: Yup.object()
          .shape({
            id: Yup.number(),
            name: Yup.string(),
          })
          .required(tRequiredFieldError),
        state: Yup.object()
          .shape({
            id: Yup.number(),
            name: Yup.string(),
          })
          .required(tRequiredFieldError),
        city: Yup.object()
          .shape({
            id: Yup.number(),
            name: Yup.string(),
          })
          .required(tRequiredFieldError),
        from: Yup.number().required(tRequiredFieldError),
        to: Yup.number().required(tRequiredFieldError),
        residency_type: Yup.object().shape({
          id: Yup.number(),
          name: Yup.string(),
        }),
        property_code: Yup.string().required(tRequiredFieldError),
        has_deposit: Yup.boolean(),
        deposit_amount: Yup.number(),
      }),
    );

  const onSubmit = (data: any) => {
    setIsLoading(true);

    const body: InterestedBody = {
      id: data?.id,
      city_id: data?.city?.id,
      from: data?.from,
      to: data?.to,
      residency_type_id: data?.residency_type?.id,
      property_code: data?.property_code,
      has_deposit: data?.has_deposit,
      deposit_amount: data?.deposit_amount,
    };

    mutate(body, {
      onSuccess: async () => {
        setSnackBarMessage(t('interested_successful_assignation_snackbar_text', { ns: 'interested' }));
        close();
      },
      onError: async () => {
        setErrorMessage(t('interested_failed_assignation_snackbar_text', { ns: 'interested' }));
      },
      onSettled: async () => {
        setIsLoading(false);
      },
    });
  };

  const formikInitProps = React.useMemo(
    () => ({
      initialValues: getInitialValues(row),
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: getValidationSchema(),
      onSubmit,
    }),
    [onSubmit, getInitialValues],
  );

  const formik = useFormik(formikInitProps);

  const handleCheck = () => {
    formik?.setFieldValue('deposit_amount', '');
    formik?.setFieldValue('has_deposit', !formik?.values?.has_deposit);
  };

  const { data: countriesData } = useQuery(['getCountries'], () => getCountries(), {
    select: (data) => data?.data?.countries,
  });
  const { data: statesData } = useQuery(
    ['getStates', formik?.values?.country?.id],
    () => getStates(Number(formik?.values?.country?.id)),
    {
      select: (data) => data?.data?.states,
      enabled: !!formik?.values?.country?.id,
    },
  );
  const { data: citiesData } = useQuery(
    ['getCities', formik?.values?.state?.id],
    () => getCities(Number(formik?.values?.state?.id)),
    {
      select: (data) => data?.data?.cities,
      enabled: !!formik?.values?.state?.id,
    },
  );

  const childProps = {
    minValue,
    isLoadingSubmit: isLoading,
    errorMessage,
    disableButton,
    setDisableButton,
    t,
    properties,
    getTomorrow,
    handleCheck,
    countriesData,
    statesData,
    citiesData,
    formik,
    close,
  };

  return <PotentialCustomerForm {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface ExtraProps {
  close: () => void;
  setSnackBarMessage: (message: string, sever?: string) => void;
  row: Interested;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
PotentialCustomerFormContainer.propTypes = propTypes;
PotentialCustomerFormContainer.defaultProps = defaultProps;

export default PotentialCustomerFormContainer;
