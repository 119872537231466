import { Badge, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export interface CounterIconProps {
  count: number;
  Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & {
    muiName: string;
  };
  iconMarginLeft?: string;
}

const CounterIcon = ({ count, Icon, iconMarginLeft = '0px' }: CounterIconProps) => (
  <Badge
    color="error"
    badgeContent={count}
    showZero
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    sx={{
      '& .MuiBadge-badge': {
        minWidth: '15px',
        height: '18px',
        fontSize: '10px',
        marginTop: '1px',
      },
    }}
  >
    <Icon fontSize="medium" sx={{ marginLeft: `${iconMarginLeft}` }} />
  </Badge>
);

export default CounterIcon;
