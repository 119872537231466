import { Box, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import CustomInput from '@/src/components/forms/CustomInput';
import { ErrorHelper } from '@/src/components/error/error-helper/ErrorHelper';
import { ConceptFormProps } from '../types';

const ConceptForm = ({
  handleSubmit,
  values,
  setFieldValue,
  errors,
  errorMessage,
  isSubmitLoading,
  isEdit,
}: ConceptFormProps) => {
  const { t } = useTranslation();

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ marginY: '5px' }}>
      <Grid container spacing={2} pt={1}>
        <Grid item xs={12}>
          <CustomInput
            field="name"
            errors={errors}
            label={t('concept_form_title_field_label', { ns: 'charges' })}
            placeholder={t('concept_form_title_field_placeholder', { ns: 'charges' })}
            setFieldValue={setFieldValue}
            values={values}
            autoFocus
            maxLength={50}
            required
          />
        </Grid>
        {errorMessage && (
          <Grid item xs={12} sm={8} md={6}>
            <ErrorHelper error={errorMessage} />
          </Grid>
        )}
        <Grid container item xs={12} justifyContent="flex-end">
          <LoadingButton
            loading={isSubmitLoading}
            variant="contained"
            size="small"
            type="submit"
            sx={{ width: { xs: '100%', sm: 'auto' } }}
          >
            {isEdit ? t('save') : t('form_button_submit_text', { ns: 'charges' })}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConceptForm;
