import React from 'react';
import FilterBoolean from './FilterBoolean';
import { FilterByColumn } from '../../../types/CustomDataTableColumnProps';

const FilterBooleanContainer = (props: FilterBooleanContainerProps) => {
  const { filter, localFilterString, setLocalFilterString } = props;

  const getCheckedValue = (query: string, expectedValue: boolean) => {
    if (query === undefined) return false;
    return !!localFilterString.includes(`${expectedValue}`); // si el valor existe es true o false, devolver el valor
  };

  const [trueInput, setTrueInput] = React.useState(getCheckedValue(filter.query, true));
  const [falseInput, setFalseInput] = React.useState(getCheckedValue(filter.query, false));

  const setFirsSwitchValue = (value: boolean) => {
    if (falseInput) {
      setTrueInput(value);
      setFalseInput(!value);
    }
    setTrueInput(value);
  };

  const setSecondSwitchValue = (value: boolean) => {
    if (trueInput) {
      setFalseInput(value);
      setTrueInput(!value);
    }
    setFalseInput(value);
  };

  const handleFilters = (query: string, value: string | number | boolean, input: string) => {
    if (input === 'trueInput') {
      setFirsSwitchValue(value as boolean);
    }
    if (input === 'falseInput') {
      setSecondSwitchValue(value as boolean);
    }
    setLocalFilterString((prev: any) => {
      const selectedValue = input === 'trueInput' ? `&${query}=true` : `&${query}=false`;
      if (value === false) {
        // si el valor es falso se elimina
        return prev.replace(selectedValue, ``);
      }
      if (localFilterString.length === 0)
        // si no existe localfilterstring es que es la primera vez que se usa
        return prev.concat(selectedValue);
      // se concatena al string 'viejo', pero se retorna solo la parte nueva.
      return selectedValue.slice(selectedValue.indexOf(`&`));
    });
  };

  const childProps = {
    filter,
    handleFilters,
    trueInput,
    falseInput,
  };

  return <FilterBoolean {...childProps} />;
};

interface FilterBooleanContainerProps {
  setLocalFilterString: React.Dispatch<React.SetStateAction<string>>;
  filter: FilterByColumn;
  localFilterString: string;
}

export default FilterBooleanContainer;
