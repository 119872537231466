import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { GuestConfirmationProps } from '../types';
import { confirmGuest } from '@/src/api/endpoints/guests';

export const GuestConfirmation = (props: GuestConfirmationProps) => {
  const { t } = useTranslation();
  const { row, close, setSnackBarMessage } = props;
  const { mutate, isLoading } = useMutation(confirmGuest, {
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successful_confirmed_invitation_snackbar_text', { ns: 'guests' }));
    },
    onError: async ({ response }: any) => {
      close();
      setSnackBarMessage(
        t(response.data.error_messages[0]?.code, {
          ns: 'errors',
          property: response.data.error_messages[0]?.property_name,
        }),
        'error',
      );
    },
  });
  return (
    <Box>
      <DialogContentText py={0.5}>{t('guest_confirmation_header_text', { ns: 'guests' })}</DialogContentText>
      <Box component="div" sx={{ widht: '100%', textAlign: 'right', pt: 1 }}>
        <Button size="small" type="button" variant="outlined" color="secondary" onClick={close} sx={{ mr: 1 }}>
          {t('delete_dialog_cancel_button_text')}
        </Button>
        <LoadingButton
          loading={isLoading}
          size="small"
          type="button"
          variant="contained"
          onClick={() => mutate(row.id)}
        >
          {t('delete_dialog_submit_button_text')}
        </LoadingButton>
      </Box>
    </Box>
  );
};
