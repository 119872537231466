import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RuleContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
`;

export const RuleTitleWrapper = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    minWidth: '200px',
    width: { xs: '100%', md: '50%' },
  }),
);

export const RuleValueWrapper = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    gap: '10px',
    minWidth: '215px',
    width: { xs: '100%', md: '30%', lg: '30%' },
  }),
);

export const RuleDetailWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '10px',
  width: '100%',
}));
