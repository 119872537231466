import { createTheme, ThemeProvider, CssBaseline, PaletteMode, Theme } from '@mui/material';
import React from 'react';
import baseLightBeachWalkTheme from './baseLightBeachWalkTheme';
import baseLightPanoramaTheme from './baseLightPanoramaTheme';
import baseLightGritTheme from './baseLightGritTheme';
import baseLightOraTheme from './baseLightOraTheme';
import baseLightNexoTheme from './baseLightNexoTheme';
import baseDarkTheme from './baseDarkTheme';
import baseLightRiderTheme from './baseLightRiderTheme';
import { ColorContext } from './ColorContext';
import GlobalStyle from './GlobalStyles';
import getEnvVariables from '../helpers/getEnvVariables';

const AppTheme = ({ children }: any) => {
  const [mode, setMode] = React.useState<PaletteMode>('light');
  const { VITE_TENANT_NAME } = getEnvVariables();

  const objTheme: {
    [key: string]: Theme;
  } = {
    grit: baseLightGritTheme,
    panorama: baseLightPanoramaTheme,
    beachwalk: baseLightBeachWalkTheme,
    ora: baseLightOraTheme,
    nexo: baseLightNexoTheme,
    rider: baseLightRiderTheme,
  };

  const theme = React.useMemo(() => createTheme(mode === 'light' ? objTheme[VITE_TENANT_NAME] : baseDarkTheme), [mode]);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  return (
    <ColorContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </ColorContext.Provider>
  );
};

export default AppTheme;
