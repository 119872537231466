import { useQuery } from '@tanstack/react-query';
import { getInterest } from '@/src/api/endpoints/interested';
import { Interest } from '@/src/utilities/helpers/commonTypes';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';

const useInterestedFilters = () => {
  const getInterestedMapper = (data: Array<Interest>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.description,
          value: item.id,
          query: 'InterestedIn',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: interestedIn, isLoading } = useQuery(['getInterest'], getInterest, {
    select: (response) => getInterestedMapper(response?.data?.interests as Array<Interest>),
  });

  return { interestedIn, isLoading };
};

export default useInterestedFilters;
