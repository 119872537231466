import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { ModalProps } from '../../../residents/account-state-section/types';

export const DeleteCharge = ({ handleClose, onConfirm, resource }: ModalProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography id="modal-modal-description" sx={{ py: 1 }}>
        {t('deleting_dialog_form_content_pronoun', {
          item: `${resource}_modal_text`,
          pronoun: ['charge'].includes(resource) ? 'este' : 'esta',
          resource: 'charge_modal_text',
        })}
      </Typography>
      <Box component="div" sx={{ widht: '100%', textAlign: 'right', pt: 1 }}>
        <Button size="small" type="button" variant="outlined" color="secondary" onClick={handleClose} sx={{ mr: 1 }}>
          {t('delete_dialog_cancel_button_text')}
        </Button>
        <Button size="small" type="button" variant="contained" onClick={onConfirm}>
          {t('delete_dialog_submit_button_text')}
        </Button>
      </Box>
    </>
  );
};
