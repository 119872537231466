import { Grid, Box } from '@mui/material';
import { RegistryProps } from './types';
import { RegistryForm } from '../components/RegistryForm';
import { Confirmation } from '../components/Confirmation';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import { SelfieModal } from '../components/SelfieModal';
import { useRegistryBreakpoints } from './hooks/useRegistryBreakpoints';
import { useExportAssets } from '@/src/assets/exportFile';
import { BoxImage, CardContainer } from './Registry.styled';

export const Registry = (props: RegistryProps) => {
  const {
    handleSubmit,
    isLoading,
    isSuccess,
    isError,
    open,
    handleClose,
    handleScreenShot,
    webcamRef,
    imageUrl,
    isPermissionGiven,
    handleChooseShot,
  } = props;
  const { headerStyles } = useRegistryBreakpoints();
  const { EventBackgroundPatternImage, whiteLogo } = useExportAssets();
  const containerStyles = {
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    backgroundColor: '#FFFEF7',
  };
  return (
    <Box component="form" onSubmit={handleSubmit} sx={containerStyles}>
      {open && (
        <SelfieModal
          open={open}
          handleClose={handleClose}
          handleScreenShot={handleScreenShot}
          webcamRef={webcamRef}
          imageUrl={imageUrl}
          isPermissionGiven={isPermissionGiven}
          handleChooseShot={handleChooseShot}
        />
      )}

      <CardContainer>
        <BoxImage
          component="div"
          sx={{
            backgroundImage: `url(${EventBackgroundPatternImage})`,
          }}
        >
          <img src={whiteLogo} alt="Panorama_Logo" style={headerStyles} />
        </BoxImage>
        <Grid container spacing={1.5} sx={{ pl: 2, pr: 2, mt: 1 }}>
          {(isLoading && <CircularSpinner />) ||
            ((isSuccess || isError) && <Confirmation requestResponse={isSuccess} />) || <RegistryForm {...props} />}
        </Grid>
      </CardContainer>
    </Box>
  );
};
