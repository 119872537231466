import { useQuery } from '@tanstack/react-query';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getPaymentStates } from '@/src/api/endpoints/payments';

const usePaymentStatesFilters = () => {
  const getPaymentStatesMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.id,
          query: 'PaymentStateIds',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: paymentStates, isLoading } = useQuery(['getPaymentStates'], getPaymentStates, {
    select: (response) => getPaymentStatesMapper(response?.data?.payment_states),
  });

  return { paymentStates, isLoading };
};

export default usePaymentStatesFilters;
