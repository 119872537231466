import { List, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { logoutMenu, profileMenu } from '@/src/router/navigation/menu/AppbarMenu';
import { CustomMenuItem } from './CustomMenuItem';
import { useLogin } from '@/src/hooks/auth/useLoginHandler';
import { SubMenuItemProp, VerticalMenuItemProp } from '@/src/router/navigation/vertical/vertical-menu';
import { Span, DrawerListItem } from './StyledDrawer';
import { getMenus } from '@/src/utilities/storage';

interface DrawerListProps {
  open: boolean;
  theme: Theme;
  handleNavigate: (listItem: any) => void;
  listButtonSelected: string;
  verticalMenus: Array<VerticalMenuItemProp>;
}
interface LoggedMenusProps {
  id: number;
  name: string;
}

const DrawerList = (props: DrawerListProps) => {
  const { open, theme, handleNavigate, listButtonSelected, verticalMenus } = props;
  const { onLogout } = useLogin({});
  const { t } = useTranslation();
  const loggedMenus: LoggedMenusProps[] = getMenus();
  const filtteredMenus = verticalMenus.filter((menu: VerticalMenuItemProp) => {
    if (loggedMenus?.findIndex((m) => m.name === menu.id) !== -1) {
      const finalMenu = menu;
      const finalSubMenus = finalMenu.subMenus?.filter((subMenu: SubMenuItemProp) => {
        if (loggedMenus?.some((loggedMenu) => loggedMenu.name === `${menu.id}/${subMenu.id}`)) return subMenu;
        return null;
      });
      finalMenu.subMenus = finalSubMenus as SubMenuItemProp[] | null;
      return finalMenu;
    }
    return null;
  });

  const handleLogout = () => {
    const guid = localStorage.getItem('deviceId');
    onLogout(guid ?? '');
    localStorage.setItem('deviceId', String(guid));
  };

  return (
    <List>
      {filtteredMenus.map((menuItem) => (
        <CustomMenuItem
          key={menuItem.id}
          menuItem={menuItem}
          drawerOpened={open}
          theme={theme}
          handleNavigate={handleNavigate}
          listButtonSelected={listButtonSelected}
        />
      ))}
      <DrawerListItem open={open}>
        <Span>{t('settings')}</Span>
      </DrawerListItem>
      <CustomMenuItem
        key={profileMenu.id}
        menuItem={profileMenu}
        drawerOpened={open}
        theme={theme}
        handleNavigate={handleNavigate}
        listButtonSelected={listButtonSelected}
      />
      <CustomMenuItem
        key={logoutMenu.id}
        menuItem={logoutMenu}
        drawerOpened={open}
        theme={theme}
        handleNavigate={() => handleLogout()}
        listButtonSelected={listButtonSelected}
      />
    </List>
  );
};

export default DrawerList;
