import { useQuery } from '@tanstack/react-query';
import { getConstructionProgressById } from '@/src/api/endpoints/constructionProgress';
import { ArticleByIdBody } from './types';

const getConstructionByIdData = async (id: number) => {
  const response = await getConstructionProgressById(id);
  return response.data;
};

export const useGetConstructionById = (id: number) => {
  const { data, isLoading, refetch } = useQuery([`${id}`, getConstructionByIdData], () => getConstructionByIdData(id), {
    cacheTime: 10000,
  });
  const dataById: ArticleByIdBody = data;
  return { dataById, isLoading, refetch };
};
