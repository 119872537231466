import { useQuery } from '@tanstack/react-query';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getBookingStates } from '@/src/api/endpoints/bookings';

const useBookingStatesFilters = () => {
  const getBookingStatesMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.id,
          query: 'StateIds',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: bookingStates, isLoading } = useQuery(['getBookingStates'], getBookingStates, {
    select: (response) => getBookingStatesMapper(response?.data?.booking_states),
  });

  return { bookingStates, isLoading };
};

export default useBookingStatesFilters;
