import { styled, FormControlLabel, Box, Button } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export default {
  CheckboxLabel: styled(FormControlLabel)(() => ({
    span: {
      fontSize: '13px',
    },
  })),
  OpenMenu: styled(KeyboardArrowUpIcon)(() => ({
    fontSize: '15px',
    marginLeft: '5px',
    cursor: 'pointer',
  })),
  ClosedMenu: styled(KeyboardArrowDownIcon)(() => ({
    fontSize: '15px',
    marginLeft: '5px',
    cursor: 'pointer',
  })),
  Dot: styled(FiberManualRecordIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '10px',
    marginRight: '5px',
    marginBottom: '2px',
  })),
  Box: styled(Box)<{ filters: boolean | undefined }>(({ filters }) => ({
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: filters ? 'white' : '',
    position: filters ? 'fixed' : 'relative',
    zIndex: filters ? '1' : '0',
    margin: filters ? '-8px 0px 0px 0px' : '0px',
    padding: filters ? '8px 2px 0px 0px' : '0px',
    borderBottomColor: 'rgba(0,0,0,0.12)',
    borderBottomStyle: 'solid',
    borderBottomWidth: '0.5px',
  })),
  Button: styled(Button)(() => ({
    color: '#6e6b7b',
    fontWeight: 600,
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: 0,
  })),
};
