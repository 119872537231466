import { useQuery } from '@tanstack/react-query';
import { getStyles } from '@/src/api/endpoints/master';

export const useGetStyles = (clientTenantCode: string) => {
  const {
    data: styles,
    isLoading,
    refetch,
  } = useQuery(['styles'], () => getStyles(clientTenantCode), {
    select: (data) => data?.data?.style_setting,
  });

  return { styles, isLoading, refetch };
};
