import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getAllModels, getBrands } from '@/src/api/endpoints/houseHold';
import { useCustomDataTable } from '@/src/components/custom-data-table/CustomDataTableContext';

const useVehicleBrands = () => {
  const { filterString } = useCustomDataTable();

  const getVehicleBrandsMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.id,
          query: 'VehicleBrandIds',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: vehicleBrands, isLoading: isLoadingBrands } = useQuery(['getVehicleBrands'], getBrands, {
    select: (response) => getVehicleBrandsMapper(response?.data.brands),
  });

  const getModelsMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.id,
          query: 'VehicleModelIds',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: vehicleModels, isLoading: isLoadingModels } = useQuery(['getModels'], getAllModels, {
    select: (response) => response?.data?.models as Model[],
  });

  const totalModels = React.useMemo(() => {
    if (vehicleBrands && !isLoadingBrands && !isLoadingModels && filterString && vehicleModels) {
      const filters = filterString?.split('&');
      filters.shift();
      const variables = filters.filter((f) => f.includes('VehicleBrandIds'));
      return getModelsMapper(vehicleModels?.filter((b) => variables.includes(`VehicleBrandIds=${b.brand.id}`)));
    }
    return vehicleModels && getModelsMapper(vehicleModels);
  }, [filterString, vehicleBrands, isLoadingBrands, isLoadingModels]);

  return { vehicleModels: totalModels, vehicleBrands, isLoading: isLoadingBrands || isLoadingModels };
};

export default useVehicleBrands;

interface Model {
  id: number;
  name: string;
  brand: {
    id: number;
    name: string;
  };
}
