import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AuthLayoutContainer = styled(Box)`
  display: flex;
`;

export const AuthLayoutNav = styled(Box)(({ width, theme }: { width: number; theme?: any }) => ({
  width: `${width}`,
  transition: 'width 1s',
  flexShrink: 0,
  position: 'static',
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
  },
}));

export const AuthLayoutMain = styled(Box)(({ width, theme }: { width: string; theme?: any }) =>
  theme.unstable_sx({
    flexGrow: 1,
    width: `${width}`,
    transition: 'width 1s',
    padding: { xs: '6px', sm: '24px', md: '24px', lg: '24px', xl: '24px' },
    marginTop: { xs: '60px', sm: '60px', md: '0px', lg: '0px', xl: '0px' },
  }),
);
