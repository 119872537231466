import { useQuery } from '@tanstack/react-query';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getActivities } from '@/src/api/endpoints/bookings';

const useActivitiesFilters = () => {
  const getActivitiesMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.description,
          value: item.id,
          query: 'ActivityIds',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: activities, isLoading } = useQuery(['getActivities'], getActivities, {
    select: (response) => getActivitiesMapper(response?.data?.activities),
  });

  return { activities, isLoading };
};

export default useActivitiesFilters;
