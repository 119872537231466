import * as Yup from 'yup';
import { TProp } from '@/src/utilities/helpers/commonTypes';

export const validate = ({ t }: TProp) =>
  Yup.lazy(() =>
    Yup.object().shape({
      alias: Yup.string(),
      brand: Yup.object()
        .shape({
          id: Yup.number(),
          name: Yup.string(),
        })
        .nullable()
        .required(t('required_field', { ns: 'errors' })),
      model: Yup.object()
        .shape({
          id: Yup.number(),
          name: Yup.string(),
        })
        .nullable(),
      color: Yup.object()
        .shape({
          id: Yup.number(),
          name: Yup.string(),
        })
        .nullable()
        .required(t('required_field', { ns: 'errors' })),
      licensePlate: Yup.string().required(t('required_field', { ns: 'errors' })),
      fuelType: Yup.object()
        .shape({
          id: Yup.number(),
          name: Yup.string(),
        })
        .nullable(),
      rentedCar: Yup.boolean(),
      attachedFile: Yup.string(),
      comments: Yup.string(),
    }),
  );
