import { useQuery } from '@tanstack/react-query';
import { ResidencyType } from '@/src/utilities/helpers/commonTypes';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getConcepts } from '@/src/api/endpoints/concepts';

const useConceptsFilters = () => {
  const getConceptsMapper = (data: Array<ResidencyType>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.id,
          query: 'ChargeConceptIds',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: concepts, isLoading } = useQuery(['getConcepts'], getConcepts, {
    select: (response) => getConceptsMapper(response?.data.charge_concepts as Array<ResidencyType>),
  });

  return { concepts, isLoading };
};

export default useConceptsFilters;
