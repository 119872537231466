import { styled } from '@mui/material/styles';

export const Container = styled('div')(({ theme }) =>
  theme.unstable_sx({
    padding: '8px 15px 8px 15px',
    display: { sm: 'flex' },
  }),
);

export const Left = styled('div')(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    marginRight: 'auto',
    alignSelf: 'baseline',
    gap: '12px',
    justifyContent: { xs: 'space-between', sm: 'space-between' },
  }),
);

export const Right = styled('div')(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignContent: 'center',
    flexWrap: 'wrap',
    gap: '12px',
    justifyContent: { xs: 'space-between', md: 'end', sm: 'space-between' },
  }),
);

interface PropTableHeader {
  left: React.ReactNode;
  right: React.ReactNode;
}

export const TableHeader = ({ left, right }: PropTableHeader) => (
  <Container>
    <Left>{left}</Left>
    <Right>{right}</Right>
  </Container>
);
