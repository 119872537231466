import { useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { FormCategoryProps, FormCategoryContainerProps } from '../../types';
import { getsCategory, editCategory } from '@/src/api/endpoints/master';
import { useGetLanguages, getCodes } from './useGetLanguages';

export const useEditCategory = ({ row, setSnackBarMessage, close }: FormCategoryContainerProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { codes, languages, isLoading } = useGetLanguages();
  const [editValues, setyEditValues] = useState<any>({});
  const [errorEditValues, setErrorEditValues] = useState<any>({});
  const [editionMessageError, setEditionMessageError] = useState<string | null>(null);
  const { data: category, isLoading: isDataLoading } = useQuery(
    [`caterory_${row?.id}`],
    () => row && getsCategory(row.id),
    {
      select: (d) => d?.data?.category,
    },
  );
  const { mutate, isLoading: isEditionLoading } = useMutation(editCategory);
  const memoizedResetQueries = useMemo(() => queryClient.resetQueries([`caterory_${row?.id}`]), []);
  useEffect(() => {
    memoizedResetQueries;
  }, []);

  useEffect(() => {
    if (languages && category) {
      const initialState: any = {};
      const filtered = category.category_translations.map((item: any, index: number) => ({
        id: item.language.id,
        name: item.name,
        category_id: category.id,
        code: getCodes(languages[index]),
      }));
      for (const i of filtered) {
        initialState[`${i.code}Id`] = i.id;
        initialState[i.code] = i.name;
        initialState.category_id = i.category_id;
        initialState.code = i.code;
      }

      setyEditValues(initialState);
    }
  }, [languages, category]);

  const handleEditCategory = (e: any) => {
    e.preventDefault();
    const valueKeys: any = Object.keys(errorEditValues);
    const filteredErrors: any = valueKeys.filter((item: any) => errorEditValues[item] === true);
    if (filteredErrors.length > 0) {
      setEditionMessageError(t('category_edition_all_fields_requiredment', { ns: 'tickets' }));
      return;
    }
    const updatedValues: Array<{ name: string; language_id: number }> = [];
    for (const i of codes) {
      updatedValues.push({
        name: editValues[i],
        language_id: editValues[`${i}Id`],
      });
    }
    mutate(
      { id: editValues.category_id, category_translations: updatedValues },
      {
        onSuccess: async () => {
          setSnackBarMessage(t('category_edition_success_message', { ns: 'messages' }));
          close();
        },
        onError: async () => {
          setEditionMessageError(t('category_edition_error_message', { ns: 'messages' }));
        },
      },
    );
  };

  const handleOnChange = (code: string, value: any) => {
    if (value.length === 0) {
      setErrorEditValues({ ...errorEditValues, [code]: true });
      setyEditValues({ ...editValues, [code]: '' });
    } else {
      setErrorEditValues({ ...errorEditValues, [code]: null });
      setyEditValues({ ...editValues, [code]: value });
    }
  };

  const response: FormCategoryProps = {
    codes,
    values: editValues,
    isLoading: isLoading || isDataLoading,
    createEditLoading: isEditionLoading,
    handleOnChange,
    handleCreateCategory: handleEditCategory,
    errors: errorEditValues,
    creationError: editionMessageError,
  };
  return response;
};
