import { CardContent, CardContentProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface CardContainerProps extends CardContentProps {
  hasFiles?: boolean;
}

export const CardContainer = styled(CardContent)<CardContainerProps>(({ theme, hasFiles }) => ({
  ...(hasFiles && {
    display: 'flex',
  }),
  gap: '1em',
  maxWidth: '100%',
  width: '100%',
  marginBottom: '20px',
  flexWrap: 'wrap',
  padding: '16px',
  backgroundColor: theme.palette.background.paper,
}));
