import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import EditIcon from '@mui/icons-material/Edit';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { ActivitiesSectionProps, Activity } from './types';
import { ActionColumn, ActionHeader } from '@/src/components/custom-data-table/types/DataTableFormModal';
import ActivitiesForm from './forms/ActivitiesForm';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import useActivitiesList from '@/src/hooks/filters/activities.hooks';
import { getLanguage } from '@/src/utilities/storage';

export const useColumns = ({ openSubGrid }: ActivitiesSectionProps) => {
  const { t } = useTranslation();
  const { activities } = useActivitiesList();
  const lang = getLanguage()?.split('-')[0];

  const columns: CustomDataTableColumnsProps<Activity>[] = [
    {
      id: 'id',
      name: t('agendas_list_id_column', { ns: 'bookings' }),
      selector: (row: Activity) => row.id,
      sortField: 'id',
      sortable: true,
      width: lang === 'en' ? '14%' : '10%',
      minWidth: '10px',
      filters: [
        {
          id: 'agenda_id',
          query: 'AgendaId',
          type: 'numeric',
        },
      ],
    },
    {
      id: 'activity',
      name: t('agendas_list_activity_column', { ns: 'bookings' }),
      selector: (row: Activity) => row.activity.description,
      sortField: 'activity',
      sortable: true,
      maxWidth: '100%',
      width: '10%',
      minWidth: '10%',
      filters: activities,
    },
    {
      id: 'name',
      name: t('agendas_list_name_column', { ns: 'bookings' }),
      selector: (row: Activity) => row.name,
      sortField: 'name',
      sortable: true,
      filters: [
        {
          id: 'agenda_name',
          query: 'AgendaName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'min_reservation_time',
      name: t('agendas_list_min_reservation_time_column', { ns: 'bookings' }),
      selector: (row: Activity) => row.min_reservation_time,
      sortField: 'min_reservation_time',
      sortable: true,
      filters: [
        {
          id: 'min_reservation',
          query: 'MinReservationTime',
          type: 'min-max',
        },
      ],
    },
    {
      id: 'max_reservation_time',
      name: t('agendas_list_max_reservation_time_column', { ns: 'bookings' }),
      selector: (row: Activity) => row.max_reservation_time,
      sortField: 'max_reservation_time',
      sortable: true,
      filters: [
        {
          id: 'max_reservation',
          query: 'MaxReservationTime',
          type: 'min-max',
        },
      ],
    },
  ];

  const headerActions: ActionHeader[] = [
    {
      id: 'activities-create',
      component: () => (
        <CustomTableAction
          modalTitle="activities"
          component={(props: any) => <ActivitiesForm {...props} />}
          Icon={() => <AddIcon />}
          type="create"
          width={{ xs: '90%', sm: '33%' }}
        />
      ),
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'edit',
      icon: (props: any) => <EditIcon sx={props.sx} />,
      component: (props: any) => <ActivitiesForm {...props} isEdit />,
      width: { xs: '90%', sm: '33%' },
    },
    {
      id: 'configure',
      icon: (props: any) => <CalendarMonthRoundedIcon sx={props.sx} />,
      onIconClick: (row: Activity) => openSubGrid(row),
    },
  ];

  return { columns, headerActions, actionColumns };
};
