import { useTranslation } from 'react-i18next';
import { AlertColor } from '@mui/material';
import { ResidentDetail } from '../types';
import ResidentCard from './ResidentCard';
import useDialog from '@/src/hooks/common/useDialog';
import { getLanguage } from '@/src/utilities/storage';

const ResidentCardContainer = (props: {
  resident: ResidentDetail | undefined;
  setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
  isLoading: boolean;
  refetch: () => void;
}) => {
  const { t } = useTranslation('residents');
  const { Modal, close, isOpen, open } = useDialog();
  const lang = getLanguage()?.split('-')[0];

  const childProps = {
    ...props,
    lang,
    t,
    Modal,
    close,
    isOpen,
    open,
  };

  return <ResidentCard {...childProps} />;
};

ResidentCardContainer.propTypes = {};

export default ResidentCardContainer;
