import React from 'react';
import { Checkbox, MenuItem } from '@mui/material';
import { TFunction } from 'react-i18next';
import St from '../FilterColumnIcon.styled';

const FilterSort = (props: FilterSortProps) => {
  const { handleOrder, order, t } = props;

  return (
    <div>
      <MenuItem>
        <St.CheckboxLabel
          onChange={() => handleOrder('asc')}
          checked={order === 'asc'}
          control={<Checkbox size="small" />}
          label={t('sort_asc')}
        />
      </MenuItem>
      <MenuItem>
        <St.CheckboxLabel
          onChange={() => handleOrder('desc')}
          checked={order === 'desc'}
          control={<Checkbox size="small" />}
          label={t('sort_desc')}
        />
      </MenuItem>
    </div>
  );
};

interface FilterSortProps {
  handleOrder: (value: 'asc' | 'desc') => void;
  order: 'asc' | 'desc' | null;
  t: TFunction;
}

export default FilterSort;
