import { styled } from '@mui/material';

export const Header = styled('div')({
  textAlign: 'center',
});

export const HeaderContainer = styled('div')({
  justifyContent: 'center',
  display: 'flex',
  width: '100%',
});
