import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useFormik, FormikValues } from 'formik';
import ConceptsForm from './ConceptsForm';
import { createConcept, editConcept } from '@/src/api/endpoints/concepts';
import { validate } from './ConceptsForm.validate';
import { ConceptColumn, BodyCreateConcept } from '../types';

const ConceptsFormContainer = ({ row, isEdit, close, setSnackBarMessage }: ConceptColumn) => {
  const [errorMessage, setErrorMessage] = useState<{ code: string; message: string; property_name: string } | null>(
    null,
  );
  const { t } = useTranslation();
  const { mutate: createConceptMutate, isLoading: isLoadingCreate } = useMutation(createConcept);
  const { mutate: editConceptMutate, isLoading: isLoadingEdit } = useMutation(editConcept);

  const action = {
    onError: async ({ response }: any) => {
      if (response.data === '') {
        setErrorMessage({
          code: 'uncatch_error',
          message: (t('uncatch_error', { ns: 'errors' }), 'error'),
          property_name: 'Concept',
        });
      } else {
        setErrorMessage(response.data.error_messages[0]);
      }
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(
        t('success_message', { item: t(isEdit ? 'edited' : 'created', { ns: 'charges' }), ns: 'charges' }),
      );
    },
  };

  const getInitialValues = () => ({
    id: row?.id || 0,
    name: row?.name || '',
  });

  const onSubmit = (data: FormikValues) => {
    const body: BodyCreateConcept = {
      id: data.id,
      name: data.name,
    };
    if (isEdit) {
      return editConceptMutate({ id: body.id, body }, action);
    }
    createConceptMutate(body, action);
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate({ t }),
  });

  const childProps = {
    handleSubmit,
    values,
    setFieldValue,
    errors,
    errorMessage,
    isSubmitLoading: isLoadingCreate || isLoadingEdit,
    isEdit,
  };
  return <ConceptsForm {...childProps} />;
};

export default ConceptsFormContainer;
