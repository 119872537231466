import { useMutation, useQuery } from '@tanstack/react-query';
import { FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useSnackBar from '@/src/components/custom-snackbar/useSnackBar';
import { getsLanguages } from '@/src/api/endpoints/master';
// import { useGetUserInfo } from './hooks/useGetUserInfo';
import { editUserInfo, getUserInfo } from '@/src/api/endpoints/admin-users';
import { errorHandlerHelper } from '@/src/utilities/helpers/errorHandlerHelper';
import { getMfaAuthentication } from '@/src/api/endpoints/accounts-security';
import { Mfa } from './hooks/types';

export const useUserProfile = () => {
  const { t } = useTranslation();
  const { SnackBar, setSnackBarMessage } = useSnackBar();
  const {
    data: userInfo,
    isLoading: isLoadingUserInfo,
    refetch,
  } = useQuery([`getUserInfo`], () => getUserInfo(), {
    select: (data) => data?.data?.profile,
  });
  const { data: languagesList, isLoading: isLoadingLanguages } = useQuery([`getLanguages`], () => getsLanguages(), {
    select: (data) => data?.data?.languages,
  });
  const finalList = languagesList?.map((option: any) => ({
    id: option?.id,
    code: t(option?.code?.slice(0, 2), { ns: 'translation' }),
  }));

  const {
    data: mfaInfo,
    isLoading: isMfaLoading,
    refetch: refetchMfa,
  } = useQuery(['getMfaData'], () => getMfaAuthentication(), {
    select: (data) => {
      const mfaData = data.data.mfa;
      const res: Mfa = {
        mfaSettings: mfaData.mfa_settings,
        enabled: mfaData.enabled,
        preferredMfaMethod: mfaData.preferred_mfa_method,
      };
      return res;
    },
  });

  const { mutate: onUpdateUser, isLoading: isUpdateUserLoading } = useMutation(editUserInfo, {
    onSuccess: async () => {
      setSnackBarMessage(t('user_profile_updated_successfully_text', { ns: 'auth' }));
      refetch();
    },
    onError: async (err: any) => {
      setSnackBarMessage(t(errorHandlerHelper(err)?.code, { ns: 'errors' }), 'error');
    },
  });

  // FORMIK DECLATARION
  const getInitialValues = () => ({
    countryIsoCode: userInfo ? userInfo.country_iso_code : '',
    countryCode: userInfo ? userInfo.country_code : '',
    email: userInfo ? userInfo?.email : '',
    name: userInfo ? userInfo?.name : '',
    phoneNumber: userInfo ? userInfo?.phone_number : '',
    surname: userInfo ? userInfo?.surname : '',
    language: userInfo ? userInfo?.language_id : '',
  });

  const getValidationSchema = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        name: Yup.string()
          .required(t('required_field', { ns: 'errors' }))
          .max(50, t('user_profile_screen_name_field_max_length_validation', { ns: 'auth' })),
        phoneNumber: Yup.string().required(t('required_field', { ns: 'errors' })),
        surname: Yup.string()
          .required(t('required_field', { ns: 'errors' }))
          .max(50, t('user_profile_screen_surname_field_max_length_validation', { ns: 'auth' })),
      }),
    );

  const onSubmit = (values: FormikValues) => {
    const valuesToUpdate = {
      name: values.name,
      surname: values.surname,
      language_id: values.language,
    };
    onUpdateUser(valuesToUpdate);
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema(),
  });

  return {
    SnackBar,
    errors,
    handleSubmit,
    isUpdateUserLoading,
    setFieldValue,
    values,
    mfaInfo,
    languages: finalList,
    showData: !(isLoadingUserInfo || isLoadingLanguages || isMfaLoading || isUpdateUserLoading),
    refetch,
    refetchMfa,
  };
};
