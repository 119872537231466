import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { FormikErrors, FormikValues } from 'formik';
import { LoadingButton } from '@mui/lab';
import { ErrorHelper } from '@/src/components/error/error-helper/ErrorHelper';
import { FadeIn } from '@/src/components/animations';
import CodeInput from '@/src/components/code-input/CodeInput';

interface LoginCompProps {
  t: any;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: string | number | boolean,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<{ email: string; code: string }>>;
  isLoading: boolean;
  errorMessage:
    | {
        code: string;
        error_messages: string;
      }
    | null
    | undefined
    | { code: string };
}

const LoginOTP = ({ handleSubmit, values, setFieldValue, t, isLoading, errorMessage }: LoginCompProps) => (
  <FadeIn>
    <Box
      component="form"
      sx={{
        width: '100%',
        px: { xs: 1, sm: 2 },
      }}
      noValidate
      onSubmit={handleSubmit}
    >
      <Typography variant="h5">{t('challenge_OTP', { ns: 'auth' })}</Typography>
      <Typography>{t('challenge_OTP_description', { ns: 'auth' })}</Typography>
      <Stack spacing={1}>
        <CodeInput
          onChange={(e) => {
            setFieldValue('code', e);
          }}
          value={values?.code}
        />
        <ErrorHelper error={errorMessage} />
        <LoadingButton type="submit" variant="contained" loading={isLoading}>
          {t('submitting_button_text')}
        </LoadingButton>
      </Stack>
    </Box>
  </FadeIn>
);

export default LoginOTP;
