import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

export const PetsPage = () => {
  const { columnsPets, actionColumnsPetsPage } = useColumns();
  const { t } = useTranslation();
  return (
    <CustomDataTable
      title={t('pets', { ns: 'customerUsers' })}
      columns={columnsPets}
      resource="pet_residencies"
      pathApi="pets/v1/pet-residencies"
      listName="pet_residencies"
      extraFilters={[{ query: 'PetStateCodes', queryValue: 'Approved' }]}
      canExport
      canSearch
      defaultSortFieldId="pet_creation_date"
      defaultSortAsc
      customActionColumns={actionColumnsPetsPage}
    />
  );
};
