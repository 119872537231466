import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Day, Row } from '../../types';
import { RowText, RowMainWrapper } from './CustomColum.styled';

const CustomColumnRow = ({ row }: { row: Row }) => {
  const { t } = useTranslation();
  const [days] = useState([
    { id: 1, day: t('Mon', { ns: 'date' }), isSelected: false, wknd: false },
    { id: 2, day: t('Tue', { ns: 'date' }), isSelected: false, wknd: false },
    { id: 3, day: t('Wed', { ns: 'date' }), isSelected: false, wknd: false },
    { id: 4, day: t('Thu', { ns: 'date' }), isSelected: false, wknd: false },
    { id: 5, day: t('Fri', { ns: 'date' }), isSelected: false, wknd: false },
    { id: 6, day: t('Sat', { ns: 'date' }), isSelected: false, wknd: true },
    { id: 0, day: t('Sun', { ns: 'date' }), isSelected: false, wknd: true },
  ]);
  const [daysToShow, setDaysToShow] = useState<Array<Day> | null>(null);

  useEffect(() => {
    if (row) {
      const description: Array<Day> = [];
      for (const i of row.days_of_week) {
        description.push(days[(i + 6) % 7]);
      }
      setDaysToShow(description);
    }
  }, [row]);

  const RenderCellInformation = ({ daysSelected }: { daysSelected: Array<Day> }) => {
    const filteredWeekDays = daysSelected.filter((day) => day.wknd !== true);
    const filteredWeekends = daysSelected.filter((day) => day.wknd === true);

    if (daysSelected.length === 7) {
      return <RowText>&nbsp;{t('btn_every_day', { ns: 'bookings' })}</RowText>;
    }

    if (daysSelected.length === 5 && filteredWeekDays.length === 5) {
      return (
        <RowText left="4px">
          {t('bookings_agenda_availability_cell_message_pronoun', { ns: 'bookings' })}
          &nbsp;{t('btn_week_days', { ns: 'bookings' })}
        </RowText>
      );
    }

    if (filteredWeekends.length === 2) {
      return (
        <RowText left="4px">
          {t('bookings_agenda_availability_cell_message_pronoun', { ns: 'bookings' })}
          &nbsp;{t('btn_week_ends', { ns: 'bookings' })}.
        </RowText>
      );
    }

    return daysSelected?.map((day: Day, idx: number) => (
      <Typography key={day.id} marginLeft="4px">
        {daysSelected?.length > 1 && (idx < daysSelected.length - 1 ? '' : '& ')}
        {t('bookings_agenda_availability_cell_message_pronoun', { ns: 'bookings' })}
        &nbsp;
        {day.day}
        {idx < daysSelected.length - 1 ? ',' : '.'}
        &nbsp;
      </Typography>
    ));
  };

  return (
    <RowMainWrapper>
      <Typography>{t('bookings_agenda_availability_cell_message', { ns: 'bookings' })}</Typography>
      {daysToShow && RenderCellInformation({ daysSelected: daysToShow })}
      <Typography>
        {t('from', { ns: 'date' })} {row.from.slice(0, 5)} -
      </Typography>
      &nbsp;
      <Typography>
        {t('to', { ns: 'date' })} {row.to.slice(0, 5)}
      </Typography>
    </RowMainWrapper>
  );
};

export default CustomColumnRow;
