import { useQuery } from '@tanstack/react-query';
import { getPackagesStates } from '@/src/api/endpoints/packages';
import { ResidencyType } from '@/src/utilities/helpers/commonTypes';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';

const usePackageStatesFilters = () => {
  const getPackagesStatesMapper = (data: Array<ResidencyType>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.id,
          query: 'PackageStateIds',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: packagesStates, isLoading } = useQuery(['getPackagesStates'], getPackagesStates, {
    select: (response) => getPackagesStatesMapper(response?.data.package_states as Array<ResidencyType>),
  });

  return { packagesStates, isLoading };
};

export default usePackageStatesFilters;
