import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { TFunction } from 'react-i18next';

const DeleteRelative = (props: DeleteRelativeProps) => {
  const { onDelete, handleClose, t, isLoading } = props;
  return (
    <Box>
      <Typography id="modal-modal-description" sx={{ py: 1 }}>
        {t('deleting_residents_relative_message', { ns: 'residents' })}
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <Button size="small" type="button" variant="outlined" color="secondary" onClick={handleClose} sx={{ mr: 1 }}>
          {t('delete_dialog_cancel_button_text')}
        </Button>
        <LoadingButton loading={isLoading} size="small" type="button" variant="contained" onClick={() => onDelete()}>
          {t('delete_dialog_submit_button_text')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

interface DeleteRelativeProps {
  onDelete: () => void;
  handleClose: () => void;
  t: TFunction;
  isLoading: boolean;
}

export default DeleteRelative;
