import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RowText = styled(Typography)(({ left }: { left?: string }) => ({
  textTransform: 'lowercase',
  marginRight: '4px',
  marginLeft: `${left && left}`,
}));

export const RowMainWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;
