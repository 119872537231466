import { Box } from '@mui/material';
import { FadeIn } from '@/src/components/animations';
import { CardImages } from './RenderImages.styles';

const RenderCommunityImages = ({ data }: RenderCommunityImagesProps) => (
  <FadeIn duration="3s">
    <Box
      display="flex"
      justifyContent="space-around"
      sx={{
        maxWidth: '100%',
        width: '100%',
        marginBottom: '20px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
      }}
    >
      {data?.map((item: CommunityImage) => (
        <CardImages key={item.id} src={item.url} alt={`attached-image-${item.id}`} />
      ))}
    </Box>
  </FadeIn>
);

type CommunityImage = {
  id: number;
  type: string;
  url: string;
};
type RenderCommunityImagesProps = {
  data: Array<CommunityImage>;
};

export default RenderCommunityImages;
