import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@tanstack/react-query';
import UserCard from './UserCard';
import { getFormattedDateFullYearEn, getFormattedDateFullYearEs } from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import { UserCardData } from '../../types';
import { getMfaAuthenticationByUserId, resetUserAuthentication } from '@/src/api/endpoints/accounts-security';
import useSnackBar from '@/src/components/custom-snackbar/useSnackBar';

const UserCardContainer = (props: UserCardContainerProps) => {
  const { values, isLoading } = props;
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const { setSnackBarMessage, SnackBar } = useSnackBar();

  const getFormattedDateNumbeByLanguague = (date: number) =>
    lang === 'es' ? getFormattedDateFullYearEs(date) : getFormattedDateFullYearEn(date);

  const { data: mfaAuthInfo, isLoading: isLoadingGetMfaAuth } = useQuery(
    [`getMfaAuthInfo_${values.id}`],
    () => getMfaAuthenticationByUserId(values.id),
    {
      select: (data) => data?.data?.mfa,
    },
  );

  const { mutate, isLoading: isLoadingReset } = useMutation(resetUserAuthentication, {
    onSuccess: () => setSnackBarMessage(t('approved_request_success_message')),
    onError: () => setSnackBarMessage(t('approved_request_error_message'), 'error'),
  });

  const onConfirmReset = () => mutate(values?.id);

  const childProps = {
    ...props,
    t,
    getFormattedDateNumbeByLanguague,
    isLoading: isLoading || isLoadingGetMfaAuth,
    onConfirmReset,
    isLoadingReset,
    mfaAuthInfo,
    SnackBar,
  };

  return <UserCard {...childProps} />;
};

interface UserCardContainerProps {
  values: UserCardData;
  isLoading: boolean;
}

export default UserCardContainer;
