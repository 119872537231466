import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@tanstack/react-query';
import ConfigureRules from './ConfigureRules';
import { getAmenityRules, createRule, deleteActivityRule, editRule } from '@/src/api/endpoints/bookings';
import useSnackBar from '@/src/components/custom-snackbar/useSnackBar';
import { CreateRuleRequest } from '@/src/api/types/types';
import { RulesProps } from '../../types';

const ConfigureRulesContainer = ({ row }: RulesProps) => {
  const { t } = useTranslation();
  const { SnackBar, setSnackBarMessage: setLocalSnackBarMessageSuccess } = useSnackBar();

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    data: dataId,
    isLoading,
    refetch,
    remove,
  } = useQuery({
    queryKey: [`activity_rule_${row.id}`],
    queryFn: () => getAmenityRules(row.id),
  });

  const { mutate: createNewRule, isLoading: isLoadingCreate } = useMutation(createRule, {
    onSuccess: async () => {
      refetch();
      setLocalSnackBarMessageSuccess(t('rule_configuration_snackback_success_message', { ns: 'bookings' }));
    },
    onError: async (e: any) => {
      setErrorMessage(e.response.data.error_messages[0]);
    },
  });

  const { mutate: deleteRule, isLoading: isLoadingDelete } = useMutation(deleteActivityRule, {
    onSuccess: () => {
      remove();
    },
    onError: async (e: any) => {
      setErrorMessage(e.response.data.error_messages[0]);
    },
  });

  const { mutate: updateRule, isLoading: isLoadingUpdate } = useMutation(editRule, {
    onSuccess: () => {
      refetch();
      setLocalSnackBarMessageSuccess(t('rule_configuration_snackback_success_message', { ns: 'bookings' }));
    },
    onError: async (e: any) => {
      setErrorMessage(e.response.data.error_messages[0]);
    },
  });

  const [inputValues, setInputValues] = React.useState({
    weekly_hours_allowed: 0,
    active_bookings_allowed: 0,
    active_bookings_same_schedule: 0,
  });

  const handleDeleteActivityRule = (id: number, position: number, fieldValue: string) => {
    if (!dataId?.data.rules[position - 1].active) return;
    deleteRule(id, {
      onSuccess: () => {
        setInputValues({ ...inputValues, [fieldValue]: 0 });
      },
    });
  };

  const rule1 = dataId?.data?.rules[0];
  const rule2 = dataId?.data?.rules[1];
  const rule3 = dataId?.data?.rules[2];
  const getInitialValues = () => ({
    id: row.id ?? row.id,
    name: row.description ?? row.description,
    isActive: row && row.isActive,
    weekly_hours_allowed_id: rule1?.id ?? 0,
    active_bookings_allowed_id: rule2?.id ?? 0,
    active_bookings_same_schedule_id: rule3?.id ?? 0,
    weekly_hours_allowed_activity_id: rule1?.activity_id ?? 0,
    active_bookings_allowed_activity_id: rule2?.activity_id ?? 0,
    active_bookings_same_schedule_activity_id: rule3?.activity_id ?? 0,
    weekly_hours_allowed_Activity_Rule_id: rule1?.activity_rule_id ?? 0,
    active_bookings_allowed_Activity_Rule_id: rule2?.activity_rule_id ?? 0,
    active_bookings_same_schedule_Activity_Rule_id: rule3?.activity_rule_id ?? 0,
    weekly_hours_allowed: rule1?.value ? Number(rule1.value) : 0,
    active_bookings_allowed: rule2?.value ? Number(rule1.value) : 0,
    active_bookings_same_schedule: rule3?.value ? Number(rule1.value) : 0,
    is_weekly_active: rule1?.active ?? false,
    is_bookings_allowed_active: rule2?.active ?? false,
    is_bookings_allowed_sameSch_active: rule3?.active ?? false,
    weekly_hours_allowed_description: rule1?.description ?? '',
    active_bookings_allowed_description: rule2?.description ?? '',
    active_bookings_same_schedule_description: rule3?.description ?? '',
    weekly_hours_limit_title: rule1?.title ?? '',
    ammount_active_res_title: rule2?.title ?? '',
    res_allowed_by_time_title: rule3?.title ?? '',
  });

  const getValidationSchema = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        name: Yup.string().required(t('required_field', { ns: 'errors' })),
        document_url: Yup.string().required(t('required_field', { ns: 'errors' })),
      }),
    );

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    onSubmit: () => {},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema(),
  });

  const activities = [
    {
      id: 1,
      title: values.weekly_hours_limit_title,
      activityRuleId: values.weekly_hours_allowed_Activity_Rule_id,
      section: values.weekly_hours_allowed_description,
      value:
        dataId && dataId.data.rules[0].value ? Number(dataId.data.rules[0].value) : inputValues.weekly_hours_allowed,
      isActive: values.is_weekly_active,
      isValueActiveField: 'is_weekly_active',
      fieldValue: 'weekly_hours_allowed',
    },
    {
      id: 2,
      title: values.ammount_active_res_title,
      activityRuleId: values.active_bookings_allowed_Activity_Rule_id,
      section: values.active_bookings_allowed_description,
      value:
        dataId && dataId.data.rules[1].value ? Number(dataId.data.rules[1].value) : inputValues.active_bookings_allowed,
      isActive: values.is_bookings_allowed_active,
      isValueActiveField: 'is_bookings_allowed_active',
      fieldValue: 'active_bookings_allowed',
    },
    {
      id: 3,
      title: values.res_allowed_by_time_title,
      activityRuleId: values.active_bookings_same_schedule_Activity_Rule_id,
      section: values.active_bookings_same_schedule_description,
      value:
        dataId && dataId.data.rules[2].value
          ? Number(dataId.data.rules[2].value)
          : inputValues.active_bookings_same_schedule,
      isActive: values.is_bookings_allowed_sameSch_active,
      isValueActiveField: 'is_bookings_allowed_sameSch_active',
      fieldValue: 'active_bookings_same_schedule',
    },
  ];

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const hadleCheck = (e: any, activity: any) => {
    setFieldValue(activity.isValueActiveField, e.target.checked);
    !activity.isActive && setExpanded(`panel${activity.id}`);
    activity.isActive && handleDeleteActivityRule(activity.activityRuleId, activity.id, activity.fieldValue);
  };

  const handleSaveValue = ({
    isValueActiveField,
    fieldValue,
    id,
  }: {
    id: number;
    section: string;
    value: number;
    isActive: boolean;
    isValueActiveField: string;
    fieldValue: string;
  }) => {
    setErrorMessage('');
    const active = dataId?.data.rules[id - 1].active;
    const dataToUpdate: CreateRuleRequest = {
      rule_id: values[`${fieldValue}_id` as keyof typeof values],
      activity_id: values[`${fieldValue}_activity_id` as keyof typeof values],
      values_to_assign: [
        {
          rule_value_id:
            values[`${fieldValue}_activity_rule_id` as keyof typeof values] ||
            values[`${fieldValue}_id` as keyof typeof values],
          value: values[fieldValue as keyof typeof values],
        },
      ],
    };

    if (active) {
      return updateRule({
        id: values[`${fieldValue}_Activity_Rule_id` as keyof typeof values],
        body: { value: dataToUpdate.values_to_assign[0].value },
      });
    }

    createNewRule(dataToUpdate);

    setInputValues({ ...inputValues, [fieldValue]: values[fieldValue as keyof typeof values] });
    setExpanded(false);
    setFieldValue(isValueActiveField, true);
  };

  const childProps = {
    values,
    activities,
    setFieldValue,
    errors,
    handleSubmit,
    expanded,
    handleChange,
    handleSaveValue,
    errorMessage,
    SnackBar,
    isLoadingGeneral: isLoading || isLoadingDelete || isLoadingUpdate || isLoadingCreate,
    hadleCheck,
  };

  return <ConfigureRules {...childProps} />;
};

export default ConfigureRulesContainer;
