import React from 'react';
import { useTranslation } from 'react-i18next';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { VisibilityRounded } from '@mui/icons-material';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import { ChargesListRow } from './types';
import ChargeInfo from './charge-info';
import ChargeForm from './charge-form';
import DeleteCharge from './delete-charge';
import useConceptsFilters from '@/src/hooks/filters/concepts.hooks';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';
import useResidencyFilters from '@/src/hooks/filters/residencyTypes.hooks';

export const useColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const isHidden: (date: number) => boolean = (date: number) => {
    const actualDate = new Date().getTime();
    return actualDate > date;
  };
  const { concepts } = useConceptsFilters();
  const { apartments } = useApartmentsFilters();
  const { residencyTypes } = useResidencyFilters();

  const columns: CustomDataTableColumnsProps<ChargesListRow>[] = [
    {
      id: 'date',
      name: t('date'),
      selector: (row: ChargesListRow) =>
        row?.creation_date
          ? `${getFormattedDateByLanguague(
              lang,
              getFormattedDateFullYearEs,
              getFormattedDateFullYearEn,
              row?.creation_date,
            )}`
          : '-',
      sortField: 'creation_date',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'creation-date',
          query: 'CreationDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'property_code',
      name: t('property_code', { ns: 'charges' }),
      selector: (row: ChargesListRow) => row.property_resident?.residency?.property?.code,
      sortField: 'property_code',
      sortable: true,
      center: true,
      filters: apartments,
    },
    {
      id: 'resident_type',
      name: t('resident_type', { ns: 'charges' }),
      selector: (row: ChargesListRow) => row?.property_resident?.residency?.type?.name,
      sortField: 'resident_type',
      width: lang === 'es' ? '160px' : '130px',
      sortable: true,
      filters: residencyTypes,
    },
    {
      id: 'name',
      name: t('name'),
      selector: (row: ChargesListRow) =>
        row?.property_resident?.person?.name
          ? `${row?.property_resident?.person?.name} ${row?.property_resident?.person?.surname}`
          : '',
      sortable: false,
      filters: [
        {
          id: 'property-resident-full-name',
          query: 'PropertyResidentFullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'concept',
      name: t('concept'),
      selector: (row: ChargesListRow) => row.charge_concept.name,
      sortField: 'concept_name',
      sortable: true,
      filters: concepts,
    },
    {
      id: 'quantity',
      name: t('quantity'),
      selector: (row: ChargesListRow) => row.quantity || 1,
      sortField: 'quantity',
      sortable: true,
      center: true,
      maxWidth: '1%',
      filters: [
        {
          id: 'quantity',
          query: 'Quantity',
          type: 'min-max',
        },
      ],
    },
    {
      id: 'amount',
      name: t('amount'),
      selector: (row: ChargesListRow) => row.amount * (row.quantity || 1),
      sortField: 'amount',
      sortable: true,
      center: true,
      maxWidth: '1%',
      filters: [
        {
          id: 'amount',
          query: 'Amount',
          type: 'min-max',
        },
      ],
    },
    {
      id: 'from',
      name: t('validity', { ns: 'charges' }),
      selector: (row: ChargesListRow) =>
        row?.property_resident
          ? `${getFormattedDateByLanguague(
              lang,
              getFormattedDateFullYearEs,
              getFormattedDateFullYearEn,
              row?.property_resident?.residency?.from,
            )} - ${getFormattedDateByLanguague(
              lang,
              getFormattedDateFullYearEs,
              getFormattedDateFullYearEn,
              row?.property_resident?.residency?.to,
            )}`
          : '-',
      sortField: 'from',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'residency-to-date',
          query: 'ResidencyToDate',
          type: 'date',
        },
      ],
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'detail',
      icon: (props: any) => <VisibilityRounded sx={props.sx} />,
      component: (props: any) => <ChargeInfo {...props} chargeId={props.row.id} />,
      width: { xs: '90%', sm: '500px', lg: '700px' },
    },
    {
      id: 'edit',
      icon: (props: any) => <ModeEditIcon sx={props.sx} />,
      component: (props: any) => <ChargeForm {...props} isEditMode />,
      width: { xs: '90%', lg: '70%', xl: '55%' },
      isHidden: (row: ChargesListRow) =>
        isHidden(row?.property_resident?.residency?.to) ||
        row?.property_resident?.residency?.has_checked_out ||
        row.is_payed,
    },
    {
      id: 'delete_btn',
      icon: (props: any) => <DeleteRoundedIcon sx={props.sx} />,
      component: (props: any) => <DeleteCharge {...props} />,
      width: '23%',
      isHidden: (row: ChargesListRow) =>
        isHidden(row?.property_resident?.residency?.to) ||
        row?.property_resident?.residency?.has_checked_out ||
        row?.is_payed,
    },
  ];

  return { columns, actionColumns };
};
