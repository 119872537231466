import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { Label } from '@/src/components/forms/styles';
import { CopytoClipboardType } from '../../types';

const CopytoClipboard = ({ text, setSnackBarMessage }: CopytoClipboardType) => {
  const { t } = useTranslation();
  const copyText = () => {
    navigator.clipboard.writeText(text);
    setSnackBarMessage(t('copied_to_clipboard'));
  };

  return (
    <Grid container item xs={12} sm={6} md={6} display="flex" width="fit-content">
      <Label
        disabled
        errorHelper=""
        sx={{ width: 'fit-content', fontSize: '10px' }} // maxWidth: '90%', overflow: 'hidden'
      >
        {text}
      </Label>
      <CopyAllIcon
        sx={{ fontSize: '32px', cursor: 'pointer' }}
        onClick={() => {
          copyText();
        }}
      />
    </Grid>
  );
};

export default CopytoClipboard;
