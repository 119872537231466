import { axiosAuthenticationInstance } from '../instances/authInstance';
import { DeliveryAuthorization, GetDeliveryInfoByPropertyCodeAndCarrierIdRequest } from '../types/types';

export const getCarriers = () => axiosAuthenticationInstance.get('/deliveries/v1/carriers');
export const getAuthorizationStates = () => axiosAuthenticationInstance.get('/deliveries/v1/authorization-states');
export const getDeliveryInfo = (id: number) => axiosAuthenticationInstance.get(`/deliveries/v1/authorizations/${id}`);
export const changeAuthorizationToDelivered = (id: number) =>
  axiosAuthenticationInstance.put(`/deliveries/v1/authorizations/${id}/admission`);
export const getPeopleByPropertyCode = (propertyCode: string) =>
  axiosAuthenticationInstance.get(`/deliveries/v1/properties/${propertyCode}/people`);
export const getDeliveryInfoByPropertyCodeAndCarrierId = (req: GetDeliveryInfoByPropertyCodeAndCarrierIdRequest) =>
  axiosAuthenticationInstance.get(`/deliveries/v1/authorizations/${req.propertyCode}/${req.carrierId}`);
export const deliveryAuthorization = (req: DeliveryAuthorization) =>
  axiosAuthenticationInstance.post(`/deliveries/v1/authorizations`, req);
