type RelativesStateOptions = {
  [key: string]: string;
};

export const commonStatus: RelativesStateOptions = {
  Approved: '#516F3F',
  Aprobado: '#516F3F',
  InReview: '#CA8A04',
  En_revision: '#CA8A04',
  Rejected: '#DC143C',
  Rechazado: '#DC143C',
};
