import { useQuery } from '@tanstack/react-query';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getColors } from '@/src/api/endpoints/houseHold';

const useVehicleColors = () => {
  const getVehicleColorsMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.id,
          query: 'VehicleColorIds',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: colors, isLoading } = useQuery(['getVehicleColors'], getColors, {
    select: (response) => getVehicleColorsMapper(response?.data.colors),
  });

  return { colors, isLoading };
};

export default useVehicleColors;
