import { useState } from 'react';
import { PeopleRowProps } from './residents-section/types';

export const useResidentsPage = () => {
  const [subGridOpen, setSubGridOpen] = useState('');
  const [selectedRow, setSelectedRow] = useState<PeopleRowProps>();

  const openSubGrid = (row: PeopleRowProps, subgrid: string) => {
    setSubGridOpen(subgrid);
    setSelectedRow(row);
  };

  const closeSubGrid = () => setSubGridOpen('');
  const isAccountStateOpen = subGridOpen === 'AccountState' && selectedRow;
  const isDetailOpen = subGridOpen === 'ResidentsDetail' && selectedRow;

  return { subGridOpen, isAccountStateOpen, isDetailOpen, selectedRow, openSubGrid, closeSubGrid };
};
