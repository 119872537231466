import React from 'react';
import { useTranslation } from 'react-i18next';
import { CanFilterContainerProps } from '../../types/DataTableFormModal';
import CanFilter from './CanFilter';

const CanFilterContainer = (props: CanFilterContainerProps) => {
  const { setStartRefresh } = props;
  const { t } = useTranslation();

  const onSubmit = () => {
    setStartRefresh(true);
  };

  const childProps = {
    ...props,
    onSubmit,
    t,
  };

  return <CanFilter {...childProps} />;
};

export default CanFilterContainer;
