import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getAllPetBreeds, getPetTypes } from '@/src/api/endpoints/houseHold';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { useCustomDataTable } from '@/src/components/custom-data-table/CustomDataTableContext';

const usePetTypesAndBreedsFilters = () => {
  const { filterString } = useCustomDataTable();
  const getPetTypesMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.id,
          query: 'PetTypeIds',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: petTypes, isLoading: isLoadingTypes } = useQuery(['getTypes'], getPetTypes, {
    select: (response) => getPetTypesMapper(response?.data?.types),
  });

  const getPetBreedsMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.id,
          query: 'PetBreedIds',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: breeds, isLoading: isLoadingBreeds } = useQuery(['getAllBreeds'], getAllPetBreeds, {
    select: (response) => response?.data?.breeds as Breeds[],
  });

  const totalBreeds = React.useMemo(() => {
    if (petTypes && !isLoadingTypes && !isLoadingBreeds && filterString && breeds) {
      const filters = filterString?.split('&');
      filters.shift();
      const variables = filters.filter((f) => f.includes('PetTypeIds'));
      return getPetBreedsMapper(breeds?.filter((b) => variables.includes(`PetTypeIds=${b.type.id}`)));
    }
    return breeds && getPetBreedsMapper(breeds);
  }, [filterString, breeds, isLoadingBreeds, isLoadingTypes]);

  return { petTypes, breeds: totalBreeds, isLoading: isLoadingTypes || isLoadingBreeds };
};

interface Breeds {
  id: string;
  name: number;
  type: {
    id: number;
    name: string;
  };
}

export default usePetTypesAndBreedsFilters;
