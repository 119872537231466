import JoditEditor from 'jodit-react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

type Config = any;

interface CustomEditorProps {
  values: any;
  valueName: string;
  setFieldValue: (field: string, val: string) => void;
}
const CustomEditor = ({ values, valueName, setFieldValue }: CustomEditorProps) => {
  const editor = useRef(null);
  const { t } = useTranslation();
  const joditConfig = {
    defaultMode: 1,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    spellcheck: true,
    readOnly: false,
    hidePoweredByJodit: true,
    buttons: ['source', 'bold', 'link'],
    placeholder: t('jodit_editor_placeholder'),
  };
  return (
    <JoditEditor
      ref={editor}
      config={joditConfig as Config | undefined}
      value={values[valueName]}
      onBlur={(val: string) => {
        setFieldValue(valueName, val);
      }}
    />
  );
};

export default CustomEditor;
