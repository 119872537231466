import { Box, Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import {
  getFormattedDateFullYearEn,
  getFormattedDateByLanguague,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { packagesStates } from '../../types';
import TitleWithStatus from '@/src/components/title-with-status/TitleWithStatus';
import { getPackageById } from '@/src/api/endpoints/packages';
import InfoLabel from '@/src/components/info-label/InfoLabel';
import { getLanguage } from '@/src/utilities/storage';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';

const InfoPackage = ({ close, row }: any) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: packageDetail, isLoading } = useQuery(['packageDetail'], () => getPackageById(row.internal_code), {
    select: (data) => data?.data?.package,
  });
  const memoizedResetQueries = useMemo(() => queryClient.resetQueries(['packageDetail']), []);
  useEffect(() => {
    memoizedResetQueries;
  }, []);

  const lang = getLanguage()?.split('-')[0];
  return (
    <Box component="div" sx={{ marginY: '5px' }}>
      <Grid container spacing={2}>
        <TitleWithStatus
          title={`${t('info_package_title', { ns: 'packages' })}`}
          statusText={row?.package_state.name}
          statusBg={packagesStates[row?.package_state.code]}
          onClose={close}
        />
        {packageDetail && ['Delivered', 'Entregado'].includes(packageDetail?.package_state.name) && (
          <>
            <Grid item xs={12} sx={{ pt: '0 !important' }}>
              <Typography variant="subtitle1" fontWeight={600}>
                {t('info_package_sub_title', { ns: 'packages' })}
              </Typography>
              <Divider sx={{ mt: '2px', mb: '5px' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_info_package_input_who_retired', { ns: 'packages' })}
                second={packageDetail?.delivered_to}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_info_package_input_withdrawal_code', { ns: 'packages' })}
                second={packageDetail?.delivery_code}
              />
            </Grid>
            <Grid item xs={12} sm={6} pb={packageDetail ? 2 : 0}>
              <InfoLabel
                first={t('modal_info_package_input_withdrawal_date', { ns: 'packages' })}
                second={
                  packageDetail?.delivery_date &&
                  getFormattedDateByLanguague(
                    lang,
                    getFormattedDateFullYearEs,
                    getFormattedDateFullYearEn,
                    packageDetail.delivery_date,
                  )
                }
              />
            </Grid>
            <Grid item xs={12} pt={2} sx={{ pt: '0 !important' }}>
              <Typography variant="subtitle1" fontWeight={600}>
                {t('info_package_sub_title_2', { ns: 'packages' })}
              </Typography>
              <Divider sx={{ mt: '2px', mb: '5px' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_load_package_input_internalId', { ns: 'packages' })}
                second={packageDetail?.internal_code}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_load_package_input_carrier_code', { ns: 'packages' })}
                second={packageDetail?.package_code}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_load_package_input_property_code', { ns: 'packages' })}
                second={packageDetail?.property.code}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_load_package_input_carrier_id', { ns: 'packages' })}
                second={packageDetail?.carrier.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight={600} marginRight="3.5px">
                {t('modal_load_package_input_user_who_entered_package', { ns: 'packages' })}:
              </Typography>
              <Typography variant="subtitle2">{packageDetail?.creation_user}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight={600} marginRight="3.5px">
                {t('modal_load_package_input_date_package_entered', { ns: 'packages' })}:
              </Typography>
              <Typography variant="subtitle2">
                {getFormattedDateByLanguague(
                  lang,
                  getFormattedDateFullYearEs,
                  getFormattedDateFullYearEn,
                  packageDetail?.creation_date,
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel first={t('rack', { ns: 'packages' })} second={packageDetail?.location ?? '-'} />
            </Grid>
          </>
        )}
        {packageDetail && ['PendingToStore'].includes(packageDetail?.package_state.code) && (
          <>
            <Grid item xs={12} pt={2} sx={{ pt: '0 !important' }}>
              <Typography variant="subtitle1" fontWeight={600}>
                {t('info_package_sub_title_2', { ns: 'packages' })}
              </Typography>
              <Divider sx={{ mt: '2px', mb: '5px' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_load_package_input_internalId', { ns: 'packages' })}
                second={packageDetail?.internal_code}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_load_package_input_carrier_code', { ns: 'packages' })}
                second={packageDetail?.package_code}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_load_package_input_property_code', { ns: 'packages' })}
                second={packageDetail?.property.code}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_load_package_input_carrier_id', { ns: 'packages' })}
                second={packageDetail?.carrier.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight={600} marginRight="3.5px">
                {t('modal_load_package_input_user_who_entered_package', { ns: 'packages' })}:
              </Typography>
              <Typography variant="subtitle2">{packageDetail?.creation_user}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight={600} marginRight="3.5px">
                {t('modal_load_package_input_date_package_entered', { ns: 'packages' })}:
              </Typography>
              <Typography variant="subtitle2">
                {getFormattedDateByLanguague(
                  lang,
                  getFormattedDateFullYearEs,
                  getFormattedDateFullYearEn,
                  packageDetail?.creation_date,
                )}
              </Typography>
            </Grid>
          </>
        )}
        {packageDetail && ['Pending', 'Pendiente'].includes(packageDetail?.package_state.code) && (
          <>
            <Grid item xs={12} pt={2} sx={{ pt: '0 !important' }}>
              <Typography variant="subtitle1" fontWeight={600}>
                {t('info_package_sub_title_2', { ns: 'packages' })}
              </Typography>
              <Divider sx={{ mt: '2px', mb: '5px' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_load_package_input_internalId', { ns: 'packages' })}
                second={packageDetail?.internal_code}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_load_package_input_carrier_code', { ns: 'packages' })}
                second={packageDetail?.package_code}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_load_package_input_property_code', { ns: 'packages' })}
                second={packageDetail?.property.code}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_load_package_input_carrier_id', { ns: 'packages' })}
                second={packageDetail?.carrier.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight={600} marginRight="3.5px">
                {t('modal_load_package_input_user_who_entered_package', { ns: 'packages' })}:
              </Typography>
              <Typography variant="subtitle2">{packageDetail?.creation_user}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight={600} marginRight="3.5px">
                {t('modal_load_package_input_date_package_entered', { ns: 'packages' })}:
              </Typography>
              <Typography variant="subtitle2">
                {getFormattedDateByLanguague(
                  lang,
                  getFormattedDateFullYearEs,
                  getFormattedDateFullYearEn,
                  packageDetail?.creation_date,
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('rack', { ns: 'packages' })}
                second={packageDetail?.location ? packageDetail?.location : null}
              />
            </Grid>
          </>
        )}
      </Grid>
      {isLoading && <CircularSpinner />}
    </Box>
  );
};

export default InfoPackage;
