import { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import { VehicleForm } from './VehicleForm';
import {
  getVehicle,
  createVehicle,
  editVehicle,
  getBrands,
  getModels,
  getColors,
  getFuelTypes,
} from '@/src/api/endpoints/houseHold';
import { Brand, Model, Color, Fuel, VehiclesEditContainerProps } from '../types';
import { validate } from './VehicleForm.validate';
import { useGenerateImageUrl } from '@/src/hooks/common/useGenerateImageUrl';

const VehicleFormContainer = ({ row, close, setSnackBarMessage, isEdit, id }: VehiclesEditContainerProps) => {
  const { t } = useTranslation();
  const [brandId, setBrandId] = useState(row?.vehicle?.model?.brand?.id);
  const { data, isLoading: isLoadingVehicleData } = useQuery({
    queryKey: [`vehicle_${row?.id}`],
    queryFn: () => getVehicle({ id: row?.vehicle?.id, residencyId: row?.residency?.id }),
    select: (response) => response.data.vehicle_residency ?? null,
    enabled: isEdit,
  });
  const residencyId = data?.residency?.id;

  const { data: brandsData, isLoading: isLoadingBrandsData } = useQuery(['getBrandsVehicleForm'], () => getBrands(), {
    select: (response) => response.data.brands as Array<Brand>,
  });

  const { data: colorsData, isLoading: isLoadingColorData } = useQuery(['getColorsVehicleForm'], () => getColors(), {
    select: (response) => response?.data?.colors as Array<Color>,
  });

  const { data: fuelData, isLoading: isLoadingFuelData } = useQuery(['getFuelVehicleForm'], () => getFuelTypes(), {
    select: (response) => response?.data?.fuel_types as Array<Fuel>,
  });

  const loadingGeneral = isLoadingVehicleData || isLoadingBrandsData || isLoadingColorData || isLoadingFuelData;

  const [errorMessage, setErrorMessage] = useState<{ code: string; message: string; property_name: string } | null>(
    null,
  );

  const { getUrl, isLoadingImage, errorSubmittingImage, url } = useGenerateImageUrl();

  const { mutate, isLoading } = useMutation(createVehicle, {
    onError: async (e: any) => {
      setErrorMessage(e.message);
      setSnackBarMessage(t('approved_request_error_message'), 'error');
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successful_vehicle_edition_snackbar_text', { ns: 'customerUsers' }));
    },
  });

  const { mutate: mutateEdit, isLoading: isLoadingEdit } = useMutation(editVehicle, {
    onError: async (e: any) => {
      setErrorMessage(e.message);
      setSnackBarMessage(t('approved_request_error_message'), 'error');
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successful_vehicle_edition_snackbar_text', { ns: 'customerUsers' }));
    },
  });

  const getInitialValues = () => ({
    residencyId: isEdit ? residencyId : id,
    brand: isEdit ? data?.vehicle?.model?.brand : null,
    model: isEdit ? data?.vehicle?.model : null,
    alias: isEdit ? data?.vehicle?.alias : null,
    color: isEdit ? data?.vehicle?.color : null,
    licensePlate: isEdit ? data?.vehicle?.license_plate : '',
    fuelType: isEdit ? data?.vehicle?.fuel_type : '',
    rentedCar: isEdit ? data?.vehicle?.is_rented : '',
    attachedFile: isEdit ? data?.vehicle?.file_url : '',
    comments: isEdit ? data?.vehicle?.comments : '',
  });

  const onSubmit = (value: FormikValues) => {
    if (isEdit) {
      const body = {
        // residency_id: value?.residencyId,
        model_id: value?.model?.id,
        brand_id: value?.brand?.id,
        color_id: value?.color?.id,
        alias: value?.alias,
        license_plate: value?.licensePlate,
        fuel_type_id: value?.fuelType?.id,
        is_rented: value?.rentedCar ? value?.rentedCar : false,
        file_url: value?.attachedFile,
        comments: value?.comments,
      };
      mutateEdit({ body, id: row?.vehicle?.id });
    } else {
      const body = {
        residency_id: value?.residencyId,
        brand_id: value?.brand?.id,
        model_id: value?.model?.id,
        color_id: value?.color?.id,
        alias: value?.alias,
        license_plate: value?.licensePlate,
        fuel_type_id: value?.fuelType?.id,
        is_rented: value?.rentedCar ? value?.rentedCar : false,
        file_url: value?.attachedFile,
        comments: value?.comments,
      };
      mutate(body);
    }
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate({ t }),
  });

  const { handleSubmit, values, setFieldValue, errors } = formik;

  const { data: modelsData, refetch } = useQuery(['getModelsVehicleForm'], () => getModels(brandId), {
    select: (response) => response?.data?.models as Array<Model>,
    enabled: !!formik.values?.brand,
  });

  useEffect(() => {
    if (brandId) refetch();
    setFieldValue('model', null);
  }, [brandId]);

  useEffect(() => {
    if (url) {
      setFieldValue('attachedFile', url as string);
    }
  }, [url]);

  const deleteImage = () => {
    setFieldValue('attachedFile', '');
  };

  const props = {
    isEdit,
    row,
    close,
    errorMessage,
    isSubmitLoading: isLoading || isLoadingEdit,
    loadingGeneral,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    getUrlIn64: getUrl,
    isLoadingImage,
    deleteImage,
    wrongImage: errorSubmittingImage,
    brandsData,
    modelsData,
    colorsData,
    fuelData,
    setBrandId,
    setSnackBarMessage,
  };

  return <VehicleForm {...props} />;
};

export default VehicleFormContainer;
