import React from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import { t } from 'i18next';
import { IconButton } from '@mui/material';
import { CanFilterProps } from '../../types/DataTableFormModal';
import CustomTableAction from '../CustomTableAction';
import FilterModal from './filter-modal';

const CanFilter = (props: CanFilterProps) => {
  const { pageLoading } = props;

  return (
    <div>
      {pageLoading ? (
        <IconButton
          disabled={pageLoading}
          aria-label="filter"
          sx={{
            backgroundColor: 'transparent',
            color: 'primary.main',
            ':hover': {
              textDecoration: 'none',
            },
            height: '40px',
            width: '24px',
          }}
        >
          <TuneIcon />
        </IconButton>
      ) : (
        // <IconButton aria-label="filter" sx={{ padding: '0px', width: '24px', height: '34px' }}>
        <CustomTableAction
          Icon={(iconProps) => <TuneIcon sx={{ ...iconProps.sx, height: '25px' }} />}
          modalTitle={t('filter')}
          type="btn_to_grid"
          component={(actionProps: any) => <FilterModal {...actionProps} {...props} />}
        />
        // </IconButton>
      )}
    </div>
  );
};

export default CanFilter;
