import { Box, Button, TextField } from '@mui/material';
import React from 'react';
import { TFunction } from 'react-i18next';
import { CustomInputStyles } from '@/src/components/forms/styles';
import { FilterByColumn } from '../../../types/CustomDataTableColumnProps';
import FilterCheckbox from '../filter-checkbox';

const FilterSearch = (props: FilterSearchProps) => {
  const {
    t,
    handleChange,
    currentValue,
    showSearchBar,
    filters,
    setLocalFilterString,
    localFilterString,
    isAllDataVisible,
    loadMoreData,
  } = props;

  return (
    <Box>
      <Box component="div" sx={{ margin: '5px 16px', p: '2px' }}>
        {showSearchBar && (
          <TextField
            label={t('search') as string}
            onChange={(e) => handleChange(e.target.value)}
            size="small"
            sx={{ ...CustomInputStyles(''), width: '150px' }}
            fullWidth
            value={currentValue}
            placeholder={t('search')}
          />
        )}
      </Box>
      {filters &&
        filters.map((f: any, i: number) => {
          const filterProps = {
            setLocalFilterString,
            localFilterString,
            filter: f,
          };

          return <FilterCheckbox key={i} {...filterProps} />;
        })}
      {!isAllDataVisible && (
        <Button fullWidth onClick={loadMoreData}>
          {t('show_more')}
        </Button>
      )}
    </Box>
  );
};

interface FilterSearchProps {
  t: TFunction;
  showSearchBar: boolean;
  filters: FilterByColumn[] | undefined;
  handleChange: (value: string) => void;
  currentValue: string;
  setLocalFilterString: React.Dispatch<React.SetStateAction<string>>;
  localFilterString: string;
  loadMoreData: () => void;
  isAllDataVisible: boolean;
}

export default FilterSearch;
