import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { ErrorHelper } from '@/src/components/error/error-helper/ErrorHelper';
import { ConfigureRuleProps } from '../../types';
import { LoaderContainer } from '@/src/components/loader-container/LoaderContainer';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import InfoLabel from '@/src/components/info-label/InfoLabel';
import { RuleContainer, RuleTitleWrapper, RuleValueWrapper, RuleDetailWrapper } from './Configure.styled';

const ConfigureRules = ({
  values,
  activities,
  handleChange,
  expanded,
  setFieldValue,
  handleSaveValue,
  SnackBar,
  isLoadingGeneral,
  errorMessage,
  hadleCheck,
}: ConfigureRuleProps) => {
  const { t } = useTranslation();

  const handleAddValue = (fieldValue: string, value: string) => {
    const numericValue = Number(value);
    const isString = Number.isNaN(numericValue);
    if (isString) {
      setFieldValue(fieldValue, '');
    } else {
      setFieldValue(fieldValue, numericValue);
    }
  };

  return isLoadingGeneral ? (
    <CircularSpinner />
  ) : (
    <>
      <Typography variant="subtitle2" sx={{ fontSize: 16, mt: 0.5 }}>
        {t('activity_modal_title_amenity', { ns: 'cdtModal' })}: {values.name}
      </Typography>
      {activities.map((activity) => (
        <Accordion key={activity.id} expanded={expanded === `panel${activity.id}`}>
          <AccordionSummary
            sx={{ cursor: 'default !important' }}
            expandIcon={
              <Checkbox
                onChange={handleChange(`panel${activity.id}`)}
                icon={<SettingsIcon />}
                checkedIcon={<SettingsIcon />}
                checked={expanded === `panel${activity.id}`}
              />
            }
          >
            <RuleContainer>
              <RuleTitleWrapper component="div">
                <Typography sx={{ width: '100%', flexShrink: 0, fontSize: '0.85em', fontWeight: 'bolder' }}>
                  {activity.title}
                </Typography>
                <Typography sx={{ width: '100%', flexShrink: 0, fontSize: '0.8em' }}>
                  {activity.section.replaceAll('(', ' ').replaceAll(')', ' ')}
                </Typography>
              </RuleTitleWrapper>
              <RuleValueWrapper component="div">
                <Typography sx={{ flexShrink: 0 }}>
                  {t('value', { ns: 'bookings' })}: {activity.value}
                </Typography>
                <Typography sx={{ flexShrink: 0 }}>
                  {t('active', { ns: 'bookings' })}
                  <Checkbox
                    checked={activity.isActive}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                    onChange={(e) => hadleCheck(e, activity)}
                  />
                </Typography>
              </RuleValueWrapper>
            </RuleContainer>
          </AccordionSummary>
          <AccordionDetails>
            <RuleDetailWrapper component="div">
              <InfoLabel first={`${t('add_button_text')} ${t('value', { ns: 'bookings' })}`} second="" />
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                type="number"
                sx={{
                  '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    textAlign: 'center',
                  },
                }}
                value={values[activity.fieldValue]}
                onChange={(e) => handleAddValue(activity.fieldValue, e.target.value)}
              />
              <Button
                variant="outlined"
                color="primary"
                type="button"
                sx={{ height: 40, width: { xs: '100%', sm: '10%' } }}
                disabled={values[activity.fieldValue] === 0}
                onClick={() => handleSaveValue(activity)}
              >
                {t('save')}
              </Button>
            </RuleDetailWrapper>
          </AccordionDetails>
        </Accordion>
      ))}
      <LoaderContainer>
        <ErrorHelper error={errorMessage} />
      </LoaderContainer>
      <SnackBar />
    </>
  );
};

export default ConfigureRules;
