import { CardContent, CardHeader } from '@mui/material';
import ImageNotFound from '@/src/assets/common/images/image_not_found.jpg';
import { getMonthDayYearValue } from '@/src/utilities/helpers/dateParser';
import { NewsCardWrapper, NewsCardFooterText } from '../styles/CreateNews.styled';
import { CardAvatar } from './NewsCard.styles';
import CustomCardMedia from '@/src/components/custom-card-media/CustomCardMedia';
import { News } from '../types';
import { getTenant } from '@/src/utilities/helpers/utils';

interface NewsCardProps {
  item: News;
  setIdSelected: (id: number) => void;
}

const textReload = (text: string) => {
  const titleReload = text.length > 45 ? `${text.slice(0, 45)}...` : text;
  return titleReload;
};

const NewsCard = ({ item, setIdSelected }: NewsCardProps) => {
  const { id, title, creation_date: creationDate, cover_image_url: coverImageUrl, subtitle } = item;
  const handleClick = (event: any, selectedId: any) => {
    setIdSelected(selectedId);
  };

  return (
    <NewsCardWrapper onClick={(event) => handleClick(event, id)} key={id}>
      <CardHeader
        avatar={<CardAvatar aria-label="card-label">{getTenant()}</CardAvatar>}
        title={textReload(title)}
        titleTypographyProps={{
          fontWeight: 'bold',
          width: '200px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        subheader={getMonthDayYearValue(creationDate)}
      />
      <CustomCardMedia key={id} src={coverImageUrl ?? ImageNotFound} alt={title} height="180px" />
      <CardContent>
        <NewsCardFooterText variant="body2">{textReload(subtitle)}</NewsCardFooterText>
      </CardContent>
    </NewsCardWrapper>
  );
};

export default NewsCard;
