import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';

import SweetSansProBold from '../../assets/fonts/SweetSansPro/SweetSansPro-Bold.ttf';
import SweetSansProMedium from '../../assets/fonts/SweetSansPro/SweetSansPro-Medium.ttf';
import SweetSansProRegular from '../../assets/fonts/SweetSansPro/SweetSansPro-Regular.ttf';
import TimesNowLight from '../../assets/fonts/TimesNow/TimesNow-Light.ttf';
import TimesNowExtraLight from '../../assets/fonts/TimesNow/TimesNow-ExtraLight.ttf';

const baseTheme = createTheme({
  palette: {
    error: {
      main: red.A400,
    },
    mode: 'light',
    primary: {
      main: '#006341',
      // main: 'rgb(0, 20, 65,0.76)',
      // main: '#7367f0',
    },
    secondary: {
      main: '#000000',
    },
    background: {
      default: '#f3f3f4',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: "TimesNow";
        font-style: italic;
        font-weight: 400;
        font-display:"swap";
        src: url("${TimesNowLight}") format("truetype");
      }
      @font-face {
        font-family: "TimesNow";
        font-style: italic;
        font-weight: 200;
        font-display:"swap";
        src: url("${TimesNowExtraLight}") format("truetype");
      }
      @font-face {
        font-family: "SweetSansPro";
        font-style: normal;
        font-weight: 500;
        font-display:"swap";
        src: url("${SweetSansProMedium}") format("truetype");
      }
      @font-face {
        font-family: "SweetSansPro";
        font-style: normal;
        font-weight: 400;
        font-display:"swap";
        src: url("${SweetSansProRegular}") format("truetype");
      }
      @font-face {
        font-family: "SweetSansPro";
        font-style: normal;
        font-weight: 600;
        font-display:"swap";
        src: url("${SweetSansProBold}") format("truetype");
      }
      @font-face {
        font-family: "SweetSansPro";
        font-style: normal;
        font-weight: 700;
        font-display:"swap";
        src: url("${SweetSansProBold}") format("truetype");
      }
      `,
    },
  },
  typography: {
    fontFamily: ['AvenirNext', 'Roboto', 'Helvetica', 'Arial', 'serif'].join(','),
  },
});

export default baseTheme;
