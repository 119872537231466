import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { RejectContainerProps } from '../types';
import { CloseConfirmationModal } from './CloseConfirmationModal';
import Reject from './Reject';

const RejectContainer = ({ fnMessageSuccess, resource, fnReject, closeModal, openModal, id }: RejectContainerProps) => {
  const { t } = useTranslation();
  const [note, setNote] = useState('');
  const [error, setError] = useState('');
  const [openModalCloseConfirmation, setOpenModalCloseConfirmation] = useState(false);
  const handleOpenModalCloseConfirmation = () => setOpenModalCloseConfirmation(!openModalCloseConfirmation);
  const { mutate, isLoading } = useMutation(fnReject, {
    onSuccess: async () => {
      fnMessageSuccess(t('success_message_in_reject_modal', { ns: 'cdtModal' }));
    },
    onError: async () => {
      setError(t('error'));
    },
  });

  const handleSubmit = () => {
    if (note.length < 1) return setError(t('required_field', { ns: 'errors' }));
    setError('');
    mutate({ id, note });
  };

  const childProps = {
    note,
    setNote,
    error,
    isLoadingSubmit: isLoading,
    closeModal,
    resource,
    openModal,
    handleSubmit,
    handleOpenModalCloseConfirmation,
  };
  return openModalCloseConfirmation ? (
    <CloseConfirmationModal
      openCloseConfirmation={openModalCloseConfirmation}
      handleBackToReject={handleOpenModalCloseConfirmation}
      handleCloseConfirmation={closeModal}
    />
  ) : (
    <Reject {...childProps} />
  );
};

export default RejectContainer;
