import axiosAnonInstance from '../instances/anonInstances';
import { axiosAuthenticationInstance } from '../instances/authInstance';
import {
  ChangePasswordRequest,
  FirebaseLoginRequest,
  ForgotPasswordConfirmationRequest,
  LoginRequest,
} from '../types/types';

export const login = (req: LoginRequest) => axiosAnonInstance.post('accounts/v1/admins/login', req);
export const forgotPassword = (req: { email: string }) =>
  axiosAnonInstance.post(`accounts/v1/admins/forgot-password`, req);
export const forgotPasswordConfirmation = (req: ForgotPasswordConfirmationRequest) =>
  axiosAnonInstance.post(`accounts/v1/admins/forgot-password/confirmation`, req);
export const changePassword = (req: ChangePasswordRequest) =>
  axiosAuthenticationInstance.post('accounts/v1/admins/change-password', req);
export const logout = (deviceId: string) => axiosAuthenticationInstance.post('accounts/v1/admins/logout', { deviceId });
export const firebaseLogin = (req: FirebaseLoginRequest) =>
  axiosAuthenticationInstance.put(`notifications/v1/user-devices/${req.deviceId}`, req.body);
