import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Box, Button, DialogContentText, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { sendToPrint } from '@/src/api/endpoints/packages';
import { PrintLabelProps } from '../../types';
import { ErrorHelper } from '@/src/components/error/error-helper/ErrorHelper';

const PrintLabel = (props: PrintLabelProps) => {
  const { t } = useTranslation();
  const { row, close, setSnackBarMessage } = props;
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const { mutate, isLoading } = useMutation(sendToPrint, {
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('success'));
    },
    onError: async (e: any) => {
      setErrorMessage(
        t(e.response.data.error_messages[0]?.code, {
          ns: 'errors',
        }),
      );
    },
  });
  return (
    <Grid>
      <DialogContentText py={1}>
        {t('print_package_label_header_text', { ns: 'packages', id: row.internal_code })}
      </DialogContentText>
      <Grid item xs={12} sm={8} md={6}>
        <ErrorHelper error={errorMessage} />
      </Grid>
      <Box component="div" sx={{ widht: '100%', textAlign: 'right', pt: 1 }}>
        <Button size="small" type="button" variant="outlined" color="secondary" onClick={close} sx={{ mr: 1 }}>
          {t('delete_dialog_cancel_button_text')}
        </Button>
        <LoadingButton
          loading={isLoading}
          size="small"
          type="button"
          variant="contained"
          onClick={() => mutate(row.internal_code as number)}
        >
          {t('print')}
        </LoadingButton>
      </Box>
    </Grid>
  );
};

export default PrintLabel;
