import getEnvVariables from '../utilities/helpers/getEnvVariables';
import EventBackgroundPattern from './common/logos/eventBackgroundPattern.svg';
import pdfIconImage from './common/logos/pdfFile.svg';
import { grit } from './grit';
import { panorama } from './panorama';
import { beachwalk } from './beachwalk';
import { ora } from './ora';
import { nexo } from './nexo';
import { rider } from './rider';

const { VITE_TENANT_NAME } = getEnvVariables();

export const useExportAssets = () => {
  const objAssets: {
    [key: string]: any;
  } = {
    ora,
    grit,
    panorama,
    beachwalk,
    nexo,
    rider,
  };

  const client = objAssets[VITE_TENANT_NAME];
  const { backgroundPic, mainLogo, whiteLogo, headLogo } = client;
  const EventBackgroundPatternImage = EventBackgroundPattern;
  const pdfIcon = pdfIconImage;
  return { backgroundPic, mainLogo, whiteLogo, headLogo, EventBackgroundPatternImage, pdfIcon };
};
