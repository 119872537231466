import { Box, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SectionProps {
  title: string;
  mt?: number | { xs?: number; sm?: number; md?: number; lg?: number };
  mb?: number | { xs?: number; sm?: number; md?: number; lg?: number };
}

export const CustomText = ({
  title,
  value,
  resource = 'guests',
}: {
  title: string;
  value: string | number;
  resource?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        gap: { xs: '5px', md: '20px' },
        justifyContent: 'flex-start',
      }}
    >
      <Typography variant="subtitle2" component="span" fontSize="13px" fontWeight="600">
        {t(title, { ns: resource })}:
      </Typography>
      <Typography
        variant="body2"
        component="span"
        fontSize="14px"
        fontWeight="400"
        sx={{ textTransform: 'capitalize' }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export const SectionTitle = ({ title, mt = 0, mb = 0.5 }: SectionProps) => {
  const { t } = useTranslation();
  return (
    <Box component="div" textAlign="left" sx={{ mb, mt }}>
      <Typography fontSize="16px" fontWeight="600">
        {t(title, { ns: 'guests' })}
      </Typography>
      <Divider sx={{ mt: '2px', mb: '5px' }} />
    </Box>
  );
};
