import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import DeleteRelative from './DeleteRelative';
import { DataTableFormModal } from '@/src/components/custom-data-table/types/DataTableFormModal';
import { deleteResidencyRelative } from '@/src/api/endpoints/houseHold';

const DeleteRelativeContainer = (props: DeleteRelativeContainerProps) => {
  const { row, setSnackBarMessage, close } = props;
  const { t } = useTranslation();
  const { mutate, isLoading } = useMutation(deleteResidencyRelative, {
    onSuccess: async () => {
      setSnackBarMessage(t('success_resident_relative_delete', { ns: 'residents' }));
      close();
    },
    onError: async () => {
      setSnackBarMessage(t('error'), 'error');
    },
  });

  const onDelete = () => {
    mutate({ relativeId: row?.relative.id, residencyId: row?.residency.id });
  };

  const childProps = {
    onDelete,
    handleClose: close,
    t,
    isLoading,
  };

  return <DeleteRelative {...childProps} />;
};

interface DeleteRelativeContainerProps extends DataTableFormModal {
  row: any;
}

export default DeleteRelativeContainer;
