import React from 'react';
import { Visibility } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';
import { VehiclesDetailModal } from './vehicles-detail/VehiclesDetail';
import { ActionColumn, CustomIconProps } from '../../../components/custom-data-table/types/DataTableFormModal';

const actionColumns: Array<ActionColumn> = [
  {
    id: 'detail',
    icon: ({ sx }: CustomIconProps) => <Visibility sx={sx} />,
    component: (props: any) => <VehiclesDetailModal {...props} showButtons />,
    customizedTitle: true,
    width: { xs: '90%', sm: '540px' },
  },
];

export const VehiclesRequestsPage = () => {
  const { columnsVehiclesRequest } = useColumns();
  const { t } = useTranslation();
  return (
    <CustomDataTable
      title={t('vehicles_requests', { ns: 'customerUsers' })}
      columns={columnsVehiclesRequest}
      resource="vehicle_residencies"
      pathApi="vehicles/v1/vehicle-residencies"
      listName="vehicle_residencies"
      extraFilters={[
        { query: 'VehicleStateCodes', queryValue: 'InReview' },
        { query: 'VehicleStateCodes', queryValue: 'Rejected' },
      ]}
      canExport
      canSearch
      customActionColumns={actionColumns}
      defaultSortFieldId="vehicle_creation_date"
      defaultSortAsc
    />
  );
};
