import { useState, useCallback } from 'react';
import { AlertColor } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import DeactivateModal from './DeactivateModal';
import useDialog from '@/src/hooks/common/useDialog';
import { validate } from '../MfaModal/MfaModal.validate';
import { BodyDisableAuthenticationUser } from '../types';
import { disableAuthentication } from '@/src/api/endpoints/accounts-security';

const DeactivateModalContainer = (props: any) => {
  const { refetchMfa } = props;
  const { t } = useTranslation();
  const { isOpen, open, close } = useDialog();
  const [errorMessage, setErrorMessage] = useState<{ code: string; message: string; property_name: string } | null>(
    null,
  );
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  const setSnackBarMessage = useCallback((msj: string, sever: AlertColor = 'success') => {
    setOpenSnack(true);
    setSeverity(sever);
    setMessage(msj);
  }, []);

  const closeModal = () => {
    setOpenSnack(false);
  };

  const { mutate, isLoading } = useMutation(disableAuthentication, {
    onError: async (e: any) => {
      setErrorMessage(e.response.data.error_messages[0]);
    },
    onSuccess: async () => {
      close();
      refetchMfa();
      setSnackBarMessage(t('successful_user_des_authentication_snackbar_text', { ns: 'security' }));
    },
  });

  const getInitialValues = () => ({
    code: '',
  });

  const onSubmit = (data: FormikValues) => {
    const body: BodyDisableAuthenticationUser = {
      code: data.code,
    };
    mutate(body);
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate({ t }),
  });

  const childProps = {
    isOpen,
    open,
    close,
    errorMessage,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    isLoading,
    openSnack,
    closeModal,
    message,
    severity,
  };

  return <DeactivateModal {...childProps} />;
};

export default DeactivateModalContainer;
