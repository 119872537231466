import headLogo from './logos/headLogo.png';
import mainLogo from './logos/mainLogo.png';
import whiteLogo from './logos/whiteLogo.png';
import loginBackground from './images/loginBackground.jpg';

export const nexo = {
  headLogo,
  mainLogo,
  whiteLogo,
  backgroundPic: loginBackground,
};
