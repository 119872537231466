import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import LoginOTP from './LoginOTP';
import { useLogin } from '@/src/hooks/auth/useLoginHandler';

export const LoginOTPContainer = () => {
  const location = useLocation();
  const {
    onLoginOTP,
    isLoadingLoginOTP: isLoading,
    errorMessage,
    noUserAllowedErrorMessage,
  } = useLogin({
    prevUrl: location.state?.from,
  });

  const { t } = useTranslation();
  // FORMIK
  const getInitialValues = () => ({
    email: location.state?.email ?? '',
    code: '',
    session: location.state?.session,
  });
  const getValidationSchema = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        email: Yup.string()
          .email(t('yup_required_email'))
          .required(t('required_field', { ns: 'errors' }))
          .trim(),
        code: Yup.string().required(t('required_field', { ns: 'errors' })),
      }),
    );

  const { handleSubmit, values, setFieldValue } = useFormik({
    initialValues: getInitialValues(),
    onSubmit: (data: { email: string; code: string; session: string }) => {
      onLoginOTP(data);
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema(),
  });

  const childProps = {
    errorMessage: errorMessage || noUserAllowedErrorMessage,
    handleSubmit,
    isLoading,
    setFieldValue,
    t,
    values,
  };

  return <LoginOTP {...childProps} />;
};
