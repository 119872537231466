import { useQuery } from '@tanstack/react-query';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getStates } from '@/src/api/endpoints/residents';

const useResidencyStates = () => {
  const getResidencyStatesMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.code,
          query: 'ResidencyStateCodes',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: residencyStates, isLoading } = useQuery(['getStates'], getStates, {
    select: (response) => getResidencyStatesMapper(response?.data?.residency_states),
  });

  return { residencyStates, isLoading };
};

export default useResidencyStates;
