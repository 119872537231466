import { styled } from '@mui/material';

export const CustomInputStyles = (disabled: any) => {
  const styles = {
    input: {
      WebkitTextFillColor: disabled ? 'rgba(0,0,0,0.6) !important' : '',
      color: disabled ? 'rgba(0,0,0,0.7) !important' : '',
      opacity: disabled ? 0.7 : '',
      backgroundColor: disabled ? 'rgba(0,0,0,0.05) ' : '',
    },
    label: {
      color: disabled ? 'rgba(0,0,0,1) !important' : '',
    },
  };
  return styles;
};

export const Label = styled('label')(({ disabled, errorHelper }: { disabled: any; errorHelper: any }) => ({
  display: 'flex',
  position: 'relative',
  padding: '8.5px 14px',
  border: '1px solid',
  borderColor: errorHelper && !disabled ? '#ff1744' : 'rgba(0, 0, 0, 0.23)',
  borderRadius: '4px',
  background: disabled ? 'rgba(0,0,0,0.05)' : '',
  '&:hover': {
    borderColor: disabled || errorHelper ? '' : 'rgb(47 47 47)',
  },
  '&:focus-visible': {
    border: '1px solid red',
  },
  span: {
    position: 'absolute',
    top: '-11px',
    left: '8px',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '1.4375em',
    background: disabled ? 'linear-gradient(to top, rgba(0,0,0,-0.95), white)' : 'white',
    color: disabled ? 'rgba(0,0,0,1)' : '',
    padding: '0 4px',
    zIndex: 1,
    width: 'max-content',
  },
  input: { width: '100%', border: 'none', outline: 'none', height: '1.4375em', font: 'inherit' },
}));

export const TextHelper = styled('p')(() => ({
  color: '#ff1744',
  fontWeight: '400',
  fontSize: '0.75rem',
  lineHeight: '1.66',
  textAlign: 'left',
  margin: '4px 14px 0 14px',
}));
