import * as Yup from 'yup';
import { TProp } from '@/src/utilities/helpers/commonTypes';

export const validate = ({ t }: TProp) =>
  Yup.lazy(() =>
    Yup.object().shape({
      carrierName: Yup.string().required(t('required_field', { ns: 'errors' })),
      propertyCode: Yup.string().required(t('required_field', { ns: 'errors' })),
    }),
  );
