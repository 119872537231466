import { Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Switch } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { Form, FormikContextType, FormikProvider } from 'formik';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { CustomDatePicker } from '../date-picker/DatePicker';
import { handlerDateSelector } from '@/src/utilities/helpers/utils';
import { SubmitButton } from './PublicationsFilter.styled';

const PublicationsFilter = (props: Props) => {
  const { t, formik, openModal, handleClose, isRestricted } = props;
  const { values, errors, setFieldValue } = formik;

  return (
    <Dialog open={openModal} onClose={handleClose} fullWidth maxWidth="sm" disableEnforceFocus>
      <FormikProvider value={formik}>
        <Form>
          <DialogTitle>
            {t('filter')}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1} paddingTop="20px">
              <Grid item xs={12} sm={6} md={6}>
                <CustomDatePicker
                  value={values?.creationDateStart}
                  maxDate={new Date()}
                  onChange={(newValue: any): void => handlerDateSelector(newValue, setFieldValue, 'creationDateStart')}
                  fieldValue="creationDateStart"
                  placeholder={t('creation_date_start')}
                  errorMessage={errors?.creationDateStart as string}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomDatePicker
                  value={values?.creationDateEnd}
                  maxDate={new Date()}
                  onChange={(newValue: any): void => handlerDateSelector(newValue, setFieldValue, 'creationDateEnd')}
                  fieldValue="creationDateEnd"
                  placeholder={t('creation_date_end')}
                  errorMessage={errors?.creationDateEnd as string}
                />
              </Grid>
            </Grid>
            {isRestricted && (
              <Grid container spacing={1} paddingTop="20px">
                <Grid item xs={12} sm={6} md={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => {
                          setFieldValue('isRestricted', !values?.isRestricted);
                        }}
                        checked={values?.isRestricted}
                        value={values?.isRestricted}
                        color="warning"
                      />
                    }
                    label={t('is_restricted')}
                  />
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <Grid container justifyContent="flex-end" p="0px 24px 20px">
            <Grid item>
              <SubmitButton variant="contained" type="submit">
                {t('filter_search')}
              </SubmitButton>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

const propTypes = {
  t: PropTypes.func.isRequired,
};

interface ExtraProps {
  openModal: boolean;
  handleClose: () => void;
  formik: FormikContextType<any>;
  isRestricted?: boolean | undefined;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
PublicationsFilter.propTypes = propTypes;

export default PublicationsFilter;
