import { axiosAuthenticationInstance, axiosAuthenticationInstanceMultipart } from '../instances/authInstance';
import { CreateImageFileRequest, PrefetchVideoUrlRequest } from '../types/types';

export const getsAllCounters = (abort: any) => axiosAuthenticationInstance.get('utils/v1/counters/bo', abort);
export const createImageFile = (req: CreateImageFileRequest) =>
  axiosAuthenticationInstance.post(`/utils/v1/files/images`, req);
export const createNewDocument = (req: any) =>
  axiosAuthenticationInstanceMultipart.post('/utils/v1/files/documents/form-data', req);
export const preFetchVideoUrl = (req: PrefetchVideoUrlRequest) =>
  axiosAuthenticationInstance.post('/utils/v1/files/presigned-url', req);
