import React from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

interface ConfirmDialogProp {
  title?: string;
  contentText: string;
  open: boolean;
  onConfirm: () => void;
  handleClose: () => void;
  isLoading?: boolean;
}

const ConfirmDialog = ({ title, contentText, open, handleClose, onConfirm, isLoading = false }: ConfirmDialogProp) => {
  const { t } = useTranslation();

  const handleSubmit = () => {
    onConfirm();
    handleClose();
  };

  return (
    <Dialog
      open={open || isLoading}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" size="small" onClick={handleClose}>
          {t('delete_dialog_cancel_button_text')}
        </Button>
        <LoadingButton size="small" variant="contained" loading={isLoading} onClick={handleSubmit}>
          {t('delete_dialog_submit_button_text')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
