import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { FormControlLabel, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useBreakpoints from '@/src/hooks/common/useBreakpoints';

interface DisableFilterProps {
  disabled: boolean;
  isLoading?: boolean;
  setShowDisabled: any;
  resource: string;
}
const DisableFilter = ({ disabled, isLoading, setShowDisabled, resource }: DisableFilterProps) => {
  const { t } = useTranslation();
  const { matchesSm } = useBreakpoints();
  const iconStyles = {
    backgroundColor: 'transparent',
    border: '1px solid primary',
    color: isLoading ? 'rgba(0, 0, 0, 0.26)' : 'primary.main',
    borderRadius: '4px',
    marginLeft: matchesSm ? '3px' : '0px',
    ':hover': {
      textDecoration: 'none',
    },
  };
  return (
    <FormControlLabel
      disabled={isLoading}
      control={
        <IconButton disabled={isLoading} onClick={() => setShowDisabled((prevState: boolean) => !prevState)}>
          {disabled ? <VisibilityOffRoundedIcon sx={iconStyles} /> : <VisibilityRoundedIcon sx={iconStyles} />}
        </IconButton>
      }
      label={disabled ? t(`hide_${resource.replaceAll('-', '_')}`) : t(`show_${resource.replaceAll('-', '_')}`)}
    />
  );
};

export default DisableFilter;
