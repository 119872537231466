import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

export const PackagesPage = () => {
  const { t } = useTranslation();
  const { columns, actionColumns, headerActions } = useColumns();

  return (
    <CustomDataTable
      title={t('packages_page_title', { ns: 'packages' })}
      columns={columns}
      resource="packages"
      pathApi="packaging/v1/packages"
      listName="packages"
      extraActionsInHeader={headerActions}
      canSearch
      canExport
      defaultSortFieldId="internal_code"
      defaultSortAsc={false}
      customActionColumns={actionColumns}
    />
  );
};
