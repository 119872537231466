import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { ModalCheckOutProps } from '../types';
import TitleWithStatus from '@/src/components/title-with-status/TitleWithStatus';

export const CheckOutComponent = ({ id, onConfirm, handleClose }: ModalCheckOutProps) => {
  const { t } = useTranslation();

  return (
    <>
      <TitleWithStatus title={t('force_checkout')} statusText="" statusBg="" addMargin={false} onClose={handleClose} />
      <Typography id="modal-modal-description" sx={{ py: 1 }}>
        {t('leave_residence', { resource: t('residence') })}
      </Typography>
      <Box component="div" sx={{ widht: '100%', textAlign: 'right', pt: 1 }}>
        <Button size="small" type="button" variant="outlined" color="secondary" onClick={handleClose} sx={{ mr: 1 }}>
          {t('delete_dialog_cancel_button_text')}
        </Button>
        <Button size="small" type="button" variant="contained" onClick={() => onConfirm(id)}>
          {t('delete_dialog_submit_button_text')}
        </Button>
      </Box>
    </>
  );
};
