import { useQuery } from '@tanstack/react-query';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getInvitationStatus } from '@/src/api/endpoints/guests';

const useInvitationStatusFilters = () => {
  const getInvitationStatusMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.description,
          value: item.id,
          query: 'InvitationStatusIds',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: invitationStatus, isLoading } = useQuery(['getInvitationStatusMapper'], getInvitationStatus, {
    select: (response) => getInvitationStatusMapper(response?.data?.invitation_status),
  });

  return { invitationStatus, isLoading };
};

export default useInvitationStatusFilters;
