import { FormControlLabel, MenuItem, Switch } from '@mui/material';
import { FilterByColumn } from '../../../types/CustomDataTableColumnProps';

const FilterBoolean = (props: FilterBooleanProps) => {
  const { filter, handleFilters, trueInput, falseInput } = props;

  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  return (
    <MenuItem>
      <FormControlLabel
        control={
          <Switch
            {...label}
            onChange={() => {
              handleFilters(filter.query, !trueInput, 'trueInput');
            }}
            checked={trueInput}
            value={trueInput}
            color="warning"
          />
        }
        label={Array.isArray(filter?.label) && filter?.label[0]}
      />
      <FormControlLabel
        control={
          <Switch
            {...label}
            onChange={() => {
              handleFilters(filter.query, !falseInput, 'falseInput');
            }}
            checked={falseInput}
            value={falseInput}
            color="warning"
          />
        }
        label={Array.isArray(filter?.label) && filter?.label[1]}
      />
    </MenuItem>
  );
};

interface FilterBooleanProps {
  filter: FilterByColumn;
  handleFilters: (query: string, value: any, input: string) => void;
  trueInput: boolean;
  falseInput: boolean;
}

export default FilterBoolean;
