import { useTranslation } from 'react-i18next';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { Category } from './types';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';
import { ActionHeader } from '../../residents/residents-detail-section/types';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';

const CategoriesPage = (props: Props) => {
  const { columns, actionColumns, headerActions } = props;
  const { t } = useTranslation();

  return (
    <CustomDataTable
      title={t('categories')}
      columns={columns}
      resource="event_categories"
      pathApi="feed/v1/event-categories"
      listName="event_categories"
      canExport
      canSearch
      canDelete
      defaultSortFieldId="name"
      defaultSortAsc
      extraActionsInHeader={headerActions}
      customActionColumns={actionColumns}
    />
  );
};

const propTypes = {};

const defaultProps = {};

interface ExtraProps {
  columns: CustomDataTableColumnsProps<Category>[];
  actionColumns: ActionColumn[];
  headerActions: ActionHeader[];
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
CategoriesPage.propTypes = propTypes;
CategoriesPage.defaultProps = defaultProps;

export default CategoriesPage;
