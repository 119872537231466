import { TextField } from '@mui/material';
import React from 'react';
import { CustomInputStyles } from './styles';

const CustomInput = React.forwardRef((props: any, ref) => {
  const {
    field,
    values,
    noError,
    errors,
    setFieldValue,
    autoFocus,
    disabled,
    label,
    placeholder,
    value,
    regex,
    maxLength,
    required,
    style,
    size = 'small',
    inputProps,
    inputType,
  } = props;
  return (
    <TextField
      inputRef={ref}
      autoFocus={autoFocus}
      fullWidth
      id={`${field}`}
      label={`${label}${required ? ' * ' : ''}`}
      type={inputType ?? 'name'}
      value={value ?? values[field]}
      onChange={({ target }: any) => {
        setFieldValue(`${field}`, regex ? target.value.replace(regex, '') : target.value);
      }}
      sx={CustomInputStyles(disabled)}
      error={!noError && errors[field] !== undefined}
      helperText={!noError && errors[field]?.toString()}
      placeholder={placeholder}
      size={size}
      variant="outlined"
      inputProps={{
        maxLength: maxLength ?? '',
        style: { ...style },
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        ...inputProps,
      }}
      disabled={disabled}
      autoComplete="no"
    />
  );
});
CustomInput.displayName = 'CustomInput';

export default CustomInput;
