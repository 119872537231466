import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: '2px',
  margin: '5px',
  width: '78px',
  background: 'white',
  '&:hover': {
    color: 'white',
    background: `${theme.palette.secondary.main}`,
  },
}));

export const ApartmentContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  background: theme.palette.primary.main,
  borderRadius: '8px',
  padding: '10px',
  justifyContent: 'center',
  div: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
