import { Box } from '@mui/material';
import { AvailabilitiesSection } from './availabilities-section/AvailabilitiesSection';
import { ActivitiesSection } from './activities-section/ActivitiesSection';
import { useActivitiesPage } from './useActivitiesPage';

export const ActivitiesPage = () => {
  const { subGridIsOpen, selectedRow, openSubGrid, closeSubGrid } = useActivitiesPage();

  return (
    <Box>
      {!subGridIsOpen ? (
        <ActivitiesSection openSubGrid={openSubGrid} />
      ) : (
        selectedRow && <AvailabilitiesSection row={selectedRow} closeSubGrid={closeSubGrid} />
      )}
    </Box>
  );
};
