import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnableComponent } from './EnableComponent';
import useDisable from '@/src/components/custom-data-table/hooks/useDisable';
import useSnackBar from '@/src/components/custom-data-table/useSnackBar';
import { PageStateProps } from '../../types';

interface CanDisableProps {
  id: number;
  resource: string;
  isDisabled: boolean;
  fake?: boolean;
  close: any;
}

const EnableComponentContainer = ({ id, close, resource, isDisabled, fake }: CanDisableProps) => {
  const [disableUserInformation, setDisableUserInformation] = useState<PageStateProps['disableUserInfo'] | null>(null);
  const { t } = useTranslation();
  const { mutateDisable } = useDisable(resource, isDisabled, fake);
  const { setSnackBarMessage } = useSnackBar();

  const onDisable = (elementId: number) =>
    mutateDisable(elementId, {
      onSuccess: () => {
        setSnackBarMessage(
          t(isDisabled ? 'successful_enabled_snackbar_text' : 'successful_disabled_snackbar_text', {
            item: t('customers_modal_text'),
          }),
        );
      },
      onError: () => {
        setSnackBarMessage(
          t(isDisabled ? 'error_enabled_snackbar_text' : 'error_disabled_snackbar_text', {
            item: t('customers_modal_text'),
          }),
          'error',
        );
      },
    });

  useEffect(() => {
    setDisableUserInformation({ idProp: id });
  }, [id]);

  const handleCloseDisable = () => {
    setDisableUserInformation(null);
    close();
  };

  const onConfirm = ({ idProp }: { idProp: number }) => {
    onDisable(idProp);
  };

  const childProps = {
    values: disableUserInformation,
    onConfirm,
    handleClose: handleCloseDisable,
  };

  return <EnableComponent {...childProps} />;
};

export default EnableComponentContainer;
