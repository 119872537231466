import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

export const ParkingGuestsPage = () => {
  const { t } = useTranslation();
  const { columns, actionColumns, headerActions } = useColumns();
  return (
    <CustomDataTable
      title={t('guests_parking_page_title', { ns: 'parking' })}
      columns={columns}
      resource="guest-parking"
      pathApi="valet-parking/v1/guest-parking"
      listName="guest_parking"
      defaultSortFieldId="internal_code"
      defaultSortAsc={false}
      canSearch
      canExport
      customActionColumns={actionColumns}
      extraActionsInHeader={headerActions}
    />
  );
};
