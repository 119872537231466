import React from 'react';
import { useTranslation } from 'react-i18next';
import { Delete, Visibility, QrCode2 } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button, Checkbox } from '@mui/material';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import TextLabel from '@/src/components/text-label/TextLabel';
import { ActionColumn, ActionHeader } from '@/src/components/custom-data-table/types/DataTableFormModal';
import DeletePackage from './components/delete-package/DeletePackage';
import InfoPackage from './components/info-package/InfoPackage';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import LoadPackageFormContainer from './components/load-package-form/LoadPackageForm.container';
import DeliverPackageFormContainer from './components/deliver-package/DeliverPackage.container';
import LoadRackPackageFormContainer from './components/load-rack-package/LoadRackPackage.container';
import { Package, packagesStates } from './types';
import {
  getDayHourYearDateEn,
  getDayHourYearDateEs,
  getFormattedDateByLanguague,
  getFormattedDateFullYearEs,
  getFormattedDateFullYearEn,
} from '@/src/utilities/helpers/dateParser';
import PrintLabel from './components/print-label/PrintLabel';
import { getLanguage } from '@/src/utilities/storage';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';
import usePackageStatesFilters from '@/src/hooks/filters/packageStates.hooks';

export const useColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];

  const { apartments } = useApartmentsFilters();
  const { packagesStates: packageStatesFilters } = usePackageStatesFilters();

  const columns: CustomDataTableColumnsProps<Package>[] = [
    {
      id: 'creation_date',
      sortField: 'creation_date',
      name: t('packages_page_table_column_creation_date', { ns: 'packages' }),
      selector: (row: Package) =>
        row?.creation_date
          ? getFormattedDateByLanguague(lang, getDayHourYearDateEs, getDayHourYearDateEn, row?.creation_date)
          : '-',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'creation-date',
          query: 'CreationDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'property_code',
      sortField: 'property_code',
      name: t('modal_load_package_input_property_code', { ns: 'packages' }),
      selector: (row: Package) => row.property.code,
      sortable: true,
      center: true,
      width: lang === 'en' ? '100px' : '150px',
      filters: apartments,
    },
    {
      id: 'delivered_to',
      name: t('packages_page_table_column_delivered_to', { ns: 'packages' }),
      selector: (row: Package) => row.delivered_to ?? '-',
      sortField: 'delivered_to',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'delivered_to',
          query: 'DeliveredTo',
          type: 'contains',
        },
      ],
    },
    {
      id: 'internal_code',
      sortField: 'id',
      name: t('packages_page_table_column_idInternal', { ns: 'packages' }),
      selector: (row: Package) => row.internal_code,
      sortable: true,
      center: true,
      maxWidth: '1%',
      filters: [
        {
          id: 'internal_code',
          query: 'PackageId',
          type: 'numeric',
        },
      ],
    },
    {
      id: 'package_code',
      sortField: 'package_code',
      name: t('packages_page_table_column_packageCode', { ns: 'packages' }),
      selector: (row: Package) => row.package_code,
      sortable: true,
      center: true,
      width: lang === 'en' ? '150px' : '200px',
      filters: [
        {
          id: 'package_code',
          query: 'PackageCode',
          type: 'contains',
        },
      ],
    },
    {
      id: 'rack',
      sortField: 'location',
      name: t('rack', { ns: 'packages' }),
      selector: (row: Package) => (row.location ? row.location : '-'),
      sortable: true,
      center: true,
      width: '125px',
      filters: [
        {
          id: 'package_location',
          query: 'PackageLocation',
          type: 'contains',
        },
      ],
    },
    {
      cell: (row: Package) => <Checkbox checked={row.is_expirable} disabled sx={{ padding: '0px' }} />,
      id: 'is_expirable',
      sortField: 'is_expirable',
      name: t('Perishable', { ns: 'packages' }),
      selector: (row: Package) => row.is_expirable,
      sortable: false,
      center: true,
      width: '180px',
    },
    {
      id: 'status',
      sortField: 'status',
      cell: (row: Package) => (
        <TextLabel
          text={row.package_state.name.toLocaleUpperCase()}
          color="#F7F7F7"
          padding="2px 12px"
          width="100px"
          bg={packagesStates[row.package_state.code]}
        />
      ),
      name: t('packages_page_table_column_status', { ns: 'packages' }),
      selector: (row: Package) => row.package_state.code.toLocaleUpperCase(),
      sortable: true,
      center: true,
      minWidth: '80px',
      filters: packageStatesFilters,
    },
    {
      id: 'delivery_date_time',
      name: t('packages_page_table_column_delivery_date_time', { ns: 'packages' }),
      selector: (row: Package) =>
        row.delivery_date
          ? getFormattedDateByLanguague(lang, getFormattedDateFullYearEs, getFormattedDateFullYearEn, row.delivery_date)
          : '-',
      sortField: 'delivery_date',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'delivery-date',
          query: 'DeliveryDate',
          type: 'date',
        },
      ],
    },
  ];

  const headerActions: ActionHeader[] = [
    {
      id: 'packages-create',
      component: () => (
        <CustomTableAction
          modalTitle="packages"
          component={(props: any) => <LoadPackageFormContainer {...props} />}
          Icon={() => <AddIcon />}
          type="create"
          width={{ xs: '90%', sm: '530px' }}
        />
      ),
    },
    {
      id: 'packages-load-rack',
      component: () => (
        <CustomTableAction
          modalTitle="load_rack_packages"
          component={(props: any) => <LoadRackPackageFormContainer {...props} />}
          Icon={() => <AddIcon />}
          ButtonElement={({ open }: { open: () => void }) => (
            <Button type="button" variant="outlined" onClick={() => open()}>
              {t('packages_page_load_rack_package_button_text', { ns: 'packages' })}
            </Button>
          )}
          type="btn_to_grid"
          width={{ xs: '90%', lg: '75%', xl: '45%' }}
        />
      ),
    },
    {
      id: 'deliver-package',
      component: () => (
        <CustomTableAction
          modalTitle="packages"
          component={(props: any) => <DeliverPackageFormContainer {...props} />}
          Icon={() => <AddIcon />}
          width={{ xs: '90%', sm: '80%', md: '60%', lg: '800px' }}
          ButtonElement={({ open }: { open: () => void }) => (
            <Button type="button" variant="outlined" onClick={() => open()}>
              {t('packages_page_delivery_package_button_text', { ns: 'packages' })}
            </Button>
          )}
          type="btn_to_grid"
        />
      ),
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'detail',
      icon: (props: any) => <Visibility sx={props.sx} />,
      customizedTitle: true,
      component: (props: any) => <InfoPackage {...props} />,
      width: { xs: '96%', sm: '560px' },
    },
    {
      id: 'print-label',
      icon: (props: any) => <QrCode2 sx={props.sx} />,
      component: (props: any) => <PrintLabel {...props} />,
      width: { xs: '90%', sm: '20%' },
    },
    {
      id: 'delette',
      icon: (props: any) => <Delete sx={props.sx} />,
      component: (props: any) => <DeletePackage {...props} />,
      isHidden: (row: any) => row.package_state.code === 'Delivered',
      width: '18%',
    },
  ];

  return {
    columns,
    headerActions,
    actionColumns,
  };
};
