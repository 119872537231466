import { useQuery } from '@tanstack/react-query';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getFuelTypes } from '@/src/api/endpoints/houseHold';

const useVehicleFuelTypes = () => {
  const getVehicleFuelTypesMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.id,
          query: 'VehicleFuelTypeIds',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: fuelTypes, isLoading } = useQuery(['getVehicleFuelType'], getFuelTypes, {
    select: (response) => getVehicleFuelTypesMapper(response?.data?.fuel_types),
  });

  return { fuelTypes, isLoading };
};

export default useVehicleFuelTypes;
