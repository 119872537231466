import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterColumnHour from './FilterHour';
import { FilterByColumn } from '../../../types/CustomDataTableColumnProps';

const FilterColumnHourContainer = (props: FilterColumnDateContainerProps) => {
  const { setLocalFilterString, filter, localFilterString } = props;
  const { t } = useTranslation('cdtModal');
  const [hourStartSelected, setHourStartSelected] = useState('');
  const [hourEndSelected, setHourEndtSelected] = useState('');

  const getInitialValues = () => {
    const variables = localFilterString.split('&');
    variables.shift;
    variables.forEach((v) => {
      if (v.includes(`${filter.query}Start`)) {
        setHourStartSelected(v.split('=')[1]);
      } else if (v.includes(`${filter.query}End`)) {
        setHourEndtSelected(v.split('=')[1]);
      }
    });
  };

  React.useEffect(() => {
    getInitialValues();
  }, []);

  const valueHandler = (query: string, value: number | string) => {
    setLocalFilterString((prev) => {
      const selectedValue = `&${query}=${value}`;
      if (prev.includes(query)) {
        const variables = prev.split('&');
        variables.shift();
        let str = '';
        variables.forEach((i) => {
          if (i.includes(query)) {
            str += `&${query}=${value}`;
          } else if (i !== '') {
            str += `&${i}`;
          }
        });
        return str;
      }
      return prev.concat(selectedValue);
    });
  };

  const setTimers = (dateInditacion: 'start' | 'end', value: string) => {
    if (dateInditacion === 'start') {
      setHourStartSelected(value);
      valueHandler(`${filter.query}Start`, value);
    } else {
      setHourEndtSelected(value);
      valueHandler(`${filter.query}End`, value);
    }
  };

  const onChange = (dateInditacion: 'start' | 'end', value: string) => {
    setTimers(dateInditacion, value);
  };

  const childProps = {
    t,
    filter,
    hourStartSelected,
    setHourStartSelected,
    hourEndSelected,
    setHourEndtSelected,
    onChange,
  };

  return <FilterColumnHour {...childProps} />;
};

interface FilterColumnDateContainerProps {
  setLocalFilterString: React.Dispatch<React.SetStateAction<string>>;
  filter: FilterByColumn;
  localFilterString: string;
}

export default FilterColumnHourContainer;
