import { Box, Checkbox } from '@mui/material';
import { TFunction } from 'react-i18next';
import { CustomDatePicker } from '@/src/components/date-picker/DatePicker';
import { FilterByColumn } from '../../../types/CustomDataTableColumnProps';
import St from '../FilterColumnIcon.styled';

const FilterColumnDate = (props: FilterColumnDateProps) => {
  const { t, filter, dateEndValue, dateStartValue, dateNullValue, onChange, handleCheckNullable } = props;
  return (
    <Box component="div" sx={{ margin: '5px 16px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <CustomDatePicker
        width={150}
        value={dateStartValue}
        onChange={(e: any) => onChange(e, 'start')}
        fieldValue={t('start_field')}
      />
      <CustomDatePicker
        width={150}
        value={dateEndValue}
        onChange={(e: any) => onChange(e, 'end')}
        fieldValue={t('end_field')}
      />
      {filter.value === 'CheckNullable' && (dateStartValue || dateEndValue) && (
        <St.CheckboxLabel
          onChange={() => handleCheckNullable(!dateNullValue)}
          checked={dateNullValue}
          value={dateNullValue}
          control={<Checkbox size="small" />}
          label={!Array.isArray(filter.label) ? filter.label : filter.label[0]}
        />
      )}
    </Box>
  );
};

interface FilterColumnDateProps {
  filter: FilterByColumn;
  onChange: (e: any, type: 'start' | 'end') => void;
  handleCheckNullable: (value: boolean) => void;
  dateStartValue: number | null;
  dateEndValue: number | null;
  dateNullValue: boolean;
  t: TFunction;
}

export default FilterColumnDate;
