import { Button, Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  getDayHourYearDateEn,
  getDayHourYearDateEs,
  getFormattedDateByLanguague,
} from '@/src/utilities/helpers/dateParser';
import { deliveryMinutes, parkingDetail, saveRequest } from '@/src/api/endpoints/parking';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import TitleWithStatus from '@/src/components/title-with-status/TitleWithStatus';
import InfoLabel from '@/src/components/info-label/InfoLabel';
import { getLanguage } from '@/src/utilities/storage';
import { DevMinutes, ParkingDetailProps, valetParkingStates } from '../types';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import { BoxContainer } from './ResidentsParkingDetail.styled';

const ParkingDetail = ({ row, close, setSnackBarMessage }: ParkingDetailProps) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { mutate, data, isLoading: getDeliveryMinutesLoading } = useMutation(deliveryMinutes);
  const { mutate: onSaveRequest, isLoading: onSaveLoading } = useMutation(saveRequest, {
    onSuccess: () => {
      close();
      setSnackBarMessage(t('success'));
    },
    onError: ({ response }) => {
      setErrorMessage(
        t(response.data?.error?.code, {
          resource: response?.data?.error?.message,
          item: 'paquete',
          ns: 'errors',
        }),
      );
    },
  });
  const [DeliveryMinutes, setDeliveryMinutes] = useState<Array<DevMinutes> | null | undefined>(null);

  const { data: response, isLoading } = useQuery(['parkingDetail', row?.id], () => parkingDetail(row?.id), {
    select: (d) => d?.data?.request,
  });

  const handleMinuteSelected = (id: number) => {
    const selected = DeliveryMinutes?.map((item: DevMinutes) =>
      item?.id === id ? { ...item, selected: true } : { ...item, selected: false },
    );
    setDeliveryMinutes(selected);
  };

  const handleSaveRequest = () => {
    const selected = DeliveryMinutes?.filter((item: DevMinutes) => item?.selected === true);
    selected && selected?.length > 0
      ? onSaveRequest({ id: row?.id, delivery_minute_id: selected[0]?.id })
      : setErrorMessage(t('no_minutes_selected', { ns: 'parking' }));
  };

  useEffect(() => {
    if (row && row?.request_state?.name === t('request_status_new', { ns: 'parking' })) {
      mutate();
    }
  }, [row, t]);

  const lang = getLanguage()?.split('-')[0];

  useEffect(() => {
    if (data) {
      const proData = data?.data?.delivery_minutes?.map((item: { id: number; minutes: number }) => ({
        ...item,
        selected: false,
      }));
      setDeliveryMinutes(proData);
    }
  }, [data]);

  return (
    <BoxContainer>
      <Grid container spacing={2} sx={{ pb: { xs: 2, md: 0 }, minHeight: '500px' }}>
        <TitleWithStatus
          title={t('guest_parking_car_detail', { ns: 'cdtModal' })}
          statusText={row?.request_state?.name}
          statusBg={valetParkingStates[row?.request_state?.name]}
          onClose={close}
        />
        {isLoading || getDeliveryMinutesLoading ? (
          <CircularSpinner />
        ) : (
          <>
            <Grid item xs={12} sx={{ pt: '0 !important' }}>
              <Typography variant="subtitle1" fontWeight={600}>
                {t('parking_page_detail_sub_title1', { ns: 'parking' })}
              </Typography>
              <Divider sx={{ mt: '2px', mb: '5px' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('parking_page_table_column_application_number', { ns: 'parking' })}
                second={response?.id}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('retreat_date', { ns: 'parking' })}
                second={
                  response?.creation_date > 0
                    ? getFormattedDateByLanguague(
                        lang,
                        getDayHourYearDateEs,
                        getDayHourYearDateEn,
                        response?.creation_date,
                      )
                    : '-'
                }
              />
            </Grid>
            {row?.request_state?.name !== t('request_status_new', { ns: 'parking' }) && (
              <>
                <Grid item xs={12} sm={6}>
                  <InfoLabel
                    first={t('selected_time', { ns: 'parking' })}
                    second={
                      response?.delivery_minute?.selected_time ? `${response?.delivery_minute?.selected_time}` : ' '
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InfoLabel
                    first={t('processing_date', { ns: 'parking' })}
                    second={getFormattedDateByLanguague(
                      lang,
                      getDayHourYearDateEs,
                      getDayHourYearDateEn,
                      response?.delivery_date,
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InfoLabel
                    first={t('email')}
                    second={response?.confirmation_user_name ? response?.confirmation_user_name : ''}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={600}>
                {t('parking_page_detail_sub_title2', { ns: 'parking' })}
              </Typography>
              <Divider sx={{ mt: '2px', mb: '5px' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel first={t('name')} second={`${response?.customer?.name} ${response?.customer?.surname}`} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('parking_page_detail_input_functional_unit', { ns: 'parking' })}
                second={row?.residency?.property?.code}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('resident_type', { ns: 'parking' })}
                second={t(row?.residency?.type?.code, { ns: 'customerUsers' })}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={600}>
                {t('parking_page_detail_sub_title3', { ns: 'parking' })}
              </Typography>
              <Divider sx={{ mt: '2px', mb: '5px' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('parking_page_detail_input_brand', { ns: 'parking' })}
                second={response?.vehicle?.brand?.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('parking_page_detail_input_model', { ns: 'parking' })}
                second={response?.vehicle?.model?.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('parking_page_detail_input_color', { ns: 'parking' })}
                second={response?.vehicle?.color?.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('parking_page_detail_input_patent', { ns: 'parking' })}
                second={response?.vehicle?.license_plate}
              />
            </Grid>
            {row?.request_state?.name === t('request_status_new', { ns: 'parking' }) && (
              <>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" fontWeight={600}>
                    {t('car_availability', { ns: 'parking' })}
                  </Typography>
                  <Divider sx={{ mt: '2px', mb: '5px' }} />
                </Grid>
                {DeliveryMinutes && DeliveryMinutes?.length > 0
                  ? DeliveryMinutes?.map((minutes: DevMinutes) => (
                      <Grid key={minutes?.id} xs={12 / DeliveryMinutes.length} item>
                        <Button
                          variant={minutes?.selected ? 'contained' : 'outlined'}
                          fullWidth
                          onClick={() => handleMinuteSelected(minutes?.id)}
                        >
                          {`${minutes?.minutes}′ `}
                        </Button>
                      </Grid>
                    ))
                  : null}
                {errorMessage && (
                  <Grid item xs={6} sx={{ textAlign: 'left' }}>
                    <ErrorText error={errorMessage as string} />
                  </Grid>
                )}
                <Grid item xs={errorMessage ? 6 : 12} sx={{ textAlign: 'right' }}>
                  <LoadingButton
                    loading={onSaveLoading}
                    variant="contained"
                    disabled={getDeliveryMinutesLoading}
                    onClick={handleSaveRequest}
                  >
                    {t('save')}
                  </LoadingButton>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </BoxContainer>
  );
};

export default ParkingDetail;
