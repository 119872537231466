import { styled } from '@mui/material';

export const ImagePreviewContainer = styled('div')`
  padding: 15px;
  border-radius: 20px;
  background: rgba(166, 139, 92, 0.1);
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
