import * as Yup from 'yup';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useSnackBar from '@/src/components/custom-snackbar/useSnackBar';
import CategoriesForm from './CategoriesForm';
import { tRequiredFieldError } from '@/src/utilities/helpers/appConstants';
import { createCategory, editCategory } from '@/src/api/endpoints/categories';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';

const getInitialValues = (isEdit: boolean, row: any) => ({
  name: isEdit ? row?.name : '',
});

const getValidationSchema = () => {
  Yup.lazy(() =>
    Yup.object().shape({
      name: Yup.string().required(tRequiredFieldError),
    }),
  );
};

const CategoriesFormContainer = (props: Props) => {
  const { isEdit, row, close, setSnackBarMessage } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const createCategorieMutation = useMutation(createCategory, {
    onError: async (e: any) => {
      setErrorMessage(e.response.data.error_message[0]);
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successful_categorie_creation_snackbar_text', { ns: 'categories' })); // agregar
    },
  });

  const editCategorieMutation = useMutation(editCategory, {
    onError: async (e: any) => {
      setErrorMessage(e.response.data.error_message[0]);
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successful_categorie_edit_snackbar_text', { ns: 'categories' })); // agregar
    },
  });

  const handleSubmit = React.useCallback(async (data: any) => {
    const payload = {
      name: data?.name,
    };
    if (isEdit) return editCategorieMutation.mutate({ id: row?.id, body: payload });
    createCategorieMutation.mutate(payload);
  }, []);

  const formikInitProps = React.useMemo(
    () => ({
      initialValues: getInitialValues(!!isEdit, row),
      validateOnChange: false,
      validateOnBlur: false,
      validateOnMount: false,
      validationSchema: getValidationSchema(),
      onSubmit: handleSubmit,
    }),
    [handleSubmit, getInitialValues],
  );

  const formik = useFormik(formikInitProps);

  const childProps = {
    formik,
    isSubmitLoading: createCategorieMutation.isLoading,
    errorMessage,
  };

  return <CategoriesForm {...childProps} />;
};

const propTypes = {};

const defaultProps = {
  isEdit: false,
};

interface ExtraProps {
  isEdit?: boolean;
  row: any;
  close: () => void;
  setSnackBarMessage: (message: string, sever?: string) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
CategoriesFormContainer.propTypes = propTypes;
CategoriesFormContainer.defaultProps = defaultProps;

export default CategoriesFormContainer;
