import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TypographyDescription = styled(Typography)(() => ({
  color: 'rgba(0, 0, 0, 0.6)',
  fontSize: '16px',
  mt: 1,
}));

export const RemoveItemIcon = styled(IconButton)(() => ({
  position: 'absolute',
  left: '30px',
  top: '-12px',
}));
