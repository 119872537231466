import { Box, Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { ModalProps } from '../types';

const VehicleDeleteModal = ({ handleClose, onConfirm }: ModalProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography id="modal-modal-description" sx={{ py: 1 }}>
        {t('deleting_dialog_vehicle', { ns: 'customerUsers' })}
      </Typography>
      <Grid xs={12} display="flex" justifyContent="flex-end">
        <Button size="small" type="button" variant="outlined" color="secondary" onClick={handleClose} sx={{ mr: 1 }}>
          {t('delete_dialog_cancel_button_text')}
        </Button>
        <Button size="small" type="button" variant="contained" onClick={() => onConfirm()}>
          {t('delete_dialog_submit_button_text')}
        </Button>
      </Grid>
    </Box>
  );
};

export default VehicleDeleteModal;
