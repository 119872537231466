import * as Yup from 'yup';

export const validate = () =>
  Yup.lazy(() =>
    Yup.object().shape({
      title: Yup.string().required('Ingrese un título'),
      subtitle: Yup.string().required('Ingrese un subtítulo'),
      body: Yup.string().required('Ingrese un mensaje'),
      cover_image_url: Yup.string().required('Ingrese una foto de portada'),
    }),
  );
