import headLogo from './logos/headLogo.png';
import mainLogo from './logos/mainLogo.svg';
import whiteLogo from './logos/whiteLogo.png';
import loginBackground from './images/loginBackground.png';

export const grit = {
  headLogo,
  mainLogo,
  whiteLogo,
  backgroundPic: loginBackground,
};
