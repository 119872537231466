import React from 'react';
import FilterCheckbox from './FilterCheckbox';
import { FilterByColumn, FilterByColumnValue } from '../../../types/CustomDataTableColumnProps';

const FilterCheckboxContainer = (props: FilterCheckboxContainerProps) => {
  const { filter, localFilterString, setLocalFilterString } = props;

  const validateFilterIfArray: (value?: FilterByColumnValue) => string | number | boolean = (value) =>
    !Array.isArray(value) && value ? value : '';

  const handleFilters = (query: string, value: string | number | boolean) => {
    const selectedValue = `&${query}=${value}`;
    setLocalFilterString((prev) => {
      if (prev.includes(selectedValue)) {
        return prev.replace(selectedValue, '');
      }
      return prev.concat(selectedValue);
    });
  };

  const getCheckedValue = (query: string, value: string | number | boolean | null | undefined) => {
    const selectedValue = `&${query}=${value}`;
    return localFilterString.includes(selectedValue);
  };

  const childProps = {
    filter,
    handleFilters,
    getCheckedValue,
    validateFilterIfArray,
  };

  return <FilterCheckbox {...childProps} />;
};

interface FilterCheckboxContainerProps {
  setLocalFilterString: React.Dispatch<React.SetStateAction<string>>;
  filter: FilterByColumn;
  localFilterString: string;
}

export default FilterCheckboxContainer;
