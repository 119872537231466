import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton } from '@mui/material';
import { t } from 'i18next';
import { StyledTableCell, StyledTableRow } from './TableComponent.styled';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';

const TableComponent = (props: Props) => {
  const { rows, deleteUser } = props;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>{`${t('news_form_table_name', { ns: 'news' })}`}</StyledTableCell>
            {/* <StyledTableCell align="right">{`${t('news_form_table_action', { ns: 'news' })}`}</StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row: any) => (
            <StyledTableRow key={row?.description ? row?.description : row?.name}>
              <StyledTableCell component="th" scope="row">
                {row?.description ? row?.description : row?.name}
              </StyledTableCell>
              <StyledTableCell align="right">
                <IconButton aria-label="delete" onClick={() => deleteUser(row)}>
                  <DeleteIcon />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const propTypes = {};

const defaultProps = {};

interface ExtraProps {
  rows: any;
  deleteUser: (id: number) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
TableComponent.propTypes = propTypes;
TableComponent.defaultProps = defaultProps;

export default TableComponent;
