import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import DocumentsDetail from './DocumentsDetail';
import { getDocumentsById } from '@/src/api/endpoints/documents';
import { getProperties } from '@/src/api/endpoints/residents';
import { PropertyType } from '../../household/vehicles/types';

const DocumentsDetailContainer = (props: any) => {
  const { row: rowData } = props;
  const { t } = useTranslation('documents');

  const { data: documentInfo, isLoading: isLoadingGetDocumentInfo } = useQuery(
    [`getDocumentInfo_${rowData.id}`],
    () => getDocumentsById(rowData.id),
    {
      select: (data) => data?.data?.document,
    },
  );

  const { data: response } = useQuery(['getProperties'], () => getProperties());
  const propertiesList: Array<PropertyType> | undefined | null = response?.data.properties;
  const properties = propertiesList?.map((prop) => prop.code);

  const childProps = {
    t,
    properties,
    documentInfo,
    isLoadingGetDocumentInfo,
  };

  return <DocumentsDetail {...childProps} />;
};

export default DocumentsDetailContainer;
