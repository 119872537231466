import { t } from 'i18next';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { VALID_NUMBER_FIELD } from '@/src/utilities/helpers/constants';
import { getPackagesByPropertyCodeAndCustomerCode } from '@/src/api/endpoints/packages';
import { DetailDeliveryHook, Carrier } from '../types';

export const useDeliveryPackage = () => {
  const [detail, setDetail] = useState<DetailDeliveryHook | any>({});
  const { mutate, isLoading } = useMutation(getPackagesByPropertyCodeAndCustomerCode);

  const getPackagesByFunctionalUnit = async (info: any) => {
    setDetail({ ...detail, error: '' });
    const { propertyCode, customerCode } = info;
    if (!propertyCode || !customerCode || !VALID_NUMBER_FIELD.test(customerCode)) return;
    mutate(info, {
      onSuccess: ({ data }) => {
        if (data.packages.length < 1)
          return setDetail({
            ...detail,
            error: t('modal_deliver_package_zero_packages_to_deliver', { ns: 'packages' }),
          });
        setDetail({
          packages: data.packages.map((el: DetailDeliveryHook) => ({ ...el, status: true })),
          userId: data.user_id,
          name: data.name,
          surname: data.surname,
          avatarUrl: data.avatar_url,
          error: data.error,
        });
      },
      onError: ({ response }: any) => {
        setDetail({
          ...detail,
          error: response.data.error_messages[0].message && t('packages_validate_withdrawal_code', { ns: 'packages' }),
        });
      },
    });
  };

  const handleToggle = (code: number) => {
    setDetail({
      ...detail,
      packages: detail.packages.map((el: Carrier) => (el.internal_code === code ? { ...el, status: !el?.status } : el)),
    });
  };

  const handleToggleAll = () => {
    const marked = detail.packages.filter((el: Carrier) => el.status === true);
    marked.length === detail.packages.length
      ? setDetail({ ...detail, packages: detail.packages.map((el: Carrier) => ({ ...el, status: false })) })
      : setDetail({ ...detail, packages: detail.packages.map((el: Carrier) => ({ ...el, status: true })) });
  };

  return { handleToggle, handleToggleAll, getPackagesByFunctionalUnit, detail, isLoading };
};
