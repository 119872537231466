import { Grid, Box, Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useExportAssets } from '@/src/assets/exportFile';
import { useRegistryBreakpoints } from '../../guests/registration/registry/hooks/useRegistryBreakpoints';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import { Confirmation } from './Confirmation';

interface CarRecallProps {
  requestResponse: boolean | null;
  isLoading: boolean;
  handleRequestGuestVehicle: () => void;
}

const Intermediary = ({ isLoading, requestResponse, handleRequestGuestVehicle }: CarRecallProps) => {
  const { t } = useTranslation();
  const { titleStyles, infoStyles } = useRegistryBreakpoints();
  return requestResponse !== null ? (
    <Confirmation requestResponse={requestResponse} />
  ) : (
    <>
      <Grid xs={12} item>
        <Typography variant="h6" component="h2" sx={titleStyles}>
          {t('parking')}
        </Typography>
      </Grid>
      <Box sx={{ display: 'flex', flexDirection: 'column', mx: 'auto', gap: 2 }}>
        <Typography variant="body1" component="div" sx={infoStyles}>
          {t('car_recall_page_body1', { ns: 'parking' })}
          <br />
          {t('car_recall_page_body2', { ns: 'parking' })}
        </Typography>
        <LoadingButton
          variant="contained"
          color="primary"
          size="small"
          type="button"
          loading={isLoading}
          onClick={handleRequestGuestVehicle}
          sx={{ width: 'max-content', mx: 'auto', px: 2, borderRadius: '70px' }}
        >
          {t('car_recall_page_button_text', { ns: 'parking' })}
        </LoadingButton>
      </Box>
    </>
  );
};

export const CarRecall = ({ handleRequestGuestVehicle, isLoading, requestResponse }: CarRecallProps) => {
  const { containerStyles, CardContainerStyles, headerStyles } = useRegistryBreakpoints();
  const { EventBackgroundPatternImage, whiteLogo } = useExportAssets();
  return (
    <Box sx={containerStyles}>
      <Card sx={CardContainerStyles}>
        <Box
          component="div"
          sx={{
            border: 'none',
            background: ({ palette }) => palette.primary.main,
            backgroundImage: `url(${EventBackgroundPatternImage})`,
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <img src={whiteLogo} alt="Panorama_Logo" style={headerStyles} />
        </Box>
        <Grid container spacing={1.5} sx={{ height: 'auto', pl: 2, pr: 2, mt: 1 }}>
          {isLoading ? (
            <CircularSpinner />
          ) : (
            <Intermediary
              isLoading={isLoading}
              requestResponse={requestResponse}
              handleRequestGuestVehicle={handleRequestGuestVehicle}
            />
          )}
        </Grid>
      </Card>
    </Box>
  );
};
