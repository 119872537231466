import { axiosAuthenticationInstance } from '../instances/authInstance';
import { CreateArticleRequest, EditArticleRequest } from '../types/types';

// get
export const getArticles = (abort: any) =>
  axiosAuthenticationInstance.get(`community/v1/customers/posts?page=0&pageSize=1000`, abort);
export const getArticleById = (id: number) => axiosAuthenticationInstance.get(`community/v1/posts/${id}`);
export const getArticlesAdmin = (
  abort?: any,
  creationDateStart?: number | undefined | null,
  creationDateEnd?: number | undefined | null,
  isRestricted?: boolean | undefined | null,
  searchText?: string | undefined | null,
) =>
  axiosAuthenticationInstance.get(
    `community/v1/posts?page=0&pageSize=1000${creationDateStart ? `&CreationDateStart=${creationDateStart}` : ''}${
      creationDateEnd ? `&CreationDateEnd=${creationDateEnd}` : ''
    }${isRestricted ? `&IsRestricted=${isRestricted}` : ''}${searchText ? `&searchText=${searchText}` : ''}`,
    abort,
  );
export const getArticleByIdAdmin = (id: number) => axiosAuthenticationInstance.get(`community/v1/posts/${id}`);
// post
export const createArticle = (req: CreateArticleRequest) =>
  axiosAuthenticationInstance.post('community/v1/customers/posts', req);

// put
export const editArticle = (req: EditArticleRequest) =>
  axiosAuthenticationInstance.put(`community/v1/customers/posts/${req.id}`, req.body);
export const editReactionArticle = (req: EditArticleRequest) =>
  axiosAuthenticationInstance.put(`community/v1/customers/posts/${req.id}/reactions`, req.body);
export const RestrictArticle = (req: any) =>
  axiosAuthenticationInstance.put(`community/v1/posts/${req.id}/restrict`, req.body);
// delete
export const deleteArticle = (id: number) => axiosAuthenticationInstance.delete(`community/v1/customers/posts/${id}`);
