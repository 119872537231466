import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';
import { AccountStateSectionProps } from './types';
import BackToBaseButton from '@/src/components/custom-data-table/components/BackToBaseButton';
import { getLanguage } from '@/src/utilities/storage';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import AccountBalance from './account-balance';

export const AccountStateSection = ({ selectedRow: selected, closeSubGrid }: AccountStateSectionProps) => {
  const { t } = useTranslation();
  const {
    chargesHeaderColumns,
    chargesColumns,
    paymentsColumns,
    chargesActionColumns,
    paymentsActionColumns,
    queryFilters,
    balance,
    isLoading,
    refetch,
  } = useColumns(selected);
  const lang = getLanguage()?.split('-')[0];

  return (
    <Grid container rowGap="20px" spacing={3}>
      <Grid item xs={12} display="flex">
        <BackToBaseButton backToBase={closeSubGrid} />
        <Typography variant="h4">{`${t('account_state', { ns: 'residents' })} - ${selected.property.code} ${t(
          'from',
        )} ${getFormattedDateByLanguague(
          lang,
          getFormattedDateFullYearEs,
          getFormattedDateFullYearEn,
          selected.residency.from,
        )} ${t('to')} ${getFormattedDateByLanguague(
          lang,
          getFormattedDateFullYearEs,
          getFormattedDateFullYearEn,
          selected.residency.to,
        )}`}</Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <AccountBalance residencyId={selected.residency.id} balance={balance} isLoading={isLoading} refetch={refetch} />
      </Grid>
      <Grid item xs={12} md={6} lg={8} display="flex" flexDirection="column" gap="20px">
        <CustomDataTable
          title={t('resident_charges')}
          columns={chargesColumns}
          customActionColumns={chargesActionColumns}
          extraActionsInHeader={chargesHeaderColumns}
          resource="charges"
          pathApi="payments/v1/charges"
          listName="charges"
          defaultSortFieldId="creation_date"
          extraFilters={queryFilters}
          customPageSize={7}
          canSearch
          defaultSortAsc
        />
        <CustomDataTable
          title={t('payments')}
          columns={paymentsColumns}
          customActionColumns={paymentsActionColumns}
          resource="payments"
          pathApi="payments/v1/payments"
          listName="payments"
          defaultSortFieldId="creation_date"
          extraFilters={queryFilters}
          customPageSize={7}
          canSearch
          defaultSortAsc
        />
      </Grid>
    </Grid>
  );
};
