import React from 'react';
import { Button, Card, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { CustomDatePicker } from '@/src/components/date-picker/DatePicker';
import { useFilters, today } from './useFilters';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import CustomLoader from '@/src/components/custom-loader';
import { FormBox, CustomBox } from './Filter.styled';
import { LoaderContainer } from '@/src/components/loader-container/LoaderContainer';
import { FieldProps, PropsFilter } from '../types';
import { handlerDateSelector } from '@/src/utilities/helpers/utils';

const Filters = (props: PropsFilter) => {
  const { activitiesList, handleSubmit, values, setFieldValue, errors, placeholder, fields, handleChange } =
    useFilters(props);

  const matches = useMediaQuery('(min-width:900px)');

  const { t } = useTranslation();

  if (!activitiesList) {
    return (
      <LoaderContainer>
        <CustomLoader />
      </LoaderContainer>
    );
  }

  return (
    <Card style={{ margin: '0 auto', marginBottom: '10px' }}>
      <FormBox onSubmit={handleSubmit}>
        {fields.map((fieldSet: FieldProps) => (
          <FormControl key={fieldSet.id} fullWidth size="small" sx={{ width: { xs: '220px', sm: '30%' } }}>
            <InputLabel id="demo-select-small">{fieldSet.label}</InputLabel>
            <Select
              fullWidth
              disabled={fieldSet.disabled}
              labelId="demo-select-small"
              id="demo-select-small"
              value={String(values[fieldSet?.fieldValue as keyof typeof values]) ?? null}
              label={fieldSet.label}
              onChange={handleChange(fieldSet.fieldValue)}
            >
              {fieldSet.values.length > 0 &&
                fieldSet.values.map((item) => (
                  <MenuItem key={item?.id} value={item.id}>
                    {item.description}
                  </MenuItem>
                ))}
            </Select>
            <ErrorText error={errors[fieldSet.fieldValue as keyof typeof errors] as string} />
          </FormControl>
        ))}
        <CustomBox component="div">
          <CustomDatePicker
            value={values.from}
            minDate={today}
            width={`${matches ? '50%' : '70%'}`}
            minWidth="115px"
            onChange={(newValue: any): void => handlerDateSelector(newValue, setFieldValue, 'from')}
            fieldValue="from"
            isReadOnly={false}
            placeholder={placeholder}
            errorMessage={errors.from as string}
          />
          <Button sx={{ height: '40px' }} color="primary" variant="outlined" type="submit" size="small">
            {t('search')}
          </Button>
        </CustomBox>
      </FormBox>
    </Card>
  );
};

export default Filters;
