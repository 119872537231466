export type GuestsParking = {
  id: number;
  parking_code: number;
  guest_name: string;
  full_phone_number: string;
  ticket_code: number;
  creation_date: number;
  request_date: number;
  processing_date: number;
  processing_username: string;
  guest_parking_state: {
    name: string;
  };
};

export interface GuestCarEntryRequest {
  ticket_code: string;
  parking_code: string;
  guest_name: string;
  country_code: string;
  country_iso_code: string;
  phone_number: number;
}

export type GuestParkingStateOptions = {
  [key: string]: string;
};

export interface SaveRequest {
  id: number;
  delivery_minute_id: number;
}
export const guestParkingStates: GuestParkingStateOptions = {
  Estacionado: '#CA8A04',
  Parked: '#CA8A04',
  Solicitado: '#424867',
  Requested: '#424867',
  Procesado: '#516F3F',
  Processed: '#516F3F',
};

export interface GuestFormProps {
  close: () => void;
  setSnackBarMessage: (message: string, sever?: string) => void;
}
