import { FormikErrors, FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import NewsFormAddressee from './NewsFormAddressee';
import { UsersList } from '../../../types';
import { getUsers } from '@/src/api/endpoints/users';

const NewsFormAddresseeContainer = (props: Props) => {
  const { t } = useTranslation();
  const { tabValue, values, setFieldValue, errors } = props;
  const [usersStillAvailableList, setUsersStillAvailableList] = useState<UsersList[] | undefined>(undefined);

  const [groupList, setGroupList] = useState([
    { id: 3, description: `${t('customers')}`, value: 'Customer' },
    { id: 4, description: `${t('potential_customers')}`, value: 'PotentialCustomer' },
  ]);

  const { data: usersData, refetch: usersRefetch } = useQuery(['getUsers'], getUsers, {
    select: (response: any) => response?.data.users as Array<UsersList>,
  });

  useEffect(() => {
    if (usersData !== undefined) setUsersStillAvailableList(usersData);
  }, [usersData]);

  useEffect(() => {
    const auxiliarUsersList: any = [];
    if (values?.userListTargetType === 'group') {
      groupList.forEach((group) => {
        values?.userSelectedList.forEach((userSelected: any) => {
          if (group.id === userSelected.id) auxiliarUsersList.push(group);
        });
      });
      setFieldValue('userSelectedList', auxiliarUsersList);
    } else if (usersStillAvailableList !== undefined) {
      usersStillAvailableList.forEach((user) => {
        values?.userSelectedList.forEach((userSelected: any) => {
          if (user.id === userSelected) auxiliarUsersList.push(user);
        });
      });
      setFieldValue('userSelectedList', auxiliarUsersList);
    }
  }, [usersStillAvailableList]);

  const SelectGroupForTable = () => {
    const userSelectedList: any[] = values?.userSelectedList;
    const selectedUser: any = values?.selectedUser;
    const auxiliarUsersList: any = [];

    if (userSelectedList.find((user) => user.id === selectedUser.id)) return;
    userSelectedList.push(selectedUser);
    setFieldValue('userSelectedList', userSelectedList);
    groupList.forEach((group: any) => {
      const answer = userSelectedList.find((groupSelected) => groupSelected.id === group.id);
      if (!answer) auxiliarUsersList.push(group);
    });
    setGroupList(auxiliarUsersList);
    setFieldValue('selectedUser', undefined);
  };

  const SelectUserForTable = () => {
    const usersAlreadySelected: any[] = values?.userSelectedList;
    const userSelected: any = values?.selectedUser;
    const allUsersList: any = usersStillAvailableList;

    if (usersAlreadySelected.find((user) => user.id === userSelected.id)) return;
    usersAlreadySelected.push(userSelected);
    setFieldValue('userSelectedList', usersAlreadySelected);
    const auxiliar = allUsersList.filter((user: any) => user.id !== userSelected.id);
    setUsersStillAvailableList(auxiliar);
    setFieldValue('selectedUser', undefined);
  };

  const DeselectUserForTable = (type: string) => {
    const allUsersList: any = usersData;
    setFieldValue('userSelectedList', []);
    if (type === 'group') {
      setGroupList([
        { id: 1, description: `${t('customers')}`, value: 'Customer' },
        { id: 2, description: `${t('potential_customers')}`, value: 'PotentialCustomer' },
      ]);
      setFieldValue('selectedUser', undefined);
    } else {
      setUsersStillAvailableList(allUsersList);
      setFieldValue('selectedUser', undefined);
    }
  };

  const childProps = {
    t,
    tabValue,
    values,
    setFieldValue,
    DeselectUserForTable,
    groupList,
    errors,
    SelectGroupForTable,
    usersStillAvailableList,
    SelectUserForTable,
    setGroupList,
  };

  return <NewsFormAddressee {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface ExtraProps {
  tabValue: number;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  errors: FormikErrors<FormikValues>;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
NewsFormAddresseeContainer.propTypes = propTypes;
NewsFormAddresseeContainer.defaultProps = defaultProps;

export default NewsFormAddresseeContainer;
