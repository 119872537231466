import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

const ChargesPage = () => {
  const { t } = useTranslation();
  const { columns, actionColumns } = useColumns();

  return (
    <CustomDataTable
      title={t('charges', { ns: 'charges' })}
      columns={columns}
      customActionColumns={actionColumns}
      resource="charges"
      pathApi="payments/v1/charges"
      listName="charges"
      defaultSortFieldId="amount"
      canSearch
      canExport
      defaultSortAsc
    />
  );
};

export default ChargesPage;
