import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import {
  changeAuthorizationToDelivered,
  deliveryAuthorization,
  getDeliveryInfo,
  getDeliveryInfoByPropertyCodeAndCarrierId,
  getPeopleByPropertyCode,
  getCarriers,
} from '@/src/api/endpoints/delivery';
import { People } from '../types';

export const useAdmission = () => {
  const { mutate, isLoading: isLoadingAdmission } = useMutation(changeAuthorizationToDelivered);
  return { mutate, isLoadingAdmission };
};

export const useAuthorizations = () => {
  const { mutate, isLoading } = useMutation(deliveryAuthorization);
  return { mutate, isLoading };
};

export const useDeliveryInfo = (id: number) => {
  const queryClient = useQueryClient();
  const { data: values, isLoading } = useQuery(['getDeliveryInfo'], () => getDeliveryInfo(id), {
    select: (data) => data?.data.authorization,
  });

  const memoizedResetQueries = useMemo(() => queryClient.resetQueries(['getDeliveryInfo']), []);
  useEffect(() => {
    memoizedResetQueries;
  }, []);

  return { values, isLoading };
};

export const useGetPersonIfAuthorized = () => {
  const {
    mutate: mutateGetPersonIfAuthorized,
    isLoading: isLoadingGetPersonIfAuthorized,
    data,
  } = useMutation(getDeliveryInfoByPropertyCodeAndCarrierId);
  const personAuthorized: People = data?.data.authorization;
  const deliveryId = data?.data.authorization.id;
  return { mutateGetPersonIfAuthorized, isLoadingGetPersonIfAuthorized, personAuthorized, deliveryId };
};

export const useGetPeople = () => {
  const { mutate: mutateGetPeople, isLoading: isLoadingGetPeople, data } = useMutation(getPeopleByPropertyCode);
  const people: People[] = data?.data.people;
  return { mutateGetPeople, isLoadingGetPeople, people };
};

export const useLoadDelivery = () => {
  const { data: company } = useQuery(['getCarriers'], () => getCarriers(), {
    select: (data) => data?.data?.carriers,
  });
  return { company };
};
