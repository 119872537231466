import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TypographyDescription = styled(Typography)(() => ({
  color: 'rgba(0, 0, 0, 0.6)',
  fontSize: '16px',
  mt: 1,
}));

export const RemoveItemIcon = styled(IconButton)(() => ({
  position: 'absolute',
  left: '30px',
  top: '-12px',
}));

export const ModalContentWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 16px;
`;
