import * as Yup from 'yup';
import { TProp } from '@/src/utilities/helpers/commonTypes';

export const validate = ({ t }: TProp) =>
  Yup.lazy(() =>
    Yup.object().shape({
      name: Yup.string().required(t('required_field', { ns: 'errors' })),
      surname: Yup.string().required(t('required_field', { ns: 'errors' })),
      email: Yup.string()
        // .required(t('required_field', { ns: 'errors' }))
        .email(t('user_form_invalid_email_field_validation', { ns: 'users' }))
        .trim(),
      countryCode: Yup.string(),
      // .required(t('required_field', { ns: 'errors' })),
      countryIsoCode: Yup.string(),
      // .required(t('required_field', { ns: 'errors' })),
      city: Yup.object()
        .shape({
          id: Yup.number(),
          code: Yup.string(),
        })
        .nullable()
        .required(t('required_field', { ns: 'errors' })),
      state: Yup.object()
        .shape({
          id: Yup.number(),
          code: Yup.string(),
        })
        .nullable()
        .required(t('required_field', { ns: 'errors' })),
      country: Yup.object()
        .shape({
          id: Yup.number(),
          code: Yup.string(),
        })
        .nullable()
        .required(t('required_field', { ns: 'errors' })),
      phoneNumber: Yup.string(),
      // .required(t('required_field', { ns: 'errors' })),
      relationshipType: Yup.object()
        .shape({
          id: Yup.number(),
          code: Yup.string(),
        })
        .nullable()
        .required(t('required_field', { ns: 'errors' })),
      birthday: Yup.number().required(t('required_field', { ns: 'errors' })),
      identificationNumber: Yup.string().nullable(),
      identificationFileUrl: Yup.string().nullable(),
      streetName: Yup.string().required(t('required_field', { ns: 'errors' })),
      streetNumber: Yup.string().required(t('required_field', { ns: 'errors' })),
      apartmentNumber: Yup.string(),
      floorNumber: Yup.number().positive(t('positive_numbers_only', { ns: 'errors' })),
      zipCode: Yup.number().required(t('required_field', { ns: 'errors' })),
      alias: Yup.string(),
      requestBackgroundCheckCopy: Yup.boolean(),
    }),
  );
