import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CardAvatar = styled(Avatar)(({ theme }) =>
  theme.unstable_sx({
    bgcolor: 'primary.main',
  }),
);

export const CardAvatarImage = styled('img')({
  width: 'inherit',
  height: 'inherit',
  margin: '5% 0% 10% 0%',
});
