import { useQuery } from '@tanstack/react-query';
import { getArticleById } from '@/src/api/endpoints/news';
import { ArticleByIdBody } from './types';

const getArticleByIdData = async (id: number) => {
  const response = await getArticleById(id);
  return response.data;
};

export const useGetArticlesById = (id: number) => {
  const { data, isLoading, refetch } = useQuery([`${id}`, getArticleByIdData], () => getArticleByIdData(id), {
    cacheTime: 10000,
  });
  const dataById: ArticleByIdBody = data;
  return { dataById, isLoading, refetch };
};
