import { useFormik, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getAmenities, getAgendaByActivityId } from '@/src/api/endpoints/bookings';
import { PropsFilter } from '../types';
import { QueryProps } from '@/src/utilities/helpers/commonTypes';

export const today = new Date().getTime();

export const useFilters = ({ showTable, setShowTable, setExtraQueryFilters, setActivityId }: PropsFilter) => {
  const { t } = useTranslation();
  const { data } = useQuery({
    queryKey: ['activities_list'],
    queryFn: () => getAmenities({ page: 0, pageSize: 10000 }),
  });
  const { data: agendasByActivityId, mutate } = useMutation(getAgendaByActivityId);
  const getInitialValues = () => ({
    activity: '',
    agenda: '',
    from: today,
  });

  const getValidationSchema = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        activity: Yup.string().required(t('required_field', { ns: 'errors' })),
        agenda: Yup.string(),
        from: Yup.number().required(t('required_field', { ns: 'errors' })),
      }),
    );

  const onSubmit = (formValues: FormikValues) => {
    const newValues: any = formValues.agenda
      ? { date: formValues.from, agendaId: formValues.agenda }
      : { date: formValues.from };
    setActivityId(formValues.activity);
    const formikKeys = Object.keys(newValues);
    const queries: Array<QueryProps> = [];

    for (const v of formikKeys) {
      queries.push({ query: v, queryValue: newValues[v] });
    }
    setExtraQueryFilters(queries);
    setShowTable(true);
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema(),
  });

  const fields = [
    {
      id: 1,
      fieldValue: 'activity',
      label: t('agendas_form_activity_field_label', { ns: 'bookings' }),
      values: data ? data.data.activities : [],
      disabled: false,
    },
    {
      id: 2,
      fieldValue: 'agenda',
      label: t('reservations_page_agenda', { ns: 'bookings' }),
      values: agendasByActivityId ? agendasByActivityId.data.agendas : [],
      disabled: !agendasByActivityId,
    },
  ];
  const handleChange = (prop: string) => (e: any) => {
    if (prop === 'activity') {
      setShowTable(false);
      setFieldValue('agenda', '');
      mutate(e.target.value);
    }
    setFieldValue(prop, e.target.value);
  };

  return {
    activitiesList: data && data.data.activities,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    placeholder: t('date'),
    fields,
    handleChange,
    showTable,
    setShowTable,
  };
};
