import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from '../../../types';
import { BoxStyles } from './styles';

export const UserButtons = ({ values, handleClose, isLoading }: ButtonProps) => {
  const { t } = useTranslation();
  if (values && values.hideButtons) {
    return (
      <Grid item xs={12} sx={BoxStyles}>
        <Button onClick={handleClose} variant="outlined" color="secondary" size="small">
          {t('close')}
        </Button>
      </Grid>
    );
  }
  return (
    <Grid item xs={12} sx={BoxStyles}>
      <LoadingButton
        type="submit"
        id="form-user"
        size="small"
        variant="contained"
        loading={isLoading}
        sx={{ width: { xs: '100%', sm: 'auto' } }}
      >
        {t('save')}
      </LoadingButton>
    </Grid>
  );
};
