import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { attendantDetail, createsAttendant, editAttendant } from '@/src/api/endpoints/parking';
import { AttendantProps, CreateAttendantRequest } from '../types';
import { validate } from './Attendant.FormValidate';

import AttendantForm from './AttendantForm';

const AttendantFormContainer = ({ row, isEdit, close, setSnackBarMessage }: AttendantProps) => {
  const { t } = useTranslation();
  const [responseError, setResponseError] = useState('');
  const {
    mutate: getAttendantDetail,
    data: detail,
    isLoading: isLoadingAttendantDetail,
  } = useMutation(attendantDetail);
  const { mutate: createAttendantMutate, isLoading: isLoadingCreate } = useMutation(createsAttendant);
  const { mutate: editAttendantMutate, isLoading: isLoadingEdit } = useMutation(editAttendant);

  useEffect(() => {
    if (isEdit) {
      getAttendantDetail(row.id);
    }
  }, []);

  const getInitialValues = () => ({
    id: detail?.data.attendant.id || 0,
    name: detail?.data.attendant.name || '',
    surname: detail?.data.attendant.surname || '',
    countryCode: detail?.data.attendant.country_code || '',
    countryIsoCode: detail?.data.attendant.country_iso_code || '',
    phoneNumber: detail?.data.attendant.phone_number || '',
    email: detail?.data.attendant.email || '',
  });

  const action = {
    onError: ({ response }: any) => {
      setResponseError(
        t(response.data.error_messages[0].code, {
          resource: response.data.error_messages[0].message,
          item: 'asistente',
          ns: 'errors',
        }),
      );
    },
    onSuccess: () => {
      close();
      setSnackBarMessage(
        t('success_message', { item: t(isEdit ? 'edited' : 'created', { ns: 'parking' }), ns: 'parking' }),
      );
    },
  };
  const onSubmit = (data: any) => {
    setResponseError('');
    const info: CreateAttendantRequest = {
      id: data.id,
      name: data.name,
      surname: data.surname,
      country_code: data.countryCode,
      country_iso_code: data.countryIsoCode,
      phone_number: data.phoneNumber,
      email: data.email.trim(),
    };

    if (isEdit) {
      return editAttendantMutate({ id: info.id, body: info }, action);
    }
    createAttendantMutate(info, action);
  };
  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate(t),
  });

  const childProps = {
    values,
    setFieldValue,
    errors,
    handleSubmit,
    responseError,
    isLoadingGeneral: isLoadingAttendantDetail,
    isLoadingSubmit: isLoadingEdit || isLoadingCreate,
    close,
  };

  return <AttendantForm {...childProps} />;
};

export default AttendantFormContainer;
