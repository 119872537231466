import { axiosAuthenticationInstance } from '../instances/authInstance';
import { CreateAttendantRequest, EditAttendantRequest } from '@/src/pages/parking/attendant/types';
import { GuestCarEntryRequest, SaveRequest } from '@/src/pages/parking/guests/types';

export const createsAttendant = (req: CreateAttendantRequest) =>
  axiosAuthenticationInstance.post(`valet-parking/v1/attendants`, req);

export const editAttendant = (req: EditAttendantRequest) =>
  axiosAuthenticationInstance.put(`/valet-parking/v1/attendants/${req.id}`, req.body);

export const deliveryMinutes = () => axiosAuthenticationInstance.get('/valet-parking/v1/delivery-minutes');

export const parkingDetail = (id: number) => axiosAuthenticationInstance.get(`/valet-parking/v1/requests/${id}`);

export const attendantDetail = (id: number) => axiosAuthenticationInstance.get(`/valet-parking/v1/attendants/${id}`);

export const saveRequest = (req: SaveRequest) =>
  axiosAuthenticationInstance.put(`/valet-parking/v1/requests/${req.id}/delivery-minutes`, {
    delivery_minute_id: req.delivery_minute_id,
  });

export const createGuestVehicle = (req: GuestCarEntryRequest) =>
  axiosAuthenticationInstance.post(`valet-parking/v1/guest-parking`, req);

export const vehicleRecallRequest = (id: number) =>
  axiosAuthenticationInstance.put(`valet-parking/v1/guest-parking/${id}/processed`);

export const requestGuestVehicle = (id: string) =>
  axiosAuthenticationInstance.post(`valet-parking/v1/guest-parking/${id}/request`);
export const getStates = () => axiosAuthenticationInstance.get(`valet-parking/v1/guest-parking-states`);

export const getRequestStates = () => axiosAuthenticationInstance.get(`/valet-parking/v1/request-states`);
