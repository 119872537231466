import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { RejectProps } from '../types';
import CustomTextArea from '@/src/components/forms/text-area';

const Reject = ({
  note,
  setNote,
  isLoadingSubmit,
  resource,
  closeModal,
  openModal,
  handleSubmit,
  error,
  handleOpenModalCloseConfirmation,
}: RejectProps) => {
  const { t } = useTranslation();
  return (
    <Dialog open={openModal} onClose={closeModal} maxWidth="xs" fullWidth>
      <DialogTitle id="alert-dialog-title">
        {t('reason_rejection_modal_title', { ns: 'cdtModal', resource })}
        <IconButton
          onClick={handleOpenModalCloseConfirmation}
          sx={{
            color: (theme) => theme.palette.grey[500],
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingX: 3, py: 0 }}>
        <CustomTextArea
          placeholder={t('reason_rejection_placeholder', { ns: 'cdtModal' })}
          minRows={3}
          style={{ width: '100%', height: '40px', outline: 'none' }}
          value={note}
          maxLength={500}
          onChange={(e) => {
            setNote(e.target.value);
          }}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </DialogContent>
      <DialogActions sx={{ paddingRight: 3 }}>
        <Button variant="outlined" color="secondary" size="small" onClick={handleOpenModalCloseConfirmation}>
          {t('delete_dialog_cancel_button_text')}
        </Button>
        <LoadingButton size="small" variant="contained" loading={isLoadingSubmit} onClick={handleSubmit}>
          {t('delete_dialog_submit_button_text')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default Reject;
