import { useTranslation } from 'react-i18next';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import GppGood from '@mui/icons-material/GppGood';
import { UserRowProps, Residency } from './types';
import { formatPhoneNumber } from '@/src/utilities/helpers/stringsHelper';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';
import EnableComponent from './EnableComponent';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import { SelectedRow } from '../types';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';
import useResidencyFilters from '@/src/hooks/filters/residencyTypes.hooks';

export const useColumns = (openDetailSection: (value: SelectedRow) => void) => {
  const { t } = useTranslation();
  const queryFilters = [
    { query: 'showEnabled', queryValue: false },
    { query: 'showDisabled', queryValue: true },
  ];
  const lang = getLanguage()?.split('-')[0];
  const { apartments } = useApartmentsFilters();
  const { residencyTypes } = useResidencyFilters();
  const getLastResidency = (row: UserRowProps): Residency => {
    let { residencies: targetResidencies, disabled_date: comparativeDate } = row;
    const currentDateTimestamp = new Date().getTime();

    const activeResidencies = row?.residencies.filter(
      (residency) =>
        !residency.has_checked_out || (residency.from < currentDateTimestamp && residency.to > currentDateTimestamp),
    );

    if (activeResidencies.length > 0) {
      targetResidencies = activeResidencies;
      comparativeDate = currentDateTimestamp;
    }

    return targetResidencies.reduce(
      (closest, residency) =>
        Math.abs(comparativeDate - residency.to) < Math.abs(comparativeDate - closest.to) ? residency : closest,
      targetResidencies[0],
    );
  };

  const columns: CustomDataTableColumnsProps<UserRowProps>[] = [
    {
      id: 'property_code',
      name: t('property_code', { ns: 'customerUsers' }),
      selector: (row: UserRowProps) => getLastResidency(row)?.property?.code,
      sortField: 'property_code',
      sortable: true,
      width: '150px',
      filters: apartments,
    },
    {
      id: 'residentType',
      sortField: 'residentType',
      name: t('resident_type', { ns: 'customerUsers' }),
      selector: (row) => getLastResidency(row)?.type?.name ?? '-',
      sortable: false,
      center: true,
      filters: residencyTypes,
      width: '180px',
    },
    {
      id: 'name',
      name: t('name'),
      selector: (row: UserRowProps) => row.name,
      sortField: 'name',
      sortable: true,
      width: '115px',
      filters: [
        {
          id: 'customer_name',
          query: 'CustomerName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'surname',
      name: t('surname'),
      selector: (row: UserRowProps) => row.surname,
      sortField: 'surname',
      sortable: true,
      width: '115px',
      filters: [
        {
          id: 'customer_sur_name',
          query: 'CustomerSurname',
          type: 'contains',
        },
      ],
    },

    {
      id: 'phone_number',
      name: t('residents_list_phone_number_column', { ns: 'customerUsers' }),
      selector: (row: UserRowProps) => formatPhoneNumber(row.phone_number),
      sortField: 'phone_number',
      sortable: true,
      filters: [
        {
          id: 'customer_full_phone_number',
          query: 'CustomerFullPhoneNumber',
          type: 'contains',
        },
      ],
    },
    {
      selector: (row: UserRowProps) =>
        getLastResidency(row)?.to
          ? getFormattedDateByLanguague(
              lang,
              getFormattedDateFullYearEs,
              getFormattedDateFullYearEn,
              getLastResidency(row)?.from,
            )
          : '',
      id: 'from',
      name: t('from_date', { ns: 'residents' }),
      sortField: 'from',
      // sortable: true,
      center: true,
      filters: [
        {
          id: 'residency_from_date',
          query: 'ResidencyFromDate',
          type: 'date',
        },
      ],
      width: '105px',
    },
    {
      selector: (row: UserRowProps) =>
        getLastResidency(row)?.to
          ? `${getFormattedDateByLanguague(
              lang,
              getFormattedDateFullYearEs,
              getFormattedDateFullYearEn,
              getLastResidency(row)?.to,
            )}`
          : '',
      id: 'to',
      name: t('to_date', { ns: 'residents' }),
      sortField: 'to',
      // sortable: true,
      center: true,
      width: '105px',
      filters: [
        {
          id: 'residency_to_date',
          query: 'ResidencyToDate',
          type: 'date',
          label: t('show_never_expires'),
          value: 'CheckNullable',
        },
      ],
    },
    {
      id: 'disabled_date',
      sortField: 'disabledDate',
      name: t('disabled_date', { ns: 'customerUsers' }),
      selector: (row) =>
        row.disabled_date
          ? getFormattedDateByLanguague(lang, getFormattedDateFullYearEs, getFormattedDateFullYearEn, row.disabled_date)
          : '-',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'disabled_Date',
          query: 'DisabledDate',
          type: 'date',
        },
      ],
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'detail',
      icon: (props: any) => <VisibilityRoundedIcon sx={props.sx} />,
      onIconClick: (personSelected: any) => openDetailSection(personSelected),
      width: { xs: '90%', sm: '80%', lg: 'max-content' },
    },
    {
      id: 'enable',
      icon: (props: any) => <GppGood sx={props.sx} />,
      customizedTitle: true,
      component: (props: any) => (
        <EnableComponent id={props.row.id} resource="customers" isDisabled={props.row.is_disabled} {...props} />
      ),
      width: { xs: '90%', sm: '80%', lg: 'max-content' },
    },
  ];

  return {
    columns,
    actionColumns,
    queryFilters,
  };
};
