import React from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Autocomplete,
  TextField,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useUserProfile } from './useUserProfile';
import PhoneSelect from '@/src/components/phone-select/PhoneSelect';
import CustomInput from '@/src/components/forms/CustomInput';
import CustomLoader from '@/src/components/custom-loader';
import MfaModal from './MfaModal';
import DeactivateModal from './DeactivateModal';

export const UserProfile = () => {
  const newLocal = useUserProfile();
  const {
    SnackBar,
    values,
    isUpdateUserLoading,
    handleSubmit,
    setFieldValue,
    errors,
    languages,
    showData,
    mfaInfo,
    refetchMfa,
  } = newLocal;
  const { t } = useTranslation();

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Card sx={{ my: 0, mx: { xs: '2%' }, borderRadius: '.428rem' }}>
        <CardHeader
          title={t('user_profile_screen_title', { ns: 'auth' })}
          titleTypographyProps={{ variant: 'h5' }}
          sx={{
            borderBottom: `1px solid #ebe9f1`,
            width: '100%',
          }}
        />
        {!showData ? (
          <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'center', margin: '1%' }}>
            <CustomLoader />
          </Grid>
        ) : (
          <Box>
            <CardContent sx={{ paddingBottom: '0px' }}>
              <Grid container spacing={2}>
                <Grid container item spacing={2}>
                  <Grid xs={12} md={6} lg={6} item>
                    <CustomInput
                      field="name"
                      errors={errors}
                      setFieldValue={setFieldValue}
                      values={values}
                      autoFocus
                      label={t('user_profile_form_name_field_label', { ns: 'auth' })}
                      placeholder={t('user_profile_form_name_field_placeholder', { ns: 'auth' })}
                      maxLength={50}
                    />
                  </Grid>
                  <Grid xs={12} md={6} lg={6} item>
                    <CustomInput
                      field="surname"
                      errors={errors}
                      setFieldValue={setFieldValue}
                      label={t('user_profile_form_surname_field_label', { ns: 'auth' })}
                      placeholder={t('user_profile_form_surname_field_placeholder', { ns: 'auth' })}
                      values={values}
                      maxLength={50}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2}>
                  <Grid xs={12} md={6} lg={6} item>
                    <PhoneSelect
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      withBorder={false}
                      isDisabled
                      withPadding={false}
                    />
                  </Grid>
                  <Grid xs={12} md={6} lg={6} item>
                    <CustomInput
                      field="email"
                      errors={errors}
                      label={t('user_profile_form_email_field_label', { ns: 'auth' })}
                      setFieldValue={setFieldValue}
                      values={values}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} md={6} lg={6} item display="flex" justifyContent="space-between" alignItems="center">
                  {!mfaInfo?.enabled ? (
                    <>
                      <Typography variant="subtitle2">{t(`not_authenticated_user_message`, { ns: 'auth' })}</Typography>
                      <MfaModal refetchMfa={refetchMfa} />
                    </>
                  ) : (
                    <>
                      <Typography variant="subtitle2">
                        {t(`authenticated_user_message`, { ns: 'auth' })}{' '}
                        {mfaInfo?.mfaSettings?.length > 0 && mfaInfo?.mfaSettings?.map((set: any) => set)}
                      </Typography>
                      <DeactivateModal refetchMfa={refetchMfa} />
                    </>
                  )}
                </Grid>
                <Grid xs={12} md={6} lg={6} item>
                  <Autocomplete
                    id="language"
                    size="small"
                    value={languages?.find((lang: any) => values?.language === lang.id) ?? null}
                    getOptionLabel={(option) => option?.code || ''}
                    isOptionEqualToValue={(option, value) => option === value || value === ''}
                    options={languages ?? []}
                    onChange={(e, value) => {
                      setFieldValue('language', value.id ?? '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={t('language', { ns: 'auth' })}
                        placeholder={t('messages_list_state_filter_placeholder', { ns: 'auth' })}
                        sx={{ whidth: '100%' }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container direction="row" justifyContent="flex-end" m={1} gap={2}>
                <Grid item xs={12} lg={1.5}>
                  <LoadingButton fullWidth type="submit" size="small" variant="contained" loading={isUpdateUserLoading}>
                    {t('user_profile_screen_submit_button_text', { ns: 'auth' })}
                  </LoadingButton>
                </Grid>
              </Grid>
            </CardActions>
          </Box>
        )}
        <SnackBar />
      </Card>
    </Box>
  );
};
