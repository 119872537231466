import { useQuery } from '@tanstack/react-query';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getAgendas } from '@/src/api/endpoints/bookings';

const useAgendasFilters = () => {
  const getAgendasMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.description,
          value: item.id,
          query: 'AgendaIds',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: agendaList, isLoading } = useQuery(['getAgendas'], getAgendas, {
    select: (response) => getAgendasMapper(response?.data?.agendas),
  });

  return { agendaList, isLoading };
};

export default useAgendasFilters;
