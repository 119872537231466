import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FormikValues, useFormik } from 'formik';
import { createAdminUser, getAdminGroups } from '@/src/api/endpoints/admin-users';
import { DataTableFormModal } from '@/src/components/custom-data-table/types/DataTableFormModal';
import UserForm2 from './AdministratorForm';
import { validate } from './AdministratorForm.validate';
import { Activities, AdminUsers, BodyCreateAdmin } from '../../types';
import { getAmenities } from '@/src/api/endpoints/bookings';

const AdministratorContainer = ({ close, setSnackBarMessage }: DataTableFormModal) => {
  const { data: response, isLoading: loading } = useQuery(['getAdminGroups'], () => getAdminGroups());
  const adminUserGroups: Array<AdminUsers> | undefined | null = response?.data.groups;
  const { data: amenitiesData, isLoading: getAmenitiesLoading } = useQuery(['activities'], () =>
    getAmenities({ page: 0 }),
  );
  const amenitiesList: { activities: Array<Activities> | undefined | null; totalCount: number } = amenitiesData?.data;
  const [errorMessage, setErrorMessage] = useState<{ code: string; message: string; property_name: string } | null>(
    null,
  );
  const { t } = useTranslation();
  const { mutate, isLoading } = useMutation(createAdminUser, {
    onError: async (e: any) => {
      setErrorMessage(e.response.data.error_messages[0]);
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successful_user_creation_snackbar_text', { ns: 'users' }));
    },
  });

  const getInitialValues = () => ({
    adminGroups: [],
    confirmPassword: '',
    email: '',
    name: '',
    notes: '',
    password: '',
    phoneNumber: '',
    countryCode: '',
    countryIsoCode: '',
    surname: '',
    activityIds: [],
  });

  const onSubmit = (data: FormikValues) => {
    const body: BodyCreateAdmin = {
      email: data.email.trim(),
      group_ids: data.adminGroups?.map((x: { id: number; name: string }) => x.id),
      name: data.name,
      notes: data.notes,
      password: data.password,
      phone_number: data.phoneNumber,
      surname: data.surname,
      country_code: data.countryCode,
      country_iso_code: data.countryIsoCode,
      activity_ids: data.activityIds?.map((x: { id: number; name: string }) => x.id),
    };
    mutate(body);
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate({ t }),
  });

  const childProps = {
    adminUserGroups,
    amenitiesList,
    getAmenitiesLoading,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    loading,
    errorMessage,
    isSubmitLoading: isLoading,
    showAmenitiesSelector:
      (values?.adminGroups?.find((rol: AdminUsers) => rol.code === 'Bookings') &&
        !values?.adminGroups?.find((rol: AdminUsers) => rol.code === 'Admins')) ??
      false,
  };
  return <UserForm2 {...childProps} />;
};

export default AdministratorContainer;
