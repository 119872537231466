import { useTranslation } from 'react-i18next';
import React, { Dispatch } from 'react';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import PublicationsFilter from './PublicationsFilter';
import { tRequiredFieldError } from '@/src/utilities/helpers/appConstants';

const getInitialValues = () => ({
  creationDateStart: '',
  creationDateEnd: '',
  isRestricted: null,
});

const getValidationSchema = (resource: string) =>
  Yup.lazy(() =>
    Yup.object().shape({
      creationDateStart: Yup.string().when([], {
        is: () => resource !== 'community',
        then: Yup.string().required(tRequiredFieldError),
        otherwise: Yup.string(),
      }),
      creationDateEnd: Yup.string(),
      isRestricted: Yup.boolean().nullable(),
    }),
  );

const PublicationsFilterContainer = (props: Props) => {
  const { resource, openModal, setOpenModal, endpoint, setPostData, isRestricted } = props;
  const { t } = useTranslation();

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleSubmit = async (data: FormikValues) => {
    const response = await endpoint(
      null,
      data?.creationDateStart ? data?.creationDateStart : null,
      data?.creationDateEnd ? data?.creationDateEnd : new Date().getTime(),
      data?.isRestricted !== null ? data?.isRestricted : null,
    );
    switch (resource) {
      case 'community':
        setPostData(response?.data?.posts);
        break;
      default:
        setPostData(response?.data?.publications);
        break;
    }
  };

  const formikInitProps = React.useMemo(
    () =>
      ({
        initialValues: getInitialValues(),
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        validationSchema: getValidationSchema(resource),
        enableReinitialize: true,
        onSubmit: handleSubmit,
      } as FormikConfig<FormikValues>),
    [handleSubmit],
  );

  const formik = useFormik(formikInitProps);

  const childProps = {
    t,
    openModal,
    setOpenModal,
    handleClose,
    handleSubmit,
    formik,
    isRestricted,
  };
  return <PublicationsFilter {...childProps} />;
};

const propTypes = {};

interface ExtraProps {
  resource: string;
  openModal: boolean;
  setOpenModal: (x: boolean) => void;
  endpoint: any;
  setPostData: Dispatch<any>;
  isRestricted?: boolean | undefined;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
PublicationsFilterContainer.propTypes = propTypes;

export default PublicationsFilterContainer;
