import { useEffect, useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import axiosAnonInstanceFake from '@/src/api/instances/anonInstancesFake';
import { axiosAuthenticationInstance } from '@/src/api/instances/authInstance';
import useSnackBar from '../useSnackBar';
import { useCustomDataTable } from '../CustomDataTableContext';
import useBreakpoints from '@/src/hooks/common/useBreakpoints';
import { QueryProps } from '@/src/utilities/helpers/commonTypes';
import { FirebaseContext } from '@/src/context/firebase.context';

const useCustomFetch = (
  pathApi: string,
  listName: string,
  canDisable: boolean,
  hasFilterList: boolean,
  clientSidePagination: boolean,
  columns: Array<any>,
  fake?: boolean,
  extraFilters?: Array<QueryProps>,
  defaultSortFieldId?: string,
  defaultSortAsc?: boolean,
  customPageSize?: number,
) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const { notificationInfo, setNotificationInfo } = useContext(FirebaseContext);
  const { SnackBar } = useSnackBar();
  const { i18n } = useTranslation();
  const { needRefresh, setNeedRefresh, showDisabled, filterString, setFilterString, setShowDisabled } =
    useCustomDataTable();
  const { matchesSm } = useBreakpoints();
  const pageSize: number = customPageSize ?? Math.ceil((window.innerHeight - (matchesSm ? 350 : 260)) / 30) - 1;
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [orderField, setOrderField] = useState(defaultSortFieldId);
  const [orderDescending, setOrderDescending] = useState<boolean>(!defaultSortAsc);
  const [modalQueryFilters, setModalQueryFilters] = useState(extraFilters);
  const [startRefresh, setStartRefresh] = useState(false);

  useEffect(
    () => () => {
      setFilterString('');
      setShowDisabled(false);
    },
    [],
  );

  useEffect(() => {
    if (notificationInfo !== null) {
      const actualRoute = window.location.pathname;
      if (actualRoute === notificationInfo?.url) {
        setNeedRefresh(true);
      }
      setNotificationInfo(null);
    }
  }, [notificationInfo]);

  useEffect(() => {
    if (startRefresh) {
      setModalQueryFilters(extraFilters);
      setStartRefresh(false);
    }
  }, [startRefresh]);

  useEffect(() => {
    if (!hasFilterList) {
      setModalQueryFilters(extraFilters);
    }
  }, [extraFilters, hasFilterList]);

  const addExtraQueryFilters = () => {
    let extraQueryFilters = '';
    if (!clientSidePagination)
      extraQueryFilters = `&page=${page}&pageSize=${pageSize}&orderField=${orderField}&orderDescending=${orderDescending}`;
    if (canDisable) extraQueryFilters += `&showDisabled=${showDisabled}`;

    modalQueryFilters?.forEach((element: QueryProps) => {
      extraQueryFilters += `&${element?.query}=${element?.queryValue}`;
    });

    extraQueryFilters += filterString;

    return extraQueryFilters;
  };

  const loadData = async (abort?: any) => {
    setLoading(true);
    const url = `${pathApi}?searchText=${searchText}${addExtraQueryFilters()}`;
    setTimeout(async () => {
      try {
        const instance = fake ? axiosAnonInstanceFake : axiosAuthenticationInstance;
        const response = await instance.get(url, abort);
        const resp = response.data;
        const listNameSplit = listName.split('.');
        let getAllObject: any = null;
        listNameSplit.forEach((element) => {
          if (!getAllObject) {
            getAllObject = resp[element];
          } else {
            getAllObject = getAllObject[element];
          }
        });
        setData(getAllObject);
        setTotalRows(resp.total_count);
      } catch (e: any) {
        setData([]);
      }
      if (!abort?.signal.aborted) setLoading(false);
    }, 1000);
  };
  useEffect(() => {
    const abortCont = new AbortController();
    loadData({ signal: abortCont.signal });
    return () => abortCont.abort();
  }, [page, searchText, orderField, orderDescending, showDisabled, i18n.language, modalQueryFilters, filterString]);

  useEffect(() => {
    if (needRefresh) loadData();
    return function cleanup() {
      setNeedRefresh(false);
    };
  }, [needRefresh]);

  const filterColumns = useMemo(
    () => columns.filter((col) => !col.isHidden || (col.isHidden === 'showDisabled' && showDisabled)),
    [loadData],
  );

  const handlePageChange = (pag: number) => {
    setPage(pag - 1);
  };

  const handleSort = (column: any, sortDirection: string) => {
    if (column?.sortField !== undefined) {
      setOrderField(column.sortField);
    }

    setOrderDescending(sortDirection === 'desc');
  };

  return {
    SnackBar,
    data,
    handlePageChange,
    handleSort,
    loading,
    pageSize,
    setSearchText,
    totalRows,
    setNeedRefresh,
    setModalQueryFilters,
    setStartRefresh,
    filterColumns,
    orderDescending,
    orderField,
    filterString,
    setFilterString,
  };
};

export default useCustomFetch;
