import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';

const useGuestTypesFilters = () => {
  const { t } = useTranslation();

  const guestTypes = React.useMemo(
    () =>
      [
        {
          id: 1,
          label: t('guests_page_recurrent_column', {
            ns: 'guests',
          }),
          value: true,
          query: 'IsRecurring',
        },
        {
          id: 2,
          label: t('guests_page_one_time_column', {
            ns: 'guests',
          }),
          value: false,
          query: 'IsRecurring',
        },
      ] as FilterByColumn[],
    [t],
  );

  return { guestTypes };
};

export default useGuestTypesFilters;
