import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

export const CancellationsPage = () => {
  const { columns } = useColumns();
  const { t } = useTranslation();
  return (
    <CustomDataTable
      title={t('cancellations_page_list_title', { ns: 'bookings' })}
      columns={columns}
      resource="bookings"
      pathApi="bookings/v1/bookings/cancelled"
      listName="bookings"
      canSearch
      defaultSortFieldId="id"
      defaultSortAsc={false}
      canExport
    />
  );
};
