import { useTranslation } from 'react-i18next';
import { Visibility } from '@mui/icons-material';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import {
  getDayHourYearDateEn,
  getDayHourYearDateEs,
  getFormattedDateByLanguague,
} from '@/src/utilities/helpers/dateParser';
import TextLabel from '@/src/components/text-label/TextLabel';
import { ParkingResidents, valetParkingStates } from './types';
import ParkingDetail from './residents-parking-detail/ResidentsParkingDetail';
import { getLanguage } from '@/src/utilities/storage';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';
import useResidencyFilters from '@/src/hooks/filters/residencyTypes.hooks';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';
import useVehicleBrands from '@/src/hooks/filters/vehicleBrandsModels.hooks';
import useRequestStatesFilters from '@/src/hooks/filters/requestStates.hook';

export const useColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const { residencyTypes } = useResidencyFilters();
  const { apartments } = useApartmentsFilters();
  const { vehicleBrands } = useVehicleBrands();
  const { requestStates } = useRequestStatesFilters();

  const actionColumns: ActionColumn[] = [
    {
      id: 'detail',
      icon: (props: any) => <Visibility sx={props.sx} />,
      component: (props: any) => <ParkingDetail {...props} />,
      width: { xs: '90%', sm: '585px' },
      customizedTitle: true,
    },
  ];

  const columns: CustomDataTableColumnsProps<ParkingResidents>[] = [
    {
      id: 'id',
      name: t('parking_page_table_column_application_number', { ns: 'parking' }),
      selector: (row: ParkingResidents) => row.id,
      sortField: 'id',
      sortable: true,
      right: true,
      maxWidth: '1%',
      filters: [
        {
          id: 'id',
          query: 'RequestId',
          type: 'contains',
        },
      ],
    },
    {
      id: 'creation_date',
      name: t('parking_page_table_column_creation', { ns: 'parking' }),
      selector: (row: ParkingResidents) =>
        row?.creation_date > 0
          ? getFormattedDateByLanguague(lang, getDayHourYearDateEs, getDayHourYearDateEn, row?.creation_date)
          : '-',
      sortField: 'creation_date',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'creation-date',
          query: 'CreationDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'functional_unit',
      name: t('parking_page_functional_unit_column', { ns: 'parking' }),
      selector: (row: ParkingResidents) => row?.residency?.property?.code,
      sortField: 'property_code',
      sortable: true,
      filters: apartments,
    },
    {
      id: 'resident_type',
      name: t('resident_type', { ns: 'parking' }),
      selector: (row: ParkingResidents) => row?.residency?.type?.name,
      sortField: 'resident_type',
      sortable: true,
      filters: residencyTypes,
    },
    {
      id: 'applicant',
      name: t('parking_page_table_column_applicant', { ns: 'parking' }),
      selector: (row: ParkingResidents) => `${row?.customer?.name} ${row?.customer?.surname}`,
      sortField: 'applicant',
      sortable: true,
      filters: [
        {
          id: 'resident-full-name',
          query: 'CustomerFullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'vehicle',
      name: t('parking_page_table_column_vehicle', { ns: 'parking' }),
      selector: (row: ParkingResidents) => `${row?.vehicle?.brand?.name} ${row?.vehicle?.model?.name}`,
      sortField: 'vehicle',
      sortable: true,
      filters: vehicleBrands,
    },
    {
      id: 'delivery_date',
      name: t('parking_page_table_column_process', { ns: 'parking' }),
      selector: (row: ParkingResidents) =>
        row?.delivery_date
          ? getFormattedDateByLanguague(lang, getDayHourYearDateEs, getDayHourYearDateEn, row?.delivery_date)
          : ' - ',
      sortField: 'delivery_date',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'delivery-date',
          query: 'DeliveryDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'status',
      cell: (row: ParkingResidents) => (
        <TextLabel
          text={row?.request_state?.name?.toUpperCase()}
          padding="2px 12px"
          width="100px"
          bg={valetParkingStates[row?.request_state?.name]}
          color="white"
        />
      ),
      name: t('guests_page_status_column', { ns: 'guests' }),
      selector: (row: ParkingResidents) => row?.request_state?.name,
      sortField: 'request_state',
      sortable: true,
      center: true,
      minWidth: '150px',
      filters: requestStates,
    },
  ];

  return { columns, actionColumns };
};
