import { useQuery } from '@tanstack/react-query';
import { getPerksBenefitsById } from '@/src/api/endpoints/perksBenefits';
import { ArticleByIdBody } from './types';

const getPerksBenefitsByIdData = async (id: number) => {
  const response = await getPerksBenefitsById(id);
  return response.data;
};

export const useGetPerksBenefitsById = (id: number) => {
  const { data, isLoading, refetch } = useQuery(
    [`${id}`, getPerksBenefitsByIdData],
    () => getPerksBenefitsByIdData(id),
    {
      cacheTime: 10000,
    },
  );
  const dataById: ArticleByIdBody = data;
  return { dataById, isLoading, refetch };
};
