import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { useTranslation } from 'react-i18next';
import { RelativesRowProps } from '../users/users-section/types';
import ResidentRequestDetail from './ResidentDetail';
import { CustomIconProps } from '../../../components/custom-data-table/types/DataTableFormModal';
import TextLabel from '@/src/components/text-label/TextLabel';
import { commonStatus } from '../common/types';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';
import useRelationshipTypesFilters from '@/src/hooks/filters/relationshipTypes.hooks';

export const useColumns = () => {
  const { t } = useTranslation();
  const { apartments } = useApartmentsFilters();
  const { relationshipTypes } = useRelationshipTypesFilters();

  const columnsRelativesRequest = [
    {
      id: 'apartment',
      sortField: 'residency_property_code',
      name: t('user_detail_apartment', { ns: 'customerUsers' }),
      selector: (row: RelativesRowProps) => row?.residency?.property?.code,
      sortable: true,
      filters: apartments,
    },
    {
      name: t('name'),
      sortField: 'holder_name',
      selector: (row: RelativesRowProps) => row?.relative?.person?.name,
      sortable: true,
      filters: [
        {
          id: 'person-name',
          query: 'Name',
          type: 'contains',
        },
      ],
    },
    {
      name: t('surname'),
      sortField: 'holder_surname',
      selector: (row: RelativesRowProps) => row?.relative?.person?.surname,
      sortable: true,
      minWidth: '140px',
      filters: [
        {
          id: 'person-surname',
          query: 'Surname',
          type: 'contains',
        },
      ],
    },
    {
      name: t('relationship', { ns: 'customerUsers' }),
      selector: (row: RelativesRowProps) => row?.relative?.relationship_type?.name,
      sortField: 'relative_relationship_type',
      sortable: true,
      minWidth: '140px',
      filters: relationshipTypes,
    },
    {
      name: t('email'),
      sortField: 'relative_email',
      selector: (row: RelativesRowProps) => row?.relative?.person?.email,
      sortable: true,
      minWidth: '180px',
      filters: [
        {
          id: 'person-email',
          query: 'email',
          type: 'contains',
        },
      ],
    },
    {
      cell: (row: RelativesRowProps) => (
        <TextLabel
          text={row?.relative?.state?.name?.toLocaleUpperCase()}
          color="#F7F7F7"
          padding="2px 12px"
          width="100px"
          bg={commonStatus[row?.relative?.state?.code.replaceAll(' ', '_')?.replaceAll('ó', 'o')]}
        />
      ),
      name: t('status'),
      selector: (row: RelativesRowProps) => row?.relative?.state?.name,
      sortable: true,
      center: true,
      sortField: 'relative_state',
    },
  ];
  const actionColumnRelatives = [
    {
      id: 'view_request',
      icon: ({ sx }: CustomIconProps) => <VisibilityRoundedIcon sx={sx} />,
      component: (props: any) => <ResidentRequestDetail {...props} />,
      customizedTitle: true,
      width: { xs: '90%', sm: '580px', md: '600px' },
    },
  ];

  return {
    columnsRelativesRequest,
    actionColumnRelatives,
  };
};
