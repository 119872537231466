import { useQuery } from '@tanstack/react-query';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getPetSexes } from '@/src/api/endpoints/houseHold';

const useSexesFilters = () => {
  const getSexesMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.id,
          query: 'PetSexIds',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: sexes, isLoading } = useQuery(['getSexes'], getPetSexes, {
    select: (response) => getSexesMapper(response?.data?.sexes),
  });

  return { sexes, isLoading };
};

export default useSexesFilters;
