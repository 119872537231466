import { useTranslation } from 'react-i18next';
import { VisibilityRounded } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import TextLabel from '@/src/components/text-label/TextLabel';
import { Delivery, deliveryStates } from './types';
import { ActionColumn, ActionHeader } from '@/src/components/custom-data-table/types/DataTableFormModal';
import DeliveryInfoContainer from './delivery-info/DeliveryInfo.container';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import DeliveryFormContainer from './delivery-form/DeliveryForm.container';
import { getLanguage } from '@/src/utilities/storage';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEs,
  getFormattedDateFullYearEn,
} from '@/src/utilities/helpers/dateParser';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';
import useCarriersFilters from '@/src/hooks/filters/carriers.hooks';
import useAuthorizationStatesFilters from '@/src/hooks/filters/authorizationStates.hooks';

export const useColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const { apartments } = useApartmentsFilters();
  const { carriers } = useCarriersFilters();
  const { authorizationStates } = useAuthorizationStatesFilters();

  const columns: CustomDataTableColumnsProps<Delivery>[] = [
    {
      id: 'id',
      sortField: 'id',
      name: t('delivery_page_column_id', { ns: 'delivery' }),
      selector: (row: Delivery) => row.id,
      sortable: true,
      right: true,
      maxWidth: '1%',
      filters: [
        {
          id: 'id',
          query: 'AuthorizationId',
          type: 'numeric',
        },
      ],
    },
    {
      id: 'resident',
      sortField: 'resident',
      name: t('delivery_page_column_resident', { ns: 'delivery' }),
      selector: (row: Delivery) => (row.customer ? `${row.customer.name} ${row.customer.surname}` : '-'),
      sortable: true,
      filters: [
        {
          id: 'resident-full-name',
          query: 'CustomerFullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'property_code',
      sortField: 'property_code',
      name: t('delivery_modal_input_property_code', { ns: 'delivery' }),
      selector: (row: Delivery) => row.property.code,
      sortable: true,
      filters: apartments,
    },
    {
      id: 'carrier_name',
      sortField: 'carrier_name',
      name: t('delivery_modal_input_carrier', { ns: 'delivery' }),
      selector: (row: Delivery) => row.carrier.name,
      sortable: true,
      filters: carriers,
    },
    {
      cell: (row: Delivery) => (
        <TextLabel
          text={row.authorization_state.name.toLocaleUpperCase()}
          color="#F7F7F7"
          padding="2px 12px"
          width="100px"
          bg={deliveryStates[row.authorization_state.name]}
        />
      ),
      name: t('delivery_page_column_status', { ns: 'delivery' }),
      selector: (row) => row.authorization_state.name,
      id: 'authorization_state',
      sortable: true,
      center: true,
      maxWidth: '175px',
      sortField: 'authorization_state',
      filters: authorizationStates,
    },
    {
      id: 'creation_date',
      name: t('delivery_page_column_creation_date', { ns: 'delivery' }),
      selector: (row: Delivery) =>
        row?.authorization_date
          ? getFormattedDateByLanguague(
              lang,
              getFormattedDateFullYearEs,
              getFormattedDateFullYearEn,
              row?.authorization_date,
            )
          : '-',
      sortField: 'creation_date',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'delivery-creation-date',
          query: 'DeliveryDate',
          type: 'date',
        },
      ],
    },
  ];

  const headerActions: ActionHeader[] = [
    {
      id: 'check-delivery',
      component: () => (
        <CustomTableAction
          modalTitle="delivery"
          customizedTitle
          component={(props: any) => <DeliveryFormContainer {...props} />}
          Icon={() => <AddIcon />}
          ButtonElement={({ open }: { open: () => void }) => (
            <Button type="button" variant="outlined" onClick={() => open()}>
              {t('delivery_page_button_report_delivery', { ns: 'delivery' })}
            </Button>
          )}
          type="btn_to_grid"
          width={{ xs: '90%', sm: '80%', lg: '700px' }}
        />
      ),
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'detail',
      icon: (props: any) => <VisibilityRounded sx={props.sx} />,
      customizedTitle: true,
      component: (props: any) => <DeliveryInfoContainer {...props} deliveryId={props.row.id} />,
      width: { xs: '90%', sm: '500px', lg: '700px' },
    },
  ];

  return { columns, headerActions, actionColumns };
};
