import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';
import { SelectedRow } from '../types';

export const UsersSection = ({ openDetailSection }: { openDetailSection: (value: SelectedRow) => void }) => {
  const { columns, actionColumns } = useColumns(openDetailSection);
  const { t } = useTranslation();

  return (
    <CustomDataTable
      title={t('customers')}
      columns={columns}
      resource="customers"
      pathApi="accounts/v1/customers"
      listName="customers"
      canDisable
      canExport
      canSearch
      defaultSortFieldId="name"
      defaultSortAsc
      customActionColumns={actionColumns}
    />
  );
};
