import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';
import {
  AddIcCallOutlined,
  Add,
  Delete,
  Visibility,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { ActionHeader, ColumnsPetsType, ColumnsRelativesType, ColumnsVehiclesType, UserResidencyProps } from './types';

import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { ActionColumn, CustomIconProps } from '@/src/components/custom-data-table/types/DataTableFormModal';
import { VehiclesDetailModal } from '../../household/vehicles/vehicles-detail/VehiclesDetail';
import { PetDetailModal } from '../../household/pets/pet-detail/PetDetailModal';
import ResidentRequestDetail from '../../household/residents-requests/ResidentDetail';
import PetsForm from '../../household/pets/pets-form';
import DeleteComponent from '../../household/pets/delete-pet';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import { PeopleRowProps } from '../residents-section/types';
import DeleteRelative from './delete-relative';
import ResidentsRelativesForm from './relative-form';

import VehicleDeleteModal from '../../household/vehicles/vehicles-delete';
import VehiclesModal from '../../household/vehicles/vehicles-form';
import useRelationshipTypesFilters from '@/src/hooks/filters/relationshipTypes.hooks';
import useSexesFilters from '@/src/hooks/filters/sexes.hooks';
import usePetTypesAndBreedsFilters from '@/src/hooks/filters/typesAndBreeds.hooks';
import useVehicleFuelTypes from '@/src/hooks/filters/vehicleFuelType.hooks';
import useVehicleColors from '@/src/hooks/filters/vehicleColors.hooks';
import useVehicleBrands from '@/src/hooks/filters/vehicleBrandsModels.hooks';

export const useColumns = (selectedRow: PeopleRowProps | UserResidencyProps) => {
  const { t } = useTranslation();
  const { relationshipTypes } = useRelationshipTypesFilters();
  const { sexes } = useSexesFilters();
  const { breeds, petTypes } = usePetTypesAndBreedsFilters();
  const { vehicleBrands, vehicleModels } = useVehicleBrands();
  const { colors } = useVehicleColors();
  const { fuelTypes } = useVehicleFuelTypes();

  const columnsPets: CustomDataTableColumnsProps<ColumnsPetsType>[] = [
    {
      id: 'name',
      sortField: 'pet_name',
      name: t('name'),
      selector: (row) => row?.pet?.name,
      sortable: true,
      filters: [
        {
          id: 'pet-name',
          query: 'PetName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'kind',
      sortField: 'kind',
      name: t('kind', { ns: 'customerUsers' }),
      selector: (row) => row?.pet?.type?.name,
      sortable: true,
      filters: petTypes,
    },
    {
      id: 'birthday',
      sortField: 'pet_birthdate',
      name: t('birthday', { ns: 'customerUsers' }),
      selector: (row) => row?.pet?.birthday,
      sortable: true,
      filters: [
        {
          id: 'birthday',
          query: 'Birthday',
          type: 'min-max',
        },
      ],
    },
    {
      id: 'breed',
      sortField: 'pet_breed',
      name: t('breed', { ns: 'customerUsers' }),
      selector: (row) => row?.pet?.breed?.name,
      sortable: true,
      filters: breeds,
    },
    {
      id: 'sex',
      sortField: 'pet_sex',
      name: t('sex', { ns: 'customerUsers' }),
      selector: (row) => row?.pet?.sex?.name,
      sortable: true,
      filters: sexes,
    },
    {
      id: 'is_sterialized',
      sortField: 'pet_is_sterilized',
      name: t('is_sterialized', { ns: 'customerUsers' }),
      selector: (row) => (row?.pet?.is_sterilized ? `${t('yes')}` : 'No'),
      cell: (row) => <Checkbox checked={row?.pet?.is_sterilized} disabled sx={{ padding: '0px' }} />,
      center: true,
      sortable: false,
    },
  ];

  const columnsRelatives: CustomDataTableColumnsProps<ColumnsRelativesType>[] = [
    {
      id: 'relationship',
      sortField: 'relationship_type',
      name: t('relationship', { ns: 'customerUsers' }),
      selector: (row) => row?.relative?.relationship_type?.name ?? '',
      sortable: true,
      minWidth: '140px',
      filters: relationshipTypes,
    },
    {
      id: 'name',
      sortField: 'name',
      name: t('name'),
      selector: (row) => `${row?.relative?.person?.name} ${row?.relative?.person?.surname}`,
      sortable: true,
      filters: [
        {
          id: 'name',
          query: 'FullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'email',
      sortField: 'email',
      name: t('email'),
      selector: (row) => row?.relative?.person?.email,
      sortable: true,
      minWidth: '180px',
      filters: [
        {
          id: 'person-email',
          query: 'Email',
          type: 'contains',
        },
      ],
    },
    // {
    //   id: 'phone_number',
    //   sortField: 'phone_number',
    //   name: t('residents_list_phone_number_column', { ns: 'customerUsers' }),
    //   selector: (row) => `${row?.relative?.person?.country_code ?? ''} ${row?.relative?.person?.phone_number ?? '-'}`,
    //   sortable: true,
    //   minWidth: '180px',
    // },
  ];

  const columnsVehicles: CustomDataTableColumnsProps<ColumnsVehiclesType>[] = [
    {
      id: 'vehicle_brand',
      sortField: 'vehicle_brand',
      name: t('vehicle_brand', { ns: 'customerUsers' }),
      selector: (row) => row?.vehicle?.model?.brand?.name,
      sortable: true,
      filters: vehicleBrands,
    },
    {
      id: 'vehicle_model',
      sortField: 'vehicle_model',
      name: t('vehicle_model', { ns: 'customerUsers' }),
      selector: (row) => row?.vehicle?.model?.name,
      sortable: true,
      filters: vehicleModels,
    },
    {
      id: 'vehicle_color',
      sortField: 'vehicle_color',
      name: t('vehicle_color', { ns: 'customerUsers' }),
      selector: (row) => row?.vehicle?.color?.name,
      sortable: true,
      filters: colors,
    },
    {
      id: 'vehicle_plate',
      sortField: 'vehicle_license_plate',
      name: t('vehicle_plate', { ns: 'customerUsers' }),
      selector: (row) => row?.vehicle?.license_plate,
      sortable: true,
      filters: [
        {
          id: 'vehicle-full-plate',
          query: 'VehicleLicensePlate',
          type: 'contains',
        },
      ],
    },
    {
      id: 'fuel_type_value',
      sortField: 'vehicle_fuel_type',
      name: t('fuel_type_value', { ns: 'customerUsers' }),
      selector: (row) => (row?.vehicle?.fuel_type?.name ? row?.vehicle?.fuel_type?.name : '-'),
      sortable: true,
      filters: fuelTypes,
    },
    {
      id: 'is_rented',
      sortField: 'vehicle_is_rented',
      name: t('is_rented', { ns: 'customerUsers' }),
      selector: (row) => (row?.vehicle?.is_rented ? `${t('yes')}` : 'No'),
      cell: (row) => <Checkbox checked={row?.vehicle?.is_rented} disabled sx={{ padding: '0px' }} />,
      center: true,
      sortable: false,
    },
  ];

  const actionColumnsVehicles: ActionColumn[] = [
    {
      id: 'detail',
      icon: ({ sx }: CustomIconProps) => <Visibility sx={sx} />,
      component: (props: any) => <VehiclesDetailModal {...props} showButtons={false} />,
      customizedTitle: true,
      width: { xs: '90%', sm: '540px' },
    },
    {
      id: 'edit',
      icon: ({ sx }: CustomIconProps) => <EditIcon sx={sx} />,
      component: (props: any) => <VehiclesModal isEdit {...props} />,
      customizedTitle: true,
      width: { xs: '90%', sm: '540px' },
    },
    {
      id: 'delete_btn',
      icon: ({ sx }: CustomIconProps) => <Delete sx={sx} />,
      component: (props: any) => <VehicleDeleteModal {...props} />,
      width: { xs: '90%', sm: '540px' },
    },
  ];

  const vehicleHeaderActions: ActionHeader[] = [
    {
      id: 'vehicles-create',
      component: () => (
        <CustomTableAction
          modalTitle="vehicle-create"
          customizedTitle
          component={(props: any) => <VehiclesModal id={selectedRow?.residency?.id} {...props} />}
          Icon={() => <AddIcCallOutlined />}
          type="create"
          width="80%"
        />
      ),
    },
  ];

  const actionColumnsPets: ActionColumn[] = [
    {
      id: 'detail',
      icon: ({ sx }: CustomIconProps) => <Visibility sx={sx} />,
      component: (props: any) => <PetDetailModal {...props} />,
      customizedTitle: true,
      width: { xs: '90%', sm: '500px', md: '550px' },
    },
    {
      id: 'edit',
      icon: (props) => <EditIcon sx={props.sx} />,
      component: (props: any) => <PetsForm {...props} isEditForm />,
      width: { xs: '90%', sm: '80%' },
    },
    {
      id: 'delete',
      icon: (props) => <DeleteIcon sx={props.sx} />,
      component: (props: any) => <DeleteComponent {...props} />,
      width: { xs: '90%', sm: '500px', md: '550px' },
    },
  ];

  const actionColumnsRelatives: ActionColumn[] = [
    {
      id: 'view_request',
      icon: ({ sx }: CustomIconProps) => <Visibility sx={sx} />,
      component: (props: any) => <ResidentRequestDetail {...props} />,
      customizedTitle: true,
      width: { xs: '90%', sm: '580px', md: '600px' },
    },
    {
      id: 'edit',
      icon: ({ sx }: CustomIconProps) => <EditIcon sx={sx} />,
      component: (props: any) => <ResidentsRelativesForm {...props} isEdit />,
      width: { xs: '50%', sm: '70%', md: '70%' },
    },
    {
      id: 'delete_btn',
      icon: (props: any) => <DeleteRoundedIcon sx={props.sx} />,
      component: (props: any) => <DeleteRelative {...props} />,
      width: '23%',
    },
  ];

  const headerActionsPets: ActionHeader[] = [
    {
      id: 'create_pet',
      component: () => (
        <CustomTableAction
          modalTitle="pet"
          component={(props: any) => <PetsForm {...props} residentRow={selectedRow} />}
          Icon={() => <AddIcon />}
          type="create"
          width={{ xs: '90%', sm: '80%' }}
        />
      ),
    },
  ];
  const headerActionsRelatives: ActionHeader[] = [
    {
      id: 'people-create',
      component: () => (
        <CustomTableAction
          modalTitle="people"
          component={(props: any) => <ResidentsRelativesForm {...props} residencyId={selectedRow?.residency?.id} />}
          Icon={() => <Add />}
          type="create"
          width={{ xs: '90%', lg: '70%', xl: '55%' }}
        />
      ),
    },
  ];

  return {
    columnsPets,
    columnsRelatives,
    columnsVehicles,
    actionColumnsVehicles,
    actionColumnsPets,
    actionColumnsRelatives,
    vehicleHeaderActions,
    headerActionsPets,
    headerActionsRelatives,
  };
};
