import { axiosAuthenticationInstance } from '../instances/authInstance';
import { CreateMarketingRequest, EditMarketingRequest } from '../types/types';

export const getMarketingMaterialsCustomer = () =>
  axiosAuthenticationInstance.get(`feed/v1/customers/marketing-materials`);
export const getMarketingMaterialsByIdCustomer = (id: number) =>
  axiosAuthenticationInstance.get(`feed/v1/customers/marketing-materials/${id}`);
export const getMarketingMaterials = (groupName: string) =>
  axiosAuthenticationInstance.get(`feed/v1/marketing-materials?groupName=${groupName}`);
export const getMarketingMaterialsById = (id: number) =>
  axiosAuthenticationInstance.get(`feed/v1/marketing-materials/${id}`);

export const createMarketingMaterial = (req: CreateMarketingRequest) =>
  axiosAuthenticationInstance.post('feed/v1/marketing-materials', req);

export const editMarketingMaterial = (req: EditMarketingRequest) =>
  axiosAuthenticationInstance.put(`feed/v1/marketing-materials/${req.id}`, req.body);

export const deleteMarketingMaterial = (id: number) =>
  axiosAuthenticationInstance.delete(`feed/v1/marketing-materials/${id}`);
