import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomLoader from '@/src/components/custom-loader';
import { UserButtons } from './components/UserButtons';
import { UserForm } from './components/UserForm';
import { RegUserProps } from '../../types';

const User = (props: RegUserProps) => {
  const {
    values,
    handleSubmit,
    errors,
    isEdit,
    setFieldValue,
    handleClose,
    isLoading,
    responseError,
    isCreateModal,
    userStates,
    isLoadingStates = false,
  } = props;
  const { t } = useTranslation();

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', height: '90%', minHeight: '90%', alignItems: 'center', mt: 1 }}
      component="form"
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
    >
      {isLoading ? (
        <CustomLoader />
      ) : (
        <Grid container spacing={2}>
          <Grid xs={12} item>
            <Typography component="div" sx={{ fontWeight: 'bolder' }}>
              {t('user_info', { ns: 'residents' })}
            </Typography>
          </Grid>
          {values.showOtherInputs && (
            <>
              <UserForm
                isEdit={isEdit}
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                responseError={responseError ?? ''}
                isCreateModal={isCreateModal}
                userStates={userStates}
                isLoadingStates={isLoadingStates}
              />
              <UserButtons values={values} isLoading={false} handleClose={handleClose} />
            </>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default User;
