import * as Yup from 'yup';

export const validate = (t: any, step: number) =>
  Yup.lazy(() =>
    Yup.object().shape({
      carrier_name: Yup.string().required(t('required_field', { ns: 'errors' })),
      property_code: step !== 1 ? Yup.string().required(t('required_field', { ns: 'errors' })) : Yup.string(),
      carrier_code: Yup.string().required(t('required_field', { ns: 'errors' })),
      is_expirable: Yup.boolean().required(t('required_field', { ns: 'errors' })),
    }),
  );
