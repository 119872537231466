import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { getTowers } from '@/src/api/endpoints/master';

export const useGetTowers = (clientTenantCode: string) => {
  const {
    data: towers,
    isLoading,
    refetch,
  } = useQuery(['towers'], () => getTowers(clientTenantCode), {
    select: (data) => data?.data?.client,
  });

  useEffect(() => {
    const fetchTowersThreeTimes = async () => {
      await refetch();
      await refetch();
      await refetch();
    };

    fetchTowersThreeTimes();
  }, [refetch]);

  return { towers, isLoading, refetch };
};
