import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getsLanguages } from '@/src/api/endpoints/master';

export interface Lang {
  id: number;
  code: string;
}

export const getCodes = (lang: Lang) => {
  const code = lang.code.split('-');
  return code[1].toLocaleLowerCase();
};

export const useGetLanguages = () => {
  const [codes, setCodes] = useState<Array<string> | Array<any>>([]);
  const { data: languages, isLoading } = useQuery(['languages'], () => getsLanguages(), {
    select: (data) => data?.data?.languages,
  });

  useEffect(() => {
    if (languages && languages?.length > 0) {
      const cds = [];
      for (const i of languages) {
        cds.push(getCodes(i));
      }
      setCodes(cds);
    }
  }, [languages]);

  return { languages, codes, isLoading };
};
