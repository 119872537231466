import { useState, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getUserInfo, editUserInfo } from '@/src/api/endpoints/admin-users';
import { errorHandlerHelper } from '@/src/utilities/helpers/errorHandlerHelper';
import { User } from './types';
import { UserContext } from '@/src/context/user.context';
import { getSecurityCode, enableAuthentication, disableAuthentication } from '@/src/api/endpoints/accounts-security';

export const useGetUserInfo = () => {
  const [errorMessage, setErrorMessage] = useState<{ code: string; error_messages: string } | null | undefined>(null);
  const [userInfo, setUserInfo] = useState<User | null>(null);
  const [securityCode, setSecurityCode] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isUserUpdatedStatus, setIsUserUpdatedStatus] = useState<'idle' | 'fulfilled' | 'rejected'>('idle');
  const { user } = useContext(UserContext);

  const ResetStatus = () => {
    setIsUserUpdatedStatus('idle');
  };

  const { mutate: onGetUserInfo, isLoading } = useMutation(getUserInfo, {
    onSuccess: async (payload: any) => {
      setUserInfo(payload.data.profile);
      ResetStatus();
    },
    onError: async (err: any) => {
      setErrorMessage(errorHandlerHelper(err));
    },
  });

  const { mutate: onGetSecurityCode, isLoading: isSecurityCodeLoading } = useMutation(getSecurityCode, {
    onSuccess: async (payload: any) => {
      setSecurityCode(payload?.data?.setup?.secret);
      ResetStatus();
    },
    onError: async (err: any) => {
      setErrorMessage(errorHandlerHelper(err));
    },
  });

  const { mutate: onUpdateUser, isLoading: isUpdateUserLoading } = useMutation(editUserInfo, {
    onSuccess: async () => {
      setIsUserUpdatedStatus('fulfilled');
      ResetStatus();
    },
    onError: async (err: any) => {
      setIsUserUpdatedStatus('rejected');
      setErrorMessage(errorHandlerHelper(err));
    },
  });

  const { mutate: onEnableAuthentication, isLoading: isAuthenticationLoading } = useMutation(enableAuthentication, {
    onSuccess: async () => {
      setIsAuthenticated(true);
    },
    onError: async (err: any) => {
      setIsAuthenticated(false);
      setErrorMessage(errorHandlerHelper(err));
    },
  });

  const { mutate: onDisableAuthentication, isLoading: isAuthenticationDisableLoading } = useMutation(
    disableAuthentication,
    {
      onSuccess: async () => {
        setIsAuthenticated(false);
      },
      onError: async (err: any) => {
        setIsAuthenticated(true);
        setErrorMessage(errorHandlerHelper(err));
      },
    },
  );

  return {
    onGetUserInfo,
    onGetSecurityCode,
    onEnableAuthentication,
    onDisableAuthentication,
    userInfo,
    securityCode,
    isAuthenticated,
    isSecurityCodeLoading,
    isLoading,
    isUpdateUserLoading,
    isAuthenticationLoading,
    isAuthenticationDisableLoading,
    errorMessage,
    isUserUpdatedStatus,
    onUpdateUser,
    ResetStatus,
    setSecurityCode,
    user,
  };
};
