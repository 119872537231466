import React from 'react';
import { Box, FormHelperText, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import CustomInput from '@/src/components/forms/CustomInput';
import { CodeHorizontalSplitIcon, LoadGridPackageCode } from './LoadRackPackage.styled';
import { NUMBER_REGEX } from '@/src/utilities/helpers/constants';

const LoadTrackPackage = (props: any) => {
  const { t } = useTranslation();
  const { handleSubmit, values, setFieldValue, errors, isLoadingSubmit, responseError, onKeyDown } = props;
  return (
    <Box component="form" onSubmit={handleSubmit} onKeyDown={onKeyDown}>
      <Grid container spacing={2} sx={{ mt: { xs: 3, md: 1 } }}>
        <LoadGridPackageCode item xs={12} sm={6} sx={{ mb: { xs: 2, md: 0 } }}>
          <CustomInput
            autoFocus
            inputType="number"
            field="package_code"
            errors={errors}
            regex={NUMBER_REGEX}
            label={t('modal_load_package_input_package_code', { ns: 'packages' })}
            placeholder={t('modal_load_package_input_package_code_placeholder', { ns: 'packages' })}
            setFieldValue={setFieldValue}
            variant="outlined"
            maxLength={20}
            inputProps={{
              endAdornment: <CodeHorizontalSplitIcon />,
            }}
            values={values}
            required
          />
        </LoadGridPackageCode>
        <LoadGridPackageCode item xs={12} sm={6} sx={{ mb: { xs: 3, md: 0 } }}>
          <CustomInput
            field="rack_code"
            errors={errors}
            label={t('modal_load_package_input_load_rack_code', { ns: 'packages' })}
            placeholder={t('modal_load_package_input_rack_load_code_placeholder', { ns: 'packages' })}
            setFieldValue={setFieldValue}
            values={values}
            variant="outlined"
            inputProps={{
              endAdornment: <CodeHorizontalSplitIcon />,
            }}
            maxLength={30}
            required
          />
        </LoadGridPackageCode>

        {responseError && (
          <Grid container justifyContent="center" mt={2}>
            <FormHelperText error>{responseError}</FormHelperText>
          </Grid>
        )}
        <Grid container item xs={12} sm={12} md={12} justifyContent="flex-end">
          <LoadingButton
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            loading={isLoadingSubmit}
            type="submit"
            variant="contained"
          >
            {t('modal_load_package_submbit_button_text', { ns: 'packages' })}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoadTrackPackage;
