import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { DeletePet } from './DeletePet';
import { deletePet } from '@/src/api/endpoints/houseHold';
import { CustomDataTablePropsPet } from '../types';

const DeletePetContainer = ({ row, close, setSnackBarMessage }: CustomDataTablePropsPet) => {
  const { t } = useTranslation();
  const resource = 'pet';
  const { mutate } = useMutation(deletePet, {
    onError: async () => {
      close();
      setSnackBarMessage(t('delete_pet_message_error', { ns: 'residents' }), 'error');
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('delete_pet_message_success', { ns: 'residents' }));
    },
  });

  const childProps = {
    onConfirm: () => mutate({ petId: row?.pet?.id, residencyId: row?.residency?.id }),
    handleClose: () => close(),
    resource,
  };

  return <DeletePet {...childProps} />;
};

export default DeletePetContainer;
