import { axiosAuthenticationInstance } from '../instances/authInstance';
import {
  CreateAmenityRequest,
  EditAmenityRequest,
  EditTyCRequest,
  CreateRuleRequest,
  CreateActivityRequest,
  EditActivityRequest,
  CreateAgendaAvailabilityRequest,
  EditAgendaAvailabilityRequest,
  GetScheduleListRequest,
  GetAmenitiesRequest,
  EditRuleRequest,
} from '../types/types';
// AMENITY
export const createAmenity = (req: CreateAmenityRequest) =>
  axiosAuthenticationInstance.post('bookings/v1/business-activities', req);

export const getAmenities = (req: GetAmenitiesRequest) =>
  axiosAuthenticationInstance.get(
    `/bookings/v1/business-activities?page=${req.page}&pageSize=${req.pageSize ? req.pageSize : ''}`,
  );

export const getAmenity = (id: number) => axiosAuthenticationInstance.get(`bookings/v1/business-activities/${id}`);

export const editAmenity = (req: EditAmenityRequest) =>
  axiosAuthenticationInstance.put(`bookings/v1/business-activities/${req.id}`, req.body);

export const editTyC = (req: EditTyCRequest) =>
  axiosAuthenticationInstance.put(`bookings/v1/business-activities/${req.id}/tyc`, req.body);

export const getAmenityRules = (id: number) =>
  axiosAuthenticationInstance.get(`bookings/v1/business-activities/${id}/rules`);

export const createRule = (req: CreateRuleRequest) =>
  axiosAuthenticationInstance.post('bookings/v1/activity-rules', req);

export const editRule = (req: EditRuleRequest) =>
  axiosAuthenticationInstance.put(`bookings/v1/activity-rule-values/${req.id}`, req.body);

export const deleteActivityRule = (id: number) =>
  axiosAuthenticationInstance.delete(`bookings/v1/activity-rules/${id}`);

export const deleteAmenity = (id: number) =>
  axiosAuthenticationInstance.delete(`bookings/v1/business-activities/${id}`);

export const createActivity = (req: CreateActivityRequest) =>
  axiosAuthenticationInstance.post(`/bookings/v1/business-agendas`, req);

export const getActivityById = (id: number) => axiosAuthenticationInstance.get(`/bookings/v1/business-agendas/${id}`);

export const editActivity = (req: EditActivityRequest) =>
  axiosAuthenticationInstance.put(`/bookings/v1/business-agendas/${req.id}`, req.body);

export const cancelReservation = (id: number) => axiosAuthenticationInstance.put(`/bookings/v1/bookings/${id}/cancel`);

export const getAvailabilityById = (id: number) => axiosAuthenticationInstance.get(`bookings/v1/availabilities/${id}`);

export const createAvailability = (req: CreateAgendaAvailabilityRequest) =>
  axiosAuthenticationInstance.post('bookings/v1/availabilities', req);

export const editAvailability = (req: EditAgendaAvailabilityRequest) =>
  axiosAuthenticationInstance.put(`bookings/v1/availabilities/${req.id}`, req.body);

export const getAgendaByActivityId = (activityId: number) =>
  axiosAuthenticationInstance.get(`/bookings/v1/agendas?activityId=${activityId}`);

export const getScheduleList = (req: GetScheduleListRequest) =>
  axiosAuthenticationInstance.get(
    `bookings/v1/activities/${req.activityId}/schedules?agendaId=${req.agendaId}&date=${req.date}`,
  );

export const getAgendas = () => axiosAuthenticationInstance.get(`/bookings/v1/agendas`);

export const getActivities = () => axiosAuthenticationInstance.get(`/bookings/v1/business-activities`);

export const getBookingStates = () => axiosAuthenticationInstance.get(`/bookings/v1/booking-states`);
