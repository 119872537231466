import { FormikErrors, FormikValues } from 'formik';
import TableComponent from './TableComponent';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { Users } from '../../../types';

const TableComponentContainer = (props: Props) => {
  const { rows, setFieldValue, usersList, setUsersList } = props;

  const deleteUser = (row: any) => {
    const { id } = row;
    setFieldValue(
      'userSelectedList',
      rows?.filter((target) => target.id !== id),
    );
    const orderedList: any = usersList;
    orderedList?.push(row);
    orderedList.sort((a: any, b: any) => a.id - b.id);
    setUsersList(orderedList);
  };

  const childProps = {
    rows,
    deleteUser,
  };

  return <TableComponent {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface ExtraProps {
  rows: Array<Users> | null | undefined;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  usersList: Array<Users> | null | undefined;
  setUsersList: (object: any) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
TableComponent.propTypes = propTypes;
TableComponent.defaultProps = defaultProps;

export default TableComponentContainer;
