import { Box } from '@mui/material';
import { FadeIn } from '@/src/components/animations';
import CustomCardMedia from '@/src/components/custom-card-media/CustomCardMedia';

const RenderConstructionImages = ({ data }: RenderConstructionImagesProps) => (
  <FadeIn duration="3s">
    <Box
      display="flex"
      justifyContent="space-around"
      sx={{
        maxWidth: '100%',
        width: '100%',
        marginBottom: '20px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
      }}
    >
      {data?.map((item: ConstructionImage) => (
        <CustomCardMedia
          key={item.id}
          src={item.image_url}
          alt={`attached-image-${item.id}`}
          height="200px"
          width="175px"
        />
      ))}
    </Box>
  </FadeIn>
);

type ConstructionImage = {
  id: number;
  image_url: string;
};
type RenderConstructionImagesProps = {
  data: Array<ConstructionImage>;
};

export default RenderConstructionImages;
