import React from 'react';
import { Box, TextField } from '@mui/material';
import { TFunction } from 'react-i18next';
import { FilterByColumn } from '../../../types/CustomDataTableColumnProps';
import { CustomInputStyles } from '@/src/components/forms/styles';

const FilterContains = (props: FilterContainsProps) => {
  const { t, currentValue, filter, handleFilters, isNumeric } = props;
  return (
    <Box component="div" sx={{ margin: '5px 16px', p: '2px' }}>
      <TextField
        placeholder={t('search')}
        type={isNumeric ? 'number' : 'text'}
        label={!Array.isArray(filter.label) ? filter.label : filter.label[0]}
        onChange={(e) => handleFilters(e.target.value)}
        size="small"
        sx={{ ...CustomInputStyles(''), width: '150px' }}
        fullWidth
        value={currentValue}
      />
    </Box>
  );
};

interface FilterContainsProps {
  t: TFunction;
  filter: FilterByColumn;
  currentValue: string | number;
  handleFilters: (value: string | number) => void;
  isNumeric: boolean;
}

export default FilterContains;
