import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';

import RobotoMonoBold from '../../assets/fonts/RobotoMono/RobotoMono-Bold.ttf';
import RobotoMonoItalic from '../../assets/fonts/RobotoMono/RobotoMono-Italic.ttf';
import RobotoMonoMedium from '../../assets/fonts/RobotoMono/RobotoMono-Medium.ttf';
import RobotoMonoRegular from '../../assets/fonts/RobotoMono/RobotoMono-Regular.ttf';
import RobotoMonoSemiBold from '../../assets/fonts/RobotoMono/RobotoMono-SemiBold.ttf';

const baseTheme = createTheme({
  palette: {
    error: {
      main: red.A400,
    },
    mode: 'light',
    primary: {
      main: '#274138',
      light: '#35614E',
    },
    secondary: {
      main: '#BF7B4E',
      light: '#9B8677',
    },
    background: {
      default: '#F0F0F0',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
          ':disabled': {
            color: '#8e9b96',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#10748B',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: "RobotoMono";
        font-style: italic;
        font-weight: 400;
        font-display:"swap";
        src: url("${RobotoMonoItalic}") format("truetype");
      }
      @font-face {
        font-family: "RobotoMono";
        font-style: normal;
        font-weight: 500;
        font-display:"swap";
        src: url("${RobotoMonoMedium}") format("truetype");
      }
      @font-face {
        font-family: "RobotoMono";
        font-style: normal;
        font-weight: 400;
        font-display:"swap";
        src: url("${RobotoMonoRegular}") format("truetype");
      }
      @font-face {
        font-family: "RobotoMono";
        font-style: normal;
        font-weight: 600;
        font-display:"swap";
        src: url("${RobotoMonoSemiBold}") format("truetype");
      }
      @font-face {
        font-family: "RobotoMono";
        font-style: normal;
        font-weight: 700;
        font-display:"swap";
        src: url("${RobotoMonoBold}") format("truetype");
      }
      `,
    },
  },
  typography: {
    fontFamily: ['RobotoMono', 'Roboto', 'Helvetica', 'Arial', 'serif'].join(','),
    allVariants: {
      color: '#4A4A4A',
    },
  },
});

export default baseTheme;
