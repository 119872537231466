import { useTranslation } from 'react-i18next';
import UserSecurityDetail from './UserSecurityDetail';
import useDialog from '@/src/hooks/common/useDialog';

const UserSecurityDetailContainer = (props: any) => {
  const { t } = useTranslation('users');
  const { isOpen, open, close } = useDialog();

  const childProps = {
    ...props,
    t,
    isOpen,
    open,
    close,
  };

  return <UserSecurityDetail {...childProps} />;
};

export default UserSecurityDetailContainer;
