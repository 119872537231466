import { styled } from '@mui/material/styles';
import { Avatar, Box, Divider } from '@mui/material';

export const Boxstyled = styled(Box)(({ theme }: any) =>
  theme.unstable_sx({
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px',
    p: 2,
    overflow: 'auto',
  }),
);

export const UserCardWrapper = styled(Box)(({ theme }: any) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    padding: '1rem',
    margin: 'auto',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    width: '100%',
    flexDirection: 'column',
    gap: { xs: 'column', sm: theme.spacing(6), md: theme.spacing(5), lg: theme.spacing(2) },
    height: '100%',
  }),
);

export const UserCardInfoWrapper = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'grid',
    gap: { xs: '0.5rem', sm: theme.spacing(6), md: '1.5rem', lg: '0.5rem' },
    width: { sm: '100%', md: '100%' },
    flexDirection: { sm: 'row', md: 'row' },
    gridTemplateColumns: { lg: 'auto' },
  }),
);

export const ImagePreviewContainer = styled('div')`
  padding: 15px;
  border-radius: 20px;
  background: rgba(166, 139, 92, 0.1);
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const BoxAvatar = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const AvatarName = styled(Avatar)(() => ({
  width: '6rem',
  height: '6rem',
  my: 'auto',
}));

export const DividerUser = styled(Divider)(() => ({
  marginTop: '2px',
  marginBottom: '5px',
}));

export const BoxLoader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));
