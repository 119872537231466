export const getDuration = (value: string) => {
  switch (value) {
    case '00:15:00':
      return 15;
    case '00:30:00':
      return 30;
    case '00:45:00':
      return 45;
    case '01:00:00':
      return 60;
    case '01:30:00':
      return 90;
    case '02:00:00':
      return 120;
    case '04:00:00':
      return 240;
    case '08:00:00':
      return 480;
    default:
      return 0;
  }
};

export const getShifts = (from: string, to: string, duration: string) => {
  let result = 0;
  let hourTime = 0;
  const shift = getDuration(duration);
  if (from && to && duration) {
    const [hourOne, minuteOne] = from.split(':');
    const [hourTwo, minuteTwo] = to.split(':');
    hourTime = (Number(hourTwo) - Number(hourOne)) * 60;

    if (Number(minuteOne) > Number(minuteTwo)) {
      hourTime -= Number(minuteOne) - Number(minuteTwo);
    } else if (Number(minuteOne) < Number(minuteTwo)) {
      hourTime += Number(minuteTwo) - Number(minuteOne);
    }
    result = Math.floor(hourTime / shift);
  }
  return { valueRoom: result, isDecimal: (hourTime / shift) % 1 > 0 };
};
