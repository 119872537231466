import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { ResidencyType } from '@/src/utilities/helpers/commonTypes';

const ResidencyTypesAutocomplete = (props: ResidencyTypesAutocompleteProps) => {
  const { data, id, onChange, t, value } = props;

  return (
    <Autocomplete
      id={id}
      sx={{ width: '100%' }}
      size="small"
      value={data?.find((property) => value?.id === property.id) ?? null}
      getOptionLabel={(option) => option?.name ?? ''}
      options={data ?? []}
      onChange={onChange}
      renderInput={(params: any) => (
        <TextField
          {...params}
          sx={{ width: '100%' }}
          variant="outlined"
          label={t('resident_type')}
          placeholder={t('residency_type_autocomplete_placeholder')}
        />
      )}
    />
  );
};

interface ResidencyTypesAutocompleteProps {
  id?: string;
  data?: Array<ResidencyType>;
  t: any;
  onChange: (e: React.SyntheticEvent<Element, Event>, value: ResidencyType | null) => void;
  value: ResidencyType | null;
}

export default ResidencyTypesAutocomplete;
