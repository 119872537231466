import { Card, CardContent, CardHeader } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FadeIn } from '@/src/components/animations';
import AlertNoData from '@/src/components/custom-data-table/components/AlertNoData';
import DataTableStyled from '@/src/components/custom-data-table/CustomDataTable.styled';
import { ChargesDataTableProps } from '../types';

export const ChargesDataTable = ({ title, data, columns }: ChargesDataTableProps) => {
  const { t } = useTranslation();
  return (
    <FadeIn>
      <Card
        sx={{
          marginBottom: '16px',
          boxShadow: 2,
          width: '100%',
        }}
      >
        <CardHeader title={title} titleTypographyProps={{ variant: 'h6' }} sx={{ padding: '8px' }} />
        <CardContent sx={{ padding: '0px !important' }}>
          <DataTableStyled
            dense
            columns={columns}
            data={data}
            noDataComponent={<AlertNoData title={t('no_data_was_found')} />}
            theme="palierGridTheme"
            pagination
            paginationComponentOptions={{ noRowsPerPage: true }}
          />
        </CardContent>
      </Card>
    </FadeIn>
  );
};

export default ChargesDataTable;
