import { Avatar, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CardAvatar = styled(Avatar)(({ theme }) =>
  theme.unstable_sx({
    bgcolor: 'primary.main',
  }),
);

export const MarketingCardWrapper = styled(Card)(() => ({
  transition: 'all .3s ease-in-out,background 0s,color 0s,border-color 0s',
  height: '310px',
  width: '100%',
  boxShadow: '0px 3px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  ':hover': {
    cursor: 'pointer',
    transform: 'translateY(-5px)',
    boxShadow: '0 4px 25px 0 rgb(34 41 47 / 25%)',
  },
}));

export const MarketingCardFooterText = styled(Typography)`
  font-size: 0.8rem;
  padding: 0px 8px;
  word-wrap: break-word;
  color: rgba(0, 0, 0, 0.6);
`;
