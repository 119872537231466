import { FormEvent } from 'react';
import { FormikErrors, FormikValues } from 'formik';
import { DataTableFormModal } from '@/src/components/custom-data-table/types/DataTableFormModal';
import { PropertyType } from '../residents/residents-section/types';

export type TPackagesStateOptions = {
  [key: string]: string;
};

export const packagesStates: TPackagesStateOptions = {
  Pending: '#516F3F',
  Delivered: '#424867',
  PendingToStore: '#CA8A04',
};
export interface Package {
  internal_code: number;
  package_code: string;
  property: {
    code: string;
  };
  package_state: {
    code: string;
    name: string;
  };
  resident: {
    name: string;
    surname: string;
    type: string;
  };
  is_expirable: boolean;
  location: string;
  creation_date: number;
  delivery_date: number | null;
  delivered_to: string | null;
}

export interface PackageDetail {
  carrier: {
    id: number;
    name: string;
  };
  creation_date: number;
  creation_user: string;
  delivered_to: string | null;
  delivery_code: string | null;
  delivery_date: number | null;
  internal_code: number;
  package_code: string;
  location: string;
  package_state: {
    code: string;
    name: string;
  };
  property: {
    code: string;
  };
}

export interface Carrier {
  id: number;
  name: string;
  code: string;
}

export interface DeletePackageProps {
  close: () => void;
  setSnackBarMessage: (message: string, sever?: string) => void;
  row: Package;
}

export interface PackageLabelProps {
  row: Package | undefined | null;
}

export interface LoadPackageModalProps extends DataTableFormModal {
  setCurrentStep: (step: number) => void;
  currentStep: number;
  propertiesList?: Array<PropertyType>;
}

export interface DeliveryPackageFormProps {
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  errors: FormikErrors<FormikValues>;
  isLoadingSubmit: boolean;
  sendPackageDelivery: ({ userId, packages }: { userId: string; packages: Array<Carrier> }) => void;
  closeModal: () => void;
  handleToggle: (code: number) => void;
  handleToggleAll: () => void;
  detail: any;
  isLoading: boolean;
  number: any;
  searchDone: boolean;
}
export interface Carrier {
  internal_code: number;
  carrier: string;
  status?: boolean;
}

export interface DetailDeliveryHook {
  userId: string;
  name?: string;
  surname?: string;
  avatarUrl?: string;
  packages: Array<Carrier>;
  error?: string;
}
export interface DetailLoadTrackHook {
  userId: string;
  name?: string;
  surname?: string;
  avatarUrl?: string;
  packages: Array<Carrier>;
  error?: string;
}

export interface PrintLabelProps {
  close: () => void;
  setSnackBarMessage: (message: string, sever?: string) => void;
  row: Package;
}
