import { useTranslation } from 'react-i18next';
import React from 'react';
import { Delete, Grading } from '@mui/icons-material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import AddIcon from '@mui/icons-material/Add';
import ConfigureRules from './abm/configure-rules/ConfigureRules.container';
import TermsConditionsContainer from './abm/terms-conditions/TermsConditions.container';
import EditAmenity from './abm/create-edit-amenity/CreateEditAmenity.container';
import DeleteAmenity from './abm/delete-amenity/DeleteAmenity';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import { ActionColumn, ActionHeader } from '@/src/components/custom-data-table/types/DataTableFormModal';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { Amenity } from './types';

export const useColumns = () => {
  const { t } = useTranslation();

  const columns: CustomDataTableColumnsProps<Amenity>[] = [
    {
      id: 'id',
      name: t('id_table_field', { ns: 'bookings' }),
      selector: (row) => row.id,
      sortField: 'id',
      sortable: true,
      right: true,
      maxWidth: '1%',
      filters: [
        {
          id: 'id',
          query: 'ActivityId',
          type: 'numeric',
        },
      ],
    },
    {
      id: 'description',
      name: t('name'),
      selector: (row) => row.description,
      sortField: 'description',
      sortable: true,
      filters: [
        {
          id: 'description',
          query: 'description',
          type: 'contains',
        },
      ],
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'edit',
      icon: (props: any) => <ModeEditIcon sx={props.sx} />,
      component: (props: any) => <EditAmenity {...props} />,
      width: { xs: '90%', sm: '25%' },
    },
    {
      id: 'configure_rules',
      icon: (props: any) => <MiscellaneousServicesIcon sx={props.sx} />,
      component: (props: any) => <ConfigureRules {...props} />,
      width: { xs: '90%', sm: '80%' },
    },
    {
      id: 'terms_conditions',
      icon: (props: any) => <Grading sx={props.sx} />,
      component: (props: any) => <TermsConditionsContainer {...props} />,
      width: { xs: '90%', sm: '23%' },
    },
    {
      id: 'delette',
      icon: (props: any) => <Delete sx={props.sx} />,
      component: (props: any) => <DeleteAmenity {...props} />,
      width: '23%',
    },
  ];

  const headerActions: ActionHeader[] = [
    {
      id: 'amenities-create',
      component: () => (
        <CustomTableAction
          modalTitle="amenities"
          component={(props: any) => <EditAmenity {...props} />}
          Icon={() => <AddIcon />}
          type="create"
          width={{ xs: '90%', sm: '25%' }}
        />
      ),
    },
  ];

  return {
    columns,
    actionColumns,
    headerActions,
  };
};
