import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import FormAvailability from './FormAvailability';
import { Day, FormAvailabilityProps } from '../../types';
import { editAvailability, createAvailability, getAvailabilityById } from '@/src/api/endpoints/bookings';
import { CreateAgendaAvailabilityRequest } from '@/src/api/types/types';
import { getShifts } from './utils/getDuration';

const date = new Date().getTime();

const FormAvailabilityContainer = (props: FormAvailabilityProps) => {
  const { row, setSnackBarMessage, close, isEdit, agendaId } = props;
  const { mutate, data: dataAvailability, isLoading: getAvailabilityByIdLoading } = useMutation(getAvailabilityById);
  useEffect(() => {
    if (isEdit) return mutate(row?.id || 0);
  }, []);

  const [error, setError] = useState<string | null>(null);
  const [allowedToSubmit, setAllowedToSubmit] = useState<boolean>(true);
  const { t } = useTranslation();
  const [days, setDays] = useState([
    { id: 1, day: t('Mon', { ns: 'date' }), isSelected: false, wknd: false },
    { id: 2, day: t('Tue', { ns: 'date' }), isSelected: false, wknd: false },
    { id: 3, day: t('Wed', { ns: 'date' }), isSelected: false, wknd: false },
    { id: 4, day: t('Thu', { ns: 'date' }), isSelected: false, wknd: false },
    { id: 5, day: t('Fri', { ns: 'date' }), isSelected: false, wknd: false },
    { id: 6, day: t('Sat', { ns: 'date' }), isSelected: false, wknd: true },
    { id: 0, day: t('Sun', { ns: 'date' }), isSelected: false, wknd: true },
  ]);
  const [frequencySelected, setFrequency] = useState('custom');
  const [customDaysPrevSelected, setCustomDaysPrevSelected] = useState<Array<Day>>([]);
  const [noDaysSelectedError, setNoDaysSelectedError] = useState<string | null>(null);
  const renderFrequencySelected = (daysOfRow: Array<Day>) => {
    const daysSelection = daysOfRow.filter((day: Day) => day.isSelected);
    if (daysSelection.length === 5) {
      setFrequency('week_days');
      return;
    }
    if (daysSelection.length === 7) {
      setFrequency('every_day');
      return;
    }

    setFrequency('custom');
  };

  useEffect(() => {
    const daysOfRow = days;

    if (row && row.days_of_week && row.days_of_week.length > 0) {
      for (const i of row.days_of_week) {
        daysOfRow[(i + 6) % 7] = { ...daysOfRow[(i + 6) % 7], isSelected: true };
      }
      setDays(daysOfRow);
      renderFrequencySelected(daysOfRow);
    }
  }, [row]);

  // FORMIK DECLARATION
  const getInitialValues = () => ({
    days_selected: days,
    frequency_selected: frequencySelected,
    spots_from:
      dataAvailability && dataAvailability.data.availability.from
        ? dataAvailability.data.availability.from.slice(0, -3)
        : '',
    spots_to:
      dataAvailability && dataAvailability.data.availability.to
        ? dataAvailability.data.availability.to.slice(0, -3)
        : '',
    shift_lasting:
      dataAvailability && dataAvailability.data.availability.appointment_duration
        ? dataAvailability.data.availability.appointment_duration
        : '',
    available_places: dataAvailability?.data.availability.available_places || 1,
    room: 0,
    isEdit: !!(dataAvailability && dataAvailability.data.availability.days_of_week),
  });

  const getValidationSchema = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        days_selected: Yup.array(),
        shift_lasting: Yup.string()
          .required(t('required_field', { ns: 'errors' }))
          .test(
            'maxLenght',
            t('not_available_shifts_on_time_frame', { ns: 'bookings' }),
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            () => valueRoom > 0 && !isDecimal,
          ),
        spots_from: Yup.string()
          .required(t('required_field', { ns: 'errors' }))
          .typeError(t('required_field', { ns: 'errors' })),
        spots_to: Yup.string()
          .required(t('required_field', { ns: 'errors' }))
          .typeError(t('required_field', { ns: 'errors' })),
        available_places: Yup.number()
          .required(t('required_field', { ns: 'errors' }))
          .typeError(t('required_field', { ns: 'errors' }))
          .positive(t('available_places_max_lenght_validation', { ns: 'bookings' })),
      }),
    );
  // EDIT AVAILABILITY
  const { mutate: updateAvailabilityFunction, isLoading: isUpdatingLoading } = useMutation(editAvailability, {
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('avilability_edition_successfull', { ns: 'bookings' }));
    },
    onError: async () => {
      setError(t('availability_edition_failed', { ns: 'bookings' }));
    },
  });

  const { mutate: createAvailabilityFunction, isLoading: isCreatingLoading } = useMutation(createAvailability, {
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('availability_creation_successfull', { ns: 'bookings' }));
    },
    onError: async ({ response }) => {
      setError(t(response.data.error_messages[0].code, { ns: 'bookings' }));
    },
  });

  const onSubmit = (data: FormikValues) => {
    const daysThatAreSelected = data.days_selected.filter((day: Day) => day.isSelected === true);
    if (daysThatAreSelected.length === 0) {
      setNoDaysSelectedError(t('no_day_selected_error', { ns: 'bookings' }));
      return;
    }
    setNoDaysSelectedError(null);

    if (allowedToSubmit) {
      if (data.isEdit) {
        const body = {
          start_date: date,
          from: `${data.spots_from}:00`,
          to: `${data.spots_to}:00`,
          appointment_duration: data.shift_lasting,
          available_places: data.available_places,
          repetition_days: daysThatAreSelected.map((day: Day) => day.id),
        };
        updateAvailabilityFunction({ id: row?.id as number, body });
      } else {
        const StructureAgendaAvailability: CreateAgendaAvailabilityRequest = {
          agenda_id: agendaId,
          from: data.spots_from,
          to: data.spots_to,
          available_places: data.available_places,
          appointment_duration: data.shift_lasting,
          repetition_days: daysThatAreSelected.map((day: Day) => day.id),
        };
        createAvailabilityFunction(StructureAgendaAvailability);
      }
    }
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema(),
  });

  const { valueRoom, isDecimal } = getShifts(
    values?.spots_from as string,
    values?.spots_to as string,
    values?.shift_lasting,
  );

  const handleSelectDays = (day: Day) => {
    const daysSelected = days.map((d: Day) => (d.id === day.id ? { ...d, isSelected: !d.isSelected } : d));
    setDays(daysSelected);
    setCustomDaysPrevSelected(daysSelected);
  };

  const handleSelectTiming = (event: SelectChangeEvent) => {
    setFieldValue('shift_lasting', event.target.value);
  };

  const handleSelectFrequency = (btnClicked: string) => {
    setFrequency(btnClicked);
    if (btnClicked === 'every_day') {
      setDays(days.map((day) => ({ ...day, isSelected: true })));
    } else if (btnClicked === 'week_days') {
      setDays(days.map((d: Day) => (d.wknd ? { ...d, isSelected: false } : { ...d, isSelected: true })));
    } else {
      setDays(
        customDaysPrevSelected.length > 0
          ? customDaysPrevSelected
          : days.map((d: Day) => ({ ...d, isSelected: false })),
      );
    }
  };

  const duration = [
    { id: 1, value: '00:15:00', label: '15 Min' },
    { id: 2, value: '00:30:00', label: '30 Min' },
    { id: 3, value: '00:45:00', label: '45 Min' },
    { id: 4, value: '01:00:00', label: '1 hr' },
    { id: 5, value: '01:30:00', label: '1 hr 30 min' },
    { id: 6, value: '02:00:00', label: '2 hrs' },
    { id: 7, value: '04:00:00', label: '4 hrs' },
    { id: 8, value: '08:00:00', label: '8 hrs' },
  ];

  const handleTimeSelector = (fieldValue: string, value: string) => {
    setFieldValue(fieldValue, value);
  };

  useEffect(() => {
    if (values.spots_from && values.spots_to) {
      const [fromHour, fromMinutes] = values.spots_from.split(':');
      const [toHour, toMinutes] = values.spots_to.split(':');
      if (Number(fromHour) > Number(toHour)) {
        setError(t('agenda_availability_date_greater_error_msn', { ns: 'bookings' }));
        setAllowedToSubmit(false);
        return;
      }
      if (Number(fromHour) === Number(toHour) && Number(fromMinutes) >= Number(toMinutes)) {
        setError(t('agenda_availability_date_greater_error_msn', { ns: 'bookings' }));
        setAllowedToSubmit(false);
        return;
      }
      setError(null);
      setAllowedToSubmit(true);
    }
  }, [values.spots_from, values.spots_to]);

  const childProps = {
    days,
    handleSelectTiming,
    handleSelectDays,
    handleSelectFrequency,
    frequencySelected,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    noDaysSelectedError,
    duration,
    error,
    isLoading: isUpdatingLoading || isCreatingLoading,
    handleTimeSelector,
    loadingGeneral: getAvailabilityByIdLoading,
    valueRoom,
  };
  return <FormAvailability {...childProps} />;
};

export default FormAvailabilityContainer;
