import { useQuery } from '@tanstack/react-query';
import { getAccountBalance } from '@/src/api/endpoints/payments';
import { AccountBalanceObject } from '../types';

const useAccountBalance = (residencyId: number) => {
  const {
    data: balance,
    isFetching,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: [`acccountBalance`],
    queryFn: () => getAccountBalance(residencyId),
    select: (data) => data?.data.balance as AccountBalanceObject,
  });

  return { balance, isLoading: (isLoading && isFetching) || isRefetching, refetch };
};

export default useAccountBalance;
