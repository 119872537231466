import { Box, Button, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConfirmationModalProps } from '../types';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: 2,
};

export const CloseConfirmationModal = ({
  openCloseConfirmation,
  handleCloseConfirmation,
  handleBackToReject,
}: ConfirmationModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal open={openCloseConfirmation}>
      <Box sx={style}>
        <Typography id="modal-modal-description" variant="h6" color="muted">
          {t('creating_modal_closing_title', { ns: 'news' })}
        </Typography>
        <Typography
          id="modal-modal-description"
          variant="body2"
          color="muted"
          sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '16px', mt: 1 }}
        >
          {t('confirmation_rejection_modal_body', { ns: 'cdtModal' })}
        </Typography>
        <Box component="div" sx={{ widht: '100%', textAlign: 'right', pt: 1 }}>
          <Button size="small" variant="outlined" color="secondary" onClick={handleBackToReject} sx={{ mr: 1 }}>
            {t('creating_modal_closing_back_button', { ns: 'news' })}
          </Button>
          <Button size="small" variant="contained" color="primary" onClick={handleCloseConfirmation}>
            {t('cancel_the_rejection_button', { ns: 'cdtModal' })}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
