import { Box, FormHelperText, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import CustomInput from '@/src/components/forms/CustomInput';
import PhoneSelect from '@/src/components/phone-select/PhoneSelect';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';

const AttendantForm = ({
  errors,
  handleSubmit,
  setFieldValue,
  responseError,
  values,
  isLoadingGeneral,
  isLoadingSubmit,
}: any) => {
  const { t } = useTranslation();
  return isLoadingGeneral ? (
    <CircularSpinner />
  ) : (
    <Box component="form" onSubmit={handleSubmit} sx={{ marginTop: '1rem' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CustomInput
            field="name"
            errors={errors}
            label={t('user_form_name_field_label', { ns: 'users' })}
            placeholder={t('user_form_name_field_placeholder', { ns: 'users' })}
            setFieldValue={setFieldValue}
            values={values}
            autoFocus
            maxLength={50}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomInput
            field="surname"
            errors={errors}
            label={t('user_form_surname_field_label', { ns: 'users' })}
            placeholder={t('user_form_surname_field_placeholder', { ns: 'users' })}
            setFieldValue={setFieldValue}
            values={values}
            maxLength={50}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            field="email"
            errors={errors}
            label={t('user_form_email_field_label', { ns: 'users' })}
            placeholder={t('user_form_email_field_placeholder', { ns: 'users' })}
            setFieldValue={setFieldValue}
            values={values}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneSelect values={values} errors={errors} setFieldValue={setFieldValue} />
        </Grid>
        {responseError && (
          <Grid container justifyContent="center" mt={2}>
            <FormHelperText error>{responseError}</FormHelperText>
          </Grid>
        )}
        <Grid container item xs={12} sm={12} md={12} justifyContent="flex-end">
          <LoadingButton
            loading={isLoadingSubmit}
            variant="contained"
            size="small"
            type="submit"
            sx={{ width: { xs: '100%', sm: 'auto' } }}
          >
            {t('save')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AttendantForm;
