import React from 'react';
import { FilterModalContainerProps } from '../../../types/DataTableFormModal';
import FilterModal from './FilterModal';

const FilterModalContainer = (props: FilterModalContainerProps) => {
  const { filterComponentsList, close, onSubmit, t } = props;
  const onSubmitModal = () => {
    onSubmit();
    close();
  };

  const childProps = {
    ...props,
    filterComponentsList,
    t,
    onSubmit: onSubmitModal,
  };
  return <FilterModal {...childProps} />;
};

export default FilterModalContainer;
