import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';
import { SelectedRow } from '../types';

export const BlacklistSection = (props: { openDetailSection: (value: SelectedRow) => void }) => {
  const { openDetailSection } = props;
  const { columns, actionColumns, queryFilters } = useColumns(openDetailSection);
  const { t } = useTranslation();
  return (
    <CustomDataTable
      title={t('blacklist')}
      columns={columns}
      resource="customers"
      pathApi="accounts/v1/customers"
      listName="customers"
      canExport
      canSearch
      extraFilters={queryFilters}
      defaultSortFieldId="name"
      defaultSortAsc
      customActionColumns={actionColumns}
    />
  );
};
