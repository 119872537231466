import { Box, Chip, Typography } from '@mui/material';
import { TFunction } from 'react-i18next';
import { FadeIn } from '@/src/components/animations';
import { AvatarName, BoxAvatar, DividerUser, UserCardInfoWrapper, UserCardWrapper } from './UserCard.styled';
import { UserCardData } from '../../types';
import CustomLoader from '@/src/components/custom-loader';
import SecurityDetail from '@/src/components/user-security-detail';

const TextContainer = ({ first, second, t }: { first: string; second: string; t: TFunction }) => (
  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
    <Typography variant="subtitle2" component="span" fontSize="13px" fontWeight="600" sx={{ marginRight: '3.5px' }}>
      {t(first)}:
    </Typography>
    <Typography variant="body2" component="span" fontSize="14px" fontWeight="400">
      {t(second)}
    </Typography>
  </Box>
);

const UserCard = ({
  values,
  t,
  getFormattedDateNumbeByLanguague,
  isLoading,
  SnackBar,
  mfaAuthInfo,
  isLoadingReset,
  onConfirmReset,
}: UserCardProps) => (
  <UserCardWrapper>
    {isLoading ? (
      <CustomLoader />
    ) : (
      <>
        <FadeIn duration="1.5s">
          <BoxAvatar>
            <AvatarName alt={`${values.name} ${values.surname}`} src={values.avatarSrc} />
            <Typography gutterBottom variant="h6" fontSize="18px" fontWeight="500" textAlign="center">
              {`${values.name} ${values.surname}`}
            </Typography>
            <Chip
              size="small"
              color={`${values.isDisabled ? 'error' : 'success'}`}
              label={values.isDisabled ? t('disabled') : t('enable')}
            />
          </BoxAvatar>
        </FadeIn>
        <UserCardInfoWrapper>
          <Typography fontSize="16px" fontWeight="600">
            {t('detail', { ns: 'customerUsers' })}
          </Typography>
          <DividerUser />
          <TextContainer t={t} first="Email" second={values.email} />
          <TextContainer
            t={t}
            first={t('residents_list_phone_number_column', { ns: 'customerUsers' })}
            second={`${values.countryCode} ${values.phoneNumber}`}
          />
          {values.gender && <TextContainer t={t} first={t('gender', { ns: 'customerUsers' })} second={values.gender} />}{' '}
          {values.birthday && (
            <TextContainer
              t={t}
              first={t('birthday', { ns: 'customerUsers' })}
              second={getFormattedDateNumbeByLanguague(values.birthday)}
            />
          )}
          <SecurityDetail
            {...mfaAuthInfo}
            userName={`${values?.name} ${values?.surname}`}
            onConfirmReset={onConfirmReset}
            isLoadingReset={isLoadingReset}
          />
        </UserCardInfoWrapper>
      </>
    )}
    <SnackBar />
  </UserCardWrapper>
);

interface UserCardProps {
  values: UserCardData;
  t: TFunction;
  getFormattedDateNumbeByLanguague: (date: number) => string;
  SnackBar: () => JSX.Element;
  isLoading: boolean;
  onConfirmReset: () => void;
  isLoadingReset: boolean;
  mfaAuthInfo: {
    enabled: boolean;
    preferred_mfa_method?: string;
    mfa_settings: string[];
  };
}

export default UserCard;
