import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import TextLabel from '@/src/components/text-label/TextLabel';
import { VehiclesRow } from './types';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import { commonStatus } from '../common/types';
import useVehicleBrands from '@/src/hooks/filters/vehicleBrandsModels.hooks';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';
import useVehicleColors from '@/src/hooks/filters/vehicleColors.hooks';
import useVehicleFuelTypes from '@/src/hooks/filters/vehicleFuelType.hooks';
import useResidencyFilters from '@/src/hooks/filters/residencyTypes.hooks';

export const useColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const { apartments } = useApartmentsFilters();
  const { residencyTypes } = useResidencyFilters();
  const { vehicleBrands, vehicleModels } = useVehicleBrands();
  const { colors } = useVehicleColors();
  const { fuelTypes } = useVehicleFuelTypes();

  const columnsVehicles: CustomDataTableColumnsProps<VehiclesRow>[] = [
    {
      id: 'creation_date',
      sortField: 'vehicle_creation_date',
      name: t('creation_date', { ns: 'customerUsers' }),
      selector: (row) =>
        row?.vehicle?.creation_date
          ? getFormattedDateByLanguague(
              lang,
              getFormattedDateFullYearEs,
              getFormattedDateFullYearEn,
              row.vehicle?.creation_date,
            )
          : '-',
      sortable: true,
      width: lang === 'en' ? '10.5%' : '15%',
      minWidth: 'fit-content',
      center: true,
      filters: [
        {
          id: 'vehicles-creation-date',
          query: 'VehicleCreationDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'apartment',
      sortField: 'residency_property_code',
      name: t('user_detail_apartment', { ns: 'customerUsers' }),
      selector: (row) => row?.residency?.property?.code,
      sortable: true,
      width: lang === 'en' ? '9%' : '8%',
      center: true,
      filters: apartments,
    },
    {
      id: 'resident_type',
      sortField: 'residency_type',
      name: t('resident_type', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.residency?.type?.name,
      sortable: true,
      width: lang === 'en' ? '12.5%' : '10%',
      center: true,
      filters: residencyTypes,
    },
    {
      id: 'resident',
      sortField: 'holder_full_name',
      name: t('resident', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.residency?.holder?.name,
      sortable: true,
      center: true,
      filters: [
        {
          id: 'customer-full-name',
          query: 'HolderFullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'vehicle_alias',
      sortField: 'vehicle_alias',
      name: t('vehicle_alias', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.vehicle?.alias,
      sortable: true,
      center: true,
      width: lang === 'en' ? '8%' : '6%',
    },
    {
      id: 'vehicle_brand',
      sortField: 'vehicle_brand',
      name: t('vehicle_brand', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.vehicle?.model?.brand?.name,
      sortable: true,
      center: true,
      filters: vehicleBrands,
      width: lang === 'en' ? '8%' : '6%',
    },
    {
      id: 'vehicle_model',
      sortField: 'vehicle_model',
      name: t('vehicle_model', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.vehicle?.model?.name ?? '-',
      sortable: true,
      center: true,
      filters: vehicleModels,
      width: lang === 'en' ? '8%' : '7%',
    },
    {
      id: 'vehicle_color',
      sortField: 'vehicle_color',
      name: t('vehicle_color', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.vehicle?.color?.name,
      sortable: true,
      center: true,
      filters: colors,
      width: lang === 'en' ? '8%' : '7%',
    },
    {
      id: 'vehicle_plate',
      sortField: 'vehicle_license_plate',
      name: t('vehicle_plate', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.vehicle?.license_plate,
      sortable: true,
      center: true,
      filters: [
        {
          id: 'vehicle-full-plate',
          query: 'VehicleLicensePlate',
          type: 'contains',
        },
      ],
    },
    {
      id: 'fuel_type_value',
      sortField: 'vehicle_fuel_type',
      name: t('fuel_type_value', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.vehicle?.fuel_type?.name ?? '-',
      sortable: true,
      center: true,
      filters: fuelTypes,
    },
    {
      id: 'is_rented',
      sortField: 'vehicle_is_rented',
      name: t('is_rented', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => (row?.vehicle?.is_rented ? `${t('yes')}` : 'No'),
      cell: (row: VehiclesRow) => <Checkbox checked={row?.vehicle?.is_rented} disabled sx={{ padding: '0px' }} />,
      sortable: false,
      center: true,
    },
  ];

  const columnsVehiclesRequest: CustomDataTableColumnsProps<VehiclesRow>[] = [
    {
      id: 'creation_date',
      sortField: 'vehicle_creation_date',
      name: t('creation_date', { ns: 'customerUsers' }),
      selector: (row) =>
        row?.vehicle?.creation_date
          ? getFormattedDateByLanguague(
              lang,
              getFormattedDateFullYearEs,
              getFormattedDateFullYearEn,
              row.vehicle?.creation_date,
            )
          : '-',
      sortable: true,
      width: lang === 'en' ? '10.5%' : '12%',
      center: true,
      filters: [
        {
          id: 'vehicles-creation-date',
          query: 'VehicleCreationDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'resident',
      sortField: 'holder_full_name',
      name: t('resident', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.residency?.holder?.name,
      sortable: true,
      filters: [
        {
          id: 'customer-full-name',
          query: 'HolderFullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'resident_type',
      sortField: 'residency_type',
      name: t('resident_type', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.residency?.type?.name,
      sortable: true,
      width: lang === 'en' ? '12.5%' : '10%',
      center: true,
      filters: residencyTypes,
    },
    {
      id: 'apartment',
      sortField: 'residency_property_code',
      name: t('user_detail_apartment', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.residency?.property?.code,
      sortable: true,
      filters: apartments,
    },
    {
      id: 'vehicle_alias',
      sortField: 'vehicle_alias',
      name: t('vehicle_alias', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.vehicle?.alias,
      sortable: true,
      center: true,
      width: lang === 'en' ? '8%' : '6%',
    },
    {
      id: 'vehicle_brand',
      sortField: 'vehicle_brand',
      name: t('vehicle_brand', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.vehicle?.model?.brand?.name,
      sortable: true,
      center: true,
      filters: vehicleBrands,
      width: lang === 'en' ? '8%' : '6%',
    },
    {
      id: 'vehicle_model',
      sortField: 'vehicle_model',
      name: t('vehicle_model', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.vehicle?.model?.name ?? '-',
      sortable: true,
      center: true,
      filters: vehicleModels,
      width: lang === 'en' ? '8%' : '7%',
    },
    {
      id: 'vehicle_color',
      sortField: 'vehicle_color',
      name: t('vehicle_color', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.vehicle?.color?.name,
      sortable: true,
      center: true,
      filters: colors,
      width: lang === 'en' ? '8%' : '7%',
    },
    {
      id: 'vehicle_plate',
      sortField: 'vehicle_license_plate',
      name: t('vehicle_plate', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.vehicle?.license_plate,
      sortable: true,
      filters: [
        {
          id: 'vehicle-full-plate',
          query: 'VehicleLicensePlate',
          type: 'contains',
        },
      ],
    },
    {
      id: 'fuel_type_value',
      sortField: 'vehicle_fuel_type',
      name: t('fuel_type_value', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => row?.vehicle?.fuel_type?.name ?? '-',
      sortable: true,
    },
    {
      id: 'is_rented',
      sortField: 'vehicle_is_rented',
      name: t('is_rented', { ns: 'customerUsers' }),
      selector: (row: VehiclesRow) => (row?.vehicle?.is_rented ? `${t('yes')}` : 'No'),
      cell: (row: VehiclesRow) => <Checkbox checked={row?.vehicle?.is_rented} disabled sx={{ padding: '0px' }} />,
      center: true,
      sortable: false,
    },
    {
      cell: (row: VehiclesRow) => (
        <TextLabel
          text={row?.vehicle?.state?.name?.toLocaleUpperCase()}
          color="#F7F7F7"
          padding="2px 12px"
          width="100px"
          bg={commonStatus[row?.vehicle?.state?.code?.replaceAll(' ', '_').replaceAll('ó', 'o')]}
        />
      ),
      id: 'status',
      sortField: 'vehicle_state',
      name: t('status'),
      selector: (row: VehiclesRow) => row?.vehicle?.state?.name,
      sortable: true,
      center: true,
    },
  ];

  return {
    columnsVehicles,
    columnsVehiclesRequest,
  };
};
