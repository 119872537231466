import { useMutation } from '@tanstack/react-query';
import { findResident, createsResidentInfo, editPersonFound } from '@/src/api/endpoints/residents';

export const useOnFindResidentInfo = () => {
  const { mutate, data, isLoading } = useMutation(findResident);
  const residentInfo = data?.data.person;
  return { mutate, residentInfo, isLoadingSearchPhone: isLoading };
};

export const useSubmitResident = () => {
  const { mutate: createResident, isLoading: isLoadingCreateResident } = useMutation(createsResidentInfo);
  const { mutate: updateResidentFound, isLoading: isLoadingUpdateResident } = useMutation(editPersonFound);

  return {
    createResident,
    updateResidentFound,
    isLoadingSubmitResident: isLoadingUpdateResident || isLoadingCreateResident,
  };
};
