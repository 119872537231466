import { LoadingButton } from '@mui/lab';
import { Button, FormControl, Grid, InputLabel, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { CustomInputStyles } from '@/src/components/forms/styles';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { Day, PropsAvailability, Item } from '../../types';
import { useResizeButtonText } from './useResizeButtonText';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';

const FormAvailability = ({
  days,
  handleSelectTiming,
  handleSelectDays,
  handleSelectFrequency,
  frequencySelected,
  handleSubmit,
  values,
  setFieldValue,
  errors,
  noDaysSelectedError,
  duration,
  isLoading,
  error,
  handleTimeSelector,
  loadingGeneral,
  valueRoom,
}: PropsAvailability) => {
  const { textForButtons } = useResizeButtonText();
  const { t } = useTranslation();

  return loadingGeneral ? (
    <CircularSpinner />
  ) : (
    <Grid component="form" container spacing={2} p={2} onSubmit={handleSubmit}>
      <Grid xs={12} item container justifyContent="space-between" spacing={1}>
        {textForButtons.map((btn) => (
          <Grid key={btn.msn} xs={12} sm={12} md={12} lg={4} item>
            <Tooltip title={btn.tooltipTitle}>
              <Button
                onClick={() => handleSelectFrequency(btn.selector)}
                variant={frequencySelected === btn.selector ? 'contained' : 'outlined'}
                color="primary"
                fullWidth
              >
                {btn.msn}
              </Button>
            </Tooltip>
          </Grid>
        ))}

        {days.map((day: Day) => (
          <Grid key={day.id} xs={4} sm={4} lg={1.7} mt={2} item>
            <Button
              onClick={() => (frequencySelected === 'custom' ? handleSelectDays(day) : null)}
              variant={day.isSelected ? 'contained' : 'outlined'}
              color="primary"
              size="small"
              fullWidth
            >
              {day.day}
            </Button>
          </Grid>
        ))}
        <Grid xs={12} item>
          <ErrorText error={noDaysSelectedError} />
        </Grid>
        <Grid xs={12} mt={2} item>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" size="small">
              {t('shift_lasting_label', { ns: 'bookings' })}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              value={values.shift_lasting}
              label={t('shift_lasting_label', { ns: 'bookings' })}
              onChange={handleSelectTiming}
            >
              {duration.map((item: Item) => (
                <MenuItem key={item.id} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ErrorText error={errors.shift_lasting as string} />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1} ml={0.4}>
        <Grid xs={12} md={6} lg={4} item>
          <TextField
            type="time"
            label={`${t('from', { ns: 'date' })} * `}
            placeholder={t('from', { ns: 'date' })}
            onChange={(e) => handleTimeSelector('spots_from', e.target.value)}
            size="small"
            sx={CustomInputStyles('')}
            fullWidth
            focused
            value={values.spots_from}
          />
          <ErrorText error={errors.spots_from as string} />
        </Grid>
        <Grid xs={12} md={6} lg={4} item>
          <TextField
            type="time"
            label={`${t('to', { ns: 'date' })} * `}
            placeholder={t('to', { ns: 'date' })}
            onChange={(e) => handleTimeSelector('spots_to', e.target.value)}
            size="small"
            sx={CustomInputStyles('')}
            fullWidth
            focused
            value={values.spots_to}
          />
          <ErrorText error={errors.spots_from as string} />
        </Grid>
        <Grid xs={12} sm={12} lg={4} item>
          <TextField
            type="number"
            label={`${t('available_places', { ns: 'bookings' })} * `}
            onChange={(e) => setFieldValue('available_places', e.target.value)}
            size="small"
            sx={CustomInputStyles('')}
            fullWidth
            value={values.available_places}
          />
          <ErrorText error={errors.available_places as string} />
        </Grid>
      </Grid>
      {valueRoom !== 0 && (
        <Grid xs={12} item>
          <Typography>
            {t('available_shifts_on_time_frame', { ns: 'bookings' })}: &nbsp;
            {valueRoom}
          </Typography>
        </Grid>
      )}
      {error && (
        <Grid xs={12} item>
          <ErrorText error={error as string} />
        </Grid>
      )}
      <Grid container mt={2} sx={{ placeContent: 'flex-end' }}>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          type="submit"
          sx={{ width: { xs: 'calc(100% - 16px)', lg: 'auto' } }}
        >
          {values.isEdit ? t('save') : t('add_button_text')}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
export default FormAvailability;
