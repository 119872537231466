import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

export const Payments = () => {
  const { columns, headerActions, actionColumns } = useColumns();
  const { t } = useTranslation();

  return (
    <CustomDataTable
      title={t('payments', { ns: 'charges' })}
      columns={columns}
      resource="charges"
      pathApi="payments/v1/payments"
      listName="payments"
      canExport
      canSearch
      defaultSortFieldId="total_amount"
      defaultSortAsc
      extraActionsInHeader={headerActions}
      customActionColumns={actionColumns}
    />
  );
};

export default Payments;
