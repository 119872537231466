import { AlertColor } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { editResidentNote, getLastNoteFromResident } from '@/src/api/endpoints/residents';
import { SelectedResident } from '@/src/pages/household/users/types';
import { Notes } from './Notes';

const NotesContainer = ({
  row,
  setSnackBarMessage,
}: {
  row: SelectedResident;
  setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}) => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuery({
    queryKey: [`userNote_${row.person.id}`],
    queryFn: () => getLastNoteFromResident(row.person.id),
  });
  const { mutate, isLoading: isLoadingSubmit } = useMutation(editResidentNote);

  const getInitialValues = () => ({
    note: data?.data.person_note?.note || '',
  });

  const validate = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        note: Yup.string().required(t('required_field', { ns: 'errors' })),
      }),
    );

  const onSubmit = (note: any) => {
    mutate(
      { id: row.person.id, note },
      {
        onSuccess: () => {
          setSnackBarMessage(t('success'));
        },
        onError: () => {
          setSnackBarMessage(t('error'), 'error');
        },
      },
    );
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    enableReinitialize: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate,
  });

  const childProps = {
    values,
    setFieldValue,
    errors,
    isLoading,
    isLoadingSubmit,
    handleSubmit,
    row,
  };

  return <Notes {...childProps} />;
};

export default NotesContainer;
