import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterByColumn } from '../../../types/CustomDataTableColumnProps';
import FilterContains from './FilterContains';

const FilterContainsContainer = (props: FilterContainsContainerProps) => {
  const { filter, localFilterString, setLocalFilterString, isNumeric } = props;
  const { t } = useTranslation();

  const getInitialValue: () => string | number = () => {
    let str = '';
    const variables = localFilterString.split('&');
    variables.shift();
    variables.forEach((i) => {
      if (i.includes(filter.query)) {
        str += i.split('=')[1];
      }
    });
    return str ?? '';
  };

  const [currentValue, setCurrentValue] = React.useState<string | number>(getInitialValue());
  const handleFilters = (value: string | number) => {
    setLocalFilterString((prev) => {
      const { query } = filter;
      if (prev.includes(query)) {
        const variables = prev.split('&');
        variables.shift();
        let str = '';
        variables.forEach((i) => {
          if (i.includes(query)) {
            str += `&${query}=${value}`;
          } else if (i !== '') {
            str += `&${i}`;
          }
        });
        return str;
      }
      return prev.concat(`&${query}=${value}`);
    });
    setCurrentValue(value);
  };

  // React.useEffect(() => {
  //   getInitialValue();
  // }, [currentValue]);

  React.useEffect(() => {
    if (!localFilterString) {
      setCurrentValue('');
    }
  }, [localFilterString]);

  const childProps = {
    t,
    filter,
    currentValue,
    handleFilters,
    isNumeric,
  };
  return <FilterContains {...childProps} />;
};

FilterContainsContainer.defaultProps = {
  isNumeric: false,
};

interface FilterContainsContainerProps {
  setLocalFilterString: React.Dispatch<React.SetStateAction<string>>;
  filter: FilterByColumn;
  localFilterString: string;
  isNumeric: boolean;
}

export default FilterContainsContainer;
