import { Box, Divider, Modal, Typography, styled } from '@mui/material';

export default {
  Divider: styled(Divider)(() => ({
    marginTop: '2px',
    marginBottom: '5px',
    width: '100%',
  })),
  ButtonContainer: styled(Typography)(() => ({
    marginTop: '0.5rem',
  })),
  Modal: styled(Modal)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })),
  ModalContent: styled(Box)(() => ({
    backgroundColor: 'white',
  })),
};
