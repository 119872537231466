import { useQuery } from '@tanstack/react-query';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getRequestStates } from '@/src/api/endpoints/parking';

const useRequestStatesFilters = () => {
  const getRequestStatesMapper = (data: Array<any>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.id,
          query: 'RequestStateIds',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: requestStates, isLoading } = useQuery(['getRequestStates'], getRequestStates, {
    select: (response) => getRequestStatesMapper(response?.data?.request_states),
  });

  return { requestStates, isLoading };
};

export default useRequestStatesFilters;
