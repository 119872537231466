import React from 'react';
import { Autocomplete, Box, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserFormProps } from '../../../types';
import { Properties } from './Properties';
import CustomInput from '@/src/components/forms/CustomInput';
import PhoneSelect from '@/src/components/phone-select/PhoneSelect';
import ResidencyTypesAutocomplete from '@/src/components/residency-types-autocomplete';

export const UserForm = ({
  values,
  errors,
  setFieldValue,
  responseError,
  isCreateModal,
  userStates,
  isEdit,
}: UserFormProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid xs={12} container spacing={2} item>
        <Grid item xs={12} sm={6}>
          <CustomInput
            field="name"
            errors={errors}
            label={t('name')}
            setFieldValue={setFieldValue}
            values={values}
            disabled={isEdit}
            autoFocus
            maxLength={50}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomInput
            field="surname"
            errors={errors}
            label={t('surname')}
            setFieldValue={setFieldValue}
            values={values}
            disabled={isEdit}
            maxLength={50}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomInput
            field="email"
            errors={errors}
            label={t('email')}
            setFieldValue={setFieldValue}
            values={values}
            disabled={isEdit}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneSelect
            errors={errors}
            values={values}
            setFieldValue={setFieldValue}
            isDisabled={values.showOtherInputs}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ResidencyTypesAutocomplete
            onChange={(e: any, value: any) => {
              setFieldValue('residencyType', value);
            }}
            value={values.residencyType?.code ?? null}
          />
        </Grid>
        {!isCreateModal && (
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="personState"
              size="small"
              options={userStates ?? []}
              onChange={(e: any, value: any) => {
                setFieldValue('state', value?.id);
              }}
              value={userStates?.find((state: any) => state.id === values?.state) || null}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Box component="li" key={option.id} {...props}>
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={`${t('user_from_state_field_label', { ns: 'users' })} * `}
                  placeholder={t('user_from_state_field_label_placeholder', { ns: 'users' })}
                  error={errors.state !== undefined}
                  helperText={errors.state?.toString()}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <Grid xs={12} item container alignItems="baseline" gap={0.5}>
        <Typography component="div" sx={{ fontWeight: 'bolder' }}>
          {t('validity', { ns: 'residents' })}
        </Typography>
        {!values.leaseSigner && (
          <Typography variant="subtitle2">{t('not_the_signer_of_the_lease', { ns: 'residents' })}</Typography>
        )}
      </Grid>
      {isCreateModal ? (
        <Properties
          functional_unit={values.functional_unit}
          from={values.from}
          to={values.to}
          setFieldValue={setFieldValue}
          errors={errors}
          datePickerDisabled={values.datePickerDisabled}
          unitDisabled={values.unitDisabled}
          has_deposit={values.has_deposit}
          deposit_amount={values.deposit_amount}
          lease_signer={values.leaseSigner}
        />
      ) : (
        <Properties
          isEdit
          functional_unit={values.functional_unit}
          from={values?.from}
          to={values?.to}
          setFieldValue={setFieldValue}
          errors={errors}
          datePickerDisabled={values.datePickerDisabled}
          unitDisabled={values.unitDisabled}
          has_deposit={values.has_deposit}
          deposit_amount={values.deposit_amount}
          lease_signer={values.leaseSigner}
        />
      )}
      {responseError && (
        <Grid xs={12} item>
          <FormHelperText error>{responseError}</FormHelperText>
        </Grid>
      )}
    </>
  );
};
