import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

export const Concepts = () => {
  const { columns, headerActions, actionColumns } = useColumns();
  const { t } = useTranslation();

  return (
    <CustomDataTable
      title={t('concepts')}
      columns={columns}
      resource="concepts"
      pathApi="payments/v1/charge-concepts"
      listName="charge_concepts"
      canDelete
      canExport
      canSearch
      defaultSortFieldId="name"
      defaultSortAsc
      extraActionsInHeader={headerActions}
      customActionColumns={actionColumns}
    />
  );
};

export default Concepts;
