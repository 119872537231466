import { Form, FormikContextType, FormikProvider } from 'formik';
import React from 'react';
import { TFunction } from 'react-i18next';
import {
  Autocomplete,
  Box,
  Button,
  CardMedia,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { PropCreateModal } from '@/src/hooks/common/useDialog';
import PhoneSelect from '@/src/components/phone-select/PhoneSelect';
import CustomInput from '@/src/components/forms/CustomInput';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import { GridImage } from '@/src/pages/bookings/amenities/abm/create-edit-amenity/CreateEditAmenity.styled';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { CustomDatePicker } from '@/src/components/date-picker/DatePicker';
import { handlerDateSelector } from '@/src/utilities/helpers/utils';

const EditPerson = (props: EditPersonProps) => {
  const {
    Modal,
    close,
    t,
    isLoading,
    isLoadingsubmit,
    formik,
    personFields,
    addressFields,
    citiesData,
    countriesData,
    statesData,
    getUrlIn64,
    isLoadingImage,
    errorSubmittingImage,
  } = props;

  return (
    <Modal title={t('edit_person_modal_title')}>
      {isLoading ? (
        <Box sx={{ width: { xs: '100%', sm: '500px' } }}>
          <CircularSpinner />
        </Box>
      ) : (
        <FormikProvider value={formik} data-testid="editPerson-form">
          <Form>
            <Grid container spacing={3} my={-0.5}>
              {personFields.map((field) => (
                <Grid key={field} item xs={12} sm={6}>
                  <CustomInput
                    field={field}
                    errors={formik.errors}
                    label={t(`edit_person_${field}_field_label`)}
                    placeholder={t(`edit_person_${field}_field_placeholder`)}
                    setFieldValue={formik.setFieldValue}
                    values={formik.values}
                    autoFocus
                    maxLength={50}
                    required
                  />
                </Grid>
              ))}
              <Grid item xs={12} sm={6}>
                <Stack spacing={3}>
                  <PhoneSelect setFieldValue={formik.setFieldValue} values={formik.values} errors={formik.errors} />
                </Stack>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <CustomInput
                  field="identificationNumber"
                  errors={formik.errors}
                  label={t(`edit_person_identification_number_field_label`)}
                  placeholder={t(`edit_person_identification_number_field_placeholder`)}
                  setFieldValue={formik.setFieldValue}
                  values={formik.values}
                  autoFocus
                  maxLength={50}
                  required
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <CustomDatePicker
                  value={formik.values?.birthday}
                  onChange={(newValue: any): void => handlerDateSelector(newValue, formik.setFieldValue, 'birthday')}
                  maxDate={new Date().getTime()}
                  fieldValue="birthday"
                  placeholder={t('edit_person_birthday_field_placeholder')}
                  errorMessage={formik.errors?.birthday}
                  required
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                {formik.values.identificationFileUrl ? (
                  <Grid
                    item
                    xs={24}
                    sm={12}
                    display="flex"
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      width: '100%',
                      marginBottom: { xs: '5px', sm: 0 },
                    }}
                  >
                    <Typography marginBottom="10px">{t('resident_identification_file_url')}</Typography>
                    <Box
                      component="div"
                      sx={{
                        borderRadius: '20px',
                        position: 'relative',
                      }}
                    >
                      <IconButton
                        onClick={() => formik.setFieldValue('identificationFileUrl', null)}
                        sx={{
                          position: 'absolute',
                          top: -15,
                          right: -26,
                        }}
                      >
                        <CancelRoundedIcon />
                      </IconButton>
                      <CardMedia
                        component="img"
                        height={110}
                        image={formik.values.identificationFileUrl}
                        alt="images"
                        sx={{ borderRadius: '10px' }}
                      />
                    </Box>
                  </Grid>
                ) : (
                  <GridImage
                    item
                    xs={24}
                    sm={12}
                    display="flex"
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      marginBottom: { xs: '5px', sm: 0 },
                    }}
                  >
                    <Typography marginBottom="10px">{t('resident_identification_file_url')}</Typography>
                    {isLoadingImage ? (
                      <CircularSpinner />
                    ) : (
                      <InputLabel htmlFor="imageHeader" style={{ margin: 'auto' }}>
                        <IconButton>
                          <InputLabel htmlFor="imageHeader">
                            <input
                              id="imageHeader"
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => getUrlIn64(e)}
                              style={{ display: 'none' }}
                              type="file"
                              accept=".jpg, .jpeg, .HEIC, .png, .svg"
                            />
                            <AddPhotoAlternateRoundedIcon fontSize="large" />
                          </InputLabel>
                        </IconButton>
                      </InputLabel>
                    )}
                    <ErrorText
                      error={(errorSubmittingImage?.code as string) || (formik.errors.identificationFileUrl as string)}
                    />
                  </GridImage>
                )}
              </Grid> */}
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="country_autocomplete"
                  size="small"
                  options={countriesData ?? []}
                  onChange={(e: any, value: any) => {
                    if (!value) {
                      formik.setFieldValue('state', null);
                      formik.setFieldValue('city', null);
                    }
                    formik.setFieldValue('country', value);
                  }}
                  value={
                    countriesData?.find((country: any) => String(country.id) === formik.values?.country.id) || null
                  }
                  getOptionLabel={(option) => option.name}
                  renderOption={(propss, option) => (
                    <Box component="li" key={option.id} {...propss}>
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={`${t('resident_country_field_label', { ns: 'residents' })} *`}
                      placeholder={t('resident_country_field_label_placeholder', { ns: 'residents' })}
                      error={formik.errors.country !== undefined}
                      helperText={formik.errors.country?.toString()}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="states_autocomplete"
                  size="small"
                  options={statesData ?? []}
                  onChange={(e: any, value: any) => {
                    if (!value) {
                      formik.setFieldValue('city', null);
                    }
                    formik.setFieldValue('state', value);
                  }}
                  value={statesData?.find((state: any) => String(state.id) === String(formik.values?.state.id)) || null}
                  getOptionLabel={(option) => option.name}
                  disabled={!formik.values.country}
                  renderOption={(propss, option) => (
                    <Box component="li" key={option.id} {...propss}>
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={`${t('resident_state_field_label')} *`}
                      placeholder={t('resident_state_field_label_placeholder')}
                      error={formik.errors.states !== undefined}
                      helperText={formik.errors.states?.toString()}
                      disabled={!formik.values.country}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="city_autocomplete"
                  size="small"
                  options={citiesData ?? []}
                  onChange={(e: any, value: any) => {
                    formik.setFieldValue('city', value);
                  }}
                  value={citiesData?.find((city: any) => String(city.id) === String(formik.values?.city.id)) || null}
                  getOptionLabel={(option) => option.name}
                  disabled={!formik.values.state}
                  renderOption={(propss, option) => (
                    <Box component="li" key={option.id} {...propss}>
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={`${t('resident_city_field_label')} *`}
                      placeholder={t('resident_city_field_label_placeholder')}
                      error={formik.errors.cityId !== undefined}
                      helperText={formik.errors.city?.toString()}
                      disabled={!formik.values.state}
                    />
                  )}
                />
              </Grid>
              {addressFields.map((field) => (
                <Grid key={field.code} item xs={12} sm={field.size === 'small' ? 3 : 6}>
                  <CustomInput
                    field={field.name}
                    errors={formik.errors}
                    label={t(`edit_person_${field.code}_field_label`)}
                    placeholder={t(`edit_person_${field.code}_field_placeholder`)}
                    setFieldValue={formik.setFieldValue}
                    values={formik.values}
                    autoFocus
                    maxLength={50}
                    inputType={field.type}
                    required
                  />
                </Grid>
              ))}
            </Grid>
            <Box display="flex" justifyContent="flex-end" marginTop="15px">
              <Button sx={{ marginRight: '10px' }} onClick={close}>
                {t('close', { ns: 'common' })}
              </Button>
              <LoadingButton loading={isLoadingsubmit} variant="contained" type="submit">
                {t('edit', { ns: 'common' })}
              </LoadingButton>
            </Box>
          </Form>
        </FormikProvider>
      )}
    </Modal>
  );
};

interface EditPersonProps {
  Modal: React.FunctionComponent<PropCreateModal>;
  close: () => void;
  t: TFunction;
  isLoading: boolean;
  isLoadingsubmit: boolean;
  formik: FormikContextType<any>;
  personFields: Array<string>;
  addressFields: Array<{ code: string; name: string; type: string; size: string }>;
  countriesData?: Array<{ id: number; name: string }>;
  statesData?: Array<{ id: number; name: string }>;
  citiesData?: Array<{ id: number; name: string }>;
  getUrlIn64: (e: any) => void;
  isLoadingImage: boolean;
  errorSubmittingImage?: {
    code: string;
    error_messages: string;
  } | null;
}

export default EditPerson;
