import { FormCategoryContainerProps, FormCategoryProps } from '../../types';
import { FormCategory } from './FormCategory';
import { useCreateCategory } from '../hook/useCreateCategory';
import { useEditCategory } from '../hook/useEditCategory';

const FormCategoryContainer = (props: FormCategoryContainerProps) => {
  const { optionHook } = props;
  const options: { [key: string]: () => FormCategoryProps } = {
    useEdit: () => useEditCategory(props),
    useCreate: () => useCreateCategory(props),
  };

  const {
    codes,
    creationError,
    isLoading,
    createEditLoading,
    errors,
    values,
    handleOnChange,
    handleCreateCategory,
  }: FormCategoryProps = options[optionHook]();

  return (
    <FormCategory
      codes={codes}
      creationError={creationError}
      isLoading={isLoading}
      createEditLoading={createEditLoading}
      errors={errors}
      values={values}
      handleOnChange={handleOnChange}
      handleCreateCategory={handleCreateCategory}
    />
  );
};

export default FormCategoryContainer;
