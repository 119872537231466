import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxTitle = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const BoxContainer2 = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: { md: '10px' },
    flexDirection: { xs: 'column', md: 'row-reverse' },
  }),
);

export const BoxContainerModal = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: { md: '10px' },
    textAlign: 'center',
    width: { xs: '100%', md: '50%' },
  }),
);

export const BoxContainerDate = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    gap: { md: '10px' },
    width: { xs: '100%', md: '50%' },
  }),
);

export const BoxButton = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    width: '100%',
    textAlign: 'right',
    alignItems: 'flex-end',
    mt: { xs: 2, md: 1 },
  }),
);
