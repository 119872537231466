import React from 'react';
import { useTranslation } from 'react-i18next';
import AccountBalance from './AccountBalance';

const AccountBalanceContainer = (props: AccountBalanceContainerProps) => {
  const { balance, isLoading, refetch } = props;
  const { t } = useTranslation();
  const tContext = { ns: 'residents' };

  const childProps = {
    t,
    balance,
    isLoading,
    tContext,
    refetch,
  };

  return <AccountBalance {...childProps} />;
};

interface AccountBalanceContainerProps {
  residencyId: number;
  balance: any;
  isLoading: boolean;
  refetch: any;
}

export default AccountBalanceContainer;
