import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { SearchIconWrapper, SearchStyled, StyledInputBase } from './SearchInput.styled';

const SearchInput = (props: Props) => {
  const { disabled, handleSearchChange, t } = props;
  return (
    <SearchStyled>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        disabled={disabled}
        placeholder={t('search_bar')}
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleSearchChange}
      />
    </SearchStyled>
  );
};

const propTypes = {
  t: PropTypes.func.isRequired,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
interface extraProps {
  disabled: boolean;
  handleSearchChange: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SearchInput.prototypes = propTypes;

export default SearchInput;
