import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterSort from './FilterSort';

const FilterSortContainer = ({
  order,
  setOrder,
}: {
  order: 'asc' | 'desc' | null;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc' | null>>;
}) => {
  const { t } = useTranslation('cdtModal');

  const handleOrder = (value: 'asc' | 'desc') => {
    if (value !== order || order === null) {
      setOrder(value);
    } else {
      setOrder(null);
    }
  };

  const childProps = {
    t,
    handleOrder,
    order,
  };

  return <FilterSort {...childProps} />;
};

export default FilterSortContainer;
