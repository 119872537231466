import { Box, FormHelperText, Grid } from '@mui/material';
import DeliveryInfoContainer from '../delivery-info/DeliveryInfo.container';
import DeliveryButtons from './components/DeliveryButtons';
import DeliveryCheck from './components/DeliveryCheck';
import { DeliveryPeople } from './components/DeliveryPeople';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';

const DeliveryForm = ({
  values,
  setFieldValue,
  errors,
  handleSubmit,
  setSnackBarMessage,
  deliveryId,
  error,
  company,
  personAuthorized,
  people,
  generalLoading,
  mutateGetPersonIfAuthorized,
  mutateGetPeople,
  setError,
  close,
  row,
}: any) => {
  const peopleExist = people?.length > 0;
  const deliveryCheckProps = {
    close,
    peopleExist,
    company,
    values,
    setFieldValue,
    errors,
  };

  const propsInfoContainer = {
    deliveryId,
    close,
    setSnackBarMessage,
    row,
  };

  const deliveryButtonsProps = {
    values,
    close,
    peopleExist,
    setSnackBarMessage,
    mutateGetPersonIfAuthorized,
    mutateGetPeople,
    setError,
  };
  return personAuthorized ? (
    <DeliveryInfoContainer {...propsInfoContainer} />
  ) : (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <DeliveryCheck {...deliveryCheckProps} />
        {generalLoading && <CircularSpinner />}
        {peopleExist && (
          <DeliveryPeople people={people} customerId={values?.customerId} setFieldValue={setFieldValue} />
        )}
        {error && (
          <Grid container item xs={12} justifyContent="center">
            <FormHelperText>{error}</FormHelperText>
          </Grid>
        )}
        <DeliveryButtons {...deliveryButtonsProps} />
      </Grid>
    </Box>
  );
};

export default DeliveryForm;
