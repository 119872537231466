import { LoadingButton } from '@mui/lab';
import { Box, Divider, Grid, Typography } from '@mui/material';

import {
  getFormattedLocalFullDateNumberEs,
  getFormattedLocalFullDateNumberEn,
  getFormattedDateByLanguague,
} from '@/src/utilities/helpers/dateParser';
import TitleWithStatus from '@/src/components/title-with-status/TitleWithStatus';
import { Subtitle } from './DeliveryInfo.styles';
import InfoLabel from '@/src/components/info-label/InfoLabel';
import { DeliveryInfoProps, deliveryStates } from '../types';

const DeliveryInfo = ({
  handleClose,
  values,
  handleAdmission,
  isLoadingAdmission,
  isDelivered,
  t,
  lang,
  noDataComponent,
}: DeliveryInfoProps) => (
  <Box sx={{ marginY: '5px' }}>
    <Grid container spacing={2}>
      <TitleWithStatus
        title={t('delivery_info_modal_title', { ns: 'delivery' })}
        statusText={values ? values.authorization_state?.name : ''}
        statusBg={values ? deliveryStates[values?.authorization_state?.name] : ''}
        onClose={handleClose}
      />
      {!values ? (
        <Grid item lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center">
          {noDataComponent}
        </Grid>
      ) : (
        <>
          <Subtitle sx={{ pt: '0 !important' }}>
            <Typography variant="subtitle1" fontWeight={600}>
              {t('delivery_info_modal_sub_title_1', { ns: 'delivery' })}
            </Typography>
            <Divider sx={{ mt: '2px', mb: '5px' }} />
          </Subtitle>
          <Grid item lg={6} md={12} sm={12} xs={12} display="flex" alignItems="center">
            <InfoLabel
              first={t('name')}
              second={values.customer ? `${values?.customer.name} ${values?.customer.surname}` : '-'}
            />
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12} display="flex" alignItems="center">
            <InfoLabel
              first={t('delivery_modal_input_property_code', { ns: 'delivery' })}
              second={values?.property.code}
            />
          </Grid>
          <Subtitle>
            <Typography variant="subtitle1" fontWeight={600}>
              {t('delivery_info_modal_sub_title_2', { ns: 'delivery' })}
            </Typography>
            <Divider sx={{ mt: '2px', mb: '5px' }} />
          </Subtitle>
          <Grid item lg={12} md={12} sm={12} xs={12} display="flex" alignItems="center">
            <InfoLabel first={t('delivery_modal_input_carriers', { ns: 'delivery' })} second={values?.carrier.name} />
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12} display="flex" alignItems="center">
            <InfoLabel
              first={t('delivery_info_modal_preauthorization', { ns: 'delivery' })}
              second={getFormattedDateByLanguague(
                lang,
                getFormattedLocalFullDateNumberEs,
                getFormattedLocalFullDateNumberEn,
                values?.authorization_date,
              )}
            />
          </Grid>
          {isDelivered && (
            <>
              <Grid item lg={6} md={12} sm={12} xs={12} display="flex" alignItems="center">
                <InfoLabel
                  first={t('delivery_info_modal_admission', { ns: 'delivery' })}
                  second={getFormattedDateByLanguague(
                    lang,
                    getFormattedLocalFullDateNumberEs,
                    getFormattedLocalFullDateNumberEn,
                    values.delivery_date,
                  )}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} display="flex" alignItems="center">
                <InfoLabel
                  first={t('delivery_info_modal_user_entered_delivery', { ns: 'delivery' })}
                  second={values.admission_user}
                />
              </Grid>
            </>
          )}
          {!isDelivered && (
            <Grid container item lg={12} sm={12} md={12} xs={12} justifyContent="flex-end">
              <LoadingButton
                loading={isLoadingAdmission}
                type="button"
                variant="contained"
                size="small"
                sx={{ marginLeft: '1%' }}
                onClick={handleAdmission}
              >
                {t('delivery_modal_button_authorize_entry', { ns: 'delivery' })}
              </LoadingButton>
            </Grid>
          )}
        </>
      )}
    </Grid>
  </Box>
);

export default DeliveryInfo;
