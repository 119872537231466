import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { TFunction } from 'i18next';
import { FadeIn } from '@/src/components/animations';
import AlertNoData from '@/src/components/custom-data-table/components/AlertNoData';
import DataTableStyled from '@/src/components/custom-data-table/CustomDataTable.styled';
import CustomLoader from '@/src/components/custom-loader';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { ResidenciesType } from '../../types';

const CustomDataTableForDocuments = (props: Props) => {
  const { title, columnsResidencies, data, isLoading, pageSize, t } = props;

  return (
    <FadeIn>
      <Card
        sx={{
          marginBottom: '16px',
          boxShadow: 2,
          width: '100%',
        }}
      >
        <CardHeader title={title} titleTypographyProps={{ variant: 'h6' }} sx={{ padding: '8px' }} />
        <CardContent sx={{ padding: '0px !important' }}>
          <DataTableStyled
            dense
            columns={columnsResidencies}
            data={data}
            noDataComponent={<AlertNoData title={t('no_data_was_found')} />}
            theme="palierGridTheme"
            paginationTotalRows={data?.length}
            paginationPerPage={pageSize}
            pagination
            paginationComponentOptions={{ noRowsPerPage: true }}
            progressPending={isLoading}
            progressComponent={<CustomLoader />}
          />
        </CardContent>
      </Card>
    </FadeIn>
  );
};

const propTypes = {};

const defaultProps = {};

interface ExtraProps {
  title: string;
  columnsResidencies: CustomDataTableColumnsProps<ResidenciesType>[];
  data: ResidenciesType[];
  isLoading: boolean;
  pageSize: number;
  t: TFunction;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
CustomDataTableForDocuments.propTypes = propTypes;
CustomDataTableForDocuments.defaultProps = defaultProps;

export default CustomDataTableForDocuments;
