import { axiosAuthenticationInstance } from '../instances/authInstance';
import { CreateArticleRequest, EditArticleRequest } from '../types/types';

// get
export const getArticles = (
  abort?: any,
  creationDateStart?: number | undefined | null,
  creationDateEnd?: number | undefined | null,
  isRestricted?: boolean | undefined | null,
  searchText?: string | undefined | null,
) =>
  axiosAuthenticationInstance.get(
    `feed/v1/publications?page=0&pageSize=1000${creationDateStart ? `&CreationDateStart=${creationDateStart}` : ''}${
      creationDateEnd ? `&CreationDateEnd=${creationDateEnd}` : ''
    }${isRestricted ? `&IsRestricted=${isRestricted}` : ''}${searchText ? `&SearchText=${searchText}` : ''}`,
    abort,
  );
export const getArticleById = (id: number) => axiosAuthenticationInstance.get(`feed/v1/publications/${id}`);
// post
export const createArticle = (req: CreateArticleRequest) =>
  axiosAuthenticationInstance.post('feed/v1/publications', req);
// put
export const editArticle = (req: EditArticleRequest) =>
  axiosAuthenticationInstance.put(`feed/v1/publications/${req.id}`, req.body);
// delete
export const deleteArticle = (id: number) => axiosAuthenticationInstance.delete(`feed/v1/publications/${id}`);
