import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

export const ResidentsRequestsPage = () => {
  const { columnsRelativesRequest, actionColumnRelatives } = useColumns();
  const { t } = useTranslation();
  return (
    <CustomDataTable
      title={t('users_req_page_title', { ns: 'residents' })}
      columns={columnsRelativesRequest}
      resource="relative_residencies"
      pathApi="families/v1/relative-residencies"
      listName="relative_residencies"
      extraFilters={[
        { query: 'RelativeStateCodes', queryValue: 'InReview' },
        { query: 'RelativeStateCodes', queryValue: 'Rejected' },
      ]}
      canExport
      canSearch
      defaultSortFieldId="residency_property_code"
      customActionColumns={actionColumnRelatives}
      defaultSortAsc
    />
  );
};
