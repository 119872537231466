import { IconButton, Typography } from '@mui/material';
import { Visibility, Image } from '@mui/icons-material';
import { ImageViewer } from '@/src/components/image-viewer/ImageViewer';
import { ImagePreviewContainer } from './ImageShow.styled';

interface ImageShowProps {
  url: string;
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

const ImageShow = ({ url, open, handleOpen, handleClose }: ImageShowProps) => {
  const extractingName = url.split('/');
  const name = extractingName[extractingName.length - 1].split('.')[0];
  return (
    <>
      <ImagePreviewContainer>
        <IconButton
          onClick={handleOpen}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Image sx={{ color: 'secondary.main' }} />
        </IconButton>
        <Typography component="span">{name}</Typography>
        <Visibility sx={{ color: 'secondary.main', marginLeft: '10px', cursor: 'pointer' }} onClick={handleOpen} />
      </ImagePreviewContainer>
      {open && <ImageViewer onClose={handleClose} url={url} open={open} />}
    </>
  );
};

export default ImageShow;
