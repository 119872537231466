import React from 'react';
import { Box, Card, CardContent, CardHeader, Stack, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FadeIn } from '@/src/components/animations';
import AlertNoData from '@/src/components/custom-data-table/components/AlertNoData';
import DataTableStyled from '@/src/components/custom-data-table/CustomDataTable.styled';
import { DataTableForUserProps } from '../../types';
import CustomLoader from '@/src/components/custom-loader';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';
import useBreakpoints from '@/src/hooks/common/useBreakpoints';

const CustomDataTableForUser = ({ title, data, columns, isLoading, customActionColumns }: DataTableForUserProps) => {
  const { t } = useTranslation();
  const { matchesSm } = useBreakpoints();
  const pageSize: number = Math.ceil((window.innerHeight - (matchesSm ? 350 : 260)) / 30) - 1;

  const extraColumnsActions = React.useCallback(
    (row: any) => (
      <Stack direction="row" spacing={2}>
        {customActionColumns?.map((customActionColumn: ActionColumn) => {
          if (customActionColumn.isHidden && customActionColumn.isHidden(row))
            return <Box key={customActionColumn.id} sx={{ width: '25px' }} />;
          if (customActionColumn.onIconClick) {
            return (
              <Tooltip
                key={customActionColumn.id}
                title={t(customActionColumn.id.replace('-', '_'), { ns: 'cdtModal' })}
              >
                <Box onClick={() => customActionColumn.onIconClick(row)}>
                  {customActionColumn.icon({ sx: { color: 'secondary.main', cursor: 'pointer' }, row })}
                </Box>
              </Tooltip>
            );
          }

          return (
            <CustomTableAction
              key={customActionColumn.id}
              row={row}
              component={customActionColumn.component}
              Icon={customActionColumn.icon}
              width={customActionColumn.width}
              modalTitle={title ?? ``}
              type={`${customActionColumn.id}`}
              customizedTitle={customActionColumn.customizedTitle}
            />
          );
        })}
      </Stack>
    ),
    [],
  );

  let finalColumns = columns;
  if (customActionColumns && customActionColumns?.length > 0)
    finalColumns = columns.concat([
      {
        id: 'actions',
        cell: extraColumnsActions,
        name: t('actions'),
        center: true,
        maxWidth: '200px',
      },
    ]);

  return (
    <FadeIn>
      <Card
        sx={{
          marginBottom: '16px',
          boxShadow: 2,
          width: '100%',
        }}
      >
        <CardHeader title={title} titleTypographyProps={{ variant: 'h6' }} sx={{ padding: '8px' }} />
        <CardContent sx={{ padding: '0px !important' }}>
          <DataTableStyled
            dense
            columns={finalColumns}
            data={data}
            noDataComponent={<AlertNoData title={t('no_data_was_found')} />}
            theme="palierGridTheme"
            paginationTotalRows={data?.length}
            paginationPerPage={pageSize}
            pagination
            paginationComponentOptions={{ noRowsPerPage: true }}
            progressPending={isLoading}
            progressComponent={<CustomLoader />}
          />
        </CardContent>
      </Card>
    </FadeIn>
  );
};

export default CustomDataTableForUser;
