import { useTranslation } from 'react-i18next';
import { MarkChatUnreadRounded } from '@mui/icons-material';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import TextLabel from '@/src/components/text-label/TextLabel';
import { Issue, issuesStates } from '../types';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';
import ChatComponent from './abm/chat/ChatComponent.container';
import {
  getDayHourYearDateEn,
  getDayHourYearDateEs,
  getFormattedDateByLanguague,
} from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';

export const useColumns = () => {
  const { t } = useTranslation();
  const { apartments } = useApartmentsFilters();
  const issuesStatesList = [
    {
      id: 'open',
      label: t('issue_open_state', { ns: 'messages' }),
      value: 1,
      query: 'IssueStateIds',
    },
    {
      id: 'in_progress',
      label: t('issue_inprogress_state', { ns: 'messages' }),
      value: 2,
      query: 'IssueStateIds',
    },
    {
      id: 'closed',
      label: t('issue_closed_state', { ns: 'messages' }),
      value: 3,
      query: 'IssueStateIds',
    },
  ] as any;
  const lang = getLanguage()?.split('-')[0];

  const columns: CustomDataTableColumnsProps<Issue>[] = [
    {
      id: 'id',
      name: t('messages_list_id_column', { ns: 'messages' }),
      selector: (row) => row.id,
      sortField: 'id',
      sortable: true,
      right: true,
      maxWidth: '1%',
      filters: [
        {
          id: 'issue_id',
          query: 'IssueId',
          type: 'numeric',
        },
      ],
    },
    {
      id: 'creation_date',
      name: t('parking_page_table_column_creation', { ns: 'parking' }),
      selector: (row) =>
        getFormattedDateByLanguague(lang, getDayHourYearDateEs, getDayHourYearDateEn, row.creation_date),
      sortField: 'last_message_date',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'creation_date',
          query: 'CreationDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'property_code',
      sortField: 'property_code',
      name: t('modal_load_package_input_property_code', { ns: 'packages' }),
      selector: (row) => row.property.code || '-',
      sortable: true,
      filters: apartments,
      width: '110px',
    },
    {
      id: 'name',
      name: t('messages_list_name_column', { ns: 'messages' }),
      selector: (row) => `${row.customer.name} ${row.customer.surname}`,
      sortField: 'name',
      sortable: true,
      filters: [
        {
          id: 'name',
          query: 'CustomerFullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'title',
      name: t('messages_list_category_column', { ns: 'messages' }),
      selector: (row) => row.category?.name,
      sortField: 'title',
      sortable: true,
    },
    {
      id: 'phone_number',
      name: t('messages_list_phone_number_column', { ns: 'messages' }),
      selector: (row) => row.customer.full_phone_number,
      sortField: 'phone_number',
      sortable: true,
      right: true,
      filters: [
        {
          id: 'customer_full_phoneNumber',
          query: 'CustomerFullPhoneNumber',
          type: 'contains',
        },
      ],
    },
    {
      id: 'status',
      name: t('messages_list_status_column', { ns: 'messages' }),
      cell: (row) => (
        <TextLabel
          text={row.status.name.toLocaleUpperCase()}
          color="#F7F7F7"
          padding="2px 12px"
          width="100px"
          bg={issuesStates[row.status.code]}
        />
      ),
      selector: (row: Issue) => row.status.code,
      sortField: 'status',
      sortable: true,
      center: true,
      filters: issuesStatesList,
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'detail',
      customizedTitle: true,
      icon: (props: any) => (
        <MarkChatUnreadRounded
          sx={{ ...props.sx, color: props.row.has_pending_messages ? '#516F3F' : 'secondary.main' }}
        />
      ),
      component: (props: any) => <ChatComponent {...props} />,
      width: { xs: '90%', sm: '70%', md: '55%', lg: '40%' },
    },
  ];

  return { columns, actionColumns };
};
