import * as React from 'react';
import { Modal, CardMedia, ClickAwayListener, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const ImageViewerContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 16px;
  background: transparent;
  border: none;
  height: 100%;
  width: fit-content;
`;

export const ImageViewer = ({ open, onClose, url }: { open: boolean; onClose: () => void; url: string }) => (
  <Modal open={open} onClick={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
    <ImageViewerContainer onClick={onClose}>
      <ClickAwayListener onClickAway={onClose}>
        <CardMedia
          sx={{ objectFit: 'contain', minWidth: '350px' }}
          component="img"
          height="100%"
          width="100%"
          image={url}
          alt="image_to_be_sent"
        />
      </ClickAwayListener>
    </ImageViewerContainer>
  </Modal>
);
