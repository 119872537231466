import React from 'react';
import { SelectedUserProps } from '../types';
import User from '../common/user/User';

export const EditResident = (props: SelectedUserProps) => {
  const { userStates, isLoadingStates, values, handleSubmit, errors, setFieldValue, handleClose, isLoading } = props;

  return (
    <User
      isEdit
      values={values}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      errors={errors}
      setFieldValue={setFieldValue}
      handleClose={handleClose}
      userStates={userStates}
      isLoadingStates={isLoadingStates}
    />
  );
};
