export type ValetParkingStateOptions = {
  [key: string]: string;
};

export const valetParkingStates: ValetParkingStateOptions = {
  Nuevo: '#424867',
  New: '#424867',
  Procesado: '#516F3F',
  Processed: '#516F3F',
};

export type DevMinutes = {
  id: number;
  minutes: number;
  selected: boolean;
};

export interface ParkingResidents {
  id: number;
  delivery_date: number | null;
  creation_date: number;
  residency: {
    id: number;
    property: {
      code: string;
    };
    type: {
      code: string;
      name: string;
    };
  };
  customer: {
    name: string;
    surname: string;
  };
  vehicle: {
    license_plate: string;
    brand: {
      name: string;
    };
    model: {
      name: string;
    };
  };
  request_state: {
    name: string;
  };
}

export interface ParkingDetailProps {
  close: () => void;
  setSnackBarMessage: (message: string) => void;
  row: ParkingResidents;
}
