import {
  LocalParking,
  EmojiPeopleOutlined,
  ConnectWithoutContactRounded,
  EmailRounded,
  ListAlt,
  EventNote,
  PeopleAlt,
  Pets,
  TimeToLeave,
  Gite,
  EventAvailable,
} from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import CounterIcon, { CounterIconProps } from '../../../router/navigation/counter-icon/CounterIcon';
import { getsAllCounters } from '@/src/api/endpoints/utils';
import { UserContext } from '@/src/context/user.context';

export type CountersObject = {
  [key: string]: () => JSX.Element;
};

export type GetAllCountersResponse = {
  support: {
    open_issues: number;
  };
  valet_parking: {
    new_guest_requests: number;
    new_requests: number;
  };
  bookings: {
    pending_bookings: number;
  };
  household: {
    families: {
      new_relative_requests: number;
    };
    pets: {
      new_pet_requests: number;
    };
    vehicles: {
      new_vehicle_requests: number;
    };
  };
};

export const useGetCounters = (doRefetch: boolean, refetchTime = 5) => {
  const [counters, setCountersData] = useState<GetAllCountersResponse | null>(null);
  const { menus: loggedMenus } = useContext(UserContext);
  const abortCont = new AbortController();

  const getCountersData = async (abort?: any) => {
    await getsAllCounters(abort)
      .then((response) => {
        setCountersData(response.data.counters);
      })
      .catch((err) => {
        // console.log(err);
        setCountersData(null);
      });
  };

  const menusWithCounters = [
    'parking',
    'parking/guests',
    'parkings/residents',
    'tickets',
    'tickets/list',
    'bookings/reservations',
    'household/relatives-requests',
    'household/pet-requests',
    'household/vehicles-requests',
  ];

  useEffect(() => {
    const haveToSearchCounters = loggedMenus?.find((menuItem: any) =>
      menusWithCounters.some((element: string) => element === menuItem.name),
    );

    if (haveToSearchCounters) {
      getCountersData({ signal: abortCont.signal });
    }

    return () => abortCont.abort();
  }, []);

  useEffect(() => {
    if (loggedMenus && !counters) getCountersData();

    if (doRefetch) {
      const timeset = 1000 * 60 * refetchTime;
      const doingRefetch = setInterval(() => {
        getCountersData();
      }, timeset);

      return () => clearInterval(doingRefetch);
    }
  }, [doRefetch, loggedMenus]);

  const getCounterIconComponent = (props: CounterIconProps) => <CounterIcon {...props} />;
  const countersData: CountersObject = {
    '/parking': () =>
      getCounterIconComponent({
        count: counters ? +counters.valet_parking.new_guest_requests + +counters.valet_parking.new_requests : 0,
        Icon: LocalParking,
        iconMarginLeft: '-4px',
      }),
    '/parking/guests': () =>
      getCounterIconComponent({
        count: +`${counters?.valet_parking.new_guest_requests ?? 0}`,
        Icon: ConnectWithoutContactRounded,
      }),
    '/parking/residents': () =>
      getCounterIconComponent({
        count: +`${counters?.valet_parking.new_requests ?? 0}`,
        Icon: EmojiPeopleOutlined,
      }),
    '/tickets': () =>
      getCounterIconComponent({
        count: +`${counters?.support.open_issues ?? 0}`,
        Icon: EmailRounded,
      }),
    '/tickets/list': () =>
      getCounterIconComponent({
        count: +`${counters?.support.open_issues ?? 0}`,
        Icon: ListAlt,
      }),
    '/bookings': () =>
      getCounterIconComponent({
        count: +`${counters?.bookings?.pending_bookings ?? 0}`,
        Icon: EventAvailable,
      }),
    '/bookings/reservations': () =>
      getCounterIconComponent({
        count: +`${counters?.bookings?.pending_bookings ?? 0}`,
        Icon: EventNote,
      }),
    '/household': () =>
      getCounterIconComponent({
        count: +`${
          (counters?.household?.families?.new_relative_requests ?? 0) +
          (counters?.household?.pets?.new_pet_requests ?? 0) +
          (counters?.household?.vehicles?.new_vehicle_requests ?? 0)
        }`,
        Icon: Gite,
      }),
    '/household/relatives-requests': () =>
      getCounterIconComponent({
        count: +`${counters?.household?.families?.new_relative_requests ?? 0}`,
        Icon: PeopleAlt,
      }),
    '/household/pets-requests': () =>
      getCounterIconComponent({
        count: +`${counters?.household?.pets?.new_pet_requests ?? 0}`,
        Icon: Pets,
      }),
    '/household/vehicles-requests': () =>
      getCounterIconComponent({
        count: +`${counters?.household?.vehicles?.new_vehicle_requests ?? 0}`,
        Icon: TimeToLeave,
      }),
  };

  return { countersData, forceRefresh: () => getCountersData({ signal: abortCont.signal }) };
};
