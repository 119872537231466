import useBreakpoints from '@/src/hooks/common/useBreakpoints';

export const useRegistryBreakpoints = () => {
  const { matchesMd, matchesSm } = useBreakpoints();

  const lgStyles = { width: '50%', marginTop: '16px', marginBottom: '34px' };
  const mdStyles = { width: '80%', marginTop: '16px', marginBottom: '34px' };
  const smStyles = { width: '250px', marginTop: '16px', marginBottom: '34px' };

  const switchHeaderStyles = () => {
    if (matchesSm) {
      return smStyles;
    }
    if (matchesMd) {
      return mdStyles;
    }
    return lgStyles;
  };

  // const titleFontSm = {
  //   fontSize: '22px',
  //   color: '#A68B5C',
  //   fontFamily: 'Sorts Mill Goudy,serif',
  // };
  // const titleFontMd = {
  //   fontSize: '30px',
  //   color: '#A68B5C',
  //   fontFamily: 'Sorts Mill Goudy,serif',
  // };
  // const bodyFontSm = { fontSize: '14px', fontFamily: 'Avenir Next !important', marginBottom: '16px' };
  // const bodyFontMd = { fontSize: '16px', fontFamily: 'Avenir Next !important', marginBottom: '16px' };

  const headerStyles = switchHeaderStyles();
  // const titleStyles = matchesSm
  //   ? titleFontSm
  //   : matchesMd
  //   ? titleFontMd
  //   : { color: '#A68B5C', fontSize: '40px', fontWeigth: '700', fontFamily: 'Sorts Mill Goudy,serif' };
  // const infoStyles = matchesSm
  //   ? bodyFontSm
  //   : matchesMd
  //   ? bodyFontMd
  //   : { fontSize: '20px', fontFamily: 'Avenir Next', fontWeight: '400', color: '#4A4A4A' };
  // const formContainerStyles = matchesSm || matchesMd || matchesXl ? {} : { height: 550 };

  const containerStyles = {
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    justifyContext: 'center',
    textAlign: 'center',
    backgroundColor: '#FFFEF7',
  };

  const CardContainerStyles = {
    width: { xs: '90%', sm: '75%' },
    margin: 'auto',
    paddingBottom: '20px',
    borderRadius: '0px',
    backgroundColor: '#f3f3f4',
  };

  const titleStyles = {
    fontSize: { xs: '22px', sm: '22px', md: '30px', lg: '40px' },
    fontFamily: 'Sorts Mill Goudy,serif !important',
    fontWeight: { lg: 700 },
    color: 'secondary.main',
  };
  const infoStyles = {
    fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '20px' },
    marginBottom: { xs: '16px', sm: '16px', md: '16px', lg: 0 },
    fontFamily: 'Avenir Next !important',
    fontWeight: { lg: 400 },
    color: { lg: '#4A4A4A' },
  };
  const selfiBtnStyles = { color: 'secondary.main', fontWeight: 'bold', textTransform: 'uppercase' };

  return {
    headerStyles,
    CardContainerStyles,
    containerStyles,
    titleStyles,
    infoStyles,
    selfiBtnStyles,
  };
};
