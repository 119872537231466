import { Box, Grid, Stack, Typography } from '@mui/material';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import BackToBaseButton from '@/src/components/custom-data-table/components/BackToBaseButton';
import { ResidentsDetailSectionProps } from './types';
import ResidentCard from './resident-card';
import Notes from './notes';

export const ResidentsDetailSection = ({
  closeSubGrid,
  t,
  isLoading,
  refetch,
  resident,
  selectedRow,
  columnsPets,
  columnsRelatives,
  columnsVehicles,
  actionColumnsVehicles,
  actionColumnsPets,
  actionColumnsRelatives,
  vehicleHeaderActions,
  headerActionsPets,
  headerActionsRelatives,
  SnackBar,
  setSnackBarMessage,
}: ResidentsDetailSectionProps) => (
  <>
    <Box display="flex" marginBottom="20px">
      <BackToBaseButton backToBase={closeSubGrid} />
      <Typography variant="h4">{t('residents_detail', { ns: 'residents' })}</Typography>
    </Box>
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          <ResidentCard
            setSnackBarMessage={setSnackBarMessage}
            isLoading={isLoading}
            refetch={refetch}
            resident={resident}
          />
          <Notes setSnackBarMessage={setSnackBarMessage} row={selectedRow} />
        </Stack>
      </Grid>
      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          <CustomDataTable
            title={t('relatives')}
            columns={columnsRelatives}
            customActionColumns={actionColumnsRelatives}
            resource="relative_residencies"
            pathApi="families/v1/relative-residencies"
            listName="relative_residencies"
            defaultSortFieldId="residency_property_code"
            extraFilters={[
              { query: 'RelativeStateCodes', queryValue: 'Approved' },
              { query: 'ResidencyId', queryValue: selectedRow.residency.id },
            ]}
            customPageSize={3}
            defaultSortAsc
            extraActionsInHeader={headerActionsRelatives}
          />
          <CustomDataTable
            title={t('pets')}
            columns={columnsPets}
            customActionColumns={actionColumnsPets}
            extraActionsInHeader={headerActionsPets}
            resource="pet_residencies"
            pathApi="pets/v1/pet-residencies"
            listName="pet_residencies"
            defaultSortFieldId="pet_creation_date"
            extraFilters={[
              { query: 'PetStateCodes', queryValue: 'Approved' },
              { query: 'ResidencyId', queryValue: selectedRow.residency.id },
            ]}
            customPageSize={3}
            defaultSortAsc
          />
          <CustomDataTable
            title={t('vehicles')}
            columns={columnsVehicles}
            customActionColumns={actionColumnsVehicles}
            resource="vehicle_residencies"
            pathApi="vehicles/v1/vehicle-residencies"
            listName="vehicle_residencies"
            defaultSortFieldId="vehicle_creation_date"
            extraFilters={[
              { query: 'VehicleStateCodes', queryValue: 'Approved' },
              { query: 'ResidencyId', queryValue: selectedRow.residency.id },
            ]}
            customPageSize={3}
            defaultSortAsc
            extraActionsInHeader={vehicleHeaderActions}
          />
        </Stack>
      </Grid>
    </Grid>
    <SnackBar />
  </>
);
