import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Box, Button, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { ApproveAndRejectProps } from './types';
import RejectContainer from './reject/RejectContainer';

const ApproveAndReject = ({
  fnApprove,
  fnReject,
  setSnackBarMessage,
  resource,
  row,
  reasonRejection,
}: ApproveAndRejectProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const { mutate, isLoading } = useMutation(fnApprove);
  const handleApproval = () => {
    setError('');
    mutate(resource === 'relative' ? row?.relative?.id : row[resource.toLowerCase()].id, {
      onSuccess: async () => {
        setSnackBarMessage(t('approved_request_success_message'));
      },
      onError: async () => {
        setError(t('approved_request_error_message'));
      },
    });
  };
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(!openModal);
  const rejectProps = {
    fnReject,
    fnMessageSuccess: setSnackBarMessage,
    closeModal: handleOpenModal,
    resource: t(resource, { ns: 'customerUsers' }).toLowerCase(),
    openModal,
    id: resource === 'relative' ? row?.relative?.id : row[resource.toLowerCase()].id,
  };

  return (
    <>
      {error && (
        <FormHelperText error sx={{ mt: 0 }}>
          {error}
        </FormHelperText>
      )}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'end', gap: 1 }}>
        {!reasonRejection && (
          <Button
            variant="outlined"
            color="error"
            size="small"
            type="button"
            onClick={handleOpenModal}
            sx={{ width: { xs: '100%', sm: 'auto' } }}
          >
            {t('reject')}
          </Button>
        )}
        <LoadingButton
          size="small"
          variant="contained"
          color="success"
          type="button"
          loading={isLoading}
          onClick={handleApproval}
          sx={{ width: { xs: '100%', sm: 'auto' } }}
        >
          {t('approve')}
        </LoadingButton>
        {openModal && <RejectContainer {...rejectProps} />}
      </Box>
    </>
  );
};

export default ApproveAndReject;
