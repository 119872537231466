import React from 'react';
import { CardContent, CardHeader, Divider, Typography } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import { AccountBalanceObject } from '../types';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import St from './AccountBalance.styled';
import RefreshButton from '@/src/components/refresh-button/RefreshButton';

const AccountBalance = (props: AccountBalanceProps) => {
  const { balance, t, isLoading, tContext, refetch } = props;
  return (
    <St.Card>
      <CardHeader
        title={t('account_balance_header', tContext)}
        action={<RefreshButton refresh={refetch} disabled={isLoading} />}
      />
      <Divider />
      <CardContent>
        {isLoading ? (
          <CircularSpinner />
        ) : (
          <St.Content>
            <Typography>{t('total_charges', tContext)}</Typography>
            <St.Amount>{`$${balance?.total_charges_amount ?? '-'}`}</St.Amount>
            <RemoveIcon sx={{ margin: '20px 0' }} />
            <Typography>{t('total_approved_payments', tContext)}</Typography>
            <St.Amount>{`$${balance?.total_completed_payments_amount ?? '-'}`}</St.Amount>
          </St.Content>
        )}
      </CardContent>
      <St.Result>
        {isLoading ? (
          <CircularSpinner />
        ) : (
          <St.SalaryContent>
            <St.TextContainer>
              <St.TotalText>{t('total_balance', tContext)}</St.TotalText>
              <St.Caption variant="caption">
                {t('pending_balance', { ...tContext, balance: balance?.total_pending_payments_amount })}
              </St.Caption>
            </St.TextContainer>
            <St.TotalAmount color="white"> {`$${balance?.total_balance ?? '-'}`}</St.TotalAmount>
          </St.SalaryContent>
        )}
      </St.Result>
    </St.Card>
  );
};

interface AccountBalanceProps {
  balance?: AccountBalanceObject;
  isLoading: boolean;
  t: any;
  tContext: { ns: string };
  refetch: any;
}

export default AccountBalance;
