import { useQuery } from '@tanstack/react-query';
import { getResidencyTypes } from '@/src/api/endpoints/master';
import { ResidencyType } from '@/src/utilities/helpers/commonTypes';
import { FilterByColumn } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';

const useResidencyFilters = () => {
  const getResidencyTypesMapper = (data: Array<ResidencyType>) => {
    const res = data?.map(
      (item) =>
        ({
          id: item.id,
          label: item.name,
          value: item.code,
          query: 'InterestedIn',
        } as FilterByColumn),
    );
    return res;
  };

  const { data: residencyTypes, isLoading } = useQuery(['getResidencyTypes'], getResidencyTypes, {
    select: (response) => getResidencyTypesMapper(response?.data.residency_types as Array<ResidencyType>),
  });

  return { residencyTypes, isLoading };
};

export default useResidencyFilters;
