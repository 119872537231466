interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}
export const countries: readonly CountryType[] = [
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
    phone: '+1-268',
  },
  { code: 'AR', label: 'Argentina', phone: '+549' },
  { code: 'AT', label: 'Austria', phone: '+43' },
  {
    code: 'AU',
    label: 'Australia',
    phone: '+61',
    suggested: true,
  },
  { code: 'AW', label: 'Aruba', phone: '+297' },
  { code: 'BB', label: 'Barbados', phone: '+1-246' },
  { code: 'BE', label: 'Belgium', phone: '+32' },
  { code: 'BG', label: 'Bulgaria', phone: '+359' },
  { code: 'BM', label: 'Bermuda', phone: '+1-441' },
  { code: 'BO', label: 'Bolivia', phone: '+591' },
  { code: 'BR', label: 'Brazil', phone: '+55' },
  { code: 'BS', label: 'Bahamas', phone: '+1-242' },
  { code: 'BY', label: 'Belarus', phone: '+375' },
  { code: 'BZ', label: 'Belize', phone: '+501' },
  {
    code: 'CA',
    label: 'Canada',
    phone: '+1 ',
    suggested: true,
  },
  { code: 'CH', label: 'Switzerland', phone: '+41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '+225' },
  { code: 'CL', label: 'Chile', phone: '+56' },
  { code: 'CN', label: 'China', phone: '+86' },
  { code: 'CO', label: 'Colombia', phone: '+57' },
  { code: 'CR', label: 'Costa Rica', phone: '+506' },
  { code: 'CU', label: 'Cuba', phone: '+53' },
  { code: 'CV', label: 'Cape Verde', phone: '+238' },
  { code: 'CW', label: 'Curacao', phone: '+599' },
  {
    code: 'DE',
    label: 'Germany',
    phone: '+49',
    suggested: true,
  },
  { code: 'DK', label: 'Denmark', phone: '+45' },
  {
    code: 'DO',
    label: 'Dominican Republic',
    phone: '+1-809',
  },
  { code: 'EC', label: 'Ecuador', phone: '+593' },
  { code: 'ES', label: 'Spain', phone: '+34' },
  { code: 'FI', label: 'Finland', phone: '+358' },
  { code: 'FJ', label: 'Fiji', phone: '+679' },
  {
    code: 'FR',
    label: 'France',
    phone: '+33',
    suggested: true,
  },
  { code: 'GB', label: 'United Kingdom', phone: '+44' },
  { code: 'GF', label: 'French Guiana', phone: '+594' },
  { code: 'GI', label: 'Gibraltar', phone: '+350' },
  { code: 'GL', label: 'Greenland', phone: '+299' },
  { code: 'GT', label: 'Guatemala', phone: '+502' },
  { code: 'GY', label: 'Guyana', phone: '+592' },
  { code: 'HK', label: 'Hong Kong', phone: '+852' },
  { code: 'HN', label: 'Honduras', phone: '+504' },
  { code: 'HR', label: 'Croatia', phone: '+385' },
  { code: 'HT', label: 'Haiti', phone: '+509' },
  { code: 'IE', label: 'Ireland', phone: '+353' },
  { code: 'IS', label: 'Iceland', phone: '+354' },
  { code: 'IT', label: 'Italy', phone: '+39' },
  { code: 'JM', label: 'Jamaica', phone: '+1-876' },
  { code: 'MX', label: 'Mexico', phone: '+52' },
  { code: 'NI', label: 'Nicaragua', phone: '+505' },
  { code: 'NL', label: 'Netherlands', phone: '+31' },
  { code: 'NO', label: 'Norway', phone: '+47' },
  { code: 'NZ', label: 'New Zealand', phone: '+64' },
  { code: 'PA', label: 'Panama', phone: '+507' },
  { code: 'PE', label: 'Peru', phone: '+51' },
  { code: 'PF', label: 'French Polynesia', phone: '+689' },
  { code: 'PT', label: 'Portugal', phone: '+351' },
  { code: 'PY', label: 'Paraguay', phone: '+595' },
  { code: 'RS', label: 'Serbia', phone: '+381' },
  { code: 'RU', label: 'Russian Federation', phone: '+7' },
  { code: 'SE', label: 'Sweden', phone: '+46' },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    phone: '+1-868',
  },
  { code: 'UA', label: 'Ukraine', phone: '+380' },
  {
    code: 'US',
    label: 'United States',
    phone: '+1',
    suggested: true,
  },
  { code: 'UY', label: 'Uruguay', phone: '+598' },
  { code: 'VE', label: 'Venezuela', phone: '+58' },
];
