import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import SearchInput from './SearchInput';

const SearchInputContainer = (props: Props) => {
  const { endpoint, resource, setPostData } = props;
  const { t } = useTranslation();

  const handleSearchChange = async (e: any) => {
    const response = await endpoint(null, null, null, null, e.target.value !== undefined ? e.target.value : null);
    switch (resource) {
      case 'community':
        setPostData(response?.data?.posts);
        break;
      default:
        setPostData(response?.data?.publications);
        break;
    }
  };

  const childProps = {
    ...props,
    handleSearchChange,
    t,
  };

  return <SearchInput {...childProps} />;
};

const propTypes = {};

// eslint-disable-next-line @typescript-eslint/naming-convention
interface extraProps {
  disabled: boolean;
  endpoint: (
    abort?: any,
    creationDateStart?: number | undefined | null,
    creationDateEnd?: number | undefined | null,
    isRestricted?: boolean | undefined | null,
    searchText?: string | undefined | null,
  ) => Promise<AxiosResponse<any, any>>;
  resource: string;
  setPostData: Dispatch<any>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SearchInputContainer.prototypes = propTypes;

export default SearchInputContainer;
