import getEnvVariables from '../helpers/getEnvVariables';
import { NotificationCustomResponse } from '../helpers/commonTypes';

const {
  VITE_FIREBASE_API_KEY,
  VITE_FIREBASE_AUTH_DOMAIN,
  VITE_FIREBASE_PROJECT_ID,
  VITE_FIREBASE_STORAGE_BUCKET,
  VITE_FIREBASE_MESSAGING_SENDER_ID,
  VITE_FIREBASE_APP_ID,
  VITE_FIREBASE_MESUREMENT_ID,
} = getEnvVariables();

export const firebaseConfig = {
  apiKey: VITE_FIREBASE_API_KEY,
  authDomain: VITE_FIREBASE_AUTH_DOMAIN,
  projectId: VITE_FIREBASE_PROJECT_ID,
  storageBucket: VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: VITE_FIREBASE_APP_ID,
  measurementId: VITE_FIREBASE_MESUREMENT_ID,
};

export const responseConfig: NotificationCustomResponse = {
  ValetParkingRequestCreated: {
    url: '/parking/residents',
  },
  ValetParkingGuestParkingRequested: {
    url: '/parking/guests',
  },
  FamilyRelativeRequestCreated: {
    url: '/household/relatives-requests',
  },
  FamilyRelativeUpdated: {
    url: '/household/relatives-requests',
  },
  PetRequestCreated: {
    url: '/household/pets-requests',
  },
  PetUpdated: {
    url: '/household/pets-requests',
  },
  VehicleRequestCreated: {
    url: '/household/vehicles-requests',
  },
  VehicleUpdated: {
    url: '/household/vehicles-requests',
  },
  BookingCreatedByCustomer: {
    url: '/bookings/reservations',
  },
  BookingCancelledByCustomer: {
    url: '/bookings/reservations',
  },
};
