import * as Yup from 'yup';
import { TProp } from '@/src/utilities/helpers/commonTypes';

export const validate = ({ t }: TProp) =>
  Yup.lazy(() =>
    Yup.object().shape({
      email: Yup.string()
        .trim()
        .email(t('registry_form_email_field_invalid_value', { ns: 'guests' }))
        .required(t('required_field', { ns: 'errors' })),
      name: Yup.string()
        // .matches(VALID_STRING_FIELD, t('registry_form_name_field_invalid_value', { ns: 'guests' }))
        .required(t('required_field', { ns: 'errors' })),
      surname: Yup.string()
        // .matches(VALID_STRING_FIELD, t('registry_form_surname_field_invalid_value', { ns: 'guests' }))
        .required(t('required_field', { ns: 'errors' })),
      countryCode: Yup.string().required(t('required_field', { ns: 'errors' })),
      countryIsoCode: Yup.string().required(t('required_field', { ns: 'errors' })),
      phoneNumber: Yup.string().required(t('required_field', { ns: 'errors' })),
      how_many: Yup.number()
        .max(10, t('registry_form_how_many_field_max_value', { ns: 'guests' }))
        .required(t('required_field', { ns: 'errors' })),
      image: Yup.string(),
    }),
  );
