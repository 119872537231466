import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { useTranslation } from 'react-i18next';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { formatPhoneNumber } from '@/src/utilities/helpers/stringsHelper';
import { getLanguage } from '@/src/utilities/storage';
import { UserRowProps } from './types';
import { SelectedRow } from '../types';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';

export const useColumns = (openDetailSection: (value: SelectedRow) => void) => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const { apartments } = useApartmentsFilters();

  const columns: CustomDataTableColumnsProps<UserRowProps | any>[] = [
    {
      id: 'name',
      name: t('name'),
      selector: (row: UserRowProps) => row.name,
      sortField: 'name',
      sortable: true,
      filters: [
        {
          id: 'customer-name',
          query: 'CustomerName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'surname',
      name: t('surname'),
      selector: (row: UserRowProps) => row.surname,
      sortField: 'surname',
      sortable: true,
      filters: [
        {
          id: 'customer-surname',
          query: 'CustomerSurname',
          type: 'contains',
        },
      ],
    },
    {
      id: 'property_code',
      name: t('property_code', { ns: 'customerUsers' }),
      selector: (row: any) => {
        const res = row?.residencies.reduce(
          (maxResidency: any, residencie: any) => (residencie.to > maxResidency.to ? residencie : maxResidency),
          row?.residencies[0],
        );
        return res?.property?.code;
      },
      sortField: 'property_code',
      sortable: true,
      filters: apartments,
    },
    {
      id: 'phone_number',
      name: t('residents_list_phone_number_column', { ns: 'customerUsers' }),
      selector: (row: UserRowProps) => formatPhoneNumber(row.phone_number),
      sortField: 'phone_number',
      sortable: true,
      filters: [
        {
          id: 'phone-number',
          query: 'CustomerFullPhoneNumber',
          type: 'contains',
        },
      ],
    },
    {
      id: 'creation_date',
      name: t('creation_date', { ns: 'customerUsers' }),
      selector: (row: UserRowProps) =>
        row.creation_date
          ? getFormattedDateByLanguague(lang, getFormattedDateFullYearEs, getFormattedDateFullYearEn, row.creation_date)
          : '-',
      sortField: 'creation_date',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'customers-creation-date',
          query: 'CreationDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'disablement_date',
      name: t('disablement_date', { ns: 'customerUsers' }),
      selector: (row: UserRowProps) =>
        row.disabled_date
          ? getFormattedDateByLanguague(lang, getFormattedDateFullYearEs, getFormattedDateFullYearEn, row.disabled_date)
          : '-',
      sortField: 'disabled_date',
      sortable: false,
      center: true,
      isHidden: 'showDisabled',
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'detail',
      icon: (props: any) => <VisibilityRoundedIcon sx={props.sx} />,
      onIconClick: (personSelected: any) => openDetailSection(personSelected),
      width: { xs: '90%', sm: '80%', lg: 'max-content' },
    },
  ];

  return {
    columns,
    actionColumns,
  };
};
