import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';

import RobotoBold from '../../assets/fonts/Roboto/Roboto-Bold.ttf';
import RobotoMedium from '../../assets/fonts/Roboto/Roboto-Medium.ttf';
import RobotoRegular from '../../assets/fonts/Roboto/Roboto-Regular.ttf';
import RobotoLight from '../../assets/fonts/Roboto/Roboto-Light.ttf';
import RobotoLightItalic from '../../assets/fonts/Roboto/Roboto-LightItalic.ttf';

const baseTheme = createTheme({
  palette: {
    error: {
      main: red.A400,
    },
    mode: 'light',
    primary: {
      main: '#653D31',
      // main: 'rgb(0, 20, 65,0.76)',
      // main: '#7367f0',
    },
    secondary: {
      main: '#000000',
    },
    background: {
      default: '#ffffff',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
          '&:before': {
            color: '#9B8677',
          },
          '&:hover:before': {
            color: '#BF7B4E',
          },
          '& input': {
            color: '#432C2C',
          },
          '& .MuiFormHelperText-root.Mui-error , & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            color: '#FF565A',
            borderColor: 'FF565A',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled, &.Mui-enabled': {
            color: '#653D31',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: "Roboto";
        font-style: italic;
        font-weight: 400;
        font-display:"swap";
        src: url("${RobotoLight}") format("truetype");
      }
      @font-face {
        font-family: "Roboto";
        font-style: italic;
        font-weight: 200;
        font-display:"swap";
        src: url("${RobotoLightItalic}") format("truetype");
      }
      @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-display:"swap";
        src: url("${RobotoMedium}") format("truetype");
      }
      @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-display:"swap";
        src: url("${RobotoRegular}") format("truetype");
      }
      @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-display:"swap";
        src: url("${RobotoBold}") format("truetype");
      }
      @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-display:"swap";
        src: url("${RobotoBold}") format("truetype");
      }
      `,
    },
  },
  typography: {
    fontFamily: ['AvenirNext', 'Roboto', 'Helvetica', 'Arial', 'serif'].join(','),
  },
});

export default baseTheme;
