import { useMutation, useQuery } from '@tanstack/react-query';
import { FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ResidentsRelativesForm from './ResidentsRelativesForm';
import { validate } from './ResidentsRelativesForm.validate';
import { createFamily, editFamily, getFamilyByResidencyId, getFamilyTypes } from '@/src/api/endpoints/houseHold';
import { useGenerateImageUrl } from '@/src/hooks/common/useGenerateImageUrl';
import { getCities, getCountries, getStates } from '@/src/api/endpoints/master';

const ResidentsRelativesFormContainer = (props: any) => {
  const { isEdit, row, close, setSnackBarMessage, residencyId } = props;
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<{ code: string; message: string } | null>(null);
  const { data: residentData, isLoading: loadingResidentData } = useQuery(
    ['getFamilyByResidencyId', row?.relative?.id, row?.residency?.id],
    () => row?.relative?.id && row?.residency?.id && getFamilyByResidencyId(row?.relative?.id, row?.residency?.id),
    {
      select: (data) => data?.data?.relative_residency ?? null,
      enabled: isEdit && !!row?.relative?.id && !!row?.residency?.id,
    },
  );

  const { data: relationshipType, isLoading: loadingFamilyTypes } = useQuery(
    ['getFamilyTypes'],
    () => getFamilyTypes(),
    {
      select: (data) => data?.data?.relationship_types,
    },
  );

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(createFamily, {
    onError: async (e: any) => {
      setErrorMessage(e.response.data.error_messages[0]);
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successfull_relative_user_creation_snackbar_text', { ns: 'users' }));
    },
  });

  const { mutate: mutateEdit, isLoading: isLoadingEdit } = useMutation(editFamily, {
    onError: async (e: any) => {
      const err = e.response.data.error_messages[0];
      if (err.code === 'UpdateRelativeUserExists') {
        setSnackBarMessage(t('UpdateRelativeUserExists', { ns: 'errors' }), 'error');
      } else {
        setErrorMessage(err);
      }
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successfull_relative_user_edit_snackbar_text', { ns: 'users' }));
    },
  });

  const getInitialValues = () => ({
    name: isEdit ? residentData?.relative?.person?.name : '',
    surname: isEdit ? residentData?.relative?.person?.surname : '',
    email: isEdit ? residentData?.relative?.person?.email : '',
    country: isEdit ? residentData?.relative?.person?.city?.state?.country : null,
    countryCode: isEdit ? residentData?.relative?.person?.country_code : '',
    countryIsoCode: isEdit ? residentData?.relative?.person?.country_iso_code : '',
    state: isEdit ? residentData?.relative?.person?.city?.state : null,
    city: isEdit ? residentData?.relative?.person?.city : null,
    phoneNumber: isEdit ? residentData?.relative?.person?.phone_number : '',
    relationshipType: isEdit ? residentData?.relative?.relationship_type : null,
    birthday: isEdit ? residentData?.relative?.person?.birthday : '',
    identificationNumber: isEdit ? residentData?.relative?.person?.identification_number : '00000000000000000000',
    // identificationFileUrl: isEdit ? residentData?.relative?.person?.identification_file_url : '',
    streetName: isEdit ? residentData?.relative?.person?.street_name : '',
    streetNumber: isEdit ? residentData?.relative?.person?.street_number : '',
    apartmentNumber: isEdit ? residentData?.relative?.person?.apartment_number : '',
    floorNumber: isEdit ? residentData?.relative?.person?.floor_number : '',
    zipCode: isEdit ? residentData?.relative?.person?.zip_code : '',
    alias: isEdit ? residentData?.relative?.person?.alias : '',
    requestBackgroundCheckCopy: isEdit ? residentData?.relative?.person?.request_background_check_copy : '',
  });

  const onSubmit = (data: FormikValues) => {
    const bodyEdit = {
      residency_id: row?.residency?.id,
      name: data?.name,
      surname: data?.surname,
      email: data?.email,
      country_code: data?.countryCode,
      country_iso_code: data?.countryIsoCode,
      phone_number: data?.phoneNumber,
      relationship_type_id: data?.relationshipType.id,
      birthday: data?.birthday,
      city_id: data?.city.id,
      identification_number: data?.identificationNumber,
      // identification_file_url: data?.identificationFileUrl,
      street_name: data?.streetName,
      street_number: data?.streetNumber,
      apartment_number: data?.apartmentNumber,
      floor_number: data?.floorNumber,
      zip_code: data?.zipCode,
      alias: data?.alias,
      request_background_check_copy: data?.requestBackgroundCheckCopy,
    };
    const bodyCreate = {
      residency_id: residencyId,
      name: data?.name,
      surname: data?.surname,
      email: data?.email,
      country_code: data?.countryCode,
      country_iso_code: data?.countryIsoCode,
      phone_number: data?.phoneNumber,
      relationship_type_id: data?.relationshipType.id,
      birthday: data?.birthday,
      city_id: data?.city.id,
      identification_number: data?.identificationNumber,
      // identification_file_url: data?.identificationFileUrl,
      street_name: data?.streetName,
      street_number: data?.streetNumber,
      apartment_number: data?.apartmentNumber,
      floor_number: data?.floorNumber,
      zip_code: data?.zipCode,
      alias: data?.alias,
      request_background_check_copy: data?.requestBackgroundCheckCopy ? data?.requestBackgroundCheckCopy : false,
    };
    isEdit ? mutateEdit({ id: row.relative?.id, body: bodyEdit }) : mutateCreate(bodyCreate);
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: validate({ t }),
  });

  const { data: countriesData, isLoading: loadingCountriesData } = useQuery(['getCountries'], () => getCountries(), {
    select: (data) => data?.data?.countries,
  });
  const { data: statesData } = useQuery(
    ['getStates', values?.country?.id],
    () => getStates(Number(values?.country?.id)),
    {
      select: (data) => data?.data?.states,
      enabled: !!values?.country?.id,
    },
  );
  const { data: citiesData } = useQuery(['getCities', values?.state?.id], () => getCities(Number(values?.state?.id)), {
    select: (data) => data?.data?.cities,
    enabled: !!values?.state?.id,
  });

  // const { getUrl, isLoadingImage, errorSubmittingImage, url } = useGenerateImageUrl();

  const isLoadingGeneral = loadingResidentData || loadingFamilyTypes || loadingCountriesData;

  // useEffect(() => {
  //   if (url) {
  //     setFieldValue('identificationFileUrl', url as string);
  //   }
  // }, [url]);

  const childProps = {
    isEdit,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    errorMessage,
    isSubmitLoading: isLoadingCreate || isLoadingEdit,
    isLoadingGeneral,
    close,
    countriesData,
    statesData,
    citiesData,
    relationshipType,
    // getUrlIn64: getUrl,
    // isLoadingImage,
    // errorSubmittingImage,
  };

  return <ResidentsRelativesForm {...childProps} />;
};

export default ResidentsRelativesFormContainer;
