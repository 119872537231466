import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';
import { ActivitiesSectionProps } from './types';

export const ActivitiesSection = ({ openSubGrid }: ActivitiesSectionProps) => {
  const { t } = useTranslation();
  const { columns, headerActions, actionColumns } = useColumns({ openSubGrid });

  return (
    <CustomDataTable
      title={t('activities_list_title', { ns: 'bookings' })}
      columns={columns}
      resource="agendas"
      exportName="agendas"
      pathApi="bookings/v1/business-agendas"
      listName="agendas"
      canExport
      canSearch
      defaultSortFieldId="name"
      defaultSortAsc
      extraActionsInHeader={headerActions}
      canDelete
      customActionColumns={actionColumns}
    />
  );
};
