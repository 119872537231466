import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import TermsConditions from './TermsConditions';
import { getAmenity, editTyC } from '@/src/api/endpoints/bookings';
import { TermsAndConditionsProps } from '../../types';

const TermsConditionsContainer = ({ row, close, setSnackBarMessage }: TermsAndConditionsProps) => {
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(false);
  const [textError, setTextError] = useState('');
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data, isLoading: isLoadingActivity } = useQuery(['activityByIdTyC'], () => getAmenity(row?.id));

  const getInitialValues = () => ({
    document: data?.data.activity.tos_url || '',
    existsTyC: data?.data.activity.tos_url,
    id: row.id,
    isEdit: true,
  });

  const getValidationSchema = () => Yup.lazy(() => Yup.object().shape({}));

  const { mutate, isLoading } = useMutation(editTyC);

  const updateTyC = ({ document, id }: { document: string; id: number }) => {
    setTextError('');
    mutate(
      { body: { file_url: document }, id },
      {
        onSuccess: async () => {
          close();
          setSnackBarMessage(t('successful_tyc_updated_snackbar_text', { ns: 'bookings' }));
        },
        onError: async (e: any) => {
          setTextError(e.response.data.error_messages[0]);
        },
      },
    );
  };

  const { handleSubmit, values, setFieldValue, setFieldError, errors } = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    onSubmit: updateTyC,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema(),
  });

  const childProps = {
    loadingGeneral: isLoadingActivity,
    handleSubmit,
    values,
    setFieldValue,
    setFieldError,
    isLoading,
    textError,
    errors,
    disableSubmitButton,
    setDisableSubmitButton,
  };

  useEffect(
    () =>
      function cleanUp() {
        queryClient.resetQueries(['activityByIdTyC']);
      },
    [],
  );
  return <TermsConditions {...childProps} />;
};

export default TermsConditionsContainer;
