import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Visibility, HowToReg } from '@mui/icons-material';
import { Media } from 'react-data-table-component';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import TextLabel from '@/src/components/text-label/TextLabel';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { Guest, guestsStates } from './types';
import GuestInfo from './guest-info/GuestInfo';
import { GuestConfirmation } from './guest-confirmation/GuestConfirmation';
import { getLanguage } from '@/src/utilities/storage';
import { ActionColumn, ActionHeader } from '@/src/components/custom-data-table/types/DataTableFormModal';
import DisableFilter from '@/src/components/custom-data-table/components/DisableFilter';
import { QueryProps } from '@/src/utilities/helpers/commonTypes';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';
import useGuestTypesFilters from '@/src/hooks/filters/guestType.hooks';
import useInvitationStatusFilters from '@/src/hooks/filters/invitationStatus.hooks';

export const useColumns = () => {
  const { t } = useTranslation('guests');
  const lang = getLanguage()?.split('-')[0];
  const [showExpired, setShowExpired] = useState<boolean>(false);
  const [queryFilters, setQueryFilters] = useState<Array<QueryProps>>([{ query: 'showExpired', queryValue: false }]);
  const { apartments } = useApartmentsFilters();
  const { guestTypes } = useGuestTypesFilters();
  const { invitationStatus } = useInvitationStatusFilters();

  useEffect(() => {
    setQueryFilters([{ query: 'showWithInvitationExpired', queryValue: showExpired }]);
  }, [showExpired]);

  const columns: CustomDataTableColumnsProps<Guest>[] = [
    {
      id: 'id',
      name: t('guests_page_invitation_id_column'),
      selector: (row: Guest) => row.id,
      sortField: 'id',
      sortable: true,
      right: true,
      grow: Media.LG ? 0.5 : 1,
      filters: [
        {
          id: 'guest-id',
          query: 'GuestId',
          type: 'contains',
        },
      ],
    },
    {
      id: 'resident',
      name: t('guests_page_resident_column'),
      selector: (row: Guest) => `${row.resident.name} ${row.resident.surname}`,
      sortField: 'resident',
      sortable: true,
      grow: Media.LG ? 1.5 : 2,
      filters: [
        {
          id: 'resident-full-name',
          query: 'ResidentFullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'property',
      name: t('guests_page_functional_unit_column'),
      selector: (row: Guest) => row.property,
      sortField: 'property_code',
      sortable: true,
      grow: Media.LG ? 1 : 2,
      filters: apartments,
    },
    {
      id: 'guest',
      name: t('guests_page_guest_column'),
      selector: (row: Guest) => `${row.guest.name} ${row.guest.surname}`,
      sortField: 'guest',
      sortable: true,
      grow: Media.LG ? 1 : 2,
      filters: [
        {
          id: 'guest-full-name',
          query: 'GuestFullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'invitation_date_from',
      name: t('guests_page_date_column'),
      selector: (row: Guest) =>
        `${
          row.invitation.date_to === null
            ? t('guests_page_never_expires')
            : `${getFormattedDateByLanguague(
                lang,
                getFormattedDateFullYearEs,
                getFormattedDateFullYearEn,
                row.invitation.date_from,
              )}${
                row.invitation.date_from !== row.invitation.date_to
                  ? `- ${getFormattedDateByLanguague(
                      lang,
                      getFormattedDateFullYearEs,
                      getFormattedDateFullYearEn,
                      row.invitation.date_to,
                    )}`
                  : ''
              }
     `
        }`,
      sortField: 'invitation_date_from',
      sortable: true,
      center: true,
      grow: 2,
      filters: [
        {
          id: 'guest-invitation-date',
          query: 'InvitationDate',
          type: 'date',
          label: t('show_never_expires'),
          value: 'CheckNullable',
        },
      ],
    },
    {
      id: 'guests_number',
      name: t('guests_page_companions_column'),
      selector: (row: Guest) => row.guests_number,
      sortField: 'guests_number',
      sortable: true,
      right: true,
      grow: Media.LG ? 0.3 : 1,
      filters: [
        {
          id: 'companions',
          query: 'Companions',
          type: 'min-max',
        },
      ],
    },
    {
      id: 'invitation_is_recurring',
      name: t('type'),
      selector: (row: Guest) =>
        t(row.invitation?.is_recurring ? 'guests_page_recurrent_column' : 'guests_page_one_time_column', {
          ns: 'guests',
        }) as string,
      sortField: 'invitation_is_recurring',
      sortable: true,
      center: true,
      grow: Media.LG ? 1 : 2,
      filters: guestTypes,
    },
    {
      id: 'status',
      cell: (row) => (
        <TextLabel
          text={row.status.toUpperCase()}
          color="#F7F7F7"
          padding="2px 12px"
          width="100%"
          bg={guestsStates[row.status]}
        />
      ),
      name: t('guests_page_status_column'),
      selector: (row: Guest) => row.status,
      sortField: '',
      sortable: false,
      center: true,
      grow: Media.LG ? 1 : 2,
      filters: invitationStatus,
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'detail',
      icon: (props: any) => <Visibility sx={props.sx} />,
      customizedTitle: true,
      component: (props: any) => <GuestInfo {...props} />,
      width: { xs: '96%', sm: '380px', md: '742px' },
    },
    {
      id: 'confirmation',
      icon: (props: any) => <HowToReg sx={props.sx} />,
      component: (props: any) => <GuestConfirmation {...props} />,
      isHidden: (row: any) => row.status !== 'Confirmed' && row.status !== 'Confirmado',
      width: '35%',
    },
  ];

  const headerActions: ActionHeader[] = [
    {
      id: 'show-expired',
      component: () => (
        <DisableFilter resource="people-expired" setShowDisabled={setShowExpired} disabled={!showExpired} />
      ),
    },
  ];

  return { columns, actionColumns, queryFilters, headerActions };
};
