import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';
import { AvailabilitiesSectionProps } from './types';

export const AvailabilitiesSection = ({ row, closeSubGrid }: AvailabilitiesSectionProps) => {
  const { t } = useTranslation();
  const { columns, headerActions, actionColumns } = useColumns(row.id);

  return (
    <CustomDataTable
      title={t('availabilities', { resource: row.name })}
      columns={columns}
      resource="availabilities"
      pathApi={`bookings/v1/agendas/${row.id}/availabilities`}
      listName="availabilities"
      canSearch
      defaultSortFieldId="description"
      defaultSortAsc
      extraActionsInHeader={headerActions}
      customActionColumns={actionColumns}
      backToBaseGrid={closeSubGrid}
    />
  );
};
