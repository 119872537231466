import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';

export type TPaymentsStateOptions = {
  [key: string]: string;
};

export const paymentsStates: TPaymentsStateOptions = {
  PendingConfirmation: '#CA8A04',
  Completed: '#424867',
  Error: '#ff6666',
  Cancelled: '#ff6666',
  Pending: '#516F3F',
};

export interface Payment {
  amount: number;
}

export interface ChargeDetailBody {
  amount: number;
  name: string;
  charge_concept: {
    name: string;
  };
  creation_date: number;
  id: number;
  quantity: number;
  total_amount: number;
}

export interface PaymentDetailBody {
  amount: number;
  amount_paid: number | null;
  charges: ChargeDetailBody[];
  creation_date: number;
  id: number;
  payment_state: {
    code: string;
    name: string;
  };
  residency: {
    check_out_date: number;
    check_out_user_id: number;
    from: number;
    has_checked_out: boolean;
    id: number;
    property: {
      code: string;
    };
    to: number;
  };
}

export interface PaymentRow {
  amount: number;
  creationDate: string | null;
  id: number;
  payment_state: {
    code: string;
    name: string;
  };
  residency: {
    id: number;
    property: {
      code: string;
    };
    from: number;
    to: number;
  };
}

export interface ChargesType {
  id: number;
  charge_concept: {
    name: string;
  };
  amount: number;
  quantity: number;
  total_amount: number;
  creation_date: number;
}

export interface PaymentColumn {
  row: any;
  isEdit: boolean;
  close: () => void;
  setSnackBarMessage: (message: string, sever?: string) => void;
}

export type CustomDataPropsType = {
  close: () => void;
  row: {
    id: string;
    date: string;
    totalAmount: number;
    state: string;
  };
  setSnackBarMessageSuccess: (msj: string) => void;
  setSnackBarMessageError: (msj: string) => void;
};

export interface ChargesDataTableProps {
  title: string | undefined;
  data: Array<ChargeDetailBody>;
  columns: CustomDataTableColumnsProps<any>[];
}
