import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import Webcam from 'react-webcam';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Registry } from './Registry';

import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { createGuest } from '@/src/api/endpoints/guests';
import { validate } from './RegistryForm.validate';
import { NUMBER_REGEX, VALID_STRING_FIELD } from '@/src/utilities/helpers/constants';
import { getLanguage } from '@/src/utilities/storage';

export const RegistryPage = () => {
  const { t, i18n } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const [componentIsMountedOnFirstRender, setComponentIsMountedOnFirstRender] = useState(true);
  const [openSelfieModal, setOpenSelfieModal] = useState(false);
  const [isPermissionGiven, setIsPermissionGiven] = useState(false);
  const [permissionError, setPermissionError] = useState<any | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const { mutate, isLoading, isSuccess, isError } = useMutation(createGuest);

  const detectedLanguage = window.navigator.language.slice(0, 2);
  const dateFromParam = searchParams.get('dateFrom');
  const dateToParam = searchParams.get('dateTo');
  const codeParam = searchParams.get('code');
  useEffect(() => {
    const wasPermissionGiven = localStorage.getItem('isPermissionGiven');
    setIsPermissionGiven(JSON.parse(wasPermissionGiven as string));
    return () => {
      setIsPermissionGiven(false);
      localStorage.setItem('isPermissionGiven', JSON.stringify(false));
    };
  }, []);

  const dateFrom = getFormattedDateByLanguague(
    lang,
    getFormattedDateFullYearEs,
    getFormattedDateFullYearEn,
    dateFromParam ? parseInt(dateFromParam, 10) : Date.now(),
  );

  const dateTo = dateToParam
    ? getFormattedDateByLanguague(
        lang,
        getFormattedDateFullYearEs,
        getFormattedDateFullYearEn,
        dateToParam ? parseInt(dateToParam, 10) : Date.now(),
      )
    : null;
  const dateFull = `${t('guest_invitation_sub_p0', { ns: 'guests' })} ${dateFrom} ${t('guest_invitation_sub_to', {
    ns: 'guests',
  })} ${dateTo}`;

  const webcamRef = useRef<Webcam>(null);
  const getInitialValues = () => ({
    name: '',
    surname: '',
    countryCode: '',
    countryIsoCode: '',
    phoneNumber: '',
    email: '',
    how_many: 0,
    image: null,
  });

  const onSubmit = (values: any) => {
    mutate({
      invitationCode: searchParams.get('code') || '',
      data: {
        name: values?.name,
        surname: values?.surname,
        email: values?.email.trim(),
        guest_number: parseInt(values?.how_many, 10),
        selfie_base64: values?.image.split(',')[1],
        country_code: values?.countryCode,
        country_iso_code: values?.countryIsoCode,
        phone_number: values?.phoneNumber,
      },
    });
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit,
    initialValues: getInitialValues(),
    validationSchema: validate({ t }),
  });

  useEffect(() => {
    if (componentIsMountedOnFirstRender) {
      i18n.changeLanguage(detectedLanguage);
      setComponentIsMountedOnFirstRender(false);
    }
  }, [detectedLanguage, i18n, componentIsMountedOnFirstRender]);

  const inputFields = [
    {
      id: 1,
      field: 'name',
      maxLength: 50,
      label: t('name'),
      xs: 12,
      md: 6,
      lg: 6,
      regex: VALID_STRING_FIELD,
      type: 'text',
    },
    {
      id: 2,
      field: 'surname',
      maxLength: 50,
      label: t('surname'),
      xs: 12,
      md: 6,
      lg: 6,
      regex: VALID_STRING_FIELD,
      type: 'text',
    },
    {
      id: 3,
      field: 'phone_number',
      label: t('phone_select_phone_number_field_label'),
      xs: 12,
      md: 6,
      lg: 6,
      type: 'text',
    },
    { id: 4, field: 'email', label: t('email'), xs: 12, md: 6, lg: 6 },
    {
      id: 5,
      field: 'how_many',
      label: t('guest_invitation_companionship', { ns: 'guests' }),
      xs: 12,
      md: 12,
      lg: 12,
      regex: NUMBER_REGEX,
      type: 'number',
    },
  ];

  // SELFIE ACTIONS
  const handleOpen = () => {
    setOpenSelfieModal(true);
    window.navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then((str) => {
        setStream(str);
        setIsPermissionGiven(str.active);
        localStorage.setItem('isPermissionGiven', JSON.stringify(str.active));
      })
      .catch((err) => setPermissionError(err));
  };

  const stopCamera = () =>
    stream?.getTracks().forEach((track) => {
      track.stop();
    });

  const handleClose = () => {
    setOpenSelfieModal(false);
    setImageUrl(null);
    stopCamera();
  };
  const handleScreenShot = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setImageUrl(imageSrc);
    }
    stopCamera();
  }, [webcamRef, stream]);

  const handleChooseShot = (shotUrl: string) => {
    setFieldValue('image', shotUrl);
    setOpenSelfieModal(false);
    setImageUrl(null);
  };

  const childProps = {
    permissionError,
    inputFields,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    isLoading,
    isSuccess,
    isError,
    open: openSelfieModal,
    handleOpen,
    handleClose,
    handleScreenShot,
    webcamRef,
    imageUrl,
    isPermissionGiven,
    handleChooseShot,
    dateFrom,
    dateTo,
    dateFull,
  };

  if ((!dateFromParam && !dateToParam) || !codeParam) return <>{navigate('/auth/login ')}</>;

  return <Registry {...childProps} />;
};
