import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Add as AddIcon,
  ModeEdit as ModeEditIcon,
  DeleteRounded as DeleteRoundedIcon,
  AccountBalance as AccountBalanceIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEs,
  getFormattedDateFullYearEn,
  getDayHourYearDateEn,
  getDayHourYearDateEs,
} from '@/src/utilities/helpers/dateParser';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import { ActionColumn, ActionHeader } from '@/src/components/custom-data-table/types/DataTableFormModal';
import RegisterUser from './register-user';
import { getLanguage } from '@/src/utilities/storage';
import EditResident from './edit-resident';
import DeleteComponent from './delete-resident';
import { PeopleRowProps, UseColumnsArgument } from './types';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import DisableFilter from '@/src/components/custom-data-table/components/DisableFilter';
import CheckOutComponent from './checkout';
import { updateArrayByKey } from '@/src/utilities/helpers/queryFilters';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';
import useResidencyFilters from '@/src/hooks/filters/residencyTypes.hooks';
import useResidencyStates from '@/src/hooks/filters/residencyStates.hooks';
import { QueryProps } from '@/src/utilities/helpers/commonTypes';
import getEnvVariables from '@/src/utilities/helpers/getEnvVariables';

export const useColumns = ({ openSubGrid }: UseColumnsArgument) => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const [queryFilters, setQueryFilters] = useState<Array<QueryProps>>([
    { query: 'justLeaseSigners', queryValue: true },
    { query: 'includeExpired', queryValue: false },
  ]);
  const actualDate = new Date().getTime();
  const [showDisabled, setShowDisabled] = useState<boolean>(false); // firmantes
  const [showExpired, setShowExpired] = useState<boolean>(false); // vencidos
  const { apartments } = useApartmentsFilters();
  const { residencyTypes } = useResidencyFilters();
  const { residencyStates } = useResidencyStates();
  const { VITE_TENANT_NAME } = getEnvVariables();

  const columns: CustomDataTableColumnsProps<PeopleRowProps>[] = [
    {
      id: 'property_code',
      name: t('property_code', { ns: 'residents' }),
      selector: (row: PeopleRowProps) => row?.property?.code ?? '',
      sortField: 'residency_property_code',
      sortable: true,
      maxWidth: '200px',
      filters: apartments,
    },
    {
      id: 'name',
      name: t('residents_list_resident_column', { ns: 'residents' }),
      selector: (row: PeopleRowProps) => `${row.person.name} ${row.person.surname}`,
      sortField: 'holder_name',
      sortable: true,
      filters: [
        {
          id: 'resident-full-name',
          query: 'ResidentFullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'phone_number',
      name: t('residents_list_phone_number_column', { ns: 'residents' }),
      selector: (row: PeopleRowProps) => row.person.full_phone_number,
      sortField: 'phone_number',
      sortable: true,
      right: true,
      filters: [
        {
          id: 'resident-full-phone-number',
          query: 'ResidentFullPhoneNumber',
          type: 'contains',
        },
      ],
    },
    {
      cell: (row: PeopleRowProps) => <Checkbox checked={row.person.is_lease_signer} disabled sx={{ padding: '0px' }} />,
      id: 'is_lease_signer',
      name: t('lease_signer', { ns: 'residents' }),
      selector: (row: PeopleRowProps) => row.person.is_lease_signer,
      sortable: true,
      center: true,
      isHidden: !showDisabled,
      sortField: 'lease_signer',
    },
    {
      id: 'resident_type',
      name: t('resident_type', { ns: 'residents' }),
      selector: (row: PeopleRowProps) => row?.residency?.type?.name,
      sortable: true,
      center: true,
      filters: residencyTypes,
    },
    {
      id: 'creation_date',
      sortField: 'creation_date',
      name: t('creation_date_column', { ns: 'residents' }),
      selector: (row: PeopleRowProps) =>
        row?.residency?.creation_date
          ? getFormattedDateByLanguague(lang, getDayHourYearDateEs, getDayHourYearDateEn, row.residency?.creation_date)
          : '-',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'residents-creation-date',
          query: 'ResidencyCreationDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'state',
      name: t('state', { ns: 'residents' }),
      selector: (row: PeopleRowProps) => row?.residency?.state?.name,
      sortField: 'residency_state_name',
      sortable: true,
      center: true,
      filters: residencyStates,
    },
    {
      id: 'hosting_start_date',
      sortField: 'residency_date_from',
      name: t('hosting_start_date', { ns: 'residents' }),
      selector: (row: PeopleRowProps) =>
        getFormattedDateByLanguague(lang, getDayHourYearDateEs, getDayHourYearDateEn, row.residency.from),
      sortable: true,
      center: true,
      filters: [
        {
          id: 'residents-residency-from',
          query: 'ResidencyFromDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'hosting_end_date',
      sortField: 'residency_date_to',
      name: t('hosting_end_date', { ns: 'residents' }),
      selector: (row: PeopleRowProps) =>
        getFormattedDateByLanguague(lang, getDayHourYearDateEs, getDayHourYearDateEn, row.residency.to),
      sortable: true,
      center: true,
      filters: [
        {
          id: 'residents-residency-to',
          query: 'ResidencyToDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'check_out_date',
      name: t('check_out_date_column', { ns: 'residents' }),
      selector: (row: PeopleRowProps) =>
        row.residency.check_out_date
          ? getFormattedDateByLanguague(
              lang,
              getFormattedDateFullYearEs,
              getFormattedDateFullYearEn,
              row.residency.check_out_date,
            )
          : '-',
      sortField: 'check_out_date',
      sortable: false,
      center: true,
      isHidden: !showExpired,
    },
  ];

  useEffect(() => {
    updateArrayByKey('justLeaseSigners', !showDisabled, setQueryFilters);
    updateArrayByKey('includeExpired', showExpired, setQueryFilters);
  }, [showDisabled, showExpired]);

  const headerActions: ActionHeader[] = [
    {
      id: 'show-lease-singers',
      component: () => <DisableFilter resource="people" setShowDisabled={setShowDisabled} disabled={showDisabled} />,
    },
    {
      id: 'show-expired',
      component: () => (
        <DisableFilter resource="people-expired" setShowDisabled={setShowExpired} disabled={!showExpired} />
      ),
    },
    {
      id: 'people-create',
      component: () => (
        <CustomTableAction
          modalTitle="people"
          component={(props: any) => <RegisterUser {...props} />}
          Icon={() => <AddIcon />}
          type="create"
          width={{ xs: '90%', lg: '70%', xl: '55%' }}
        />
      ),
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'checkout',
      icon: (props: any) => <CheckCircleOutlineIcon sx={props.sx} />,
      component: (props: any) => <CheckOutComponent id={props.row.id} resource="property-residents" {...props} />,
      width: { xs: '90%', lg: '70%', xl: '55%' },
      isHidden: (row: any) => !(row.from < actualDate && row.person.is_lease_signer && !row.has_checked_out),
      customizedTitle: true,
    },
    {
      id: 'detail',
      icon: (props: any) => <VisibilityIcon sx={props.sx} />,
      onIconClick: (row: any) => openSubGrid(row, 'ResidentsDetail'),
    },
    {
      id: 'edit',
      icon: (props: any) => <ModeEditIcon sx={props.sx} />,
      component: (props: any) => <EditResident {...props} />,
      isHidden: (row: any) => row.has_checked_out || row.to < actualDate,
      width: { xs: '90%', lg: '70%', xl: '55%' },
    },
    {
      id: 'account-state',
      icon: (props: any) => <AccountBalanceIcon sx={props.sx} />,
      onIconClick: (row: any) => openSubGrid(row, 'AccountState'),
      isHidden: () => VITE_TENANT_NAME === 'ora',
    },
    {
      id: 'delete_btn',
      icon: (props: any) => <DeleteRoundedIcon sx={props.sx} />,
      component: (props: any) => <DeleteComponent {...props} />,
      isHidden: (row: any) => !row.person.is_lease_signer,
      width: '23%',
    },
  ];

  return {
    columns,
    headerActions,
    actionColumns,
    queryFilters,
  };
};
