import {
  BuildingSwap as BuildingSwapPage,
  ChangePassword as ChangePasswordPage,
  UsersPage,
  ResidentsRequestsPage,
  PetsPage,
  PetsRequestPage,
  VehiclesPage,
  VehiclesRequestsPage,
  NewsPage,
  PackagesPage,
  MessagesPage,
  MessagesCategoriesPage,
  ResidentsPage,
  UserProfile as UserProfilePage,
  AdministratorsPage,
  Delivery as DeliveryPage,
  GuestsPage,
  ParkingPage,
  ActivitiesPage,
  AmenitiesPage,
  AttendantPage,
  CancellationsPage,
  ReservationsPage,
  SchedulePage,
  ParkingGuestsPage,
  Payments as PaymentsPage,
  CommunityPage,
  // CommunityPage,
} from '@/src/pages';
import ChargesPage from '../pages/charges/list/ChargesPage';
import ConceptsPage from '../pages/charges/concepts/ConceptsPage';
import ConstructionProgressPage from '../pages/construction-progress';
import MarketingMaterialsLivingPage from '../pages/marketing-materials/marketing-materials-living';
import MarketingMaterialsOwningPage from '../pages/marketing-materials/marketing-materials-owning';
import PerksBenefitsPage from '../pages/perks-benefits';
import CategoriesPage from '../pages/events/categories';
import EventsPage from '../pages/events/events';
import DocumentsPage from '../pages/documents';
import PotentialCustomerPage from '../pages/potential-Customer';

type TPagesOptions = {
  [key: string]: any;
};

export const pages: TPagesOptions = {
  administrators: <AdministratorsPage />,
  news: <NewsPage />,
  community: <CommunityPage />,
  marketing: <MarketingMaterialsLivingPage />,
  'marketing-materials/living': <MarketingMaterialsLivingPage />,
  'marketing-materials/owning': <MarketingMaterialsOwningPage />,

  'construction-progress': <ConstructionProgressPage />,
  'perks-benefits': <PerksBenefitsPage />,
  'potential-customers': <PotentialCustomerPage />,
  'events/categories': <CategoriesPage />,
  'events/events': <EventsPage />,
  'tickets/list': <MessagesPage />,
  'tickets/categories': <MessagesCategoriesPage />,
  packages: <PackagesPage />,
  residents: <ResidentsPage />,
  'household/users': <UsersPage />,
  'household/blacklist': <UsersPage isBlacklist />,
  'household/relatives-requests': <ResidentsRequestsPage />,
  'household/pets': <PetsPage />,
  'household/pets-requests': <PetsRequestPage />,
  'household/vehicles': <VehiclesPage />,
  'household/vehicles-requests': <VehiclesRequestsPage />,
  delivery: <DeliveryPage />,
  guests: <GuestsPage />,
  parking: <ParkingPage />,
  'parking/residents': <ParkingPage />,
  'parking/guests': <ParkingGuestsPage />,
  'parking/attendants': <AttendantPage />,
  documents: <DocumentsPage />,
  accounts: <UserProfilePage />,
  'account/profile': <UserProfilePage />,
  'account/change-password': <ChangePasswordPage />,
  building: <BuildingSwapPage />,
  bookings: <AmenitiesPage />,
  'bookings/amenities': <AmenitiesPage />,
  'bookings/activities': <ActivitiesPage />,
  'bookings/reservations': <ReservationsPage />,
  'bookings/cancellations': <CancellationsPage />,
  'bookings/schedules': <SchedulePage />,
  charges: <ChargesPage />,
  'charges/list': <ChargesPage />,
  'charges/concepts': <ConceptsPage />,
  'charges/payments': <PaymentsPage />,
};
