import React from 'react';
import User from '../common/user/User';
import SearchComponent from './search-component/SearchComponent';

export const RegisterUser = ({
  errors,
  handleSubmit,
  setFieldValue,
  values,
  isLoading,
  valuesSearch,
  handleSubmitSearch,
  setSearchValue,
  errorsSearch,
  handleClose,
  responseError,
  searchResponseError,
  isCreateModal,
}: any) =>
  values.showOtherInputs ? (
    <User
      isEdit={false}
      errors={errors}
      handleSubmit={handleSubmit}
      setFieldValue={setFieldValue}
      values={values}
      handleClose={handleClose}
      isLoading={isLoading}
      responseError={responseError}
      isCreateModal={isCreateModal}
    />
  ) : (
    <SearchComponent
      values={valuesSearch}
      errors={errorsSearch}
      setFieldValue={setSearchValue}
      handleSubmit={handleSubmitSearch}
      responseError={searchResponseError}
    />
  );
