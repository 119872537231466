import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { VisibilityRounded as VisibilityRoundedIcon } from '@mui/icons-material';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { SelectedResident, SelectedRow, UserCardData, UserDetail, UserResidencies } from '../types';
import { getCustomer } from '@/src/api/endpoints/users';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import { CustomIconProps } from '@/src/components/custom-data-table/types/DataTableFormModal';

export const useColumns = (
  personSelected: SelectedRow | null,
  openResidentSection: (row: SelectedResident) => void,
) => {
  const lang = getLanguage()?.split('-')[0];
  const { t } = useTranslation();
  const { data: customerData, isLoading } = useQuery(
    [`customerSelected_${personSelected?.id}`],
    () => getCustomer(personSelected?.id ?? ''),
    {
      select: (data) => data?.data.customer as UserDetail,
    },
  );

  const userCardData: UserCardData = {
    id: customerData?.id ?? '',
    countryCode: customerData?.country_code ?? '+549',
    email: customerData?.email ?? '',
    name: customerData?.name ?? '',
    phoneNumber: customerData?.phone_number ?? '',
    surname: customerData?.surname ?? '',
    avatarSrc: customerData?.avatar_url ?? '',
    birthday: customerData?.birthday ?? 0,
    gender: customerData?.gender ?? '',
    fileUrl: customerData?.avatar_url,
    isDisabled: personSelected?.is_disabled ?? true,
  };

  const columnsResidencies: CustomDataTableColumnsProps<UserResidencies>[] = [
    {
      id: 'property_code',
      name: t('property_code', { ns: 'customerUsers' }),
      selector: (row: UserResidencies) => row?.property?.code,
      sortField: 'property_code',
      sortable: true,
    },
    {
      id: 'state',
      name: t('state', { ns: 'customerUsers' }),
      selector: (row: UserResidencies) => row?.state?.name,
      sortField: 'state',
      sortable: true,
    },
    {
      id: 'resident_type',
      name: t('resident_type', { ns: 'customerUsers' }),
      selector: (row: UserResidencies) => row?.type?.name,
      sortField: 'resident_type',
      sortable: true,
    },
    {
      id: 'dates',
      name: t('lodging_time', { ns: 'customerUsers' }),
      selector: (row: UserResidencies) =>
        row?.from && row?.to
          ? `${getFormattedDateByLanguague(
              lang,
              getFormattedDateFullYearEs,
              getFormattedDateFullYearEn,
              row?.from,
            )} - ${getFormattedDateByLanguague(lang, getFormattedDateFullYearEs, getFormattedDateFullYearEn, row?.to)}`
          : '-',
      sortField: 'dates',
      sortable: false,
      center: true,
    },
  ];

  const actionColumnResidencies = [
    {
      id: 'view_request',
      icon: ({ sx }: CustomIconProps) => <VisibilityRoundedIcon sx={sx} />,
      onIconClick: (row: any) =>
        openResidentSection({
          id: row?.holder?.property_resident_id,
          person: { id: row?.holder?.person_id },
          residency: { id: row?.id },
        }),
      customizedTitle: true,
      width: { xs: '90%', sm: '580px', md: '600px' },
    },
  ];

  return {
    columnsResidencies,
    actionColumnResidencies,
    isLoading,
    residencies: customerData?.residencies,
    userCardData,
  };
};
