import { useTranslation } from 'react-i18next';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import {
  getDayHourYearDateEn,
  getDayHourYearDateEs,
  getFormattedDateByLanguague,
} from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import { GuestHistory } from './types';

export const useColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const columns: CustomDataTableColumnsProps<GuestHistory>[] = [
    {
      id: 'id',
      name: t('id'),
      selector: (row) => row.id,
      sortField: 'id',
      sortable: true,
      center: true,
    },
    {
      id: 'check_in_date',
      name: t('modal_detail_input_date_ingress', { ns: 'guests' }),
      selector: (row) =>
        getFormattedDateByLanguague(lang, getDayHourYearDateEs, getDayHourYearDateEn, row.check_in_date),
      sortField: 'check_in_date',
      sortable: true,
      center: true,
    },
  ];

  return { columns };
};
