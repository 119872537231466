import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Box, Button, DialogContentText, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { vehicleRecallRequest } from '@/src/api/endpoints/parking';
import { GuestsParking } from '../types';

interface RequestProcessingModalProps {
  close: () => void;
  setSnackBarMessage: (message: string, sever?: string) => void;
  row: GuestsParking;
}

const RequestProcessingModal = ({ row, setSnackBarMessage, close }: RequestProcessingModalProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const { mutate, isLoading } = useMutation(vehicleRecallRequest, {
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successful_request_processing_modal_snackbar_text', { ns: 'parking' }));
    },
    onError: async () => {
      setError(t('error'));
    },
  });
  const handleSubmit = (id: number) => {
    setError('');
    mutate(id);
  };
  return (
    <Grid>
      <DialogContentText id="alert-dialog-description" my={0.5}>
        {t('request_processing_body', { ns: 'parking' })}
      </DialogContentText>
      <ErrorText error={error} />
      <Box component="div" sx={{ widht: '100%', textAlign: 'right', pt: 1 }}>
        <Button size="small" type="button" variant="outlined" color="secondary" onClick={close} sx={{ mr: 1 }}>
          {t('delete_dialog_cancel_button_text')}
        </Button>
        <LoadingButton
          loading={isLoading}
          size="small"
          type="button"
          variant="contained"
          onClick={() => handleSubmit(row.id)}
        >
          {t('delete_dialog_submit_button_text')}
        </LoadingButton>
      </Box>
    </Grid>
  );
};

export default RequestProcessingModal;
