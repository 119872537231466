import { useTranslation } from 'react-i18next';
import CustomDataTable from '../../components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

export const GuestsPage = () => {
  const { t } = useTranslation();
  const { columns, actionColumns, queryFilters, headerActions } = useColumns();

  return (
    <CustomDataTable
      title={t('guests_page_title', { ns: 'guests' })}
      columns={columns}
      resource="guests"
      pathApi="guests/v1/guests"
      listName="guests"
      defaultSortFieldId="id"
      defaultSortAsc={false}
      canSearch
      extraFilters={queryFilters}
      extraActionsInHeader={headerActions}
      canExport
      customActionColumns={actionColumns}
    />
  );
};
