import React from 'react';
import { Box, TextField } from '@mui/material';
import { TFunction } from 'i18next';
import { CustomInputStyles } from '@/src/components/forms/styles';

const FilterMinMax = (props: FilterMinMaxProps) => {
  const { startValue, endValue, t, onChange } = props;

  return (
    <>
      <Box component="div" sx={{ margin: '5px 16px', p: '2px' }}>
        <TextField
          type="number"
          label={t('min') as string}
          onChange={(e) => onChange(e.target.value, 'start')}
          size="small"
          sx={{ ...CustomInputStyles(''), width: '150px' }}
          fullWidth
          value={startValue}
        />
      </Box>
      <Box component="div" sx={{ margin: '5px 16px', p: '2px' }}>
        <TextField
          type="number"
          label={t('max') as string}
          onChange={(e) => onChange(e.target.value, 'end')}
          size="small"
          sx={{ ...CustomInputStyles(''), width: '150px' }}
          fullWidth
          value={endValue}
        />
      </Box>
    </>
  );
};

interface FilterMinMaxProps {
  startValue: string | number;
  endValue: string | number;
  onChange: (value: string | number, type: 'start' | 'end') => void;
  t: TFunction;
}

export default FilterMinMax;
