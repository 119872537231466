import { useQuery } from '@tanstack/react-query';
import { getEventsById } from '@/src/api/endpoints/events';
import { ArticleByIdBody } from './types';

const getEventByIdData = async (id: number) => {
  const response = await getEventsById(id);
  return response.data;
};

export const useGetEventById = (id: number) => {
  const { data, isLoading, refetch } = useQuery([`${id}`, getEventByIdData], () => getEventByIdData(id), {
    cacheTime: 10000,
  });
  const dataById: ArticleByIdBody = data;
  return { dataById, isLoading, refetch };
};
