import { Box, TextField } from '@mui/material';
import { TFunction } from 'react-i18next';
import { CustomInputStyles } from '@/src/components/forms/styles';

const FilterColumnHour = (props: FilterColumnDateProps) => {
  const { t, hourStartSelected, hourEndSelected, onChange } = props;
  return (
    <Box component="div" sx={{ margin: '5px 16px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <TextField
        type="time"
        label={t('from', { ns: 'date' })}
        placeholder={t('from', { ns: 'date' })}
        onChange={(e) => onChange('start', e.target.value)}
        size="small"
        sx={CustomInputStyles('')}
        fullWidth
        focused
        value={hourStartSelected}
      />
      <TextField
        type="time"
        label={t('to', { ns: 'date' })}
        placeholder={t('to', { ns: 'date' })}
        onChange={(e) => onChange('end', e.target.value)}
        size="small"
        sx={CustomInputStyles('')}
        fullWidth
        focused
        value={hourEndSelected}
      />
    </Box>
  );
};

interface FilterColumnDateProps {
  onChange: (dateInditacion: 'start' | 'end', value: any) => void;
  t: TFunction;
  hourStartSelected: string;
  hourEndSelected: string;
}

export default FilterColumnHour;
