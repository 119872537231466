import { Box, styled } from '@mui/material';

export const BoxNews = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
}));

export const NewsHeader = styled('div')(({ pr }: { pr: string }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
  paddingRight: pr,
}));

export const ScrollStyles = {
  '&::-webkit-scrollbar': {
    width: 0,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
    height: '60%',
    outline: '1px solid slategrey',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 16px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
};

export const NewsPagseStylesDefault = {
  background: '#fff',
  borderRadius: '12px',
  boxShadow: '0 4px 24px #22292f1a',
  padding: '14px 10px 0',
  height: 'auto',
};

export const BoxHeaderInputs = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
}));
