import getEnvVariables from './getEnvVariables';

export const deleteObjectDuplicateInArray = (data: Array<object>) =>
  data.filter((value: object, index: number) => {
    const val = JSON.stringify(value);
    return index === data.findIndex((obj) => JSON.stringify(obj) === val);
  });

export const parseToISO = (date: any) => {
  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const dateISO = new Date(date - tzoffset).toISOString().slice(0, -1);
  return dateISO;
};

export const handlerDateSelector = (newValue: any, setFieldValue: any, fieldValue: string): void => {
  if (newValue && newValue.$d) {
    const getMilis = new Date(newValue.$d);
    const offset = getMilis.getTimezoneOffset();
    if (offset < 0) {
      getMilis.setMinutes(Math.abs(offset));
    }
    setFieldValue(fieldValue, getMilis.getTime());
  } else {
    setFieldValue(fieldValue, null);
  }
};

export const extractFirstDecimalNumberFromString = (inputString: string) => {
  const match = inputString.match(/-?\d+(\.\d+)?/);
  if (match) {
    return parseFloat(match[0]);
  }
  return null;
};

export const getTenant = () => {
  const { VITE_TENANT_NAME } = getEnvVariables();
  const tenants: { [key: string]: any } = { panorama: 'P', grit: 'G', beachwalk: 'BW', ora: 'O', default: 'D' };
  return tenants[VITE_TENANT_NAME] ?? tenants.default;
};
