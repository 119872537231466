import { useTranslation } from 'react-i18next';
import { useViewPortSize } from '@/src/hooks/common/useViewPortSize';

export const useResizeButtonText = () => {
  const { t, i18n } = useTranslation();

  const { viewPortW } = useViewPortSize();
  const firstButton = viewPortW >= 1200 && viewPortW <= 1300;
  const secondButton = viewPortW >= 1200 && viewPortW <= 1496;
  const everyDay = firstButton && i18n.language === 'es' ? 'Todos los...' : t('btn_every_day', { ns: 'bookings' });
  const weekDays = secondButton && i18n.language === 'es' ? 'Días de la...' : t('btn_week_days', { ns: 'bookings' });

  const textForButtons = [
    {
      tooltipTitle: `${firstButton && i18n.language === 'es' ? t('btn_every_day', { ns: 'bookings' }) : ''}`,
      msn: everyDay,
      selector: 'every_day',
    },
    {
      tooltipTitle: `${secondButton && i18n.language === 'es' ? t('btn_week_days', { ns: 'bookings' }) : ''}`,
      msn: weekDays,
      selector: 'week_days',
    },
    { tooltipTitle: '', msn: t('btn_custom_days', { ns: 'bookings' }), selector: 'custom' },
  ];

  return {
    textForButtons,
  };
};
