import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CustomInput from '@/src/components/forms/CustomInput';
import { LoaderContainer } from '@/src/components/loader-container/LoaderContainer';
import CustomLoader from '@/src/components/custom-loader';
import { NUMBER_REGEX } from '@/src/utilities/helpers/constants';
import { ChargesFormProps } from '../types';
import CustomCurrencyInput from '@/src/components/forms/CustomCurrencyInput';

const ChargeForm = ({
  isEditMode = false,
  values,
  concepts,
  residents,
  errors,
  setFieldValue,
  t,
  handleSubmit,
  isLoading,
  isLoadingCharge,
}: ChargesFormProps) => (
  <Box component="form" onSubmit={handleSubmit} sx={{ marginY: '10px' }}>
    {isLoadingCharge ? (
      <LoaderContainer>
        <CustomLoader />
      </LoaderContainer>
    ) : (
      <Grid xs={12} container spacing={2} item>
        <Grid xs={12} md={6} lg={6} item>
          <Autocomplete
            id="concept"
            size="small"
            value={values?.concept || ''}
            getOptionLabel={(option) => option?.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id || value === ''}
            options={concepts ?? []}
            onChange={(e, value) => {
              setFieldValue('concept', value ?? '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t('concept')}
                placeholder={t('concept_form_field_placeholder', { ns: 'residents' })}
                error={errors.concept !== undefined}
                helperText={errors.concept?.toString()}
              />
            )}
          />
        </Grid>
        <Grid xs={12} md={6} lg={6} item>
          <Autocomplete
            id="resident"
            size="small"
            value={values?.resident || ''}
            disabled={isEditMode ?? true}
            getOptionLabel={(option) => (isEditMode ? option?.name : option?.person?.name) || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id || value === ''}
            options={
              isEditMode
                ? [{ id: values?.resident?.id, name: values?.resident?.name, surname: values?.resident?.surname }]
                : residents ?? []
            }
            onChange={(e, value) => {
              setFieldValue('resident', value ?? '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t('people_modal_text')}
                placeholder={t('resident', { ns: 'residents' })}
                error={errors.resident !== undefined}
                helperText={errors.resident?.toString()}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <CustomCurrencyInput
            field="amount"
            values={values}
            required
            label={t('amount')}
            setFieldValue={setFieldValue}
            errors={errors}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <CustomInput
            field="quantity"
            errors={errors}
            label={t('quantity')}
            setFieldValue={setFieldValue}
            values={values}
            inputType="number"
            autoFocus
            maxLength={50}
            required
            regex={NUMBER_REGEX}
          />
        </Grid>
        <Grid container item xs={12} alignItems="flex-end" justifyContent="flex-end">
          <LoadingButton type="submit" id="form-user" size="small" variant="contained" loading={isLoading}>
            {t(isEditMode ? 'save' : 'create')}
          </LoadingButton>
        </Grid>
      </Grid>
    )}
  </Box>
);

export default ChargeForm;
